import React from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { Contact } from '../../types/Contact'

export const OrderReceiver = ({ receiver }: { receiver?: Contact }) => (
  <>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='Informal caregiver (receiver) email'
        value={receiver?.email ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Tel. nr.'
        value={receiver?.phoneNumber ?? '-'}
      />
    </TbDashDetailedItemRow>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='First name'
        value={receiver?.firstname ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Last name'
        value={receiver?.lastname ?? '-'}
      />
    </TbDashDetailedItemRow>
  </>
)
