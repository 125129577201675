import 'reflect-metadata'
import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../../../../../common/redux/root.reducer"
import { OrderFinal } from "../../../types/OrderFinal"
import { validateObj } from "../../../../../common/utilities/validator"

export const selectOrderConceptState = (state: RootState) =>
  state.orderConcept

export const validOrder = createSelector(
  selectOrderConceptState,
  ({ orderConcept }): OrderFinal | false => {
    try {
      const order = validateObj(orderConcept, OrderFinal) as OrderFinal
      return order
    } catch (error) {
      console.error('Order form invalid: ', error)
      return false
    }
  }
)