import { IsInt, IsString, Min, MinLength } from "class-validator"

export class OrderRobot {
  @IsString()
  @MinLength(1)
  serial: string // 16 characters
  
  @IsInt()
  @Min(1)
  id: number

  @IsInt()
  @Min(1)
  boxNumber: number
}