import { createAsyncThunk } from '@reduxjs/toolkit'
import { IHttpService } from 'angular'
import { RootState } from '../../../../common/redux/root.reducer'
import {
  OrderOverviewQueryParams,
  OrderOverviewThunkPayload
} from '../../types/types'
import { mapOrderFinalsToTable } from '../tableMappers'
import { getOrders, retrieveConceptsRequest } from './api'

export const getOrderOverview = createAsyncThunk<
  OrderOverviewThunkPayload,
  { loadMore: boolean; urlParams: OrderOverviewQueryParams },
  { extra: { $http: IHttpService } }
>(
  'ordersOverview/getOverview',
  async ({ loadMore, urlParams: params }, thunkApi) => {
    const { orderId, ...urlParams } = { ...params }
    if (!urlParams.sortBy) {
      urlParams.sortBy = 'orderId'
      urlParams.sortOrder = 'desc'
    }
    urlParams.limit = loadMore ? 15 : 30
    const { orderOverview } = thunkApi.getState() as RootState
    const lastId = loadMore ? orderOverview.lastId : undefined
    if (lastId) {
      ;(urlParams as OrderOverviewQueryParams).lastId = lastId
    }
    const convertedParams = Object.fromEntries(
      Object.entries(urlParams).map(([key, value], i) => {
        if (key.includes('Ids')) {
          return [`${key}[]`, value]
        }
        return [key, value]
      })
    )
    const { data } = await getOrders(thunkApi.extra.$http, {
      params: convertedParams
    })
    const noMoreItems = urlParams.limit > data.length
    const lastItemInResponse = data.slice(-1)[0]
    const lastIdNew = noMoreItems ? null : lastItemInResponse.id
    const payload: OrderOverviewThunkPayload = {
      rows: data ? mapOrderFinalsToTable(data) : [],
      lastId: lastIdNew,
      loadMore
    }
    return payload
  }
)

export const retrieveConcepts = createAsyncThunk<
  any[],
  undefined,
  { extra: { $http: IHttpService } }
>(
  'ordersOverview/retrieveConcepts',
  async (_, thunkApi) => {
    const { data } = await retrieveConceptsRequest(thunkApi.extra.$http, {})
    return data
  }
)
