/**
 * @file Wrapper component in AngularJS for React overview page
 * @author Max van Loosbroek
 */

import template from './dashtransferPage.html'

const dashtransferPage = {
  bindings: {
    user: '<'
  },
  template,
  controller: /* @ngInject */ class DashtransferPageController {
    constructor (StoreService, $state, $location) {
      this.StoreService = StoreService
      this.$state = $state
      this.$location = $location
      this.urlParams = this.$state.params
    }
  }
}

export default dashtransferPage
