import template from './tb-pop-up-v2.html';
import './tb-pop-up-v2.scss';

const tbPopUpv2 = {
  bindings: {
    type: '<',
    scalable: '<',
    contentNr: '<',
    cancelButton: '@',
    submitButton: '@',
    position: '@',
    onSwitchTab: '&',
    onCancel: '&',
    onSubmit: '&',
    data: '<'
  },
  transclude: {
    tbPopUpMessage: '?tbPopUpMessage',
    tbPopUpTitle: '?tbPopUpTitle',
    tbPopUpTitle1: '?tbPopUpTitle1',
    tbPopUpTitle2: '?tbPopUpTitle2',
    tbPopUpBody: '?tbPopUpBody',
    tbPopUpBody1: '?tbPopUpBody1',
    tbPopUpBody2: '?tbPopUpBody2'
  },
  template,
  controller: /* @ngInject */ class NewTbPopUp {
    cancel () {
      this.onCancel({ $event: { data: this.data } })
    }

    openAnotherPopUp ({ info }) {
      this.onCancel({ $event: { info } })
    }

    saveData ({ data }) {
      this.data = data
    }

    submit () {
      this.onSubmit({ $event: { data: this.data } })
    }

    switchTab (contentNr) {
      const currentContentNr = this.contentNr
      const switchTab = {
        newContentNr: contentNr,
        currentContentNr: currentContentNr,
        data: this.data
      }
      this.onSwitchTab({ $event: { switchTab: switchTab } })
    }

    $onChanges (changes) {
      if (changes.data) {
        this.data = angular.copy(this.data)
      }
      !this.type && (this.type = 'info')
    }
  }
}

export default tbPopUpv2
