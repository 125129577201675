import * as FileSaver from 'file-saver'
import { Workbook } from 'exceljs'
import moment from 'moment'
import { isISO8601Date } from '../isDate/isISO8601Date'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

export function filterArrays (obj: any, path = '') {
    let arrays: any = []
    const noArrayObject: any = {}

    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            arrays.push({ [`${path}${key}`]: obj[key] })
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            const { arrays: nestedArrayObjects, noArrayObject: nestedFilteredObj } = filterArrays(
                obj[key],
                `${path}${key}.`
            )
            if (nestedArrayObjects.length > 0) {
                arrays = arrays.concat(nestedArrayObjects)
            }
            if (Object.keys(nestedFilteredObj).length > 0) {
                noArrayObject[key] = nestedFilteredObj
            }
        } else {
            noArrayObject[key] = obj[key]
        }
    }

    return { arrays, noArrayObject }
}

export function flattenObject (obj: any, parentKey = ''): any {
    let flattenedObj: any = {}

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const newKey = parentKey ? `${parentKey}.${key}` : key

            if (typeof obj[key] === 'object' && obj[key] !== null) {
                const nestedObj = flattenObject(obj[key], newKey)
                flattenedObj = { ...flattenedObj, ...nestedObj }
            } else {
                flattenedObj[newKey] = obj[key]
            }
        }
    }

    return flattenedObj
}

export function prependKeys (nestedObject: any, prefix: string): any {
    const newObj: any = {}

    for (const key in nestedObject) {
        if (nestedObject.hasOwnProperty(key)) {
            const newKey = prefix + key
            if (Array.isArray(nestedObject[key])) {
                newObj[newKey] = nestedObject[key].map((item: any) => {
                    if (typeof item === 'object' && item !== null) {
                        return prependKeys(item, '')
                    } else {
                        return item
                    }
                })
            } else if (typeof nestedObject[key] === 'object' && nestedObject[key] !== null) {
                newObj[newKey] = prependKeys(nestedObject[key], '')
            } else {
                newObj[newKey] = nestedObject[key]
            }
        }
    }

    return newObj
}

export function parseJson (jsonData: any, parentData: any, table: any[]) {
    const { arrays, noArrayObject } = filterArrays(jsonData)
    if (arrays.length > 1) {
        const keyNames: string[] = []
        for (const array of arrays) {
            keyNames.push(Object.keys(array)[0])
        }
        const arraysString = keyNames.join(', ')
        throw new Error(
            `Can not convert data to table due to multiple conflicting arrays. The properties ${arraysString} must be in seperate queries to export to Excel.`
        )
    } else if (arrays.length === 0) {
        const newItem = { ...parentData, ...flattenObject(noArrayObject) }
        table.push(newItem)
    } else {
        const parent = { ...parentData, ...flattenObject(noArrayObject) }
        const arrayKey = Object.keys(arrays[0])[0]
        const arrayValues = arrays[0][arrayKey]
        if (arrayValues.length > 0) {
            for (const item of arrayValues) {
                if (typeof item === 'object') {
                    const parseItem = prependKeys(item, `${arrayKey}.`)
                    table = parseJson(parseItem, parent, table)
                } else {
                    const newItem = { ...parent }
                    newItem[arrayKey] = item
                    table.push(newItem)
                }
            }
        } else {
            const newItem = { ...parentData, ...flattenObject(noArrayObject) }
            table.push(newItem)
        }
    }
    return table
}

export function validate (jsonData: any): {
    valid: boolean
    error?: string
} {
    try {
        parseJson(jsonData, {}, [])
        return {
            valid: true
        }
    } catch (error) {
        return {
            valid: false,
            error: error.message
        }
    }
}

export function getColumnKeys (objects: any[]) {
    const columns: { [key: string]: string } = {}
    const getObjectColumns = (object: any, columns: { [key: string]: string }) => {
        Object.entries(object).forEach(([key, value]) => {
            const type = value === null || value === undefined ? 'empty' : typeof value
            switch (type) {
                case 'object':
                    throw Error('should not be used on nested objects')
                default:
                    if (!columns[key]) {
                        columns[key] = key
                    }
                    break
            }
        })
    }
    objects.forEach(object => getObjectColumns(object, columns))
    return Object.keys(columns)
}

export function createSheet ({
    workbook,
    items,
    columnKeys,
    sheetName
}: {
    workbook: Workbook
    items: any[]
    columnKeys: string[]
    sheetName?: string
}) {
    const subs = workbook.addWorksheet(sheetName ? sheetName : 'sheet-1')
    const columns: { header: string; key: string; width: number }[] = columnKeys.map(key => ({
        header: key,
        key,
        width: key?.length * 0.9
    }))
    subs.columns = columns

    for (const [index, item] of items.entries()) {
        const row = subs.getRow(index + 2)
        columnKeys.forEach(key => {
            const value = item[key] ?? null
            const isDate = isISO8601Date(value)
            row.getCell(key).value = isDate ? new Date(value) : value
        })
    }
}

export function createReport (data: any, sheetName?: string): Workbook {
    const workbook = new Workbook()
    workbook.creator = 'Tinybots'
    workbook.lastModifiedBy = 'Tinybots'
    workbook.created = new Date()
    workbook.modified = new Date()
    const items = parseJson(data, {}, [])
    const columnKeys = getColumnKeys(items)
    createSheet({ workbook, items, columnKeys, sheetName })
    return workbook
}

export const exportToSpreadsheet = async (inputData: any, fileName: string, sheetName: string) => {
    const workbook = createReport(inputData, sheetName)
    const buffer: Buffer = (await workbook.xlsx.writeBuffer()) as Buffer
    const fileData = new Blob([buffer], { type: fileType })
    FileSaver.saveAs(fileData, fileName + fileExtension)
}
