/**
 * @file Table for displaying the subs that are part of a chain
 * @author Max van Loosbroek
 */

import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { TbDashItemLabel } from 'tinybots-react-components'
import { getSubRef } from '../../../stringTransformers'
import { SubscriptionDetailed } from '../../../types/types'

const useStyles = makeStyles(() => {
  return createStyles({
    cell: {
      fontSize: 10,
      fontFamily: 'Roboto-Mono-Light',
      color: 'black',
      padding: 2
    },
    row: {
      cursor: 'pointer'
    },
    rowActive: {
      cursor: 'pointer',
      background: '#dfdfdf'
    }
  })
})

const columns: {
  key: keyof SubscriptionDetailed
  headerName: string
}[] = [
  {
    key: 'subscriptionReference',
    headerName: 'Subscription ref.'
  },
  {
    key: 'paymentReference',
    headerName: 'Payment ref.'
  },
  {
    key: 'subscriptionLength',
    headerName: 'Sub. length'
  },
  {
    key: 'startAt',
    headerName: 'Start date'
  },
  {
    key: 'endAt',
    headerName: 'End date'
  }
]

export interface SusbcriptionsTableProps {
  onSelectRow: (sub: SubscriptionDetailed) => void
  subs: SubscriptionDetailed[]
  activeSub: number
}

const SusbcriptionsTable = ({
  onSelectRow,
  subs,
  activeSub
}: SusbcriptionsTableProps) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <div style={{ position: 'relative', paddingTop: 10, marginLeft: 10 }}>
        <TbDashItemLabel>Subscription history</TbDashItemLabel>
        <div style={{ maxHeight: 270, overflowY: 'auto' }}>
          <Table stickyHeader aria-label='Subscription Table'>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    className={classes.cell}
                    key={`headerrow_column-${column.key}`}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subs.map(s => (
                <TableRow
                  className={
                    s.subscriptionId === activeSub
                      ? classes.rowActive
                      : classes.row
                  }
                  onClick={() => onSelectRow(s)}
                  key={`row_${s.subscriptionId}`}
                >
                  <TableCell
                    className={classes.cell}
                    key={`row_${s.subscriptionId}-column-subscriptionReference`}
                  >
                    {getSubRef(s)}
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    key={`row_${s.subscriptionId}-column-${columns[1].key}`}
                  >
                    {s[columns[1].key]}
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    key={`row_${s.subscriptionId}-column-${columns[2].key}`}
                  >
                    {s[columns[2].key]}
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    key={`row_${s.subscriptionId}-column-startAt`}
                  >
                    {s.startAt ? moment(s.startAt).format('DD-MM-YYYY') : '-'}
                  </TableCell>
                  <TableCell
                    className={classes.cell}
                    key={`row_${s.subscriptionId}-column-endDAte`}
                  >
                    {s.endAt ? moment(s.endAt).format('DD-MM-YYYY') : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Grid>
  )
}

export default SusbcriptionsTable
