import React, { useCallback, useEffect, useState } from 'react'
import { Org } from '../../../organisations/OrganisationAccounts/types'
import { ButtonBase, TextField, Tooltip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { copyStringToClipboard } from '../../../../common/utilities/dom'
import { useSafeAsync } from 'tinybots-react-components/lib/components/hooks'

const Icon = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    return (
        <div
            style={{
                flexShrink: 0,
                width: 36,
                height: 36,
                display: 'block',
                marginLeft: 'auto',
                marginRight: 0,
                background: "url('assets/content_copy_FILL0_wght400_GRAD0_opsz24.svg') no-repeat 50%"
            }}
            aria-label='Item Icon'
            {...props}
        />
    )
}

export const OrganisationsSearch = ({ organisations }: { organisations: Org[] }) => {
    const [id, setId] = useState<number>()
    const [copiedMessage, setShowCopiedMessage] = useState<boolean>(false)
    const safeAsync = useSafeAsync()
    const onSelect = useCallback((_, value) => {
        setId(undefined)
        if (value) {
            setId(value.relationId)
            copyStringToClipboard(value.relationId)
            setShowCopiedMessage(true)
        }
    }, [])
    const copy = useCallback(() => {
        if (id) {
            copyStringToClipboard(id.toString())
            setShowCopiedMessage(true)
        }
    }, [id])
    const hideToolTip = useCallback(
        () => safeAsync(new Promise(resolve => setTimeout(resolve, 2000)).then(() => setShowCopiedMessage(false))),
        []
    )
    useEffect(() => {
        if (copiedMessage) {
            hideToolTip()
        }
    }, [copiedMessage])
    return (
        <Tooltip
            PopperProps={{
                disablePortal: true
            }}
            placement='bottom-end'
            open={copiedMessage}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title='Copied to clipboard'
        >
            <div style={{ position: 'relative', marginLeft: 'auto' }}>
                <Autocomplete
                    aria-label='Organisations Autocomplete Select'
                    options={organisations ?? []}
                    getOptionLabel={option => `${option.relationId} - ${option.name}`}
                    style={{ width: 500, marginTop: 10 }}
                    renderInput={params => {
                        return <TextField {...params} placeholder='Search and copy relation id of organisation' />
                    }}
                    onChange={onSelect}
                    renderOption={(option: Org) => (
                        <li style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <div style={{ flexShrink: 1 }}>
                                {option.relationId} - {option.name}
                            </div>
                            <Icon />
                        </li>
                    )}
                />
                {id && (
                    <ButtonBase style={{ position: 'absolute', right: 50, bottom: -2 }} onClick={copy}>
                        <Icon />
                    </ButtonBase>
                )}
            </div>
        </Tooltip>
    )
}
