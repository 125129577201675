const Dymo = require('dymojs');
import { cpuLabel } from './templates/v1-cpu-serial.label';
import { boxLabel } from './templates/v1-box-label.label';
import { v2MacLabel } from './templates/v2-mac-addresses.label';
const mustache = require('mustache');
const numeral = require('numeral');

export class TbDymo {
  private dymo: any
  public connect() {
    this.dymo = new Dymo({
      hostname: '127.0.0.1'
    });
  }

  public async isConnected () {
    try {
      const res: string = await this.dymo.getPrinters()
      const connected = res.includes('<IsConnected>True')
      return connected
    } catch (err) {
      console.error('Error checking connection', err)
      return false
    }
  }

  private async printLabel (labelTemplate: string, variables: any, count = 1) {
    const rendered = mustache.render(labelTemplate, variables);
    for (let i = 0; i < count; i++) {
      await this.dymo.print('DYMO LabelWriter 450', rendered);
    }
  }

  private postFix(value: string) {
    return value.substr(value.length - 4);
  }

  private preFix(value: string) {
    return value.substr(0, value.length - 4);
  }

  public async printSerial(cpuSerial: string) {
    const templateVariables = {
      cpu_serial: cpuSerial,
      cpu_serial_postfix: this.postFix(cpuSerial),
      cpu_serial_prefix: this.preFix(cpuSerial)
    }
    await this.printLabel(cpuLabel, templateVariables)
  }

  public async printBox(boxNumber: number) {
    const templateVariables = {
      shipment_number: numeral(boxNumber).format('000000'),
    }
    await this.printLabel(boxLabel, templateVariables)
  }

  public async printMac(ethMacAddress: string, wlanMacAddress: string) {
    const templateVariables = {
      eth_mac: ethMacAddress,
      wlan_mac: wlanMacAddress
    }
    await this.printLabel(v2MacLabel, templateVariables)
  }
}