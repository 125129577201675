import 'class-transformer'

import { OrderBody } from "../types/types"
import { OrderConcept } from '../types/OrderConcept'
import { Requester } from '../../../types/Requester'

export const mapOrderBody = ({
  id,
  clientId,
  clientUuid,
  relation: { relationId, id: relationIdFallback },
  tessaExpertNeeded,
  deliveryAddress,
  teamId,
  discipline,
  healthCareDemand,
  notes,
  internalNotes,
  requester,
  receiver,
  healthcareProfessional,
  orderConceptId,
  integration
}: OrderConcept): OrderBody => {
  return {
    conceptOrderId: id,
    clientId,
    clientUuid,
    relationId: relationId ?? relationIdFallback,
    integrationId: integration?.id,
    tessaExpertNeeded: tessaExpertNeeded as 'yes' | 'no' | 'unknown',
    deliveryAddress,
    teamId,
    discipline,
    healthCareDemand,
    notes,
    internalNotes,
    requester: requester as Requester,
    receiver,
    healthcareProfessional,
    orderConceptId
  }
}