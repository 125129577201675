const tbIntegerValidation = /* @ngInject */ () => {
  const isValid = function (str) {
    const reg = /^\d+$/
    return !str || reg.test(str)
  }

  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ngModelCtrl) => {
      ngModelCtrl.$parsers.unshift((viewValue) => {
        if (viewValue === undefined) return ''

        const transformedInput = viewValue.replace(/[^0-9]/g, '')
        if (transformedInput !== viewValue) {
          ngModelCtrl.$setViewValue(transformedInput)
          ngModelCtrl.$render()
        }
        ngModelCtrl.$setValidity('integer', isValid(transformedInput))

        return transformedInput
      })

      ngModelCtrl.$formatters.unshift((modelValue) => {
        ngModelCtrl.$setValidity('integer', isValid(modelValue))
        return modelValue
      })
    }
  }
}

export default tbIntegerValidation
