import { createSelector } from '@reduxjs/toolkit'
import fastDeepEqual from 'fast-deep-equal'
import moment from 'moment'
import { RootState } from '../../../../common/redux/root.reducer'
import { taasPatchSelector } from '../../../overview/Detailed/redux/selectors'
import { getStatusString } from '../../overview/tableMappers'
import { OrdersDetailedState } from '../../types/types'
import {
  canAccept,
  canAcceptReturn,
  canDecline,
  canDeliver,
  canRejectReturn,
  canSetReturned,
  canShip
} from './statusHelpers'

export const selectOrderDetailedState = (state: RootState) =>
  state.ordersDetailed

export const willAccept = createSelector(
  selectOrderDetailedState,
  ({ originalOrder, statusString }) => {
    return canAccept(originalOrder?.status) && statusString === 'processing'
  }
)

export const willDecline = createSelector(
  selectOrderDetailedState,
  ({
    originalOrder,
    order,
    statusString
  }) => {
    return (
      canDecline(originalOrder?.status) &&
      statusString === 'declined' &&
      !!order?.declineReason
    )
  }
)

export const willShip = createSelector(
  selectOrderDetailedState,
  ({
    originalOrder,
    order,
    statusString
  }) => {
    const shipStatus =
      statusString === 'shipping' ||
      statusString === 'shipped' ||
      originalOrder?.status?.shippedAt !== order?.status?.shippedAt
    const hasShipDate =
      !!order?.status?.shippedAt && moment(order?.status?.shippedAt).isValid()
    return canShip(originalOrder?.status) && hasShipDate && shipStatus
  }
)

export const willDeliver = createSelector(
  selectOrderDetailedState,
  ({ originalOrder, statusString }) => {
    return canDeliver(originalOrder?.status) && statusString === 'delivered'
  }
)

export const willAcceptReturn = createSelector(
  selectOrderDetailedState,
  ({ originalOrder, statusString }) => {
    return canAcceptReturn(originalOrder?.status) && statusString === 'returnAccepted'
  }
)

export const willRejectReturn = createSelector(
  selectOrderDetailedState,
  ({
    originalOrder,
    order,
    statusString
  }) => {
    return (
      canRejectReturn(originalOrder?.status) &&
      statusString === 'returnRejected' &&
      !!order?.returnRejectReason
    )
  }
)

export const willSetReturned = ({
  detailed,
  ordersDetailed: { originalOrder }
}: RootState) => {
  const taasPatch = taasPatchSelector(detailed)
  return canSetReturned(originalOrder) && taasPatch?.endAt !== undefined
}

export const willAddTrackTrace = createSelector(
  selectOrderDetailedState,
  ({
    originalOrder,
    order: { trackTraceCode }
  }) => {
    const hasACode = trackTraceCode || originalOrder?.trackTraceCode
    const changes = trackTraceCode !== originalOrder?.trackTraceCode
    const hasChanges = hasACode && changes
    return hasChanges
  }
)

export const hasStateChanges = ({
  originalOrder,
  order,
  statusString
}: OrdersDetailedState) => {
  const orderChanged = !fastDeepEqual(originalOrder, order)
  const statusChanged = getStatusString(originalOrder?.status) !== statusString
  return statusChanged || orderChanged
}

export const validTrackTrace = createSelector(
  selectOrderDetailedState,
  ({ order }) => {
    return !order?.trackTraceCode || /^3S[A-Z]+[0-9]+$/.test(order?.trackTraceCode)
  }
)

export const statusChanged = createSelector(
  selectOrderDetailedState,
  ({ originalOrder, statusString }) => {
    const status = originalOrder?.status
    return statusString !== getStatusString(status)
  }
)

export const stateStatusString = createSelector(
  selectOrderDetailedState,
  ({ statusString }) => {
    return statusString
  }
)

export const validStatusChange = (state: RootState) => {
  return (
    willAccept(state) ||
    willDecline(state) ||
    willShip(state) ||
    willDeliver(state) ||
    willAcceptReturn(state) ||
    willRejectReturn(state) ||
    willSetReturned(state)
  )
}

export const orderFormValidSelector = createSelector(
  statusChanged,
  validStatusChange,
  validTrackTrace,
  (statusHasChanged, statusChangeValid, trackTraceValid) => {
    return (!statusHasChanged || statusChangeValid) && trackTraceValid
  }
)
