/**
 * @file Wrapper of header with search component for the overview page
 * @author Max van Loosbroek
 */

import {
  Checkbox,
  createStyles,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Radio
} from '@material-ui/core'
import React from 'react'
import { OverviewColumn, OverviewFilter } from '../types/types'
import { useDispatch } from 'react-redux'
import { hideColumn, showColumn } from '../redux/reducer'
import { OverviewUrlParams } from '../Overview/Overview'
import { OVERVIEW_FILTERS } from '../constants'
import { SearchType } from 'tinybots-react-components/lib/components/DashboardComponents/Search'
import { TbDashHeader, TbDashSearch } from 'tinybots-react-components'
import { groupParamsByKey } from '../../../common/utilities/groupParamsByKey'
import { useState } from 'react'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import { useEffect } from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    toggles: {
      height: '100%'
    },
    formRow: {
      flexWrap: 'nowrap',
      height: '50%',
      alignItems: 'center'
    },
    formRowTitle: {
      fontFamily: 'Montserrat-Bold',
      color: 'white',
      fontSize: 12,
      width: 70,
      paddingTop: 13
    },
    label: {
      fontSize: 11,
      color: 'white'
    },
    control: {
      width: 55,
      marginLeft: 5
    },
    checkbox: {
      padding: 0,
      color: 'white',
      '&$checked': {
        color: 'white'
      }
    }
  })
)

export const overviewSearchItems: SearchType[] = [
  {
    searchLabel: 'relation & robot',
    searchType: 'robotRelation',
    placeholder: 'Search for relation or robot'
  },
  {
    searchLabel: 'box',
    searchType: 'boxNumber',
    placeholder: 'Search for box number'
  },
  {
    searchLabel: 'payment ref.',
    searchType: 'paymentReference',
    placeholder: 'Search for payment reference'
  },
  {
    searchLabel: 'Team or client id',
    searchType: 'teamClient',
    placeholder: 'Search by team or client'
  },
  {
    searchLabel: 'iccId',
    searchType: 'iccId',
    placeholder: 'Search by iccId'
  }
]

export interface OverviewHeaderProps {
  params: OverviewUrlParams
  columns: OverviewColumn[]
  setUrlParams: (params: OverviewUrlParams) => void
  onSubmit: (props: { searchTerm: string; searchType: string }) => void
  color: string
}

export const handleFilter = (
  { filterType, filterTerm }: OverviewFilter,
  setUrlParams: (params: OverviewUrlParams) => void,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(window.location.search)
  if (filterTerm === 'robotRelation') {
    currentUrlParams.delete(filterType)
  } else {
    currentUrlParams.set(filterType, filterTerm)
  }
  setUrlParams(groupParamsByKey(currentUrlParams as any) as OverviewUrlParams)
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const OverviewHeader: React.FunctionComponent<OverviewHeaderProps> = ({
  onSubmit,
  params,
  setUrlParams,
  columns,
  color
}) => {
  const previousParams = usePrevious(params)
  const [searchType, setSearchType] = useState(params.searchType)
  const [searchTerm, setSearchTerm] = useState(params.searchTerm)
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleToggleColumn = (c: OverviewColumn) => {
    if (c.hidden) {
      dispatch(showColumn(c.key))
    } else {
      dispatch(hideColumn(c.key))
    }
  }

  useEffect(() => {
    if (previousParams?.searchTerm !== params?.searchTerm) {
      setSearchTerm(params.searchTerm)
      }
    if (previousParams?.searchType !== params?.searchType) {
      setSearchType(params.searchType)
    }
  }, [previousParams, params])

  return (
    <TbDashHeader color={color}>
      <TbDashSearch
        color={color}
        searchTerm={searchTerm}
        searchType={searchType}
        items={overviewSearchItems}
        onSubmit={onSubmit}
        setActiveSearchType={(type) => setSearchType(type.searchType)}
        setSearchTerm={setSearchTerm}
      ></TbDashSearch>
      <div className={classes.toggles} aria-label='Overview Settings'>
        <FormGroup row className={classes.formRow}>
          <span className={classes.formRowTitle}>Filters: </span>
          {OVERVIEW_FILTERS.map((f, i) => {
            return (
              <FormControlLabel
                key={`filter-${i}`}
                label={f.label}
                labelPlacement='top'
                classes={{ label: classes.label, root: classes.control }}
                control={
                  <Radio
                    className={classes.checkbox}
                    checked={
                      params[f.filterType] === f.filterTerm ||
                      (!params[f.filterType] &&
                        f.filterTerm === 'robotRelation')
                    }
                    onChange={() => handleFilter(f, setUrlParams)}
                    value='a'
                    color='default'
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
              />
            )
          })}
        </FormGroup>
        <FormGroup row className={classes.formRow}>
          <span className={classes.formRowTitle}>Overview: </span>
          {columns.map((c, i) => {
            return (
              <FormControlLabel
                key={`column-${c.key}`}
                label={c.headerName}
                labelPlacement='top'
                classes={{ label: classes.label, root: classes.control }}
                control={
                  <Checkbox
                    checked={!c.hidden}
                    onChange={() => handleToggleColumn(c)}
                    className={classes.checkbox}
                    color='default'
                  />
                }
              />
            )
          })}
        </FormGroup>
      </div>
    </TbDashHeader>
  )
}
