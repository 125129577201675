import template from './error.html'

const errorWindow = {
  binding: {
    type: '@'
  },
  template
}

export default errorWindow
