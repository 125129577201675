import qrcode from 'qrcode-generator'
export const generateQr = (qrSecret: string, email: string) => {
    const typeNumber = 0
    const errorCorrectionLevel = 'L'
    const qr = qrcode(typeNumber, errorCorrectionLevel)
    qr.addData(
        `otpauth://totp/Tinybots%20Admin%20Dashboard:${email}?secret=${qrSecret}&issuer=Tinybots%20Admin%20Dashboard`
    )
    qr.make()
    return qr.createImgTag(5, 20)
}
