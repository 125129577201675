import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import React, { useCallback, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { retrieveConcepts } from '../redux/thunks';
const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: 'white'
    },
    tooltipLabel: {
      textWrap: 'nowrap'
    }
  })
)

export const OrderOverviewSpeedDial = ({ handleCreate, handleReturn }: { handleCreate: () => void, handleReturn: () => void }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  const toggle = useCallback(() => {
    setOpen(!open)
  }, [setOpen, open])
  const retrieve = useCallback(async () => {
    handleClose()
    dispatch(retrieveConcepts())
  }, [handleClose])
  const addOrder = useCallback(() => {  
    handleClose()
    handleCreate()
  }, [handleClose])
  const addReturn = useCallback(() => {
    handleClose()
    handleReturn()
  }, [handleClose])
  const actions = [
    { icon: <img src='assets/tb-db-icon-add-return.svg' style={{ width: 28 }} />, name: 'Add return', onClick: addReturn },
    { icon: <img src='assets/tb-db-icon-add-return-center.svg' style={{ width: 28 }} />, name: 'Add order', onClick: addOrder },
    { icon: <img src='assets/tb-db-icon-retrieve-latest.svg' style={{ width: 24 }} />, name: 'Retrieve latest', onClick: retrieve }
  ];
  return (
    <SpeedDial
      ariaLabel='SpeedDial example'
      icon={<SpeedDialIcon classes={{ icon: classes.icon }} />}
      onClose={handleClose}
      onClick={toggle}
      open={open}
      direction={'up'}
      style={{ position: 'absolute', bottom: 30, right: 30 }}
    >
      {actions.map(action => (
        <SpeedDialAction
          FabProps={{ 'aria-label': `Action ${action.name}` }}
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={action.onClick}
          classes={{ staticTooltipLabel: classes.tooltipLabel }}
        />
      ))}
    </SpeedDial>
  )
}
