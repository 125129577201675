/**
 * @file Wrapper module in AngularJS for routing to dashtransfer page
 * @author Max van Loosbroek
 */

import './Dashtransfer/Dashtransfer.module'
import StoreService from '../../common/redux/store.service'
import dashtransferPage from './dashtransferPage.component'
import { Dashtransfer } from './Dashtransfer/Dashtransfer';

export default angular
  .module('components.dashtransferPage', ['components.tb.dashtransferReact'])
  .component('dashtransferPage', dashtransferPage)
  .service('StoreService', StoreService)
  .config($stateProvider => {
    $stateProvider.state('dashtransfer', {
      parent: 'app',
      url: `^/dashtransfer/?
        searchType&
        searchValue&
        sort&
        sort_order&
        limit&
        token`,
      component: 'dashtransferPage',
      reloadOnSearch: false,
      params: {},
      resolve: {}
    })
  }).name
