import template from './customQueryPage.html'
import './customQueryPage.scss'
import { DEFAULT_QUERY } from './DEFAULT_QUERY'

const customQueryPage = {
    bindings: {
        result: '<',
    },
    template,
    controller: /* @ngInject */ class ProfileController {
        constructor ($state, $q, $http, $scope, URLS) {
            this.$state = $state
            this.$q = $q
            this.$scope = $scope
            this.$http = $http
            this.URLS = URLS
        }

        $onInit () {
            const storedTheme = localStorage.getItem('graphiql:theme')
            if (!storedTheme) {
                localStorage.setItem('graphiql:theme', 'light')
            }
            const root = ReactDOM.createRoot(document.getElementById('graphiql'))
            const fetcher = async graphQLParams => {
                const res = await this.$http
                    .post(`${this.URLS.admin.graphql}`, JSON.stringify(graphQLParams), {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((res) => {
                        return res
                    })
                    .catch(e => e)
                if (!res.data) {
                    // Error handling
                } else if (!res.data.data?.__schema) {
                    this.$scope.$apply(() => {
                        this.result = { dateTime: new Date().getTime(), queryName: graphQLParams.operationName, ...res.data }
                    })
                }
                return res.data
            }
            const explorerPlugin = GraphiQLPluginExplorer.explorerPlugin()
            root.render(
                React.createElement(GraphiQL, {
                    style: {
                        height: 1000
                    },
                    fetcher,
                    defaultQuery: DEFAULT_QUERY,
                    defaultEditorToolsVisibility: true,
                    plugins: [explorerPlugin]
                })
            )
        }
    }
}

export default customQueryPage
