/**
 * @file Angular service to be able to access store in the whole app
 * @author Max van Loosbroek
 */

/* @ngInject */
import { initStore } from './store';
import { IHttpService } from 'angular';
export default class StoreService {
  store: ReturnType<typeof initStore>

  constructor ($http: IHttpService) {
    'ngInject';
    this.store = initStore($http)
  }
}
