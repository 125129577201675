/**
 * @file Utilize different events to detect a long press and excecute a callback funtion after a preset time in ms.
 * @author Max van Loosbroek
 */


import { useState, useEffect, useCallback } from 'react';

export default function useLongPress(callback = () => { }, ms = 300) {
    const [startLongPress, setStartLongPress] = useState(false);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (startLongPress) {
            timerId = setTimeout(callback, ms);
        } else {
            clearTimeout(timerId);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [callback, ms, startLongPress]);

    const start = useCallback(() => {
        setStartLongPress(true);
    }, []);
    const stop = useCallback(() => {
        setStartLongPress(false);
    }, []);

    return {
        onMouseDown: ms > 0 ? start : callback,
        onMouseUp: stop,
        onMouseLeave: stop,
        onTouchStart: ms > 0 ? start : callback,
        onTouchEnd: stop,
    };
}
