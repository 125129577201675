import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './vocabulary/en';

const translationMaintenanceMode = process.env.NODE_ENV !== 'production' && JSON.parse(localStorage.getItem('debugLang'));

const resources = {
  en: { translation: en }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    keySeparator: '.',
    debug: translationMaintenanceMode,
    fallbackLng: translationMaintenanceMode ? false : 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
