import './tb-core/tb-core.module'
import './utilities/utilities.module'

import appComponent from './app.component'

export const app = angular
  .module('common', [
    'common.tb.core',
    'common.utilities',
  ])
  .component('app', appComponent)
  .config($stateProvider => {
    $stateProvider
      .state('app', {
        url: '/app',
        redirectTo: 'overview',
        component: 'app',
        data: { requiredAuth: true }
      })
  })
  .name
