import template from './organisations.html'
import './organisations.scss'

const filterKeys = ['name']

const organisation = {
  require: {
    parent: '^^app'
  },
  bindings: {
    subscriptionId: '<'
  },
  template,
  controller: /* @ngInject */ class OrganisationController {
    constructor(SubscriptionService, RelationsService, RobotsService, NotesService, AuthService, $state, $q, $location, $scope) {
      this.SubscriptionService = SubscriptionService
      this.RelationsService = RelationsService
      this.RobotsService = RobotsService
      this.NotesService = NotesService
      this.AuthService = AuthService
      this.$state = $state
      this.$scope = $scope
      this.$q = $q
      this.$location = $location
      this.filteredOrganisations = undefined
      this.filterItems = [{
        name: 'RELATION',
        searchParam: 'name'
      }]
    }

    $onInit() {
      this.addSubscriptionRelations = []
      this.popUps = {
        addSubscriptions: {
          visible: false,
          type: 'addSubscriptions',
          params: {
            relationWarning: null,
            subscriptionLengthWarning: null,
            paymentReferenceWarning: null,
            numberOfSubscriptionsWarning: null
          }
        },
        subsAdded: {
          visible: false,
          type: 'subsAdded',
          params: {
            count: null,
            relation: null,
            paymentReference: null
          }
        },
        organisation: {
          visible: false,
          type: 'organisation',
          params: {
            organisation: null
          }
        },
        detailedCancel: {
          visible: false,
          type: 'cancel'
        },
        requestFailed: {
          visible: false,
          type: 'warning',
          params: {
            errorMessage: null,
            errorTitle: null
          }
        }
      }
      this.sortParams = {
        relation: {
          path: 'name',
          sort: true
        },
        // servicePackage: 'basic' | 'TaaS';
        service: {
          path: 'servicePackage',
          sort: false
        },
        // premiumPackage: null | 'robotPremium' | 'beheerPremium' | 'proVersion';
        premiumPackage: {
          path: 'premiumPackage',
          sort: false
        },
        '# robots': {
          path: 'robots',
          sort: false
        },
        '# subs': {
          path: 'subscriptions',
          sort: false
        },
        addSubscriptions: {
          path: 'addSubscriptions',
          sort: false,
          noSort: true
        },
        notes: {
          path: 'notes',
          sort: false
        }
      }
      this.tableType = 'organisations'
      this.getOrganisations().then(() => {
        const search = this.$state.params
        const relationId = search.relationId && parseInt(search.relationId)
        if(relationId) {
          const org = this.RelationsService.organisations.find(o => o.relationId === relationId)
          org && this.showDetails({item: org})
        }
      })
    }

    updateFilterUrl(key, value) {
      this.$location.search(key, value)
      filterKeys.forEach(k => {
        if (k !== key) {
          this.$location.search(k, null)
        }
      })
    }

    getFilterFromUrl() {
      const search = this.$state.params
      filterKeys.forEach(key => {
        const term = search[key]
        if (term) {
          this.searchTerm = term
          this.searchType = key === 'robotSerial' ? 'serial' : key
        }
      })
    }

    getSubsParams(chainIds) {
      const link = chainIds.map(id => `chainIds=${id}`).join('&')
      return link
    }

    getTaasParams(taasIds) {
      const link = taasIds.map(id => `taasIds=${id}`).join('&')
      return link
    }

    filterCondition(overview, param) {
      const item = overview[param]
      return item && item.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
    }

    filterOrganisations({ searchTerm }) {
      this.searchTerm = searchTerm.term
      this.searchType = searchTerm.type
      this.updateFilterUrl(this.searchType, this.searchTerm)
      if ((searchTerm.term === null) || searchTerm.term.length < 1) {
        this.filteredOrganisations = this.RelationsService.organisations
      } else {
        this.filteredOrganisations = this.RelationsService.organisations.filter(organisations => (this.filterCondition(organisations, this.searchType)))
      }
    }

    getOrganisations() {
      this.showPreloader = true
      return this.RelationsService.getOrganisations()
        .then(response => {
          this.getFilterFromUrl()
          if (this.searchTerm) {
            this.filterOrganisations({
              searchTerm: {
                term: this.searchTerm,
                type: this.searchType
              }
            })
          } else {
            this.filteredOrganisations = response.data
          }
        })
        .catch(errorRes => {
          this.errorPopUp(errorRes)
          this.showPreloader = false
          throw errorRes
        })
        .finally(() => {
          this.showPreloader = false
        })
    }

    showDetails({ item }) {
      const params = {
        item
      }
      this.$location.search('relationId', item.relationId)
      this.showPopUp('organisation', params)
      this.popUps.organisation.params.contentNr = 1
    }

    hasChanges(originalOrg, updatedOrg) {
      let changes = false
      if (!updatedOrg || !originalOrg) return false
      if (updatedOrg.notes !== undefined && updatedOrg.notes !== originalOrg.notes) changes = true
      if (updatedOrg.servicePackage !== undefined && updatedOrg.servicePackage !== originalOrg.servicePackage) changes = true
      if (updatedOrg.premiumPackage !== undefined && updatedOrg.premiumPackage !== originalOrg.premiumPackage) changes = true
      return changes
    }

    cancelDetailed({ data }) {
      const updatedOrg = data && data.relation
      const orgId = updatedOrg && updatedOrg.relationId
      const originalOrg = orgId ? this.RelationsService.organisations.find(r => r.relationId === orgId) : undefined
      if (!data || !this.hasChanges(originalOrg, updatedOrg)) {
        this.hidePopUp('organisation')
        this.$location.search('relationId', null)
      } else {
        this.leaveFunction = () => { this.hidePopUp('detailedCancel'); this.hidePopUp('organisation'); this.$location.search('relationId', null) }
        this.stayFunction = () => { this.hidePopUp('detailedCancel') }
        this.showPopUp('detailedCancel')
      }
    }

    switchOrgTab({ switchTab }) {
      const newType = switchTab.newContentNr === 1 ? 'addSubscriptions' : 'addTaas'
      this.popUps.addSubscriptions.params.data = switchTab.data
      this.popUps.addSubscriptions.params.contentNr = switchTab.newContentNr
      this.popUps.addSubscriptions.type = newType
    }

    switchTabDetailed({ switchTab }) {
      const newType = switchTab.newContentNr === 1 ? 'general' : 'accounts'
      this.popUps.organisation.params.data = switchTab.data
      this.popUps.organisation.params.contentNr = switchTab.newContentNr
      this.popUps.organisation.type = newType
    }

    // Add new subscriptions /////////////////////////////////
    addSubscriptions({ item }) {
      this.showPopUp('addSubscriptions', { item })
      this.popUps.addSubscriptions.params.contentNr = 1
    }

    patchOrganisation({ data }) {
      const updatedOrg = data ? data.relation : undefined
      const orgId = updatedOrg && updatedOrg.relationId
      const originalOrg = orgId ? this.RelationsService.organisations.find(r => r.relationId === orgId) : undefined
      if(!data || !this.hasChanges(originalOrg, updatedOrg)) {
        return this.hidePopUp('organisation')
      }
      this.showPreloader = true
      this.RelationsService.patchOrganisation(data.relation, data.relation.relationId).then(() => {
        this.showPreloader = false
        this.hidePopUp('organisation')
        this.getOrganisations()
      })
      .catch(errorRes => {
        this.showPreloader = false
        this.showPopUp('requestFailed', {
          errorTitle: `${errorRes.status} ${errorRes.statusText}`,
          errorMessage: errorRes.data ? errorRes.data.message : `Unknown error`
        })
      })
      .finally(() => {
        this.showPreloader = false
      })
    }

    clearAddSubscriptionWarning({ name }) {
      this.popUps.addSubscriptions.params[name] = null
    }

    getRelations({ searchTerm }) {
      return this.RelationsService.getRelationsByName(searchTerm).then(response => {
        this.addSubscriptionRelations = response.data
      }).catch(errorRes => {
        this.showPopUp('requestFailed', {
          errorTitle: `${errorRes.status} ${errorRes.statusText}`,
          errorMessage: errorRes.data.message
        })
      })
    }

    createSubscriptions({ data }) {
      const type = this.popUps.addSubscriptions.type
      if(type === 'addSubscriptions') {
        this.createRegularSubscriptions({data})
      } else {
        this.createTaasSubscriptions({data})
      }
    }

    createTaasSubscriptions({ data }) {
      if (!data) { data = {} }
      const popUp = this.popUps.addSubscriptions.params
      popUp.relationWarning = (data.relation && (data.relation.name > 0)) ? null : 'relation not found'
      popUp.relationWarning = (data.relation && (data.relation.relationId > 0)) ? null : 'relation not found'
      popUp.numberOfSubscriptionsWarning = data.numberOfSubscriptions > 0 ? null : 'At least one subscription must be created'
      if (!popUp.relationWarning && !popUp.numberOfSubscriptionsWarning) {
        const relationName = data.relation.name
        const taasSubscription = {
          relationId: data.relation.relationId,
          notes: data.notes ? data.notes : null,
          subscriptions: parseInt(data.numberOfSubscriptions)
        }
        this.showPreloader = true
        this.SubscriptionService.addTaasSubscriptions(taasSubscription).then((res) => {
          return this.getOrganisations().then(() => {
            this.showPreloader = false
            this.hidePopUp('addSubscriptions')
            const taasIds = res.data.map(s => s.id)
            this.showPopUp('subsAdded', {
              link: this.getTaasParams(taasIds),
              count: data.numberOfSubscriptions,
              relation: relationName
            })
          })
        }).catch(errorRes => {
          this.showPreloader = false
          this.showPopUp('requestFailed', {
            errorTitle: `${errorRes.status} ${errorRes.statusText}`,
            errorMessage: errorRes.data.message
          })
        }).finally(() => { this.showPreloader = false })
      }
    }

    createRegularSubscriptions({ data }) {
      if (!data) { data = {} }
      const popUp = this.popUps.addSubscriptions.params
      popUp.relationWarning = (data.relation && (data.relation.name > 0)) ? null : 'relation not found'
      popUp.relationWarning = (data.relation && (data.relation.relationId > 0)) ? null : 'relation not found'
      popUp.subscriptionLengthWarning = data.subscriptionLength > 0 ? null : 'not a valid subscription length'
      popUp.paymentReferenceWarning = (data.paymentReference && data.paymentReference.length > 0) ? null : 'Please fill in a payment reference'
      popUp.numberOfSubscriptionsWarning = data.numberOfSubscriptions > 0 ? null : 'At least one subscription must be created'
      if (!popUp.relationWarning && !popUp.subscriptionLengthWarning &&
        !popUp.paymentReferenceWarning && !popUp.numberOfSubscriptionsWarning) {
        const relationName = data.relation.name
        const subscription = {
          relation: {
            relationId: data.relation.relationId
          },
          subscriptionLength: data.subscriptionLength,
          paymentReference: data.paymentReference,
          notes: data.notes ? data.notes : null,
          numberOfSubscriptions: data.numberOfSubscriptions
        }
        this.showPreloader = true
        this.SubscriptionService.addSubscriptions(subscription).then((res) => {
          return this.getOrganisations().then(() => {
            this.showPreloader = false
            this.hidePopUp('addSubscriptions')
            this.showPopUp('subsAdded', {
              link: this.getSubsParams(res.data.map(s => s.chainId)),
              count: subscription.numberOfSubscriptions,
              relation: relationName,
              paymentReference: subscription.paymentReference
            })
          })
        }).catch(errorRes => {
          this.showPreloader = false
          this.showPopUp('requestFailed', {
            errorTitle: `${errorRes.status} ${errorRes.statusText}`,
            errorMessage: errorRes.data.message
          })
        }).finally(() => { this.showPreloader = false })
      }
    }

    // Pop-up Logic ////////////////////////////////////////

    errorPopUp(error) {
      console.error({ error })
      const errorTitle = error && error.status && error.statusText ? `${error.status} ${error.statusText}` : 'Unkown Error'
      const errorMessage = (error && error.data && error.data.message) || 'Please create a product Issue'
      this.showPopUp('requestFailed', {
        errorTitle: errorTitle,
        errorMessage: errorMessage
      })
    }

    showPopUp(showPopUpName, params) {
      params && (this.popUps[showPopUpName].params = params)
      this.popUps[showPopUpName].visible = true
    }

    hidePopUp(hidePopUpName) {
      this.popUps[hidePopUpName].visible = false
      const params = this.popUps[hidePopUpName].params
      if (params) {
        for (const param in params) {
          params[param] = null
        }
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////
  }
}

export default organisation
