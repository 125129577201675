/**
 * @file Dialog for creating order
 * @author Max van Loosbroek
 */

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TbDashDetailedColumn,
  TbDashDetailedContainer,
  TbDashDetailedDialog,
  TbDashErrorDialog
} from 'tinybots-react-components'
import { AppDispatch } from '../../../../common/redux/store'
import { RootState } from '../../../../common/redux/root.reducer'
import {
  clearErrorsReturnConcept,
  clearSearch,
  setDone,
  updateReturnForm
} from './redux/reducer'
import { GeneralReturnOrderForm } from './Form/General/GeneralReturnOrderForm'
import { createReturn, getReturnConceptById } from './redux/thunks'
import { ReturnInfoForm } from './Form/ReturnInfo/ReturnInfoForm'
import { ReturnForm } from './types/ReturnForm'
import { NotesForm } from './Form/Notes/NotesForm'
import { OrderReceiver } from '../OrderDetailed/OrderReceiver'
import { OrderRequester } from '../OrderDetailed/OrderRequester'
import { OrderHealthcareProfessional } from '../OrderDetailed/OrderHealthcareProfessional'
import { ReturnFormState } from './types/types'
import { OrderDeliveryAddress } from '../OrderDetailed/OrderDeliveryAddress'
import { ConceptReturnsSearch } from './Form/ConceptReturnSearch/ConceptReturnSearch'
import { ContactsConceptOrderForm } from '../CreateOrder/Form/Contacts/ContactsConceptOrderForm'
import { SelectConceptDialog } from '../CreateOrder/SelectConcept/SelectConceptDialog'
import { OrderFinal } from '../../types/OrderFinal'
import { validReturn } from './redux/selectors'

export type CreateReturnDialogProps = {
  returnConceptId?: number
  returnFormValid: boolean
  returnConceptState: ReturnFormState
  open: boolean
  color: string
  dispatch: AppDispatch
  handleClose: () => void
  afterSubmit: (id: string | number) => void
}

export const CreateReturnDialogComponent = ({
  returnConceptId,
  returnFormValid,
  open,
  returnConceptState,
  color,
  handleClose,
  afterSubmit,
  dispatch
}: CreateReturnDialogProps) => {
  const {
    salesOrder,
    patchError,
    getError,
    getting,
    patching,
    returnForm,
    searching,
    returnConceptsSearchResults
  } = returnConceptState
  const errorMessage = getError || patchError
  const [robotSerial, setRobotSerial] = useState<string>('')
  const [clientId, setClientId] = useState<string>('')
  const [uuid, setUuid] = useState<string>('')
  const [showErrors, setShowErrors] = useState(false)
  const handleSubmit = useCallback(async () => {
    setShowErrors(true)
    if (!returnFormValid) {
      return
    } else {
      const res = await dispatch(
        createReturn({
          returnForm: returnForm as ReturnForm
        })
      )
      if (res?.meta.requestStatus === 'fulfilled') {
        afterSubmit((res.payload as OrderFinal).id)
      }
    }
  }, [returnFormValid, returnForm])
  const onChangeForm = useCallback(
    changes => {
      dispatch(updateReturnForm(changes))
    },
    [dispatch, updateReturnForm]
  )
  const clearErrors = useCallback(() => {
    dispatch(clearErrorsReturnConcept())
  }, [dispatch])
  useEffect(() => {
    if (returnConceptId) {
      dispatch(getReturnConceptById(returnConceptId))
    } else {
      dispatch(setDone())
    }
  }, [returnConceptId])
  const closeSelect = useCallback(() => {
    dispatch(clearSearch())
  }, [])
  const pickConcept = useCallback((id: number) => {
    dispatch(getReturnConceptById(id))
    dispatch(clearSearch())
  }, [])
  const tabs: { title: string; content: any }[] = [
    {
      title: 'Return order',
      content: (
        <TbDashDetailedContainer>
          <TbDashDetailedColumn>
            <ConceptReturnsSearch
              clientId={clientId}
              setClientId={setClientId}
              robotSerial={robotSerial}
              setRobotSerial={setRobotSerial}
              uuid={uuid}
              setUuid={setUuid}
            />
            <GeneralReturnOrderForm
              searching={searching}
              salesOrder={salesOrder}
              returnForm={returnForm}
              onChangeForm={onChangeForm}
              showErrors={showErrors}
              dispatch={dispatch}
            />
          </TbDashDetailedColumn>
          <div style={{ marginTop: 100 }} />
          <TbDashDetailedColumn>
            <NotesForm
              salesOrder={salesOrder}
              returnForm={returnForm}
              onChangeForm={onChangeForm}
            />
            <div style={{ marginTop: 100 }} />
            <OrderRequester requester={salesOrder?.requester} />
            <div style={{ marginTop: 100 }} />
            <OrderHealthcareProfessional
              professional={salesOrder?.healthcareProfessional}
            />
            <div style={{ marginTop: 100 }} />
            <OrderReceiver receiver={salesOrder?.receiver} />
            <div style={{ marginTop: 100 }} />
            <OrderDeliveryAddress
              deliveryAddress={salesOrder?.deliveryAddress}
            />
            <div style={{ marginTop: 100 }} />
            <ReturnInfoForm
              salesOrder={salesOrder}
              returnForm={returnForm}
              onChangeForm={onChangeForm}
              showErrors={showErrors}
              dispatch={dispatch}
              searching={searching}
            />
          </TbDashDetailedColumn>
        </TbDashDetailedContainer>
      )
    }
  ]
  return (
    <>
      <TbDashDetailedDialog
        height={1200}
        loading={getting || patching}
        open={open}
        color={color}
        tabs={tabs}
        activeTab={0}
        handleTabChange={() => {}}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <SelectConceptDialog
        handleClose={closeSelect}
        onPick={pickConcept}
        open={returnConceptsSearchResults.length > 0}
        items={returnConceptsSearchResults.map(
          ({ id, returner, createdAt: date }) => ({
            id,
            email: returner?.email,
            date: new Date(date)
          })
        )}
      />
      <TbDashErrorDialog
        message={errorMessage}
        open={!!errorMessage}
        handleClose={() => {
          clearErrors()
        }}
      />
    </>
  )
}

const ConnectedCreateReturnDialog = (
  props: Omit<CreateReturnDialogProps, 'dispatch' | 'returnConceptState' | 'returnFormValid'>
) => {
  const dispatch: AppDispatch = useDispatch()
  const returnConceptState = useSelector(
    (state: RootState) => state.orderReturn
  )
  const returnFormValid = useSelector(validReturn)
  return (
    <CreateReturnDialogComponent
      {...props}
      returnFormValid={returnFormValid}
      returnConceptState={returnConceptState}
      dispatch={dispatch}
    />
  )
}

export default React.memo(ConnectedCreateReturnDialog)
