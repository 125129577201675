import { ButtonBase, ButtonBaseProps, styled } from '@material-ui/core'
import { FunctionComponent } from 'react'

export const ExportButton: FunctionComponent<
    {
        active?: boolean
        ref?: React.MutableRefObject<any>
    } & ButtonBaseProps
> = styled(ButtonBase)({
    display: 'inline-block',
    padding: '7px 14.5px',
    textAlign: 'center',
    verticalAlign: 'middle',
    height: 36,
    minWidth: 80,
    outline: 'none',
    MozAppearance: 'none',
    fontFamily: 'Montserrat !important',
    fontSize: 16,
    lineHeight: 1,
    boxSizing: 'border-box',
    borderRadius: 5,
    marginTop: 8,
    marginRight: 64,
    marginLeft: 95,
    background: ({ disabled }: { disabled?: boolean }) => (disabled ? '#e8e8e8' : '#333333' ),
    color: ({ disabled }: { disabled?: boolean }) => (disabled ? '#d5d5d5' : 'white'),
    cursor: ({ disabled }: { disabled?: boolean }) => (disabled ? 'initial' : 'pointer')
}) as any
