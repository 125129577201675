import loginMfa from './login-mfa.component';
import "./MfaForm/MfaForm.module";

export default angular
    .module('loginMfa', ['components.tb.mfaForm'])
    .component('loginMfa', loginMfa)
    .config(($stateProvider) => {
        $stateProvider
            .state('auth.loginMfa', {
                url: '^/login-mfa',
                component: 'loginMfa',
                params: {
                    password: null,
                    username: null
                }
            });
    })
    .name;