/**
 * @file Dialog where you can add a robot to a sub
 * @author Max van Loosbroek
 */

import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import { RobotDetailed } from '../../types/types'
import { getRobotRef } from '../../stringTransformers'

export interface RobotSearchProps {
  searchRobots: (params: { serial?: string; boxNumber?: string }) => any
  robotsSearch: (RobotDetailed & { disabled: boolean })[]
  robotHasError: (value?: string | number | boolean) => string
  selectedRobot: RobotDetailed
  setSelectedRobot: (robot: RobotDetailed) => void
}

export const RobotSearch = ({
  searchRobots,
  robotHasError,
  robotsSearch,
  selectedRobot,
  setSelectedRobot
}: RobotSearchProps) => {
  const [serial, setSerial] = useState('')
  const previousSerial = usePrevious(serial)
  const [boxNumber, setBoxNumber] = useState('')
  const [selectedBoxNumber, setSelectedBoxNumber] = useState('')
  const previousBoxNumber = usePrevious(boxNumber)
  const selected: (RobotDetailed & {
    disabled: boolean
  })[] = robotsSearch.filter(r => {
    const hasBox = r.boxNumber !== null
    const serialMatch = getRobotRef(r) === serial
    const boxMatch = hasBox && r.boxNumber?.toString() === boxNumber
    const match = serialMatch || boxMatch 
    return match && !r.disabled
  })
  const robotOptions = serial ? robotsSearch.map(r => ({
    key: getRobotRef(r),
    ...r
  })) : []
  const robotOptionsBox = boxNumber ? robotsSearch.map(r => ({
    key: r.boxNumber?.toString() ?? getRobotRef(r),
    label: r.boxNumber?.toString() ?? '-',
    ...r
  })).filter(r => r.boxNumber !== null) : []
  const debouncedRobotsGet = useCallback(
    debounce(
      (params: { serial?: string; boxNumber?: string }) =>
        searchRobots(params),
      500,
      {
        leading: true,
        trailing: true
      }
    ),
    []
  )

  useEffect(() => {
    if (
      boxNumber !== previousBoxNumber ||
      getRobotRef(selected[0]) === serial
    ) {
      // user has changed boxNumber, so do not search by serial
      return
    }
    if (selected.length === 1 && !selected[0].disabled) {
      setBoxNumber(selected[0].boxNumber?.toString())
      setSelectedRobot(selected[0])
    } else if (selected.length === 0 && selectedRobot !== undefined) {
      setSelectedRobot(undefined)
    }
  }, [robotsSearch, serial, boxNumber, previousBoxNumber, selected])

  useEffect(() => {
    const noBoxNumbers = selectedRobot && !boxNumber && selectedRobot.boxNumber === null
    const boxMatch = selectedRobot && boxNumber === selectedRobot.boxNumber?.toString()
    const serialMatch = selectedRobot && serial === getRobotRef(selectedRobot)
    if (
      serialMatch && (noBoxNumbers || boxMatch)
    ) {
      // no changes
      return
    }
    const serialChanged = serial !== previousSerial
    const boxChanged = boxNumber !== previousBoxNumber
    const oneMatch = selected.length === 1
    const robotSelectedByBox =
      oneMatch && selectedBoxNumber === selected[0]?.boxNumber?.toString() && !serialChanged
    const robotSelectedBySerial =
      oneMatch && serial === getRobotRef(selected[0]) && !boxChanged
    if (selectedRobot && serialChanged && !serialMatch) {
      setSelectedRobot(undefined)
      setBoxNumber(undefined)
      setSelectedBoxNumber(undefined)
    } else if (selectedRobot && boxChanged && !boxMatch) {
      setSelectedRobot(undefined)
      setSerial(undefined)
    } else if (robotSelectedByBox && !robotSelectedBySerial) {
      setSerial(getRobotRef(selected[0]))
      setSelectedRobot(selected[0])
    } else if (robotSelectedBySerial && !robotSelectedByBox) {
      setBoxNumber(selected[0].boxNumber?.toString())
      setSelectedRobot(selected[0])
    } else if (selected.length === 0 && selectedRobot !== undefined) {
      setSelectedRobot(undefined)
    }
  }, [
    robotsSearch,
    boxNumber,
    serial,
    previousSerial,
    selected,
    selectedBoxNumber,
    selectedRobot
  ])

  useEffect(() => {
    if (
      serial &&
      serial !== previousSerial &&
      getRobotRef(selected[0]) !== serial
    ) {
      debouncedRobotsGet({ serial })
    }
  }, [serial, debouncedRobotsGet, previousSerial, boxNumber, selected])

  useEffect(() => {
    if (
      boxNumber &&
      boxNumber !== previousBoxNumber &&
      selected[0]?.boxNumber?.toString() !== boxNumber &&
      boxNumber !== '-'
    ) {
      debouncedRobotsGet({ boxNumber })
    }
  }, [boxNumber, debouncedRobotsGet, previousBoxNumber, selected])

  return (
    <>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='autoComplete'
          label='Robot Serial'
          iconUrl='assets/tessa-dark.svg'
          onChange={v => setSerial(v as string)}
          options={robotOptions}
          hasError={robotHasError}
          value={serial}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='autoComplete'
          label='Box number'
          iconUrl='assets/category.svg'
          onChange={v => setBoxNumber(v as string)}
          options={robotOptionsBox}
          hasError={robotHasError}
          onSelect={v => setSelectedBoxNumber(v.boxNumber.toString())}
          value={boxNumber}
        />
      </TbDashDetailedItemRow>
    </>
  )
}

export default RobotSearch
