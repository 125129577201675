/**
 * @file Transfer inputs for robots
 * @author Max van Loosbroek
 */


import { ArrowRightAlt } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TbDashDetailedItemRow, TbDashDetailedItem } from 'tinybots-react-components'
import { RootState } from '../../../common/redux/root.reducer'
import { AppDispatch } from '../../../common/redux/store'
import { useDebouncedFunction } from '../../overview/Detailed/LinkDialog/Robot/hooks'
import { getRobotRef } from '../../overview/stringTransformers'
import { RobotBase, SubscriptionBase } from '../../overview/types/types'
import {
  dashtransferSelector,
  robotsSearchSelector,
  robotsTaasSearchSelector,
  setRobotSerial
} from '../redux/reducer'
import { getNewRobot, searchRobotsDashtransfer } from '../redux/thunks'
import { DashtransferState } from '../types'

export interface RobotItemProps {
  newSub?: SubscriptionBase
  robotSerial?: string
  currentRobot?: RobotBase | undefined
  newRobot?: RobotBase | undefined
  robotsSearch: ReturnType<typeof robotsSearchSelector>
  dispatch: AppDispatch
  showErrors: boolean
  relationId?: string
  taas: boolean
}

export const RobotItemComponent = React.memo(
  ({
    currentRobot,
    newRobot,
    robotsSearch,
    relationId,
    dispatch,
    taas,
    robotSerial
  }: RobotItemProps) => {
    const setNewRobotSerial = (serial: string) =>
      dispatch(setRobotSerial(serial))
    const debouncedSearch = useDebouncedFunction({
      changeHandler: (robotSerial: string, relationId: string) =>
        dispatch(searchRobotsDashtransfer({ serial: robotSerial, relationId })),
      delay: 100
    })
    const activeItemNew =
      robotSerial && robotSerial === newRobot?.serial
        ? {
            disabled: false,
            key: newRobot?.serial
          }
        : null
    const handleSelectNewRobot = (serial: string) => {
      const robot = robotsSearch.find(r => getRobotRef(r) === serial)
      dispatch(getNewRobot(robot))
    }
    const robotOptions = robotsSearch.map(r => ({
      key: r.taas ? `T-${r.serial}` : r.serial,
      disabled: r.disabled
    }))
    useEffect(() => {
      robotSerial && debouncedSearch(robotSerial, relationId)
    }, [robotSerial, relationId])

    return (
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          hasError={v => null}
          type='text'
          disabled
          label='Current Robot'
          iconUrl='assets/tessa-dark.svg'
          value={currentRobot?.serial ? getRobotRef(currentRobot) : '-'}
        />
        <TbDashDetailedItem
          hasError={v => null}
          type='autoComplete'
          label='New Robot'
          iconUrl=''
          activeItem={activeItemNew}
          onChange={v => {
            setNewRobotSerial(v as string)
          }}
          onSelect={r => r && handleSelectNewRobot(r.key)}
          options={robotOptions}
          value={robotSerial ? robotSerial : (newRobot ? getRobotRef(newRobot) : '')}
        />
        <ArrowRightAlt
          fontSize='inherit'
          style={{
            fontSize: 28,
            transform: 'scaleX(-1)',
            position: 'absolute',
            left: '50%',
            marginLeft: 8,
            bottom: 13
          }}
        />
      </TbDashDetailedItemRow>
    )
  }
)

const ConnectedRobotItem = (props: Pick<RobotItemProps, 'showErrors' | 'taas'>) => {
  const { currentRobot, newRobot, newRelation, robotSerial, newSub } = useSelector<
    RootState,
    DashtransferState
  >(dashtransferSelector)
  const robotsSearch = props.taas ? useSelector(robotsTaasSearchSelector) : useSelector(robotsSearchSelector)
  const dispatch: AppDispatch = useDispatch()
  
  return (
    <RobotItemComponent
      {...props}
      newSub={newSub}
      robotSerial={robotSerial}
      relationId={newRelation?.relationId.toString()}
      currentRobot={currentRobot}
      newRobot={newRobot}
      robotsSearch={robotsSearch}
      dispatch={dispatch}
    />
  )
}

export default ConnectedRobotItem
