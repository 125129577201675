/**
* @typedef {Object} RobotAccount
* @property {number} id
* @property {string} username - robot serial, eg. 931ed089f509f8ec
* @property {'robot'} role - no other options than 'robot' currently
* @property {('ACTIVE'|'BLOCKED')} status - robot status
* @property {string} pairedUserId
*/

/**
* @typedef {Object} Relation
* @property {number} relationId
* @property {string} name - eg. "OrganisationA"
* @property {string} teamleaderId - eg. "cb8da52a-ce89-4bf6-8f7e-8ee6cb85e3b5"
* @property {string} type - eg. "company"
*/

/**
* @typedef {Object} Subscription
* @property {number} subscriptionId
* @property {string} shippedAt - date string eg. "23-03-2018"
* @property {string} endAt - date string eg. "23-03-2018"
* @property {Relation} relation - no other options than 'robot' currently
*/

/**
* @typedef {Object} Robot
* @property {number} boxNr
* @property {string} serial - robot serial, eg. 931ed089f509f8ec
* @property {string} assembledAt - date string eg. "23-03-2018"
* @property {string} ethMac - eg. "A9:E3:21:E9:98:11"
* @property {string} wlanMac - eg. "A9:E3:21:E9:98:11"
* @property {string} hardwareVersion - eg. "2.0"
* @property {boolean} excludeOkr
* @property {string} deactivatedAt - date string eg. "9999-12-23 23:59:59"
* @property {string} notes
* @property {Subscription[]} subscriptions
* @property {RobotAccount} account
*/

/* @ngInject */
export default class RobotsService {
  constructor ($http, URLS) {
    this.$http = $http
    this.URLS = URLS
  }

  getRobotsBySerial (serial, hasSubscription) {
    let url
    if (hasSubscription != null) {
      url = `${this.URLS.admin.robotsV3}?serial=${serial}&hassubscription=${hasSubscription}`
    } else {
      url = `${this.URLS.admin.robotsV3}?serial=${serial}`
    }
    return this.$http.get(url)
  }

  /**
  * Returns a promise that resolves a robot account
  * @param  {serialId} robotId - eg. 931ed089f509f8ec
  * @return {Promise<Robot>}
  */
  getRobotById (serialId) {
    const url = `${this.URLS.admin.robotsV3}/${serialId}`
    return this.$http.get(url)
  }

  getRobotAccount (serialId) {
    const robotAccountUrl = `${this.URLS.admin.robotsV3}/${serialId}/account`
    return this.$http.get(robotAccountUrl)
  }
};
