import heartbeat from './heartbeat.component'
import HeartbeatService from './heartbeat.service'
import RobotsService from '../robots/robots.service'

export const heartbeatModule = angular
  .module('components.heartbeat', ['chart.js'])
  .component('heartbeat', heartbeat)
  .service('HeartbeatService', HeartbeatService)
  .service('RobotsService', RobotsService)
  .config(($stateProvider) => {
    $stateProvider
      .state('heartbeat', {
        parent: 'app',
        url: '^/heartbeat/:serial?startDate&endDate&untilNow',
        component: 'heartbeat',
        params: {
          serial: null,
          startDate: null,
          endDate: null,
          untilNow: null
        },
        resolve: {}
      })
  })
  .name
