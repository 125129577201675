/**
 * @file Wrapper module to load react overview component inside angular
 * @author Max van Loosbroek
 */

import Dashtransfer from './Dashtransfer'
import StoreService from '../../../common/redux/store.service'
import angular from 'angular'
import { IHttpService } from 'angular'

export default angular
  .module('components.tb.dashtransferReact', ['react'])
  .directive(
    'tbDashtransferReact',
    (reactDirective, $auth, $http: IHttpService, StoreService: StoreService) =>
      reactDirective(Dashtransfer, [], {}, { $auth, $http, StoreService })
  )
