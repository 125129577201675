/**
 * @file Reducer for managing dashtransfer state
 * @author Max van Loosbroek
 */

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { RootState } from '../../../common/redux/root.reducer'
import { getDefaultSub } from '../../overview/Detailed/redux/selectors'
import { getRobotRef, getSubRefLength } from '../../overview/stringTransformers'
import {
  SubscriptionDetailed,
  TaasDetailed,
  RobotDetailed,
  SubscriptionBase,
  ChainDetailed,
} from '../../overview/types/types'
import { DashtransferBody, DashtransferState } from '../types'
import {
  getNewRobot,
  getTransferData,
  searchRelationsDashtransfer,
  searchRobotsDashtransfer,
  searchSubscriptionsDashtransfer,
  searchTaasDashtransfer,
  transferEntities
} from './thunks'
import { RelationBase } from '../../overview/types/RelationBase'

export const subscriptionsSearchAdapter = createEntityAdapter<SubscriptionBase>(
  {
    selectId: entity => entity.subscriptionId
  }
)

export const taasSearchAdapter = createEntityAdapter<TaasDetailed>({
  selectId: entity => entity.id
})

export const robotsSearchAdapter = createEntityAdapter<RobotDetailed>({
  selectId: entity => entity.serialId
})

export const relationsSearchAdapter = createEntityAdapter<RelationBase>({
  selectId: entity => entity.relationId
})

export const initialStateDashtransfer: DashtransferState = {
  showDialog: false,
  getting: true,
  getError: null,
  patching: false,
  patchError: null,
  currentRelation: undefined,
  newRelation: undefined,
  currentRobot: undefined,
  newRobot: undefined,
  currentSub: undefined,
  newSub: undefined,
  currentTaasSubscription: undefined,
  currentChain: undefined,
  subRef: undefined,
  subscriptionsSearch: subscriptionsSearchAdapter.getInitialState(),
  robotsSearch: robotsSearchAdapter.getInitialState(),
  relationsSearch: relationsSearchAdapter.getInitialState(),
  taasSearch: taasSearchAdapter.getInitialState(),
  transferData: false,
  errorLog: []
}

export const dashtransferSlice = createSlice({
  name: 'dashtransfer',
  initialState: initialStateDashtransfer,
  reducers: {
    setSubRef: (state, { payload }: PayloadAction<string>) => {
      if(payload !== getSubRefLength(state.newSub)) {
        state.newRobot = undefined
      }
      state.subRef = payload
    },
    toggleTransferData: (state) => {
      state.transferData = !state.transferData
    },
    setRelationName: (state, { payload }: PayloadAction<string>) => {
      if(payload !== state.newRelation?.name) {
        state.newRelation = undefined
      }
      state.relationName = payload
    },
    setRobotSerial: (state, { payload }: PayloadAction<string>) => {
      if(payload !== getRobotRef(state.newRobot)) {
        state.newRobot = undefined
      }
      state.robotSerial = payload
    },
    clearStateDashtransfer: () => initialStateDashtransfer,
    clearErrorsDashtransfer: state => {
      state.getError = null
      state.patchError = null
    },
    setCurrentRelation: (state, { payload }: PayloadAction<RelationBase>) => {
      state.currentRelation = payload
    },
    setNewRelation: (state, { payload }: PayloadAction<RelationBase>) => {
      if (!payload) {
        state.newRelation = undefined
        return
      }
      if (
        state.newRobot &&
        state.newRobot.relation?.relationId !== payload?.relationId
      ) {
        state.newRobot = undefined
        state.robotSerial = undefined
      }
      if (
        state.newSub &&
        state.newSub.relation?.relationId !== payload?.relationId
      ) {
        state.newSub = undefined
        state.subRef = undefined
      }
      state.newRelation = payload
    },
    setCurrentRobot: (state, { payload }: PayloadAction<RobotDetailed>) => {
      state.currentRobot = payload
    },
    setNewRobot: (state, { payload }: PayloadAction<RobotDetailed>) => {
      if (!payload) {
        state.newRobot = undefined
        return
      }
      if (
        state.newRelation &&
        state.newRelation?.relationId !== payload?.relation?.relationId
      ) {
        state.newRelation = payload.relation
      }
      if (state.newSub && state.newSub?.robot?.serial !== payload?.serial) {
        state.subRef = undefined
        state.newSub = undefined
      }
      state.newRobot = payload
    },
    setCurrentSub: (
      state,
      { payload }: PayloadAction<SubscriptionDetailed | SubscriptionBase>
    ) => {
      state.currentSub = payload
    },
    setNewSub: (
      state,
      { payload }: PayloadAction<SubscriptionDetailed | SubscriptionBase>
    ) => {
      if (!payload) {
        state.newSub = undefined
        return
      }
      if (
        state.newRelation &&
        state.newRelation?.relationId !== payload?.relation?.relationId
      ) {
        state.relationName = payload?.relation?.name
        state.newRelation = payload?.relation
      }
      if (state.newRobot && state.newRobot?.serial !== payload?.robot?.serial) {
        state.robotSerial = undefined
        state.newRobot = undefined
      }
      state.newSub = payload
    },
    setCurrentTaasSubscription: (
      state,
      { payload }: PayloadAction<TaasDetailed>
    ) => {
      state.currentTaasSubscription = payload
    },
    setChain: (state, { payload }: PayloadAction<ChainDetailed>) => {
      state.currentChain = payload
    },
    toggleDialog: (
      state,
      {
        payload: { show, force }
      }: { payload: { show: boolean; force?: boolean } }
    ) => {
      state.showDialog = show
    }
  },
  extraReducers: builder => {
    builder.addCase(getTransferData.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getTransferData.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(getTransferData.fulfilled, (state, action) => {
      const { chain, robot, taas, relation } = action.payload
      state.getting = false
      state.getError = null
      state.currentRelation = relation
      state.currentChain = chain
      state.currentRobot = robot
      state.currentTaasSubscription = taas
      state.currentSub = getDefaultSub(
        chain?.subscriptions
      ) as SubscriptionDetailed
    })
    builder.addCase(transferEntities.pending, state => {
      state.patching = true
      state.patchError = null
    })
    builder.addCase(transferEntities.rejected, (state, action) => {
      if (action.error?.message.includes('Invalid form')) {
        state.patching = false
        state.patchError = null
      } else {
        state.patching = false
        const errorString = action.error?.message ?? 'Unknown error'
        state.patchError = errorString
        state.errorLog = [...state.errorLog, errorString]
      }
    })
    builder.addCase(transferEntities.fulfilled, state => {
      state.patching = false
      state.patchError = null
      state.showDialog = false
    })
    builder.addCase(getNewRobot.pending, state => {
      state.getError = null
    })
    builder.addCase(getNewRobot.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(getNewRobot.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      state.newRobot = action.payload
      if (action.payload?.relation) {
        state.newRelation = action.payload?.relation
      }
      if (action.payload?.subscriptions) {
        const sub = getDefaultSub(action.payload.subscriptions)
        state.newSub = sub
      }
    })
    builder.addCase(searchRobotsDashtransfer.pending, state => {
      state.getError = null
    })
    builder.addCase(searchRobotsDashtransfer.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(searchRobotsDashtransfer.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      robotsSearchAdapter.setAll(state.robotsSearch, action.payload)
    })
    builder.addCase(searchSubscriptionsDashtransfer.pending, state => {
      state.getError = null
    })
    builder.addCase(
      searchSubscriptionsDashtransfer.rejected,
      (state, action) => {
        state.getting = false
        state.getError = action.error?.message ?? 'Unknown error'
      }
    )
    builder.addCase(
      searchSubscriptionsDashtransfer.fulfilled,
      (state, action) => {
        state.getting = false
        state.getError = null
        const target = state.subscriptionsSearch
        subscriptionsSearchAdapter.setAll(target, action.payload)
      }
    )
    builder.addCase(searchTaasDashtransfer.pending, state => {
      state.getError = null
    })
    builder.addCase(searchTaasDashtransfer.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(searchTaasDashtransfer.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      const target = state.taasSearch
      taasSearchAdapter.setAll(target, action.payload)
    })
    builder.addCase(searchRelationsDashtransfer.pending, state => {
      state.getError = null
    })
    builder.addCase(searchRelationsDashtransfer.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(searchRelationsDashtransfer.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      const target = state.relationsSearch
      relationsSearchAdapter.setAll(target, action.payload.slice(0, 20))
    })
  }
})

export const {
  setSubRef,
  setRelationName,
  setRobotSerial,
  clearStateDashtransfer,
  clearErrorsDashtransfer,
  toggleDialog,
  setCurrentRelation,
  setNewRelation,
  setCurrentRobot,
  setNewRobot,
  setCurrentSub,
  setNewSub,
  toggleTransferData,
  setCurrentTaasSubscription,
  setChain
} = dashtransferSlice.actions

// Extract the action creators object and the reducer
const { reducer } = dashtransferSlice

export const dashtransferReducer = reducer
export const dashtransferSelector = ({ dashtransfer }: RootState) =>
  dashtransfer
export const dashtransferBodySelector = ({
  dashtransfer
}: RootState): DashtransferBody => {
  return {
    current: {
      relationId: dashtransfer.currentRelation?.relationId,
      serialId: dashtransfer.currentRobot?.serialId,
      chainId: dashtransfer.currentChain?.chainId,
      taasId: dashtransfer.currentTaasSubscription?.id
    },
    change: {
      relationId: dashtransfer.newRelation?.relationId,
      serialId: dashtransfer.newRobot?.serialId,
      chainId: dashtransfer.newSub?.chainId
    },
    dataTransfer: dashtransfer.transferData
  }
}
export const subsSelectorDashtransfer = createSelector(
  dashtransferSelector,
  (state: DashtransferState) => state.currentChain?.subscriptions
)
export const defaultSubSelectorDashtransfer = createSelector(
  subsSelectorDashtransfer,
  getDefaultSub
)

export const robotsSearchSelector = (state: RootState) =>
  robotsSearchAdapter
    .getSelectors()
    .selectAll(state.dashtransfer.robotsSearch)
    .map(r => {
      const disabled: boolean = !!r?.taas
      return { ...r, disabled: disabled }
    })
    .sort((a, b) => {
      if (a?.disabled && !b?.disabled) {
        return 1
      } else if (!a?.disabled && b?.disabled) {
        return -1
      } else {
        return 0
      }
    })
    .slice(0, 20)

export const robotsTaasSearchSelector = (state: RootState) =>
  robotsSearchAdapter
    .getSelectors()
    .selectAll(state.dashtransfer.robotsSearch)
    .map(r => {
      const disabled: boolean = !r?.taas || !!r.taasId // is not taas or has taas sub
      return { ...r, disabled: disabled }
    })
    .sort((a, b) => {
      if (a?.disabled && !b?.disabled) {
        return 1
      } else if (!a?.disabled && b?.disabled) {
        return -1
      } else {
        return 0
      }
    })
    .slice(0, 20)
