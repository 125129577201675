/**
 * @file Functions for deciding which status can be applied to orders
 * @author Max van Loosbroek
 */

import { getStatusString } from '../../overview/tableMappers'
import { OrderFinal, OrderFinalV5 } from '../../types/OrderFinal'
import { OrderStatus } from '../../types/OrderStatus'
import { PossibleStatuses } from '../../types/types'

export type StatusOrStatusString = OrderStatus | PossibleStatuses

export const convertStatusToString = (status?: StatusOrStatusString) => {
  if (typeof status === 'string') {
    return status
  } else if (status) {
    return getStatusString(status)
  }
}

export const canAccept = (status: StatusOrStatusString) => {
  const statusString = convertStatusToString(status)
  const isOrdered = statusString === 'ordered'
  return isOrdered
}

export const canDecline = (status: StatusOrStatusString) => {
  const statusString = convertStatusToString(status)
  const isOrdered = statusString === 'ordered'
  const isAccepted = statusString === 'processing'
  const isShipping = statusString === 'shipping'
  return isOrdered || isAccepted || isShipping
}

export const canShip = (status: OrderStatus) => {
  const shippedAtOrAccepted =
    getStatusString(status) === 'processing' ||
    (status?.shippedAt !== undefined && status?.shippedAt !== null)
  return shippedAtOrAccepted
}

export const canDeliver = (status: StatusOrStatusString) => {
  const statusString = convertStatusToString(status)
  const isShipping = statusString === 'shipping'
  const isShipped = statusString === 'shipped'
  return isShipping || isShipped
}

export const canAcceptReturn = (status: StatusOrStatusString) => {
  const statusString = convertStatusToString(status)
  const newReturn = statusString === 'returnProcessStarted'
  const returnRejected = statusString === 'returnRejected'
  return newReturn || returnRejected
}

export const canRejectReturn = (status: StatusOrStatusString) => {
  const statusString = convertStatusToString(status)
  const newReturn = statusString === 'returnProcessStarted'
  return newReturn
}

export const canSetReturned = (order: OrderFinal | OrderFinalV5) => {
  const hasTaas =
    order !== undefined &&
    order.taasId !== undefined &&
    order.taasId !== null &&
    order.taasId > 0
  return hasTaas
}
