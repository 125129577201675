/**
 * @file Displays account information
 * @author Max van Loosbroek
 */

import React, { useCallback, useState } from 'react'
import { OrganisationAdminAccount } from './types'
import { Item, LeftColumn, MoreMenu, TbDashDetailedItem, TbDashDetailedItemRow } from 'tinybots-react-components'
import moment from 'moment'
import { Box, Grid, createStyles, createTheme, makeStyles } from '@material-ui/core'
import DetailedItemIcon from 'tinybots-react-components/lib/components/DashboardComponents/Detailed/DetailedItemIcon'
import { useTranslation } from 'react-i18next'
import { TbMuiThemeProvider } from '../../../common/react/theme/TbMuiThemeProvider'
import { useDispatch } from 'react-redux'
import { deleteOwner } from './redux/thunks'

export const AccountItemInfoLine = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            style={{
                marginBottom: 5
            }}
        >
            <span
                style={{
                    wordBreak: 'break-word',
                    fontFamily: 'Montserrat-Light',
                    color: '#6F6F6F'
                }}
            >
                {children || '-'}
            </span>
        </div>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& *': {
                cursor: 'text'
            }
        }
    })
)

const materialTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            500: '#00000'
        }
    }
})

export const AccountItem = ({
    account,
    relationId
}: {
    account: OrganisationAdminAccount
    relationId: number | string
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const deleteTessaOwner = useCallback(() => dispatch(deleteOwner({ email: account.email, relationId })), [])
    const moreMenuItems: {
        type: 'edit' | 'editInScript' | 'delete' | 'primaryUser' | 'unpair' | 'view' | 'retry'
        text: string
        fn: () => any
    }[] = [
        {
            type: 'delete',
            text: t(`OWNERS.DELETE`),
            fn: deleteTessaOwner
        }
    ]
    const classes = useStyles()
    const [menuOpen, setMenuOpen] = useState(false)
    return (
        <Box
            my={2}
            style={{
                position: 'relative',
                cursor: 'default',
                background: 'white',
                margin: 0,
                marginLeft: 8,
                marginRight: 8,
                marginTop: 4,
                marginBottom: 4,
                borderRadius: 5,
                padding: 10
            }}
            onClick={() => {
                setMenuOpen(!menuOpen)
            }}
        >
            <Item height={'auto'} minHeight={24} cursor='default'>
                <LeftColumn>
                    <div style={{ position: 'absolute', left: 1, top: 1 }}>
                        <DetailedItemIcon url='assets/person.svg/' />
                    </div>
                </LeftColumn>
                {moreMenuItems.length > 0 && (
                    <TbMuiThemeProvider theme={materialTheme}>
                        <div></div>
                        <div style={{ color: 'black' }}>
                            {' '}
                            <MoreMenu uuid={account.email + 'moreMenu'} moreMenuItems={moreMenuItems} />
                        </div>
                    </TbMuiThemeProvider>
                )}
                <div style={{ fontFamily: 'Montserrat', fontSize: 12, cursor: 'default', paddingRight: 30 }}>
                    <div
                        style={{
                            marginBottom: 5,
                            color: 'black'
                        }}
                    >
                        Tessa Owner Account
                    </div>
                    <AccountItemInfoLine>{account.name}</AccountItemInfoLine>
                    <AccountItemInfoLine>{account.email}</AccountItemInfoLine>
                    <AccountItemInfoLine>{account.phoneNumber}</AccountItemInfoLine>
                    <AccountItemInfoLine>{`Created: ${moment(account.createdAt).format(
                        'DD MMM YYYY'
                    )}`}</AccountItemInfoLine>
                </div>
            </Item>
        </Box>
    )
}
