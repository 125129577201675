/**
 * @file Section displaying info related to order healthcare professional
 * @author Max van Loosbroek
 */

import React from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { Contact } from '../../types/Contact'

export const OrderHealthcareProfessional = ({ professional }: { professional?: Contact }) => (
  <>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='Care professional email'
        value={professional?.email ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Tel. nr.'
        value={professional?.phoneNumber ?? '-'}
      />
    </TbDashDetailedItemRow>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='First name'
        value={professional?.firstname ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Last name'
        value={professional?.lastname ?? '-'}
      />
    </TbDashDetailedItemRow>
  </>
)
