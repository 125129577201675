import React, { useState, useCallback, useEffect } from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { ReturnForm } from '../../types/ReturnForm'
import { AppDispatch } from '../../../../../../common/redux/store'
import { useDebouncedFunction } from 'tinybots-react-components/lib/components/hooks'
import { OrderFinal, OrderFinalV5 } from '../../../../types/OrderFinal'
import { getOrderForReturn } from '../../redux/thunks'
import { removeOrder } from '../../redux/reducer'

interface HookProps {
  returnForm: Partial<ReturnForm>
  salesOrder: OrderFinalV5
  searching: boolean
  showErrors: boolean
  dispatch: AppDispatch
  onChangeForm: (changes: Partial<ReturnForm>) => void
}

const useError = ({
  returnForm,
  showErrors,
  searching,
  salesOrderId
}: Pick<HookProps, 'returnForm' | 'showErrors' | 'searching'> & {
  salesOrderId: string
}) => {
  const salesOrderHasError = useCallback(
    () =>
      ((!returnForm?.orderId && showErrors) ||
      (!returnForm?.orderId && !searching && salesOrderId))
        ? 'Sales order not found'
        : undefined,
    [returnForm?.orderId, showErrors, salesOrderId, searching]
  )
  return salesOrderHasError
}

export const useSalesOrderSearchItem = ({
  returnForm,
  showErrors,
  dispatch,
  salesOrder,
  searching
}: HookProps) => {
  const [salesOrderId, setSalesOrderId] = useState<string>(
    returnForm?.orderId?.toString() ?? ''
  )
  const debouncedSearch = useDebouncedFunction({
    changeHandler: (id: string) => {
      dispatch(id ? getOrderForReturn(id) : removeOrder())
    },
    delay: 100
  })
  let helperText = ''
  if (!salesOrderId) {
    helperText = 'Search for a sales order'
  }
  if (searching) {
    helperText = 'Searching...'
  } else if (salesOrder) {
    helperText = 'Sales order found'
  }

  const hasError = useError({ returnForm, showErrors, searching, salesOrderId })

  useEffect(() => {
    debouncedSearch(salesOrderId)
  }, [salesOrderId])

  const salesOrderItem = (
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        xs={12}
        hasError={hasError}
        helperText={helperText}
        type='number'
        label='SO number*'
        iconUrl='assets/invoice.svg'
        onChange={v => setSalesOrderId(v as string)}
        value={salesOrderId}
      />
    </TbDashDetailedItemRow>
  )
  return salesOrderItem
}
