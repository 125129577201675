/**
 * @file Form for displaying order and handling order status
 * @author Max van Loosbroek
 */

import { ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import moment from 'moment'
import React, { CSSProperties, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { RootState } from '../../../../common/redux/root.reducer'
import { AppDispatch } from '../../../../common/redux/store'
import getDateString from '../../../../common/utilities/getDateString'
import { OverviewDetailedRobotProps } from '../../../overview/Detailed/OverviewDetailedDialog/OverviewDetailedRobot/OverviewDetailedRobot'
import {
  toggleLinkDialog,
  updateTaas
} from '../../../overview/Detailed/redux/reducer'
import { STATUS_LABELS } from '../../overview/constants'
import { getStatusString } from '../../overview/tableMappers'
import { OrdersDetailedState, PossibleStatuses } from '../../types/types'
import { updateOrder, updateOrderStatus } from '../redux/reducer'
import {
  canAccept,
  canAcceptReturn,
  canDecline,
  canDeliver,
  canRejectReturn,
  canSetReturned,
  canShip
} from '../redux/statusHelpers'
import { DividerRow } from './DividerRow'
import { OrderDeliveryAddress } from './OrderDeliveryAddress'
import { OrderReceiver } from './OrderReceiver'
import { OrderRequester } from './OrderRequester'
import { OrderReturner } from './OrderReturner'
import { OrderHealthcareProfessional } from './OrderHealthcareProfessional'
import { OrderFinalV5 } from '../../types/OrderFinal'

export interface OrderDetailedProps {
  originalOrder: OrderFinalV5
  order: OrderFinalV5
  dispatch: AppDispatch
  statusString: PossibleStatuses
  showErrors: boolean
  taasEndAt?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    serial: {
      '& button': {
        fontFamily: 'Roboto-Mono-Light'
      }
    }
  })
)

const disabledStyle: CSSProperties = {
  opacity: 0.3,
  pointerEvents: 'none'
}

export const StatusForm = styled('section')({
  display: 'flex',
  flexDirection: 'column'
})

export const StatusButton = styled(ButtonBase)({
  display: 'inline-block',
  padding: '8px 22px',
  textAlign: 'center',
  verticalAlign: 'middle',
  height: 36,
  minWidth: 80,
  outline: 'none',
  MozAppearance: 'none',
  fontFamily: 'Montserrat!important',
  fontSize: 16,
  lineHeight: 1,
  boxSizing: 'border-box',
  borderRadius: 5,
  marginTop: 8
})

const getLinks = (
  order: OrderFinalV5
): { robotLink?: string; taasLink?: string } => {
  const taasId = order.taasId
  const robotId = order.robot?.id
  if (taasId && robotId) {
    const baseLink = `/overview/?taasId=${taasId}&serialId=${robotId}&taasIds=${taasId}&serialIds=${robotId}`
    const robotLink = `${baseLink}&tab=robot`
    const taasLink = `${baseLink}&tab=sub`
    return { robotLink, taasLink }
  } else if (taasId) {
    const taasLink = `/overview/?taasId=${taasId}&tab=sub&taasIds=${taasId}`
    return { taasLink }
  } else if (robotId) {
    const robotLink = `/overview/?serialId=${robotId}&tab=robot&serialIds=${robotId}`
    return { robotLink }
  } else {
    return {}
  }
}

export const OverviewDetailedRobotComponent = React.memo(
  ({
    showErrors,
    order,
    originalOrder,
    statusString,
    dispatch,
    taasEndAt
  }: OrderDetailedProps) => {
    const classes = useStyles()
    const statusStringOriginal = originalOrder?.status
      ? getStatusString(originalOrder.status)
      : undefined

    const endAtOriginal = originalOrder?.status?.returnedAt
    const teamleaderLink = `https://app.teamleader.eu/company_detail.php?id=${order?.relation?.teamleaderId}`
    // Accept button
    const enableAccept = canAccept(statusStringOriginal)
    const acceptStyle: React.CSSProperties = enableAccept ? {} : disabledStyle
    const handleClickAccept = useCallback(() => {
      if (!enableAccept) return
      dispatch(
        updateOrderStatus(
          statusString !== 'processing' ? 'processing' : statusStringOriginal
        )
      )
    }, [statusString, enableAccept])
    // Ship button and shippedAt
    const enableShip =
      canShip(originalOrder?.status) && !originalOrder.status.shippedAt
    const shipStyle: React.CSSProperties = enableShip ? {} : disabledStyle
    const handleClickShip = useCallback(() => {
      if (!enableShip) return
      if (statusString !== 'shipped') {
        dispatch(toggleLinkDialog({ show: 'taas' }))
      } else {
        dispatch(
          updateOrder({
            status: {
              ...order.status,
              shippedAt: undefined
            }
          })
        )
        dispatch(updateOrderStatus(statusStringOriginal))
      }
    }, [statusString, enableShip, order])
    const handleShippedChange = useCallback(
      (value: string) => {
        dispatch(
          updateOrder({
            status: {
              ...order.status,
              shippedAt: value
            }
          })
        )
      },
      [order.status]
    )
    const shippingHasError = (value: string) => {
      const invalidDate = value && !moment(value).isValid()
      const noShipping =
        !value &&
        !(
          statusString === 'ordered' ||
          statusString === 'declined' ||
          statusString === 'processing'
        )
      const inValid = invalidDate || noShipping
      if (noShipping && showErrors) return 'Required shipping date'
      if (inValid && showErrors) return 'Invalid shipping date'
    }
    const trackTraceError = (value: string) =>
      showErrors && value && !/^3S[A-Z]+[0-9]+$/.test(value)
        ? 'Invalid code'
        : ''
    // Deliver button
    const enableDeliver = canDeliver(statusStringOriginal)
    const deliverStyle: React.CSSProperties = enableDeliver ? {} : disabledStyle
    const handleClickDeliver = useCallback(() => {
      if (!enableDeliver) return
      dispatch(
        updateOrderStatus(
          statusString !== 'delivered' ? 'delivered' : statusStringOriginal
        )
      )
    }, [statusString])
    // Decline button
    const enableDecline = canDecline(statusStringOriginal)
    const declineStyle: React.CSSProperties = enableDecline ? {} : disabledStyle
    const handleClickDecline = useCallback(() => {
      if (!enableDecline) return
      dispatch(
        updateOrderStatus(
          statusString !== 'declined' ? 'declined' : statusStringOriginal
        )
      )
    }, [statusString])
    const handleChangeDeclineExplanation = useCallback(
      (value: string) => {
        dispatch(
          updateOrder({
            declineReason: value
          })
        )
      },
      [order.status]
    )
    const handleChangeInternalNotes = useCallback(
      (value: string) => {
        dispatch(
          updateOrder({
            internalNotes: value
          })
        )
      },
      [order.status]
    )
    const explanationError = (value: string) =>
      showErrors && !value && statusString === 'declined'
        ? 'Give an explanation for declining the order'
        : ''
    // Accept return butotn
    const enableAcceptReturn = canAcceptReturn(statusStringOriginal)
    const acceptReturnStyle: React.CSSProperties = enableAcceptReturn
      ? {}
      : disabledStyle
    const handleClickAcceptReturn = useCallback(() => {
      if (!enableAcceptReturn) return
      dispatch(
        updateOrderStatus(
          statusString !== 'returnAccepted'
            ? 'returnAccepted'
            : statusStringOriginal
        )
      )
    }, [statusString])
    // Set returned (end TaaS)
    const enableReturned =
      canSetReturned(originalOrder) && !originalOrder.status.returnedAt
    const returnedStyle: React.CSSProperties = enableReturned
      ? {}
      : disabledStyle
    const handleClickReturned = useCallback(() => {
      if (!enableReturned) return
      dispatch(
        updateTaas({
          endAt:
            statusString !== 'returned'
              ? new Date().toISOString()
              : endAtOriginal
        })
      )
      dispatch(
        updateOrderStatus(
          statusString !== 'returned' ? 'returned' : statusStringOriginal
        )
      )
    }, [statusString])
    // End TaaS: returned status
    const handleTaasEndChange = useCallback((value: string) => {
      dispatch(
        updateOrder({
          status: {
            ...order.status,
            returnedAt: value
          }
        })
      )
      dispatch(
        updateTaas({
          endAt: value
        })
      )
    }, [])
    const taasEndError = (value: string) =>
      showErrors &&
      statusString === 'returned' &&
      (!moment(value).isValid() || !value)
        ? 'Invalid code'
        : ''
    // Decline return
    const enableDeclineReturned = canRejectReturn(statusStringOriginal)
    const declineReturnStyle: React.CSSProperties = enableDeclineReturned
      ? {}
      : disabledStyle
    const handleClickDeclineReturn = useCallback(() => {
      if (!enableDeclineReturned) return
      dispatch(
        updateOrderStatus(
          statusString !== 'returnRejected'
            ? 'returnRejected'
            : statusStringOriginal
        )
      )
    }, [statusString])
    const handleChangeReturnRejectReason = useCallback(
      (value: string) => {
        dispatch(
          updateOrder({
            returnRejectReason: value
          })
        )
      },
      [order.status]
    )
    const returnRejectReasonError = (value: string) =>
      showErrors && !value && statusString === 'returnRejected'
        ? 'Give an explanation for declining the return'
        : ''
    // Decide which buttons are shown
    const showReturn =
      statusStringOriginal === 'delivered' ||
      statusStringOriginal === 'returnProcessStarted' ||
      statusStringOriginal === 'returnAccepted'
    // Links to detailed views
    const { robotLink, taasLink } = getLinks(order)
    return !order ? null : (
      <TbDashDetailedContainer>
        {/* Left column */}
        <TbDashDetailedColumn>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='SO number'
              iconUrl='assets/invoice.svg'
              value={`SO-${order.id}`}
            />
            <TbDashDetailedItem
              type='text'
              disabled
              label='Source'
              value={order.integration.name ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Relation'
              iconUrl='assets/relation.svg'
              value={order.relation.name ?? '-'}
            />
            <TbDashDetailedItem
              type='link'
              href={teamleaderLink}
              disabled
              label=''
              target='_href'
              value='View in teamleader'
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            {order.robot?.serial ? (
              <TbDashDetailedItem
                type='link'
                href={robotLink}
                disabled
                label='Robot serial'
                className={classes.serial}
                iconUrl='assets/tessa-dark.svg'
                value={order.robot?.serial ?? '-'}
              />
            ) : (
              <TbDashDetailedItem
                type='text'
                disabled
                label='Robot serial'
                iconUrl='assets/tessa-dark.svg'
                value='-'
              />
            )}
            <TbDashDetailedItem
              type='text'
              disabled
              label='Boxnumber'
              value={order.robot?.boxNumber ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            {order.taasId ? (
              <TbDashDetailedItem
                type='link'
                href={taasLink}
                label='Subscription reference'
                iconUrl='assets/subref.svg'
                value={order.taasId ? `T-${order.taasId}` : '-'}
              />
            ) : (
              <TbDashDetailedItem
                type='text'
                disabled
                label='Subscription reference'
                iconUrl='assets/subref.svg'
                value='-'
              />
            )}
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Client id'
              iconUrl='assets/person.svg'
              value={order.client?.clientNumber ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Universally unique id (UUID)'
              iconUrl='assets/TB-DB_icon_UUID.svg'
              value={order.client?.clientUuid ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Team id'
              iconUrl='assets/people_black_24dp.svg'
              value={order.teamId ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Discipline'
              iconUrl='assets/TB-DB_icon_medical_services_black_24dp.svg'
              value={order.discipline ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Healthcare demand(s)'
              iconUrl='assets/TB-DB_icon_medical_information_black_24dp.svg'
              value={order.healthCareDemand ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Tessa Expert requested?'
              iconUrl='assets/TB-DB_icon_Tessa_Expert.svg'
              value={order.tessaExpertNeeded ?? 'unknown'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              xs={12}
              type='textArea'
              textAreaAutoSize
              label='Internal notes'
              iconUrl='assets/TB-APP_Icon_Editing.svg'
              value={order.internalNotes}
              onChange={handleChangeInternalNotes}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='custom'
              disabled
              label='Status'
              iconUrl='assets/TB-DB_icon_inventory_black_24dp.svg'
              value={''}
            >
              {!showReturn && (
                <StatusForm>
                  <div style={{ marginTop: 16 }}>
                    {STATUS_LABELS[statusString].toUpperCase()}
                  </div>
                  <StatusButton
                    style={{
                      backgroundColor: '#e6c02b',
                      color: 'white',
                      ...acceptStyle
                    }}
                    onClick={handleClickAccept}
                  >
                    ACCEPT ORDER
                  </StatusButton>
                  <StatusButton
                    style={{
                      backgroundColor: '#549788',
                      color: 'white',
                      ...shipStyle
                    }}
                    onClick={handleClickShip}
                  >
                    SHIP ORDER
                  </StatusButton>
                  <StatusButton
                    style={{
                      backgroundColor: '#417921',
                      color: 'white',
                      ...deliverStyle
                    }}
                    onClick={handleClickDeliver}
                  >
                    DELIVERED
                  </StatusButton>
                  <StatusButton
                    style={{ backgroundColor: '#f7371e', ...declineStyle }}
                    onClick={handleClickDecline}
                  >
                    DECLINE
                  </StatusButton>
                  <StatusButton
                    style={{
                      backgroundColor: '#417921',
                      color: 'white',
                      ...returnedStyle
                    }}
                    onClick={handleClickReturned}
                  >
                    RETURNED
                  </StatusButton>
                </StatusForm>
              )}
              {showReturn && (
                <StatusForm>
                  <div style={{ marginTop: 16 }}>
                    {STATUS_LABELS[statusString].toUpperCase()}
                  </div>
                  <StatusButton
                    style={{
                      backgroundColor: '#e6c02b',
                      color: 'white',
                      ...acceptReturnStyle
                    }}
                    onClick={handleClickAcceptReturn}
                  >
                    ACCEPT RETURN
                  </StatusButton>
                  <StatusButton
                    style={{
                      visibility: 'hidden'
                    }}
                  ></StatusButton>
                  <StatusButton
                    style={{
                      backgroundColor: '#417921',
                      color: 'white',
                      ...returnedStyle
                    }}
                    onClick={handleClickReturned}
                  >
                    RETURNED
                  </StatusButton>
                  <StatusButton
                    style={{
                      backgroundColor: '#f7371e',
                      ...declineReturnStyle
                    }}
                    onClick={handleClickDeclineReturn}
                  >
                    REJECT RETURN
                  </StatusButton>
                </StatusForm>
              )}
            </TbDashDetailedItem>
          </TbDashDetailedItemRow>
          {(originalOrder?.status.declinedAt ||
            statusString === 'declined') && (
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type={originalOrder?.declineReason ? 'custom' : 'textArea'}
                label='Explanation for declining'
                hasError={explanationError}
                iconUrl=''
                onChange={handleChangeDeclineExplanation}
                value={order?.declineReason}
              >
                <div
                  style={{
                    marginTop: 10,
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    padding: '10px 15px',
                    backgroundColor: 'rgb(240, 240, 240)',
                    opacity: 0.6
                  }}
                >
                  {order?.declineReason}
                </div>
              </TbDashDetailedItem>
            </TbDashDetailedItemRow>
          )}
          {(originalOrder?.status.returnRejectedAt ||
            statusString === 'returnRejected') && (
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type={originalOrder?.returnRejectReason ? 'custom' : 'textArea'}
                label='Explanation for declining'
                hasError={returnRejectReasonError}
                iconUrl=''
                onChange={handleChangeReturnRejectReason}
                value={order.returnRejectReason}
              >
                <div
                  style={{
                    marginTop: 10,
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    padding: '10px 15px',
                    backgroundColor: 'rgb(240, 240, 240)',
                    opacity: 0.6
                  }}
                >
                  {order?.returnRejectReason}
                </div>
              </TbDashDetailedItem>
            </TbDashDetailedItemRow>
          )}
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              xs={5}
              type='text'
              disabled
              label='Order date'
              iconUrl='assets/invoice.svg'
              value={getDateString(order.status.orderedAt)}
            />
            <TbDashDetailedItem
              xs={4}
              type='text'
              disabled
              label='Accepted at'
              value={getDateString(order.status.processingAt)}
            />
            <TbDashDetailedItem
              xs={3}
              type='text'
              disabled
              label='Declined at'
              value={getDateString(order.status.declinedAt)}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              xs={5}
              type='date'
              disabled={!order.taasId || statusString === 'processing'}
              label='Shipping date'
              iconUrl='assets/TB-DB_icon_Shipping.svg'
              onChange={handleShippedChange}
              hasError={shippingHasError}
              value={order.status.shippedAt}
            />
            <TbDashDetailedItem
              type='text'
              xs={7}
              disabled={!order.taasId}
              label='Track & trace'
              hasError={trackTraceError}
              onChange={trackTraceCode => {
                dispatch(updateOrder({ trackTraceCode }))
              }}
              value={order.trackTraceCode}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              xs={5}
              type='text'
              disabled
              label='Return started date'
              iconUrl='assets/TB-DB_icon_Return.svg'
              value={getDateString(order.status.returnProcessStartedAt)}
            />
            <TbDashDetailedItem
              xs={4}
              type='date'
              disabled={
                !(
                  statusString === 'returned' ||
                  statusStringOriginal === 'returned'
                )
              }
              label='Returned at'
              hasError={taasEndError}
              onChange={handleTaasEndChange}
              value={taasEndAt}
            />
            <TbDashDetailedItem
              xs={3}
              type='text'
              disabled
              label='Declined at'
              value={getDateString(order.status.returnRejectedAt)}
            />
          </TbDashDetailedItemRow>
        </TbDashDetailedColumn>

        {/* Right column */}
        <TbDashDetailedColumn>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              xs={12}
              type='console'
              disabled
              label='Sales order notes'
              value=''
            >
              {order.notes}
              {order.returnReason ? (
                <>
                  <br></br>
                  <br></br>
                  Return reason:<br></br>
                  {order.returnReason}
                </>
              ) : null}
            </TbDashDetailedItem>
          </TbDashDetailedItemRow>
          <OrderRequester requester={order.requester} />
          <DividerRow />
          <OrderHealthcareProfessional
            professional={order.healthcareProfessional}
          />
          <DividerRow />
          <OrderReceiver receiver={order.receiver} />
          <DividerRow />
          <OrderDeliveryAddress deliveryAddress={order.deliveryAddress} />
          <DividerRow opacity={1} />
          <OrderReturner
            returner={order.returner}
            pickupAddress={order.pickupAddress}
          />
        </TbDashDetailedColumn>
      </TbDashDetailedContainer>
    )
  }
)

const ConnectedOverviewDetailedRobot = (
  props: Pick<OverviewDetailedRobotProps, 'showErrors'>
) => {
  const { order, statusString, originalOrder } = useSelector<
    RootState,
    OrdersDetailedState
  >(({ ordersDetailed }) => ordersDetailed)
  const dispatch: AppDispatch = useDispatch()
  const taasEndAt = useSelector<RootState, string>(
    ({ detailed }) => detailed?.taas?.endAt
  )

  return (
    <OverviewDetailedRobotComponent
      originalOrder={originalOrder}
      dispatch={dispatch}
      order={order}
      statusString={statusString}
      taasEndAt={taasEndAt}
      {...props}
    />
  )
}

export default ConnectedOverviewDetailedRobot
