/**
 * @file Dialog containing form for handling order
 * @author Max van Loosbroek
 */

import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TbDashDetailedDialog } from "tinybots-react-components"
import { RootState } from "../../../../common/redux/root.reducer"
import { AppDispatch } from "../../../../common/redux/store"
import { detailedValidSelector } from "../../../overview/Detailed/redux/selectors"
import { DetailedState } from "../../../overview/types/types"
import { OrdersDetailedState, OrderTabs } from "../../types/types"
import { orderFormValidSelector } from "../redux/selectors"
import { updateDetailedOrder } from "../redux/thunks"
import OrderDetailed from "./OrderDetailed"

export interface OrderDetailedDialogProps {
  ordersDetailed?: OrdersDetailedState
  detailed?: DetailedState
  activeTab?: OrderTabs
  open: boolean
  color: string
  dispatch: AppDispatch
  onTabChange: (tab: OrderTabs) => void
  handleClose: () => void
  afterSubmit: () => void
  orderFormValid: boolean
}

const getActiveTab = (tabs: {type: OrderTabs}[], active: OrderTabs) => {
  const index = tabs.findIndex(t => t.type === active)
  return index > -1 ? index : 0
}

export const OrderDetailedDialogComponent = ({
  open,
  dispatch,
  handleClose,
  onTabChange,
  color,
  ordersDetailed,
  afterSubmit,
  detailed,
  orderFormValid
}: OrderDetailedDialogProps) => {
  const [showErrors, setShowErrors] = useState(false)
  const handleSubmit = async () => {
    setShowErrors(true)
    if(!orderFormValid) {
      return
    } else {
      const res = await dispatch(updateDetailedOrder())
      if (res?.meta.requestStatus === 'fulfilled') {
        afterSubmit()
      }
    }
  }
  const tabs: {type: OrderTabs, title: string, content: any}[] = []
  const invalid = !detailedValidSelector(detailed).detailedValid || !orderFormValid

  if (open && ordersDetailed.order) {
    const title = 'Sales Order View'
    tabs.push({
      type: 'robot',
      title,
      content: <OrderDetailed showErrors={showErrors} />
    })
  }

  const activeTab = getActiveTab(tabs, ordersDetailed.activeTab)
  const handleTabChange = (index: number) => {
    if (invalid) {
      setShowErrors(true)
    } else {
      onTabChange(tabs[index]?.type)
    }
  }
  
  return (
    <TbDashDetailedDialog
      height={1200}
      loading={ordersDetailed.getting || ordersDetailed.patching}
      open={open}
      color={color}
      tabs={tabs}
      activeTab={activeTab}
      handleTabChange={handleTabChange}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  )
}

const ConnectedOrderDetailedDialog = (
  props: Omit<OrderDetailedDialogProps, 'ordersDetailed' | 'orderFormValid' | 'detailed' >
) => {
  const ordersDetailed = useSelector<RootState, OrdersDetailedState>(
    ({ ordersDetailed }) => ordersDetailed
  )
  const detailed = useSelector<RootState, DetailedState>(
    ({ detailed }) => detailed
  )
  const orderFormValid = useSelector<RootState, boolean>(orderFormValidSelector)
  const dispatch: AppDispatch = useDispatch()

  return (
    <OrderDetailedDialogComponent
      {...props}
      detailed={detailed}
      orderFormValid={orderFormValid}
      ordersDetailed={ordersDetailed}
      activeTab={ordersDetailed.activeTab}
      dispatch={dispatch}
    />
  )
}

export default React.memo(ConnectedOrderDetailedDialog)