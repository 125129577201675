/**
 * @file Linking  a TaaS sub to a specific robot
 * @author Max van Loosbroek
*/

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem,
  TbDashDetailedDialog
} from 'tinybots-react-components'
import { RootState } from '../../../../../common/redux/root.reducer'
import {
  DetailedState,
  TaasDetailed
} from '../../../types/types'
import { AppDispatch } from '../../../../../common/redux/store'
import {
  useChangeHandlersLinkTaasRobot,
  useLinkTaasRobotFormInfo,
  useSubmitLinkTaasRobot
} from './hooks'
import {
  relationsSearchSelector,
  taasSearchSelector
} from '../../redux/selectors'
import {
  searchRelations,
  searchTaas
} from '../../redux/thunks'
import { getTaasRef } from '../../../stringTransformers'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import moment from 'moment';
import { RelationDetailed } from '../../../types/RelationDetailed'

export interface LinkDialogTaasRobotProps {
  detailed?: DetailedState
  open: boolean
  color: string
  dispatch: AppDispatch
  handleClose: () => void
  afterSubmit: () => void
}

export const LinkDialogTaasRobotComponent = ({
  open,
  dispatch,
  handleClose,
  afterSubmit,
  color,
  detailed
}: LinkDialogTaasRobotProps) => {
  const [showErrors, setShowErrors] = useState(false)
  const [taasId, setTaasId] = useState<string>()
  const previousTaasId = usePrevious(taasId)
  const [selectedTaas, setSelectedTaas] = useState<TaasDetailed>()
  const patchTaas = useCallback(newValue => {
    setSelectedTaas(oldValue => {
      return { ...oldValue, ...newValue }
    })
  }, [])
  const [relationName, setRelationName] = useState<string>()
  const [selectedRelationId, setSelectedRelationId] = useState<string>()
  const { robot } = detailed
  const taasSearch = useSelector(taasSearchSelector(taasId))
  const relationsSearch = useSelector((state: RootState) =>
    relationsSearchSelector(state.detailed)
  )
  const selectedRelation = relationsSearch.find(
    r => r.relationId.toString() === selectedRelationId
  )
  const relation: RelationDetailed | undefined = robot?.relation

  const taasOptions = taasSearch.map(t => {
    const disabled =
      !(selectedRelation && selectedRelation.relationId) ||
      (selectedRelation && ['Depot', 'Distributer'].includes(selectedRelation.relationType))
    const key = getTaasRef(t)
    return { key, disabled }
  })

  useEffect(() => {
    if (taasId === previousTaasId) return
    const selected = taasSearch.filter(s => {
      const key = getTaasRef(s)
      return key.toString() === taasId
    })
    if (selected.length === 1) {
      setSelectedTaas(selected[0])
    } else if (selected.length === 0) {
      setSelectedTaas(undefined)
    }
  }, [taasSearch, taasId])

  useEffect(() => {
    const rId = selectedRelation?.relationId
    if (rId) {
      dispatch(searchTaas({ relationId: rId.toString() }))
    }
  }, [selectedRelation])

  useEffect(() => {
    relationName && dispatch(searchRelations({ name: relationName.toString() }))
  }, [relationName])

  const {
    handleChangeStartDate,
    handleChangeShippedAt,
    handleSubNoteChange,
    handleRobotNoteChange
  } = useChangeHandlersLinkTaasRobot({ patchTaas })

  const handleSubmit = useSubmitLinkTaasRobot({
    relation,
    selectedTaas,
    selectedRelation,
    setShowErrors,
    dispatch,
    afterSubmit,
    robot
  })

  const {
    error: {
      shippedAtHasError,
      subscriptionStartHasError,
      relationHasError
    }
  } = useLinkTaasRobotFormInfo({
    selectedTaas,
    relation,
    selectedRelation,
    showErrors
  })

  const previousShipped = usePrevious(selectedTaas?.shippedAt)
  useEffect(() => {
    if(selectedTaas?.shippedAt && previousShipped !== selectedTaas?.shippedAt && !selectedTaas?.startAt) {
      const startDefault = moment(selectedTaas?.shippedAt).add(1, 'day').toISOString()
      handleChangeStartDate(startDefault)
    }
  }, [selectedTaas, previousShipped])

  const tabs = [
    {
      title: 'Link Robot with TaaS',
      content: (
        <TbDashDetailedContainer>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                hasError={relationHasError}
                type='autoComplete'
                label='Relation'
                iconUrl='assets/relation.svg'
                onChange={v => setRelationName(v as string)}
                onSelect={r => r && setSelectedRelationId(r.key.toString())}
                options={relationsSearch.map(r => ({
                  label: r.name,
                  disabled: false,
                  key: r.relationId.toString()
                }))}
                value={relationName}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='Robot Serial'
                iconUrl='assets/tessa-dark.svg'
                value={robot?.serial}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                iconUrl='assets/category.svg'
                label='Box number'
                value={robot?.boxNumber || '-'}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              {
                <TbDashDetailedItem
                  disabled={!selectedRelation?.relationId}
                  hasError={() =>
                    showErrors && relation && !selectedTaas?.id
                      ? 'Select a TaaS instance'
                      : undefined
                  }
                  type='autoComplete'
                  label='Subscription reference'
                  iconUrl='assets/subref.svg'
                  onChange={v => {
                    setTaasId(v as string)
                  }}
                  options={taasOptions}
                  value={taasId}
                />
              }
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedTaas?.id}
                type='date'
                label='Shipping date (DD-MM-YYYY)'
                iconUrl='assets/TB-DB_icon_Shipping.svg'
                hasError={shippedAtHasError}
                value={selectedTaas?.shippedAt}
                onChange={handleChangeShippedAt}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedTaas?.id}
                type='date'
                label='Start date (DD-MM-YYYY)'
                hasError={subscriptionStartHasError}
                iconUrl='assets/calendar.svg'
                value={selectedTaas?.startAt}
                onChange={handleChangeStartDate}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedTaas?.id}
                xs={12}
                type='textArea'
                label='Subscription notes'
                value={selectedTaas?.notes}
                onChange={handleSubNoteChange}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type='textArea'
                label='Robot notes'
                value={robot?.notes}
                onChange={handleRobotNoteChange}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
        </TbDashDetailedContainer>
      )
    }
  ]

  return (
    <TbDashDetailedDialog
      loading={detailed.getting || detailed.patching}
      open={open}
      color={color}
      tabs={tabs}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      activeTab={0}
    />
  )
}

const ConnectedLinkDialogTaasRobot = (props: LinkDialogTaasRobotProps) => {
  const detailed = useSelector<RootState, DetailedState>(
    ({ detailed }) => detailed
  )

  const dispatch: AppDispatch = useDispatch()

  return (
    <LinkDialogTaasRobotComponent
      {...props}
      detailed={detailed}
      dispatch={dispatch}
    />
  )
}

export default React.memo(ConnectedLinkDialogTaasRobot)
