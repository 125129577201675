/**
 * @file Constants used for overview feature
 * @author Max van Loosbroek
 */

import {
  OrderOverviewColumn,
  OrderOverviewFilter,
  PossibleStatuses,
  UrlStatuses
} from '../types/types'

export const ORDER_OVERVIEW_COLUMNS: OrderOverviewColumn[] = [
  {
    key: 'orderId',
    headerName: 'SO number',
    width: 120
  },
  {
    key: 'integration',
    headerName: 'source',
    width: 150,
    disableSort: true
  },
  {
    key: 'orderDate',
    headerName: 'order date',
    width: 100,
    disableSort: true
  },
  {
    key: 'relation',
    headerName: 'relation',
    width: 120,
    disableSort: true
  },
  {
    key: 'status',
    headerName: 'order status',
    width: 185,
    disableSort: true
  },
  {
    key: 'subRef',
    headerName: 'sub. ref.',
    width: 85,
    canHide: true,
    disableSort: true
  },
  {
    key: 'boxNr',
    headerName: 'box nr.',
    width: 85,
    fontFamily: 'Roboto-Mono-Light',
    canHide: true,
    disableSort: true
  },
  {
    key: 'serial',
    headerName: 'robot',
    width: 140,
    canHide: true,
    disableSort: true
  },
  {
    key: 'shippingDate',
    headerName: 'ship date',
    width: 120,
    canHide: true,
    disableSort: true
  },
  {
    key: 'tessaExpertNeeded',
    headerName: 'T. expert',
    width: 85,
    canHide: true,
    disableSort: true
  },
  {
    key: 'clientId',
    headerName: 'client id',
    width: 150,
    fontFamily: 'Roboto-Mono-Light',
    canHide: true,
    disableSort: true
  },
  {
    key: 'teamId',
    headerName: 'team id',
    width: 150,
    fontFamily: 'Roboto-Mono-Light',
    canHide: true,
    disableSort: true
  },
  {
    key: 'notes',
    headerName: 'SO notes',
    fontFamily: 'Roboto-Mono-Light',
    width: 178,
    canHide: true,
    disableSort: true
  }
]

export const ORDER_OVERVIEW_FILTERS: OrderOverviewFilter[] = [
  {
    label: 'All',
    filterType: 'status',
    filterTerm: 'all'
  },
  {
    label: 'New',
    filterType: 'status',
    filterTerm: 'ordered'
  },
  {
    label: 'Accepted',
    filterType: 'status',
    filterTerm: 'processing'
  },
  {
    label: 'Shipped',
    filterType: 'status',
    filterTerm: 'shipped'
  },
  {
    label: 'Delivered',
    filterType: 'status',
    filterTerm: 'delivered'
  },
  {
    label: 'Active',
    filterType: 'status',
    filterTerm: 'activated'
  },
  {
    label: 'Declined',
    filterType: 'status',
    filterTerm: 'declined'
  },
  {
    label: 'New return',
    filterType: 'status',
    filterTerm: 'returnProcessStarted'
  },
  {
    label: 'Returning',
    filterType: 'status',
    filterTerm: 'returnAccepted'
  },
  {
    label: 'R. rejects',
    filterType: 'status',
    filterTerm: 'returnRejected'
  },
  {
    label: 'Returned',
    filterType: 'status',
    filterTerm: 'returned'
  }
]

export const STATUS_LABELS: {
  [key in PossibleStatuses]: string
} = {
  ordered: 'NEW ORDER',
  processing: 'ACCEPTED',
  shipping: 'SHIPPING',
  shipped: 'SHIPPED',
  returnProcessStarted: 'NEW RETURN',
  returned: 'RETURNED',
  declined: 'DECLINED',
  delivered: 'DELIVERED',
  returnAccepted: 'RETURNING',
  returnRejected: 'RETURN REJECT',
  activated: 'ACTIVE'
}

export const STATUS_COLORS: {
  [key in PossibleStatuses]: string
} = {
  ordered: '#ba9a20',
  processing: '#e6c02b',
  shipping: '#549788',
  shipped: '#417921',
  returnProcessStarted: '#8356ac',
  returned: '#3f7a19',
  declined: '#7d7d7d',
  delivered: '#3f7a19',
  activated: '#3f7a19',
  returnAccepted: '#e6c02b',
  returnRejected: '#3f7a19'
}

export const URL_STATUS: {
  [key in UrlStatuses]:
    | 'accept'
    | 'decline'
    | 'delivered'
    | 'accept-return'
    | 'reject-return'
} = {
  processing: 'accept',
  declined: 'decline',
  delivered: 'delivered',
  returnAccepted: 'accept-return',
  returnRejected: 'reject-return'
}
