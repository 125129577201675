/**
 * @file Dialog where you can add a subscription/relation to a robot
 * @author Max van Loosbroek
 */

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem,
  TbDashDetailedDialog
} from 'tinybots-react-components'
import { RootState } from '../../../../../common/redux/root.reducer'
import {
  DetailedState,
  SubscriptionBase
} from '../../../types/types'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import { getSubRefLength, isCustomer } from '../../../stringTransformers'
import { AppDispatch } from '../../../../../common/redux/store'
import {
  useChangeHandlersLinkRobot,
  useLinkRobotFormInfo,
  useSubmitLinkRobot
} from './hooks'
import {
  defaultSubSelector,
  subsSearchSelector,
  relationsSearchSelector
} from '../../redux/selectors'
import {
  searchSubscriptions,
  searchRelations
} from '../../redux/thunks'
import { RelationDetailed } from '../../../types/RelationDetailed'
export interface LinkDialogRobotProps {
  detailed?: DetailedState
  open: boolean
  color: string
  dispatch: AppDispatch
  handleClose: () => void
  afterSubmit: () => void
}

export const LinkDialogRobotComponent = ({
  open,
  dispatch,
  handleClose,
  afterSubmit,
  color,
  detailed
}: LinkDialogRobotProps) => {
  const [showErrors, setShowErrors] = useState(false)
  const [subRef, setSubRef] = useState('')
  const previousSubRef = usePrevious(subRef)
  const [selectedSub, setSelectedSub] = useState<SubscriptionBase>()
  const [relationName, setRelationName] = useState<string>()
  const [selectedRelationId, setSelectedRelationId] = useState<string>()
  const { chain, robot } = detailed
  const patchSub = useCallback(newValue => {
    setSelectedSub(oldValue => {
      return { ...oldValue, ...newValue }
    })
  }, [])
  const defaultSub = useSelector((state: RootState) =>
    defaultSubSelector(state.detailed)
  )
  const subsSearch = useSelector((state: RootState) =>
    subsSearchSelector(state.detailed)
  )
  const relationsSearch = useSelector((state: RootState) =>
    relationsSearchSelector(state.detailed)
  )
  const selectedRelation = relationsSearch.find(
    r => r.relationId.toString() === selectedRelationId
  )
  const relation: RelationDetailed | undefined =
    chain?.relation ?? robot?.relation

  const subOptions = subsSearch.map(s => {
    const key = getSubRefLength(s)
    return { key, disabled: false }
  })

  useEffect(() => {
    if (subRef === previousSubRef) return
    const selected = subsSearch.filter(s => {
      const label = getSubRefLength(s)
      return label === subRef
    })
    if (selected.length === 1) {
      setSelectedSub(selected[0])
    } else if (selected.length === 0) {
      setSelectedSub(undefined)
    }
  }, [subsSearch, subRef])

  useEffect(() => {
    const rId = selectedRelation?.relationId ?? relation?.relationId
    if (rId) {
      dispatch(searchSubscriptions({ relationId: rId.toString() }))
    }
  }, [relation, selectedRelation])

  useEffect(() => {
    relationName && dispatch(searchRelations({ name: relationName.toString() }))
  }, [relationName])

  const {
    handleChangePaymentReference,
    handleChangeZohoReference,
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangeShippedAt,
    handleSubNoteChange,
    handleRobotNoteChange
  } = useChangeHandlersLinkRobot({ patchSub })

  const handleSubmit = useSubmitLinkRobot({
    relation,
    selectedSub,
    selectedRelation,
    setShowErrors,
    dispatch,
    afterSubmit,
    robot
  })

  const {
    error: {
      shippedAtHasError,
      referenceError,
      subscriptionStartHasError,
      ticketRefHasError,
      subscriptionEndHasError,
      relationHasError
    },
    helperText: { refHelperText }
  } = useLinkRobotFormInfo({
    relation,
    selectedSub,
    selectedRelation,
    showErrors,
    robot
  })

  const tabs = [
    {
      title: 'Link Robot with Subscription',
      content: (
        <TbDashDetailedContainer>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              {isCustomer(relation) ? (
                <TbDashDetailedItem
                  type='text'
                  disabled
                  label='Relation'
                  iconUrl='assets/relation.svg'
                  value={relation?.name}
                />
              ) : (
                <TbDashDetailedItem
                  hasError={relationHasError}
                  type='autoComplete'
                  label='Relation'
                  iconUrl='assets/relation.svg'
                  onChange={v => setRelationName(v as string)}
                  onSelect={r => r && setSelectedRelationId(r.key.toString())}
                  options={relationsSearch.map(r => ({
                    label: r.name,
                    disabled: false,
                    key: r.relationId.toString()
                  }))}
                  value={relationName}
                />
              )}
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='Robot Serial'
                iconUrl='assets/tessa-dark.svg'
                value={robot?.serial}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                iconUrl='assets/category.svg'
                label='Box number'
                value={robot?.boxNumber || '-'}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              {chain ? (
                <TbDashDetailedItem
                  type='text'
                  disabled
                  label='Subscription reference'
                  iconUrl='assets/subref.svg'
                  value={`${defaultSub?.subscriptionReference} (${defaultSub?.subscriptionLength} days)`}
                />
              ) : (
                <TbDashDetailedItem
                  hasError={() =>
                    showErrors && isCustomer(relation) && !selectedSub?.subscriptionId
                      ? 'Select a subscription'
                      : undefined
                  }
                  type='autoComplete'
                  label='Subscription reference'
                  iconUrl='assets/subref.svg'
                  onChange={v => setSubRef(v as string)}
                  options={subOptions}
                  value={subRef}
                />
              )}
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                iconUrl='assets/invoice.svg'
                label='Robot Payment Reference'
                value={robot?.paymentReference}
                onChange={handleChangePaymentReference}
                helperText={refHelperText}
                hasError={referenceError}
              />
              <TbDashDetailedItem
                type='number'
                iconUrl='assets/tb_zendesk.png'
                label='Zoho Reference'
                value={robot?.zendeskTicketId}
                onChange={handleChangeZohoReference}
                hasError={referenceError ?? ticketRefHasError}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedSub?.subscriptionId}
                type='date'
                label='Shipping date (DD-MM-YYYY)'
                iconUrl='assets/TB-DB_icon_Shipping.svg'
                hasError={shippedAtHasError}
                value={selectedSub?.shippedAt}
                onChange={handleChangeShippedAt}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedSub?.subscriptionId}
                type='date'
                label='Start date (DD-MM-YYYY)'
                hasError={subscriptionStartHasError}
                iconUrl='assets/calendar.svg'
                value={selectedSub?.startAt}
                onChange={handleChangeStartDate}
              />
              <TbDashDetailedItem
                disabled={!selectedSub?.subscriptionId}
                type='date'
                label='End date (DD-MM-YYYY)'
                hasError={subscriptionEndHasError}
                value={selectedSub?.endAt}
                onChange={handleChangeEndDate}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedSub?.subscriptionId}
                xs={12}
                type='textArea'
                label='Subscription notes'
                value={selectedSub?.notes}
                onChange={handleSubNoteChange}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type='textArea'
                label='Robot notes'
                value={robot?.notes}
                onChange={handleRobotNoteChange}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
        </TbDashDetailedContainer>
      )
    }
  ]

  return (
    <TbDashDetailedDialog
      loading={detailed.getting || detailed.patching}
      open={open}
      color={color}
      tabs={tabs}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      activeTab={0}
    />
  )
}

const ConnectedLinkDialogRobot = (props: LinkDialogRobotProps) => {
  const detailed = useSelector<RootState, DetailedState>(
    ({ detailed }) => detailed
  )

  const dispatch: AppDispatch = useDispatch()

  return (
    <LinkDialogRobotComponent
      {...props}
      detailed={detailed}
      dispatch={dispatch}
    />
  )
}

export default React.memo(ConnectedLinkDialogRobot)
