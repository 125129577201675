import { createAsyncThunk } from '@reduxjs/toolkit'
import { IHttpService } from 'angular'
import { RelationsByEmailItem, deleteOwnerRequest, getOrganisationAdminAcounts, getRelationsByEmail, sendInviteRequest } from './api'
import { OrganisationAdminAccount } from '../types'
import { parseHttpError } from '../../../../common/utilities/errorHandling'

export const retrieveOrganisationAdminAcounts = createAsyncThunk<
  OrganisationAdminAccount[],
  { relationId: number | string },
  { extra: { $http: IHttpService } }
>(
  'organisationAccounts/retrieveOrganisationAdminAcounts',
  async ({ relationId }, thunkApi) => {
    const { data } = await getOrganisationAdminAcounts(
      thunkApi.extra.$http,
      relationId
    )
    return data
  }
)

export const searchTeamleader = createAsyncThunk<
  RelationsByEmailItem[],
  { email: string; relationId: number | string },
  { extra: { $http: IHttpService } }
>('organisationAccounts/searchTeamleader', async ({ email, relationId }, thunkApi) => {
  const { data } = await getRelationsByEmail(thunkApi.extra.$http, email)
  return data.filter(item => item.companies.findIndex(c => c.relationId === relationId) > -1)
})

export const inviteOwner = createAsyncThunk<
  void,
  { email: string; relationId: number | string },
  { extra: { $http: IHttpService } }
>('organisationAccounts/inviteOwner', async (body, thunkApi) => {
  await sendInviteRequest(thunkApi.extra.$http, body)
})

export const deleteOwner = createAsyncThunk<
  void,
  { email: string; relationId: number | string },
  { extra: { $http: IHttpService } }
>('organisationAccounts/deleteOwner', async ({ email, relationId }, thunkApi) => {
  await deleteOwnerRequest(thunkApi.extra.$http, email).catch(parseHttpError)
  await thunkApi.dispatch(retrieveOrganisationAdminAcounts({
    relationId
  }))
})
