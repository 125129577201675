import template from './tb-table.html'
import './tb-table.scss'

const tbTable = {
  bindings: {
    type: '<',
    sortParams: '<',
    items: '<',
    sortDirection: '<',
    onSort: '&',
    onShip: '&',
    onLinkRelation: '&',
    onShow: '&',
    onDetailed: '&',
    onAddSubscriptions: '&',
  },
  template,
  controller: /* @ngInject */ class TableController {
    constructor(PREMIUM_PACKAGES, SERVICE_PACKAGES) {
      this.premiumPackages = PREMIUM_PACKAGES;
      this.servicePackages = SERVICE_PACKAGES;
    }

    sort(param) {
      if (this.sortParams[param].noSort) { return }
      this.sortDirection = this.sortParams[param].sort ? -this.sortDirection : 1
      Object.keys(this.sortParams).forEach(key => {
        this.sortParams[key].sort = false
      })
      this.sortParams[param].sort = true
      this.sortType = this.sortParams[param]
      this.sortType.name = param
      this.sortTable(param)
    }

    moreItems() {
      const nViewItems = this.viewItems.length
      const nItems = this.items.length
      if (nViewItems < nItems) {
        const addedItems = Math.min(50, nItems - nViewItems)
        this.viewItems = this.items.slice(0, this.viewItems.length + addedItems)
      }
    }

    sortTable() {
      this.items.sort((a, b) => {
        let valueA = a[this.sortType.path]
        let valueB = b[this.sortType.path]
        if (['startDate', 'endDate'].includes(this.sortType.name)) {
          valueA = valueA ? moment.utc(valueA, 'DD-MM-YYYY').unix() : null
          valueB = valueB ? moment.utc(valueB, 'DD-MM-YYYY').unix() : null
        }
        if (valueA == null && valueB == null) {
          return 0
        } else if (valueA == null) {
          return 1
        } else if (valueB == null) {
          return -1
        } else {
          return valueA < valueB ? -this.sortDirection : this.sortDirection
        }
      })
      this.viewItems = this.items.slice(0, this.viewItems.length)
    }

    toName(str) {
      return str.replace(/([A-Z])/g, ' $1').toLowerCase()
    }

    tableValue(item, path) {
      return item[path]
    }

    hasButton(key, item, value) {
      return ((key === 'relation' || key === 'robot') && !this.tableValue(item, value.path))
    }

    text(key, item, value) {
      return (!((key === 'relation' || key === 'robot') && !this.tableValue(item, value.path)) && !(key === 'ex.Okr' || key === 'ex.Analytics'))
    }

    shipRobot(item) {
      this.onShip({ $event: { item } })
    }

    linkRelation(item) {
      this.onLinkRelation({ $event: { item } })
    }

    details(item) {
      this.onDetailed({ $event: { item } })
    }

    show(view) {
      this.onShow({ $event: { view: view } })
    }

    addSubscription($event, item) {
      $event.stopPropagation()
      this.onAddSubscriptions({ $event: { item } })
    }

    getPremiumPackageItem(premiumPackage) {
      const { displayName } = this.premiumPackages.find(p => p.value === premiumPackage);
      return displayName
    }

    getServicePackageItem(servicePackage) {
      const { displayName } = this.servicePackages.find(p => p.value === servicePackage)
      return displayName
    }

    $onChanges(changes) {
      if (changes.items) {
        this.items = angular.copy(this.items)
        if (this.items && this.viewItems) {
          const itemsLength = Math.max(25, this.viewItems.length)
          this.viewItems = this.items.slice(0, itemsLength)
          this.sortTable()
        }
      }
    }

    $onInit() {
      const sorter = Object.keys(this.sortParams).filter(name => this.sortParams[name].sort === true)
      this.sortType = this.sortParams[sorter[0]]
      this.sortType.name = sorter[0]
      this.sortDirection = 1
      this.viewItems = this.items ? this.items.slice(0, 25) : []
    }
  }
}

export default tbTable
