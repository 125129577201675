import React, { useState } from 'react'
import { TbDashDetailedItem } from 'tinybots-react-components'

export const AddressType = ({
  addressType,
  onChange
}: {
  addressType?: string | null
  onChange: (addressType: string) => void
}) => {
  return (
    <TbDashDetailedItem
      type='text'
      label='Delivery address of'
      value={addressType}
      onChange={onChange}
    />
  )
}
