import { IsDateString, IsInt, IsOptional, IsString, Min, MinLength, ValidateNested } from 'class-validator'
import { Type } from 'class-transformer'
import { Address } from './Address'
import { OrderStatus } from './OrderStatus'
import { Contact } from './Contact'
import { ThirdpartyIntegration } from './ThirdPartyIntegration'
import { OrderRobot } from './OrderRobot'
import { RelationDetailed } from '../../overview/types/RelationDetailed'
import { Requester } from './Requester'
import { Client } from './Client'
export class OrderBase {
  @IsOptional()
  @IsInt()
  @Min(1)
  id?: number | null

  @IsOptional()
  @IsInt()
  orderConceptId?: number

  @IsOptional()
  @IsDateString()
  createdAt?: string
  
  @ValidateNested()
  @Type(() => RelationDetailed)
  relation: RelationDetailed

  @ValidateNested()
  @Type(() => ThirdpartyIntegration)
  integration: ThirdpartyIntegration

  @IsOptional()
  @IsInt()
  taasId?: number | null

  @ValidateNested()
  @Type(() => Address)
  deliveryAddress: Address

  @IsOptional()
  @ValidateNested()
  @Type(() => Address)
  pickupAddress?: Address | null

  @MinLength(1)
  @IsString()
  teamId: string

  @IsOptional()
  @IsString()
  discipline?: string | null

  @IsOptional()
  @IsString()
  healthCareDemand?: string | null

  @IsOptional()
  @IsString()
  notes?: string | null

  @IsOptional()
  @ValidateNested()
  @Type(() => OrderStatus)
  status?: OrderStatus

  @IsOptional()
  @ValidateNested()
  @Type(() => OrderRobot)
  robot?: OrderRobot | null

  @IsOptional()
  @IsString()
  declineReason?: string | null

  @IsOptional()
  @IsString()
  returnReason?: string | null

  @IsOptional()
  @IsString()
  returnRejectReason?: string | null

  @ValidateNested()
  @Type(() => Requester)
  requester: Requester

  @IsOptional()
  @ValidateNested()
  @Type(() => Contact)
  receiver?: Contact | null

  @IsOptional()
  @ValidateNested()
  @Type(() => Contact)
  returner?: Contact | null

  @IsOptional()
  @ValidateNested()
  @Type(() => Contact)
  healthcareProfessional?: Contact | null

  @IsOptional()
  @IsString()
  trackTraceCode?: string | null

  @IsOptional()
  @IsString()
  internalNotes?: string | null
  
  @IsString()
  tessaExpertNeeded: 'yes' | 'no' | 'unknown'
}

export class OrderFinal extends OrderBase {
  @MinLength(1)
  @IsString()
  clientId: string | null

  @IsOptional()
  @IsString()
  clientUuid: string | null
}

export class OrderFinalV5 extends OrderBase {
  @ValidateNested()
  @Type(() => Client)
  client: Client
}

