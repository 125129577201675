/* @ngInject */
export default class NotesService {
  constructor () {
    this.seperator = '\n\n--AUTO--\n'
  }

  addToNote (note, update) {
    let start = -1
    if (note) {
      start = note.search(this.seperator)
    }
    if (start === -1) {
      return `${note || ''}\n\n--AUTO--\n${update}`
    } else {
      return `${note.slice(0, start + this.seperator.length)}${update}${note.slice(start + this.seperator.length)}`
    }
  }

  robotNote (note, title, params) {
    let update = `\n>> ${moment().format('YYYY-MM-DD')}: Robot [${title}]\n`
    Object.keys(params).forEach((key, index) => {
      update += `${key}: ${params[key]}\n`
    })
    update += 'Comment: -\n'
    return this.addToNote(note, update)
  }

  dataTransferNotes (currentRobot, updateRobot) {
    const currentParams = {
      'Switched to': updateRobot.serial
    }
    const updateParams = {
      'Switched from': currentRobot.serial
    }
    return {
      updateRobotNotes: this.robotNote(updateRobot.notes, 'DATA TRANSFER', updateParams),
      currentRobotNotes: this.robotNote(currentRobot.notes, 'DATA TRANSFER', currentParams)
    }
  }
};
