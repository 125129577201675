/**
 * @file Wrapper component in AngularJS for React overview page
 * @author Max van Loosbroek
 */

import template from './orderOverviewPage.html'

const OrderOverviewPage = {
  bindings: {
    user: '<'
  },
  template,
  controller: /* @ngInject */ class OrderOverviewPageController {
    constructor (StoreService, $state, $location) {
      this.StoreService = StoreService
      this.$location = $location
      this.urlParams = $state.params
    }
  }
}

export default OrderOverviewPage
