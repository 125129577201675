import { DialogTitle, DialogContent } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { TbDashDialog } from 'tinybots-react-components'
import { clearStateDetailed } from '../../components/overview/Detailed/redux/reducer'
import { clearDetailedParams } from '../../components/overview/Overview/Overview'

export default ({ handleClose }: { handleClose: (...args: any) => any }) => {
  const [showSavedDialog, setShowSavedDialog] = useState(false)
  const savedMessage = (
    <TbDashDialog
      color='rgb(218, 71, 37)'
      open={showSavedDialog}
      closeButton={'CLOSE'}
      handleClose={() => {
        setShowSavedDialog(false)
        handleClose()
      }}
      submitButton={'CONTINUE'}
      handleSubmit={() => {
        setShowSavedDialog(false)
      }}
    >
      <DialogTitle>Changes are saved</DialogTitle>
      <DialogContent>Do you want to continue editing?</DialogContent>
    </TbDashDialog>
  )
  return {
    setShowSavedDialog,
    savedMessage
  }
}
