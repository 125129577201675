import './login/login.module'
import './login-mfa/login-mfa.module';
import './set-mfa/set-mfa-page.module';
import AuthService from './auth.service'
import authForm from './auth-form/auth-form.component'
import authWarning from './auth-warning/auth-warning.component'
import StoreService from '../../common/redux/store.service';

export default angular
  .module('components.auth', ['login', 'loginMfa', 'setMfaPage'])
  .component('authForm', authForm)
  .component('authWarning', authWarning)
  .service('StoreService', StoreService)
  .service('AuthService', AuthService)
  .config(($stateProvider, $urlRouterProvider) => {
    $stateProvider
      .state('auth', {
        redirectTo: 'auth.login',
        url: '/auth',
        template: '<div ui-view></div>'
      })
    $urlRouterProvider.otherwise('/login')
  })
  .run(($transitions, $state, $auth) => {
    $transitions.onStart({ to: 'auth.**' }, () => {
      if ($auth.isAuthenticated()) {
        return $state.target('app')
      }
    })

    $transitions.onStart({ to: state => !!(state.data && state.data.requiredAuth) },
      () => {
        if (!$auth.isAuthenticated()) {
          return $state.target('auth.login')
        }
      })
  })
  .name
