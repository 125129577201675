/**
 * @file Wrapper component in AngularJS for React overview page
 * @author Max van Loosbroek
 */

import template from './overviewPage.html'

const overviewPage = {
  bindings: {
    user: '<'
  },
  template,
  controller: /* @ngInject */ class OverviewPageController {
    constructor (StoreService, $state, $location) {
      this.StoreService = StoreService
      this.$state = $state
      this.$location = $location
      this.urlParams = this.$state.params
    }
  }
}

export default overviewPage
