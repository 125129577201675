/* @ngInject */
export default class HeartbeatService {
  constructor ($http, URLS) {
    'ngInject';
    this.$http = $http
    this.URLS = URLS
  }

  getBeats (serial, date, hour, token) {
    const url = `${this.URLS.admin.health}/heartbeats/${serial}`
    const params = {
      date: date,
      hour: hour,
      token: token
    }
    return this.$http.post(url, params)
  }

  getLogs (robotId, token) {
    let url = `${this.URLS.admin.health}/logs/${robotId}`
    if (token) {
      url = `${url}?token=${token}`
    }
    return this.$http.get(url)
  }

  getConfig (robotId) {
    let url = `${this.URLS.admin.health}/config/${robotId}`
    return this.$http.get(url)
  }

  update (robotId) {
    let url = `${this.URLS.admin.health}/update/${robotId}`
    return this.$http.post(url)
  }

  getBeat (key) {
    if (key) {
      const url = `${this.URLS.admin.health}/heartbeats?key=${key}`
      return this.$http.get(url)
    }
  }

  getcoreBeat (robotId) {
    if (robotId) {
      const url = `${this.URLS.admin.health}/corebeat/${robotId}`
      return this.$http.get(url)
    }
  }

  reboot (robotId) {
    const url = `${this.URLS.admin.health}/reboot/${robotId}`
    return this.$http.post(url)
  }

  requestLogs (robotId) {
    const url = `${this.URLS.admin.health}/logs/${robotId}`
    return this.$http.post(url)
  }

  gets3LogUrl (key) {
    return `${this.URLS.admin.s3robotlogs}/${key}?region=eu-central-1&tab=overview`
  }
}
