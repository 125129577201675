import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { OrganisationAccountsState, OrganisationAdminAccount } from '../types'
import { deleteOwner, inviteOwner, retrieveOrganisationAdminAcounts, searchTeamleader } from './thunks'
import { RootState } from '../../../../common/redux/root.reducer'
import { RelationsByEmailItem } from './api'

export const organisationAdminAccountsAdapter =
  createEntityAdapter<OrganisationAdminAccount>({
    selectId: entity => entity.email
  })

export const searchByEmailAdapter = createEntityAdapter<RelationsByEmailItem>({
  selectId: entity => entity.relationId
})

export const initialStateOrganisationAccounts =
  (): OrganisationAccountsState => ({
    ...organisationAdminAccountsAdapter.getInitialState({
      getting: true,
      getError: null
    }),
    invite: searchByEmailAdapter.getInitialState({
      patching: false,
      patchError: null,
      getting: false,
      getError: null
    })
  })

export const overviewSlice = createSlice({
  name: 'organisationAccounts',
  initialState: initialStateOrganisationAccounts(),
  reducers: {
    clearErrorsOrganisationAccounts: state => {
      state.getError = null
    },
    clearErrorsInvite: state => {
      state.invite.getError = null
      state.invite.patchError = null
    },
    clearState: () => initialStateOrganisationAccounts(),
    clearSearch: state => {
      state.invite = searchByEmailAdapter.getInitialState({
        patching: false,
        patchError: null,
        getting: false,
        getError: null
      })
    }
  },
  extraReducers: builder => {
    builder.addCase(retrieveOrganisationAdminAcounts.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(retrieveOrganisationAdminAcounts.rejected, state => {
      state.getting = false
      state.getError = 'Unknown error'
    })
    builder.addCase(
      retrieveOrganisationAdminAcounts.fulfilled,
      (state, action) => {
        state.getting = false
        state.getError = null
        organisationAdminAccountsAdapter.setAll(state, action.payload)
      }
    )
    builder.addCase(searchTeamleader.pending, state => {
      state.invite.getting = true
      state.invite.getError = null
    })
    builder.addCase(searchTeamleader.rejected, state => {
      state.invite.getting = false
      state.invite.getError = 'Unknown error'
    })
    builder.addCase(searchTeamleader.fulfilled, (state, action) => {
      state.invite.getting = false
      state.invite.getError = null
      searchByEmailAdapter.setAll(state.invite, action.payload)
    })
    builder.addCase(inviteOwner.pending, state => {
      state.invite.patching = true
      state.invite.patchError = null
    })
    builder.addCase(inviteOwner.rejected, state => {
      state.invite.patching = false
      state.invite.patchError = 'Unknown error'
    })
    builder.addCase(inviteOwner.fulfilled, (state, action) => {
      state.invite.patching = false
      state.invite.patchError = null
    })
    builder.addCase(deleteOwner.pending, state => {
      state.invite.patching = true
      state.invite.patchError = null
    })
    builder.addCase(deleteOwner.rejected, (state, action) => {
      state.invite.patching = false
      state.invite.patching = false
      state.invite.patchError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(deleteOwner.fulfilled, (state, action) => {
      state.invite.patching = false
      state.invite.patchError = null
    })
  }
})

export const { clearState, clearSearch, clearErrorsInvite } = overviewSlice.actions
export const organisationAdminAccountsSelector = ({
  organisationAccounts
}: RootState) => {
  const accounts = organisationAdminAccountsAdapter
    .getSelectors()
    .selectAll(organisationAccounts)
  return accounts.sort((a, b) => {
    return new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
      ? -1
      : 1
  })
}

// Extract the action creators object and the reducer
const { reducer } = overviewSlice

export const organisationAccountsReducer = reducer
