/**
 * @file Functions for making http requests for order detailed
 * @author Max van Loosbroek
 */

import { IHttpService } from 'angular'
import { URLS } from '../../../../../common/utilities/constants/constants.module'
import { parseHttpError } from '../../../../../common/utilities/errorHandling'
import { ReturnConcept } from '../types/types'
import { ReturnForm } from '../types/ReturnForm'
import { OrderFinal } from '../../../types/OrderFinal'

export interface SearchParamsReturnConcept {
  clientId?: string
  uuid?: string
  robotSerial?: string
}

export const getReturnConceptsRequest = (
  $http: IHttpService,
  { clientId, uuid, robotSerial }: SearchParamsReturnConcept
) => {
  const params: {
    clientUuid?: string
    clientId?: string
    tessaCode?: string
  } = {}
  if (uuid) params.clientUuid = uuid
  if (clientId) params.clientId = clientId
  if (robotSerial) params.tessaCode = robotSerial
  const url = URLS.admin.taasReturnConcepts
  return $http.get<ReturnConcept[]>(url, { params }).catch(parseHttpError)
}

export const getReturnConceptByIdRequest = (
  $http: IHttpService,
  conceptId: number
) => {
  const url = `${URLS.admin.taasReturnConcepts}/${conceptId}`
  return $http.get<ReturnConcept>(url).catch(parseHttpError)
}

export const createReturnRequest = (
  $http: IHttpService,
  returnForm: ReturnForm,
  orderId: number
) => {
  const url = `${URLS.admin.taasOrdersV4}/${orderId}/return`
  return $http.post<OrderFinal>(url, returnForm).catch(parseHttpError)
}
