/**
 * @file Screen to setup mfa accessed, with instructions, a secret from the server, a qr-code and an otp input field to verify.
 * @author Max van Loosbroek
 */

import React, { useContext, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext, ThemeProvider } from 'styled-components'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { OTPInput, PopUp, Spinner } from 'tinybots-react-components'
import useLongPress from '../../../../common/utilities/react/useLongPress'
import { copyStringToClipboard } from '../../../../common/utilities/dom'
import { makeStyles, ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { materialThemeLight } from '../../../../common/react/theme/materialTheme'
import styledComponentsTheme from '../../../../common/react/theme/styledComponentsTheme'
import Auth from '../../auth.service'
import { setMfaStyles } from './setMfaStyles'
import { useSetMfaForm } from './useSetMfaForm'
import { generateQr } from './generateQr'

export interface StateParamsSetMfa {
    secret: string
    email: string
}

const Error = styled.div`
    /* position: absolute; */
    margin-top: 5px;
    display: inline-block;
    color: #da4725;
    font-size: 12px;
    font-family: 'Montserrat';
`

const useStyles = makeStyles(() => ({
    otpInput: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 80,
        paddingRight: 40,
        justifyContent: 'center',
        ['@media (min-width: 380px)']: {
            padding: 0,
            justifyContent: 'center'
        }
    },
    wrapper: {
        color: '#e8e4da',
        fontSize: 12,
        fontFamily: 'Montserrat-Light',
        WebkitFontSmoothing: 'antialiased',
        '& a': {
            textDecoration: 'underline',
            color: 'inherit'
        }
    },
    settingsRow: {
        padding: 0,
        paddingLeft: 80,
        paddingRight: 40,
        ['@media (min-width: 380px)']: {
            padding: '0px 80px'
        }
    },
    mfaSecret: {
        fontSize: 8,
        flexBasis: '100%',
        maxWidth: 149,
        ['@media (min-width: 380px)']: {
            fontSize: 11
        }
    },
    qrCode: {
        '& img': {
            display: 'block',
            height: 'auto',
            width: 119,
            maxWidth: '100%'
        }
    }
}))

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1040;
`

interface SetMfaProps {
    longPressDelay: number
    $state: any
    AuthService: Auth
}

export const SetMfaComponent = ({ longPressDelay, $state, AuthService }: SetMfaProps) => {
    const [copied, setCopied] = useState(false)
    const [mfaPage, setMfaPage] = useState(false)
    const [showErrorPopup, setShowErrorPopup] = useState(false)
    const themeContext = useContext(ThemeContext)
    const { rowStyle, labelColumnStyle, contentColumnStyle, secretStyle, headingStyle, pStyle, submitStyle } =
        setMfaStyles(themeContext)
    const { t } = useTranslation()
    const qrCodeImg = useRef(null)
    const secret = $state.params ? $state.params.secret : ''
    const classes = useStyles()
    const { setOtpCode, setPassword, enableMfa, setStatus, email, formStatus, submitted, formValidation } =
        useSetMfaForm($state, AuthService)

    if (!$state.params || !$state.params.password || !secret) {
        $state.go('generalSettingsPage')
    }

    useEffect(() => {
        if (!secret || !email) {
            $state.go('auth.login')
        }
        if (formStatus === 'error') {
            return setShowErrorPopup(true)
        }
        return setShowErrorPopup(false)
    }, [formStatus, secret, email])

    useEffect(() => {
        if (qrCodeImg.current) {
            qrCodeImg.current.innerHTML = generateQr(secret, email)
        }
    }, [qrCodeImg, secret, email])

    const copyLongpress = useLongPress(() => {
        setCopied(true)
        copyStringToClipboard(secret)
    }, longPressDelay)

    return (
        <div
            style={{
                background: themeContext.colors.cremewhite,
                maxWidth: 450,
                height: 650,
                padding: 50,
                paddingTop: 20,
                paddingBottom: 20,
                borderRadius: 5
            }}
        >
            {formStatus === 'patching' && <Spinner />}
            <div className={classes.wrapper + ' react-component'}>
                <h1 style={headingStyle}>
                    1.&nbsp;
                    {t('MFA.STEPS.STEP1.TITLE')}
                </h1>
                <p style={pStyle}>
                    {t('MFA.STEPS.STEP1.ACTION')}
                    :&nbsp;
                    <a target='_blank' rel='noopener noreferrer' href={t('MFA.STEPS.STEP1.LINK1')}>
                        {t('MFA.STEPS.STEP1.OPTION1')}
                    </a>
                    <br />
                    {t('MFA.STEPS.STEP1.SEPARATOR')}
                    &nbsp;
                    <span style={{ textTransform: 'lowercase' }}>{t('MFA.STEPS.STEP1.ACTION')}</span>
                    :&nbsp;
                    <a target='_blank' rel='noopener noreferrer' href={t('MFA.STEPS.STEP1.LINK2')}>
                        {t('MFA.STEPS.STEP1.OPTION2')}
                    </a>
                </p>
                <h1 style={headingStyle}>
                    2.&nbsp;
                    {t('MFA.STEPS.STEP2.TITLE')}
                </h1>
                <div style={rowStyle} className={classes.settingsRow}>
                    <div style={labelColumnStyle}>{t('MFA.STEPS.STEP2.QR')}:</div>
                    <div id='placeHolder' ref={qrCodeImg} style={contentColumnStyle} className={classes.qrCode} />
                </div>
                <div style={rowStyle} className={classes.settingsRow}>
                    <div style={labelColumnStyle}>{t('MFA.STEPS.STEP2.SECRET')}:</div>
                    {secret && (
                        <div
                            id='mfa-secret'
                            className={classes.mfaSecret}
                            style={{ ...contentColumnStyle, cursor: 'default' }}
                            {...copyLongpress}
                        >
                            <div style={secretStyle} className='secret'>
                                {secret
                                    .substring(0, 16)
                                    .match(/.{1,4}/g)
                                    .join(' ')}
                            </div>
                            <div style={secretStyle} className='secret'>
                                {secret
                                    .substring(16, 32)
                                    .match(/.{1,4}/g)
                                    .join(' ')}
                            </div>
                            <button
                                type='button'
                                style={{
                                    top: '0',
                                    background: 'none',
                                    color: themeContext.colors.darkestgrey,
                                    border: 'none',
                                    padding: '0',
                                    cursor: 'pointer',
                                    outline: 'inherit',
                                    textAlign: 'center',
                                    fontSize: '10px',
                                    position: 'absolute',
                                    right: '-40px'
                                }}
                                onClick={() => {
                                    setCopied(true)
                                    copyStringToClipboard(secret)
                                }}
                            >
                                <FileCopyIcon style={{ fontSize: '20px', opacity: copied ? '0.6' : '1' }} />
                                <br />
                                copy
                            </button>
                        </div>
                    )}
                </div>
                <h1 style={headingStyle}>
                    3.&nbsp;
                    {t('MFA.STEPS.STEP3.TITLE')}
                </h1>
                <p style={pStyle}>{t('MFA.STEPS.STEP3.CONTENT')}</p>
                <div className={classes.otpInput}>
                    <OTPInput
                        onChange={code => {
                            setStatus('idle')
                            setOtpCode(code)
                        }}
                        wrapperStyle={{ width: '149px' }}
                        inputStyle={{ borderLeft: 0, borderRight: 0, borderTop: 0 }}
                    />
                    {submitted && !formValidation.validOtp && formStatus !== 'otp-error' ? (
                        <Error>{t('AC.MFA.ERROR_INCOMPLETE')}</Error>
                    ) : null}
                    {formStatus === 'otp-error' ? <Error>{t('AC.MFA.ERROR')}</Error> : null}
                </div>
                <h1 style={headingStyle}>
                    4.&nbsp;
                    {t('MFA.STEPS.STEP4.TITLE')}
                </h1>
                <p style={pStyle}>{t('MFA.STEPS.STEP4.CONTENT')}</p>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ background: 'white', borderRadius: 5, padding: '4.5px 12px', width: 149 }}>
                        <input
                            data-testid='password-input'
                            type='password'
                            style={{ width: 130, border: 0, borderBottom: '1px solid black', lineHeight: '20px' }}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    {submitted && !formValidation.validPassword && formStatus !== 'pw-error' ? (
                        <Error>{t('MFA.ERROR_PW')}</Error>
                    ) : null}
                    {formStatus === 'pw-error' ? <Error>{t('AC.MFA.ERROR_PW_EMAIL')}</Error> : null}
                </div>
                <button style={submitStyle} onClick={enableMfa}>
                    {t('MFA.SUBMIT')}
                </button>
            </div>
            {!mfaPage && (
                <PopUp
                    type='info'
                    title={t('MFA.WARN.TITLE')}
                    text={t('MFA.WARN.MESSAGE')}
                    onSubmit={() => setMfaPage(true)}
                    submitButton={t('POPUP.ERROR.BUTTON_SUBMIT')}
                />
            )}
            {showErrorPopup && (
                <PopUp
                    type='warning'
                    title={t('POPUP.ERROR.TITLE')}
                    text={t('POPUP.ERROR.MESSAGE')}
                    onSubmit={() => setStatus('idle')}
                    submitButton={t('POPUP.ERROR.BUTTON_SUBMIT')}
                />
            )}
        </div>
    )
}

SetMfaComponent.defaultProps = {
    longPressDelay: 500,
    mfaEnabledAt: null
}

export default (props: SetMfaProps) => (
    <MuiThemeProvider theme={materialThemeLight}>
        <ThemeProvider theme={styledComponentsTheme}>
            <SetMfaComponent {...props} />
        </ThemeProvider>
    </MuiThemeProvider>
)
