/**
 * @file
 * Form for changing general return values
 * @author Max van Loosbroek
 */

import { useCallback } from 'react'
import { ReturnForm } from '../../types/ReturnForm'
import { OrderFinalV5 } from '../../../../types/OrderFinal'
import { AppDispatch } from '../../../../../../common/redux/store'
import React from 'react'
import {
  TbDashDetailedItem,
  TbDashDetailedItemRow
} from 'tinybots-react-components'
import { Contact } from '../../../../types/Contact'
import { Address } from '../../../../types/Address'
import { useFormItemValidation } from '../../../../../../common/utilities/useFormItemValidation'
import { AddressType } from '../../../AddressType/AddressType'

const useChangeHandlers = ({
  onChangeForm,
  returnForm
}: {
  onChangeForm: (changes: Partial<ReturnForm>) => void
  returnForm: Partial<ReturnForm>
}) => {
  const onChangeReturnerInfo = useCallback(
    (changes: Partial<Contact>) => {
      onChangeForm({
        returner: { ...returnForm.returner, ...changes }
      })
    },
    [returnForm.returner]
  )
  const onChangePickupAddress = useCallback(
    (address: Partial<Address>) => {
      onChangeForm({
        pickupAddress: { ...returnForm.pickupAddress, ...address }
      })
    },
    [returnForm.pickupAddress]
  )
  const onChangeHasPickup = useCallback((value: string) => {
    if (value === 'yes') {
      onChangeForm({
        pickupAddress: {
          recipient: '',
          street: '',
          homeNumber: '',
          city: '',
          zipcode: '',
          country: 'Netherlands'
        }
      })
    } else {
      onChangeForm({
        pickupAddress: undefined
      })
    }
  }, [])
  return {
    onChangeReturnerInfo,
    onChangeHasPickup,
    onChangePickupAddress
  }
}

export interface ReturnInfoFormProps {
  salesOrder: OrderFinalV5
  returnForm: Partial<ReturnForm>
  onChangeForm: (changes: Partial<ReturnForm>) => void
  showErrors: boolean
  dispatch: AppDispatch
  searching: boolean
}

export const ReturnInfoForm = ({
  onChangeForm,
  showErrors,
  returnForm
}: ReturnInfoFormProps) => {
  const { returner, pickupAddress } = returnForm
  const { onChangeReturnerInfo, onChangeHasPickup, onChangePickupAddress } =
    useChangeHandlers({ onChangeForm, returnForm })
  const { generalEmptyError, emailError, phoneError, numberError } =
    useFormItemValidation(showErrors)

  return (
    <>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='text'
          label='Return reason*'
          value={returnForm?.returnReason ?? ''}
          hasError={generalEmptyError}
          onChange={(returnReason: string) =>
            onChangeForm({
              returnReason
            })
          }
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='Returner email*'
          hasError={emailError}
          value={returner?.email ?? ''}
          onChange={(email: string) =>
            onChangeReturnerInfo({
              email
            })
          }
        />
        <TbDashDetailedItem
          type='text'
          label='Tel. nr.*'
          hasError={phoneError}
          value={returner?.phoneNumber ?? ''}
          onChange={(phoneNumber: string) =>
            onChangeReturnerInfo({
              phoneNumber
            })
          }
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='First name*'
          hasError={generalEmptyError}
          value={returner?.firstname ?? ''}
          onChange={(firstname: string) =>
            onChangeReturnerInfo({
              firstname
            })
          }
        />
        <TbDashDetailedItem
          type='text'
          label='Last name*'
          hasError={generalEmptyError}
          onChange={(lastname: string) =>
            onChangeReturnerInfo({
              lastname
            })
          }
          value={returner?.lastname ?? ''}
        />
      </TbDashDetailedItemRow>
      <div style={{ marginTop: 100 }} />
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='select'
          label='Pickup service?'
          options={[
            { label: 'Yes', key: 'yes', disabled: false },
            { label: 'No', key: 'no', disabled: false }
          ]}
          value={pickupAddress ? 'yes' : 'no'}
          onChange={onChangeHasPickup}
        />
      </TbDashDetailedItemRow>
      {pickupAddress && (
        <>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              label='Returner address*'
              hasError={generalEmptyError}
              value={pickupAddress?.street ?? ''}
              onChange={(street: string) =>
                onChangePickupAddress({
                  street
                })
              }
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='number'
              label='House no.*'
              hasError={numberError}
              value={pickupAddress.homeNumber}
              onChange={(homeNumber: string) =>
                onChangePickupAddress({
                  homeNumber: parseInt(homeNumber) || homeNumber
                })
              }
            />
            <TbDashDetailedItem
              type='text'
              label='House no. extension'
              value={pickupAddress.homeNumberExtension}
              onChange={(homeNumberExtension: string) =>
                onChangePickupAddress({
                  homeNumberExtension
                })
              }
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              label='Zipcode*'
              hasError={generalEmptyError}
              value={pickupAddress?.zipcode ?? ''}
              onChange={(zipcode: string) =>
                onChangePickupAddress({
                  zipcode
                })
              }
            />
            <TbDashDetailedItem
              type='text'
              label='City*'
              hasError={generalEmptyError}
              value={pickupAddress?.city ?? ''}
              onChange={(city: string) =>
                onChangePickupAddress({
                  city
                })
              }
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              label='Location description'
              value={pickupAddress?.locationDescription ?? ''}
              onChange={(locationDescription: string) =>
                onChangePickupAddress({
                  locationDescription
                })
              }
            />
            <TbDashDetailedItem
              type='text'
              label='Country*'
              hasError={generalEmptyError}
              value={pickupAddress?.country ?? ''}
              onChange={(country: string) =>
                onChangePickupAddress({
                  country
                })
              }
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              label='Return address contact (recipient)*'
              hasError={generalEmptyError}
              value={pickupAddress?.recipient ?? ''}
              onChange={(recipient: string) =>
                onChangePickupAddress({
                  recipient
                })
              }
            />
            <AddressType
              addressType={pickupAddress?.addressType ?? ''}
              onChange={(addressType: string) =>
                onChangePickupAddress({
                  addressType
                })
              }
            />
          </TbDashDetailedItemRow>
        </>
      )}
    </>
  )
}
