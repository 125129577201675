/**
 * @jest-environment jsdom
 * @file Functions for handling url params
 * @author Max van Loosbroek
 */

import { OverviewUrlParams, OverviewQueryParams, IdParams } from "./Overview/Overview"
import { Tabs } from "./types/types"

export const getOverviewQueryParams = (
  urlParams?: OverviewUrlParams
): OverviewQueryParams => {
  if (!urlParams) return null
  if (
    !urlParams.searchType &&
    !urlParams.searchTerm &&
    !urlParams.sortBy &&
    !urlParams.sortOrder &&
    !urlParams.token &&
    !urlParams.status
  ) {
    if(urlParams.chainIds || urlParams.taasIds || urlParams.serialIds) {
      return getIdParams(urlParams)
    }
    return {}
  }
  const overviewParams = {
    searchType: urlParams.searchType,
    searchTerm: urlParams.searchTerm,
    sortBy: urlParams.sortBy,
    sortOrder: urlParams.sortOrder,
    token: urlParams.token,
    status: urlParams.status
  }
  return overviewParams
}

export const getIdParams = (
  urlParams?: OverviewUrlParams
): IdParams | {} => {
  if (!urlParams?.serialIds && !urlParams?.chainIds && !urlParams?.taasIds) {
    return {}
  }
  const overviewParams = {
    sortBy: urlParams.sortBy,
    serialIds: urlParams.serialIds ?? [],
    chainIds: urlParams.chainIds ?? [],
    taasIds: urlParams.taasIds ?? []
  }
  return overviewParams
}

export const getDetailedParams = (
  urlParams: OverviewUrlParams
): {
  serialId?: number
  chainId?: number
  taasId?: number
  tab?: Tabs
} => {
  if (!urlParams) return null
  if (!urlParams?.serialId && !urlParams?.chainId && !urlParams?.taasId) {
    return {}
  }
  const detailedParams = {
    serialId: urlParams.serialId ? parseInt(urlParams.serialId) : undefined,
    taasId: urlParams.taasId ? parseInt(urlParams.taasId) : undefined,
    chainId: urlParams.chainId ? parseInt(urlParams.chainId) : undefined,
    tab: urlParams.tab
  }
  return detailedParams
}
