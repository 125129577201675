import template from './heartbeat.html'
import moment from 'moment'
import './heartbeat.scss'
import { getRobotRef } from '../overview/stringTransformers'

const heartbeat = {
  require: {
    parent: '^^app'
  },
  template,
  controller: /* @ngInject */ class HeartbeatController {
    constructor (
      AuthService,
      HeartbeatService,
      SubscriptionService,
      RobotsService,
      $state,
      $q
    ) {
      this.AuthService = AuthService
      ;(this.HeartbeatService = HeartbeatService),
        (this.SubscriptionService = SubscriptionService)
      this.RobotsService = RobotsService
      this.$state = $state
      this.$q = $q
    }

    showPopUp (showPopUpName, params) {
      params && (this.popUps[showPopUpName].params = params)
      this.popUps[showPopUpName].visible = true
    }

    hidePopUp (hidePopUpName) {
      this.popUps[hidePopUpName].visible = false
      const params = this.popUps[hidePopUpName].params
      if (params) {
        for (const param in params) {
          params[param] = null
        }
      }
    }

    requestLogs () {
      this.showPreloader = true
      this.HeartbeatService.requestLogs(this.robot.robotId)
        .then(() => {
          this.showPreloader = false
          this.showPopUp('requestSend')
        })
        .catch(err => {
          this.showPreloader = false
          this.showPopUp('requestFailed', {
            errorTitle: `${err.status} ${err.statusText}`,
            errorMessage: err.data && err.data.message
          })
        })
    }

    requestReboot () {
      this.showPopUp('reboot')
    }

    rebootRobot () {
      this.hidePopUp('reboot')
      this.showPreloader = true
      this.HeartbeatService.reboot(this.robot.robotId)
        .then(() => {
          this.showPreloader = false
          this.showPopUp('requestSend')
        })
        .catch(err => {
          this.showPreloader = false
          this.showPopUp('requestFailed', {
            errorTitle: `${err.status} ${err.statusText}`,
            errorMessage: err.data && err.data.message
          })
        })
    }

    setUpdateStatus () {
      this.updateStatus = {}
      if (
        this.robot &&
        this.robot.robotId &&
        this.robotConfig &&
        this.corebeat
      ) {
        const menderSoftware = this.robotConfig.software
        const online = this.corebeat.status === 'online'
        const version = this.corebeat.version
        this.updateStatus.enabled =
          !menderSoftware.upToDate &&
          menderSoftware.updateStatus !== 'downloading' &&
          online &&
          menderSoftware.installed === version
        switch (menderSoftware.updateStatus) {
          case 'readyToInstall':
            this.updateStatus.button = 'INSTALL UPDATE'
            break
          case 'downloading':
            this.updateStatus.button = 'SENDING'
            break
          default:
            this.updateStatus.button = 'SEND UPDATE'
        }
        this.updateStatus.softwareLabel =
          menderSoftware.installed === version
            ? menderSoftware.upToDate
              ? 'Software'
              : 'Software (not up to date)'
            : 'Software (Mender error)'
        this.updateStatus.upToDate = menderSoftware.upToDate
        this.updateStatus.warning =
          menderSoftware.installed !== version || !menderSoftware.upToDate
      } else {
        this.updateStatus.enabled = false
        this.updateStatus.button = 'SEND UPDATE'
        this.updateStatus.softwareLabel = 'Software'
        this.updateStatus.upToDate = true
        this.updateStatus.warning = false
      }
    }

    showDateRange () {
      this.showTimeSelector = !this.showTimeSelector
    }

    searchRobot ({ searchTerm }) {
      this.$state.go('heartbeat', {
        serial: searchTerm.term,
        startDate: this.timeData.startAt.valueOf(),
        endDate: this.timeData.endAt.valueOf(),
        untilNow: this.timeData.untilNow
      })
    }

    setHours (hours) {
      const endAt = moment()
      const startAt = endAt.clone().subtract(hours, 'hour')
      this.$state.go('heartbeat', {
        serial: this.robot && this.robot.serial,
        startDate: startAt.valueOf(),
        endDate: endAt.valueOf(),
        untilNow: true
      })
    }

    setMinutes (minutes) {
      const endAt = moment()
      const startAt = endAt.clone().subtract(minutes, 'minutes')
      this.$state.go('heartbeat', {
        serial: this.robot && this.robot.serial,
        startDate: startAt.valueOf(),
        endDate: endAt.valueOf(),
        untilNow: true
      })
    }

    setRange (startAt, endAt) {
      this.$state.go('heartbeat', {
        serial: this.robot && this.robot.serial,
        startDate: startAt.valueOf(),
        endDate: endAt.valueOf(),
        untilNow: false
      })
    }

    today () {
      const startAt = moment().startOf('day')
      const endAt = moment().endOf('day')
      this.setRange(startAt, endAt)
    }

    yesterday () {
      const yesterday = moment().subtract(1, 'days')
      const startAt = yesterday.clone().startOf('day')
      const endAt = yesterday.clone().endOf('day')
      this.setRange(startAt, endAt)
    }

    week () {
      const startAt = moment().startOf('week')
      const endAt = moment().endOf('week')
      this.setRange(startAt, endAt)
    }

    setAbsoluteTime () {
      this.setRange(
        moment(this.timeData.absoluteStartDate),
        moment(this.timeData.absoluteEndDate)
      )
    }

    async getRobot (serial) {
      await this.RobotsService.getRobotsBySerial(serial).then(robots => {
        if (robots.data.length > 0) {
          this.RobotsService.getRobotById(robots.data[0].serialId).then(
            async robot => {
              this.robot = robot.data
              try {
                const account = await this.RobotsService.getRobotAccount(
                  robot.data.serialId
                )
                this.robot.account = account.data
                this.robot.robotId = account.data.robotId
                this.robot.appUser = account.data.primaryUser
              } catch (e) {
                console.info(e)
              }
              this.findHeartbeats(this.robot.serial)
              this.getCoreBeat(this.robot.robotId)
              this.getRobotConfig(this.robot.robotId)
              this.HeartbeatService.getLogs(this.robot.robotId).then(logs => {
                this.logs = logs.data.Contents
              })
            }
          )
        }
      })
    }

    getRobotConfig (robotId) {
      if (!robotId) {
        return
      }
      this.HeartbeatService.getConfig(this.robot.robotId).then(config => {
        this.robotConfig = config.data
        this.setUpdateStatus()
      })
    }

    updateRobot () {
      if (this.corebeat.version[0] === 'T') {
        if (
          this.robotConfig &&
          this.robotConfig.software.updateStatus === 'outdated'
        ) {
          this.showPreloader = true
          this.HeartbeatService.update(this.robot.robotId)
            .then(() => {
              this.showPopUp('requestSend')
              setTimeout(() => {
                this.getRobotConfig(this.robot.robotId)
              }, 2000)
            })
            .catch(err => {
              const params = {
                errorMessage: 'Could not update',
                errorTitle: err.data.message
              }
              this.showPopUp('requestFailed', params)
            })
            .finally(() => {
              this.showPreloader = false
            })
        } else if (
          this.robotConfig &&
          this.robotConfig.software.updateStatus === 'readyToInstall'
        ) {
          this.requestReboot()
        }
      } else if (this.corebeat.version === "'0.7.2'") {
        this.showPopUp('migrate')
      }
    }

    getCoreBeat (robotId) {
      if (!robotId) {
        return
      }
      this.HeartbeatService.getcoreBeat(robotId).then(result => {
        this.corebeat = result.data
        this.corebeat.lastSeen =
          result.data.lastSeen && moment(result.data.lastSeen)
        this.corebeat.lastSavedAt =
          result.data.lastSavedAt && moment(result.data.lastSavedAt)
        if (this.corebeat.lastSeen) {
          const age = moment().diff(this.corebeat.lastSeen) / (1000 * 60)
          const savedAge =
            moment().diff(this.corebeat.lastSavedAt) / (1000 * 60)
          const totalBeats = (3 * savedAge) | 0
          const beatPercentage =
            (this.corebeat.beatsSinceLastSave /
              (totalBeats > 0 ? totalBeats : 1)) *
            100
          if (age < 1 && savedAge > 2 && savedAge < 30 && beatPercentage > 60) {
            this.corebeat.status = 'online'
          } else if (
            age < 1 &&
            savedAge > 2 &&
            savedAge < 30 &&
            beatPercentage < 60
          ) {
            this.corebeat.status = 'intermittent'
          } else if (age > 1) {
            this.corebeat.status = 'offline'
          } else if (age <= 1) {
            this.corebeat.status = 'online'
          }
        }
        this.setUpdateStatus()
      })
    }

    getAppUser () {
      return this.robot.appUser
        ? this.robot.appUser.firstName + ' ' + this.robot.appUser.lastName
        : this.robot.appUser === null
        ? 'Not paired'
        : 'Not installed'
    }

    getS3Link (log) {
      return this.HeartbeatService.gets3LogUrl(log.Key)
    }

    getAppEmail () {
      return (this.robot.appUser && this.robot.appUser.email) || '-'
    }

    getAppPhone () {
      return (this.robot.appUser && this.robot.appUser.phoneNumber) || '-'
    }

    findHeartbeats (serial) {
      const dateRange = this.timeData.getDates()
      this.waitingForData = true
      this.gotData = {}
      if (dateRange.length > 8) {
        this.findHeartbeatsSync(serial, dateRange)
      } else {
        this.findHeartbeatsAsync(serial, dateRange)
      }
    }

    findHeartbeatsAsync (serial, dateRange) {
      for (let i = 0; i < dateRange.length; i++) {
        this.gotData[`${dateRange[i].date}-${dateRange[i].hour}`] = false
        this.getData(serial, dateRange[i].date, dateRange[i].hour)
      }
    }

    async findHeartbeatsSync (serial, dateRange) {
      for (let i = 0; i < dateRange.length; i++) {
        this.gotData[`${dateRange[i].date}-${dateRange[i].hour}`] = false
      }
      for (let i = 0; i < dateRange.length; i++) {
        await this.getData(serial, dateRange[i].date, dateRange[i].hour)
      }
    }

    checkDone () {
      let isDone = true
      for (let key in this.gotData) {
        if (!this.gotData[key]) {
          isDone = false
          break
        }
      }
      this.waitingForData = !isDone
    }

    sortBeats () {
      if (this.beats) {
        this.beats.sort((a, b) => {
          return b.LastModified > a.LastModified
            ? 1
            : b.LastModified < a.LastModified
            ? -1
            : 0
        })
      }
      this.beats = this.beats.slice(0, 180) // max show 180 heartbeart (constant online 1 hour)
    }

    getRobotSerial () {
      return getRobotRef(this.robot)
    }

    details (beat) {
      beat.show = !beat.show
      if (beat.show) {
        this.HeartbeatService.getBeat(beat.Key).then(data => {
          beat.data = data.data
        })
      }
    }

    showDetailed () {
      this.$state.go('overview', {
        robotSerial: this.robot.serial,
        serial: this.robot.serial,
        serialId: this.robot.serialId,
        chainId: this.robot.chainId
      })
      const chainId = this.robot.chainId ? `&chainId=${this.robot.chainId}` : ''
      const taasId = this.robot.taasId ? `&taasId=${this.robot.taasId}` : ''
      window.location.href = location.origin + `/overview/?serialId=${this.robot.serialId}${chainId}${taasId}`
    }

    getData (serial, date, hour, token) {
      return this.HeartbeatService.getBeats(serial, date, hour, token).then(
        data => {
          const beats = data.data.Contents.filter(beat => {
            beat.LastModified = moment(beat.LastModified)
            beat.show = false
            beat.data = null
            if (
              beat.LastModified >= this.timeData.startAt &&
              beat.LastModified <= this.timeData.endAt
            ) {
              return beat
            }
          })
          this.timeData.fillBucket(beats)
          this.beats = this.beats.concat(beats)
          this.sortBeats()
          this.updateGraph()
          const continueToken = data.data.NextContinuationToken
          if (continueToken) {
            this.getData(serial, date, hour, continueToken)
          } else {
            this.gotData[`${date}-${hour}`] = true
            this.checkDone()
          }
        }
      )
    }

    updateGraph () {
      this.labels = Object.keys(this.timeData.bucketData)
      if (this.corebeat) {
        this.data = [
          this.timeData.getBuckets(),
          this.timeData.covertCorebeat(this.corebeat)
        ]
        const color =
          this.corebeat.status === 'online'
            ? 'rgba(50,205,50,0.4)'
            : this.corebeat.status === 'offline'
            ? 'rgba(255,99,132,0.4)'
            : 'rgba(255,206,86,0.4)'
        this.chartColors = ['#45b7cd', color, '#ff8e72']
        this.datasetOverride = [
          {
            label: 'heartbeats',
            borderWidth: 1,
            type: 'bar',
            xAxisID: 'bar-x-axis1'
          },
          {
            label: `status: ${this.corebeat.status}`,
            borderWidth: 3,
            type: 'bar',
            xAxisID: 'bar-x-axis2'
          }
        ]
      } else {
        this.series = ['heartbeats']
        this.data = [this.timeData.getBuckets()]
      }
    }

    $onInit () {
      this.showPreloader = false
      this.waitingForData = false
      this.searchOptions = [
        {
          name: 'ROBOT',
          searchParam: 'serial'
        }
      ]
      this.popUps = {
        requestFailed: {
          visible: false,
          params: {
            errorMessage: null,
            errorTitle: null
          }
        },
        requestSend: {
          visible: false
        },
        reboot: {
          visible: false
        },
        migrate: {
          visible: false
        }
      }
      this.tableType = 'heartbeat'
      this.showTimeSelector = false
      this.data = []
      this.labels = []
      this.beats = []
      this.options = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true
              },
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              stacked: true,
              id: 'bar-x-axis1',
              gridLines: {
                display: false
              }
            },
            {
              display: false,
              stacked: true,
              id: 'bar-x-axis2',
              gridLines: {
                display: false
              },
              gridLines: {
                offsetGridLines: true
              },
              offset: true
            }
          ]
        }
      }

      this.setUpdateStatus()

      if (
        this.$state.params.serial &&
        this.$state.params.startDate &&
        this.$state.params.endDate
      ) {
        this.timeData = new TimedData(
          parseInt(this.$state.params.startDate),
          parseInt(this.$state.params.endDate),
          this.$state.params.untilNow === 'true'
        )
        this.updateGraph()
        this.getRobot(this.$state.params.serial)
      } else if (this.$state.params.startDate && this.$state.params.endDate) {
        this.timeData = new TimedData(
          parseInt(this.$state.params.startDate),
          parseInt(this.$state.params.endDate),
          this.$state.params.untilNow === 'true'
        )
        this.updateGraph()
      } else if (this.$state.params.serial) {
        const now = moment()
        const oneHourAgo = now.clone().subtract(1, 'hour')
        this.$state.go('heartbeat', {
          serial: this.$state.params.serial,
          startDate: oneHourAgo.valueOf(),
          endDate: now.valueOf(),
          untilNow: true
        })
      } else {
        const now = moment()
        const oneHourAgo = now.clone().subtract(1, 'hour')
        this.timeData = new TimedData(oneHourAgo, now, true)
        this.updateGraph()
      }
    }
  }
}

export default heartbeat

class TimedData {
  constructor (startDate, endDate, untilNow) {
    this.untilNow = untilNow
    if (untilNow && endDate && startDate) {
      const diff = moment(endDate).diff(moment(startDate))
      this.endAt = moment()
      this.startAt = this.endAt.clone().subtract(diff, 'milliseconds')
    } else if (!endDate && startDate) {
      this.startAt = moment(startDate)
      this.endAt = moment()
    } else if (endDate && startDate) {
      this.startAt = moment(startDate)
      this.endAt = moment(endDate)
    } else {
      throw new Error('need a start date')
    }
    if (this.startAt >= this.endAt) {
      throw new Error('dates are equal')
    }
    this.dateRangeText = `${this.startAt.format(
      'DD-MM-YYYY (HH:mm'
    )}h)-${this.endAt.format('DD-MM-YYYY (HH:mm')}h)`
    this.absoluteStartDate = this.startAt.toDate()
    this.absoluteEndDate = this.endAt.toDate()
    this.startDateString = this.startAt.format('YYYY-MM-DD')
    this.startTimeString = this.startAt.format('HH:mm')
    this.endDateString = this.endAt.format('YYYY-MM-DD')
    this.endTimeString = this.endAt.format('HH:mm')
    this.getInterval()
    this.bucketData = this.dateTimeRange(
      this.startAt,
      this.endAt,
      this.interval,
      this.format
    )
  }

  getInterval () {
    this.timeDifference = this.endAt.diff(this.startAt) / 1000 / 60 / 60
    if (this.timeDifference < 1) {
      this.interval = 1
      this.format = 'DD-MM-YYYY HH:mm'
    } else if (this.timeDifference < 5) {
      this.interval = Math.round(this.timeDifference)
      this.format = 'DD-MM-YYYY HH:mm'
    } else if (this.timeDifference < 60) {
      this.interval = Math.round(this.timeDifference / 10) * 10
      this.format = 'DD-MM-YYYY HH:mm'
    } else {
      this.interval = Math.round(this.timeDifference / 60) * 60
      this.format = 'DD-MM-YYYY HH:mm'
    }
  }

  dateTimeRange (start, end, interval, format) {
    const bucketData = {}
    start = this.nearestMinutes(interval, start)
    end = this.nearestMinutes(interval, end)
    while (start <= end) {
      bucketData[start.format(format)] = 0
      start = start.add(interval, 'minutes')
    }
    return bucketData
  }

  covertCorebeat (corebeat) {
    const date = this.nearestMinutes(this.interval, corebeat.lastSeen).format(
      this.format
    )
    const corebeatData = angular.copy(this.bucketData)
    const converted = Object.keys(corebeatData).map(key => {
      if (key === date) {
        return corebeatData[key] > 0 ? corebeatData[key] : 1
      } else {
        return 0
      }
    })
    return converted
  }

  nearestMinutes (interval, someMoment) {
    const roundedMinutes =
      Math.round(someMoment.clone().valueOf() / (1000 * 60 * interval)) *
      1000 *
      60 *
      interval
    return moment(roundedMinutes).second(0)
  }

  getBuckets () {
    return Object.keys(this.bucketData).map(key => {
      return this.bucketData[key]
    })
  }

  getDays () {
    let now = this.startAt
      .clone()
      .utc()
      .startOf('day')
    const data = []
    while (
      now <=
      this.endAt
        .clone()
        .utc()
        .endOf('day')
    ) {
      data.push({
        date: moment.utc(now).format('YYYY.MM.DD')
      })
      now = now.add(1, 'days')
    }
    return data
  }

  getHours () {
    let now = this.startAt
      .clone()
      .utc()
      .startOf('hour')
    const data = []
    while (
      now <=
      this.endAt
        .clone()
        .utc()
        .endOf('hour')
    ) {
      data.push({
        date: moment.utc(now).format('YYYY.MM.DD'),
        hour: moment.utc(now).hours()
      })
      now = now.add(1, 'hours')
    }
    return data
  }

  getDates () {
    if (this.timeDifference > 4) {
      return this.getDays()
    } else {
      return this.getHours()
    }
  }

  fillBucket (beats) {
    this.groupByMinute(beats)
  }

  groupByMinute (items) {
    items.map(item => {
      const date = this.nearestMinutes(this.interval, item.LastModified).format(
        this.format
      )
      if (this.bucketData[date] !== undefined) {
        this.bucketData[date] += 1
      } else {
        console.error(date, 'IS NOT IN BUCKET')
      }
    })
  }
}
