import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../../../../../common/redux/root.reducer"
import { validateObj } from "../../../../../common/utilities/validator"
import { ReturnForm } from "../types/ReturnForm"

export const selectOrderReturnState = (state: RootState) =>
  state.orderReturn

export const validReturn = createSelector(
  selectOrderReturnState,
  ({ returnForm }) => {
    try {
      validateObj(returnForm, ReturnForm)
      return true
    } catch (error) {
      console.error('Return form invalid: ', error)
      return false
    }
  }
)