/* @ngInject */
export default class SubscriptionService {
  constructor ($http, URLS, $q) {
    'ngInject';
    this.$http = $http
    this.URLS = URLS
    this.$q = $q
  }

  getSubscriptions () {
    const url = this.URLS.admin.subscriptions
    return this.$http.get(url)
  }

  getChain (chainId) {
    const url = `${this.URLS.admin.chains}/${chainId}`
    return this.$http.get(url)
  }

  updateChain (chainId, update) {
    const url = `${this.URLS.admin.chains}/${chainId}`
    return this.$http.patch(url, update)
  }

  getFreeSubscriptionsByRelationId (relationId) {
    const url = `${this.URLS.admin.subscriptions}?relationid=${relationId}&hasrobot=false`
    return this.$http.get(url)
  }

  getSubscriptionsByRelationId (relationId) {
    const url = `${this.URLS.admin.subscriptions}?relationid=${relationId}`
    return this.$http.get(url)
  }

  getSubscriptionById (id) {
    const url = `${this.URLS.admin.subscriptions}?id=${id}`
    return this.$http.get(url)
  }

  addSubscriptions (data) {
    const url = this.URLS.admin.subscriptions
    return this.$http.post(url, data)
  }

  addTaasSubscriptions (data) {
    const url = this.URLS.admin.taas
    return this.$http.post(url, data)
  }

  updateSubscription (subscriptionId, update) {
    const url = `${this.URLS.admin.subscriptions}/${subscriptionId}`
    return this.$http.patch(url, update)
  }

  mapSubscriptions (subscriptions) {
    subscriptions.map(subscription => {
      subscription.startAt = subscription.startAt ? moment.utc(subscription.startAt).format('DD-MM-YYYY') : null
      subscription.endAt = subscription.endAt ? moment.utc(subscription.endAt).format('DD-MM-YYYY') : null
      subscription.shippedAt = subscription.shippedAt ? moment.utc(subscription.shippedAt).format('DD-MM-YYYY') : null
      subscription.subscriptionReference = padZero(subscription.chainId, 5) + '-' + padZero(subscription.subscriptionId, 5)
      subscription.subscriptionReferenceTitle = subscription.subscriptionReference + ' (' + subscription.subscriptionLength + ' days)'
    })
    return subscriptions
  }

  mapChain (chain) {
    chain.cancelled = !!chain.canceledAt
    chain.subscriptions = this.mapSubscriptions(chain.subscriptions)
    return chain
  }

  mapChains (subscriptions) {
    const chains = {}
    subscriptions.forEach(sub => {
      sub.startAt = sub.startAt ? moment.utc(sub.startAt) : null
      sub.endAt = sub.endAt ? moment.utc(sub.endAt) : null
      if (subscriptions[sub.chainId]) {
        chains[sub.chainId].push(sub)
      } else {
        chains[sub.chainId] = [sub]
      }
    })
    const chainRows = []
    Object.keys(chains).forEach(key => {
      const row = this.getCurrentSubscription(chains[key])
      row.subscriptionReference = padZero(row.chainId, 5) + '-' + padZero(row.subscriptionId, 5)
      row.subscriptionReferenceTitle = row.subscriptionReference + ' (' + row.subscriptionLength + ' days)'
      row.endAt = row.endAt ? moment.max(chains[key].map(sub => sub.endAt)).format('DD-MM-YYYY') : null
      row.startAt = row.startAt ? moment.min(chains[key].map(sub => sub.startAt)).format('DD-MM-YYYY') : null
      row.shippedAt = row.shippedAt ? moment(row.shippedAt).format('DD-MM-YYYY') : null
      chainRows.push(row)
    })
    return chainRows
  }

  getCurrentSubscription (subscriptions) {
    let current = {}
    const currentSub = subscriptions.filter(sub => sub.startAt == null)
    if (currentSub.length > 0) {
      current = currentSub[0]
    } else {
      const currentPastSub = subscriptions.filter(sub => sub.startAt <= moment.utc())
      if (currentPastSub.length > 0) {
        current = currentPastSub.sort((a, b) => b.startAt.valueOf() - a.startAt.valueOf())[0]
      } else {
        current = subscriptions.sort((a, b) => a.startAt.valueOf() - b.startAt.valueOf())[0]
      }
    }
    return current
  }
};

function padZero (item, n) {
  item = item.toString()
  return '0'.repeat(n - item.length) + item
}
