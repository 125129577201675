/**
 * @file Showing info and apply changes to a specific TaaS sub
 * @author Max van Loosbroek
 */

import moment from 'moment'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../common/redux/root.reducer'
import { AppDispatch } from '../../../../../common/redux/store'
import { TaasDetailed } from '../../../types/types'
import { updateTaas } from '../../redux/reducer'
import { getTaasRef } from '../../../stringTransformers'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { TbConfirmDeleteDialog } from './ConfirmDeleteDialog'
import { useState } from 'react'

export interface OverviewDetailedTaasProps {
  taas: TaasDetailed
  dispatch: AppDispatch
  showErrors: boolean
}

export const OverviewDetailedTaasComponent = React.memo(
  ({ taas, dispatch, showErrors }: OverviewDetailedTaasProps) => {
    const [showDeleteWarn, setShowDeleteWarn] = useState(false)
    const teamleaderLink = `https://app.teamleader.eu/company_detail.php?id=${taas?.relation?.teamleaderId}`
    const handleChangeCanceled = useCallback(
      value => {
        if (value === true && !taas.endAt) {
          dispatch(
            updateTaas({ endAt: moment().toISOString(), canceled: value })
          )
        } else if (value === false) {
          dispatch(updateTaas({ endAt: null, canceled: value }))
        }
      },
      [taas.endAt]
    )
    const handleChangeDelete = useCallback(
      value => {
        if (!value) {
          dispatch(updateTaas({ delete: value }))
        } else {
          setShowDeleteWarn(true)
        }
      },
      [taas.endAt]
    )
    const handleChangeShippedAt = useCallback(
      value => dispatch(updateTaas({ shippedAt: value as string })),
      []
    )
    const handleChangeStartDate = useCallback(
      value => dispatch(updateTaas({ startAt: value as string })),
      []
    )
    const handleChangeEndDate = useCallback(
      value => dispatch(updateTaas({ endAt: value as string })),
      []
    )
    const handleChangeNotes = useCallback(
      value => dispatch(updateTaas({ notes: value as string })),
      []
    )
    const handleChangeTeamId = useCallback(
      value => dispatch(updateTaas({ teamId: value as string })),
      []
    )
    const handleChangeClientId = useCallback(
      value => dispatch(updateTaas({ clientId: value as string })),
      []
    )
    const handleCloseDeleteWarn = useCallback(() => {
      setShowDeleteWarn(false)
    }, [])
    const handleSubmitDeleteWarn = useCallback(value => {
      if (value === 'DELETE TAAS') {
        dispatch(updateTaas({ delete: true }))
        setShowDeleteWarn(false)
      }
    }, [])
    const taasStartHasError = (value: string) => {
      const invalidDate = value && !moment(value).isValid()
      const invalidLength = taas?.endAt && moment(value) > moment(taas?.endAt)
      const noStart = !value && taas?.endAt
      const inValid = invalidDate || invalidLength || noStart
      if (noStart && showErrors) return 'Required when canceling'
      if (inValid && showErrors) return 'Invalid taas length'
    }
    const shippingHasError = (value: string) => {
      const invalidDate = value && !moment(value).isValid()
      const invalidLength = taas?.endAt && moment(value) >= moment(taas?.endAt)
      const noShipping = !value && taas?.endAt
      const inValid = invalidDate || invalidLength || noShipping
      if (noShipping && showErrors) return 'Required when canceling'
      if (inValid && showErrors) return 'Invalid shipping date'
    }
    const taasEndHasError = (value: string) => {
      const invalidDate = value && !moment(value).isValid()
      const invalidLength =
        taas?.startAt && moment(taas?.startAt) >= moment(value)
      const inValid = invalidDate || invalidLength
      if (inValid && showErrors) return 'Invalid taas length'
    }
    const clientIdHasError = (value: string) => {
      const inValid = value?.length > 256
      if (inValid) return 'Client id must be max. 256 long'
    }
    const teamIdHasError = (value: string) => {
      const inValid = value?.length > 256
      if (inValid) return 'Team id must be max. 256 long'
    }
    const orderLink = taas?.order
      ? `/orders/?orderId=${taas.order.id}`
      : undefined
    if (!taas) return null
    return (
      <>
        <TbDashDetailedContainer key={`taasDetails-${taas.id}`}>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='Relation'
                iconUrl='assets/relation.svg'
                value={taas?.relation.name}
              />
              <TbDashDetailedItem
                type='link'
                href={teamleaderLink}
                disabled
                label=''
                target='_href'
                value='View in teamleader'
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='Robot Serial'
                iconUrl='assets/tessa-dark.svg'
                value={taas?.robot?.serial ? `T-${taas?.robot?.serial}` : '-'}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='TaaS reference'
                iconUrl='assets/subref.svg'
                value={getTaasRef(taas)}
              />
              <TbDashDetailedItem
                xs={3}
                disabled={taas?.order !== undefined}
                type='checkbox'
                label='Canceled'
                value={!!taas?.endAt}
                onChange={handleChangeCanceled}
              />
              <TbDashDetailedItem
                xs={3}
                disabled={!!taas?.robot}
                type='checkbox'
                label='DELETE'
                value={!!taas?.delete}
                onChange={handleChangeDelete}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type='text'
                label='Client id'
                disabled={taas?.order !== undefined}
                iconUrl='assets/person.svg'
                value={taas?.clientId}
                hasError={clientIdHasError}
                onChange={handleChangeClientId}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type='text'
                label='Team id'
                disabled={taas?.order !== undefined}
                iconUrl='assets/people_black_24dp.svg'
                value={taas?.teamId}
                hasError={teamIdHasError}
                onChange={handleChangeTeamId}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='date'
                label='Shipping date (DD-MM-YYYY)'
                iconUrl='assets/TB-DB_icon_Shipping.svg'
                hasError={shippingHasError}
                value={taas?.shippedAt}
                onChange={handleChangeShippedAt}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='date'
                label='Start date (DD-MM-YYYY)'
                hasError={taasStartHasError}
                iconUrl='assets/calendar.svg'
                value={taas?.startAt}
                onChange={handleChangeStartDate}
              />
              {!taas.order || taas.endAt ? (
                <TbDashDetailedItem
                  disabled={!taas?.canceled}
                  type='date'
                  label='End date (DD-MM-YYYY)'
                  hasError={taasEndHasError}
                  value={taas?.endAt}
                  onChange={handleChangeEndDate}
                />
              ) : (
                <TbDashDetailedItem
                  type='link'
                  href={orderLink}
                  disabled
                  label='End date (DD-MM-YYYY)'
                  value='Edit in order'
                />
              )}
            </TbDashDetailedItemRow>
            {taas.order && (
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  type='link'
                  href={orderLink}
                  label='SO number'
                  iconUrl='assets/invoice.svg'
                  value={'SO-' + taas?.order.id}
                />
              </TbDashDetailedItemRow>
            )}
          </TbDashDetailedColumn>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type='textArea'
                label='taas notes'
                value={taas?.notes}
                onChange={handleChangeNotes}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
        </TbDashDetailedContainer>
        <TbConfirmDeleteDialog
          salesOrderLink={orderLink}
          open={showDeleteWarn}
          handleClose={handleCloseDeleteWarn}
          handleSubmit={handleSubmitDeleteWarn}
        />
      </>
    )
  }
)

const ConnectedOverviewDetailedTaas = (
  props: Pick<OverviewDetailedTaasProps, 'showErrors'>
) => {
  const taas = useSelector<RootState, TaasDetailed>(
    ({ detailed: { taas } }) => taas
  )
  const dispatch: AppDispatch = useDispatch()

  return (
    <OverviewDetailedTaasComponent dispatch={dispatch} taas={taas} {...props} />
  )
}

export default ConnectedOverviewDetailedTaas
