/**
 * @file Dashtransfer page component with search functionality and data table
 * @author Max van Loosbroek
 */

import React, { useEffect, useState } from 'react'
import fastDeepEqual from 'fast-deep-equal'
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../common/redux/root.reducer'
import StoreService from '../../../common/redux/store.service'
import { createStyles, createTheme, makeStyles, ThemeProvider } from '@material-ui/core'
import { OverviewColumn, OverviewItemRow, OverviewState, Tabs } from '../../overview/types/types'
import { AppDispatch } from '../../../common/redux/store'
import { clearErrorsOverview, clearState, getOverview } from '../../overview/redux/reducer'
import { Waypoint } from 'react-waypoint'
import { handleSearch, handleSortClick, IdParams, OverviewUrlParams } from '../../overview/Overview/Overview'
import LinkDialogRobot from '../../overview/Detailed/LinkDialog/Robot/LinkDialogRobot'
import LinkDialogSub from '../../overview/Detailed/LinkDialog/Sub/LinkDialogSub'
import LinkDialogTaas from '../../overview/Detailed/LinkDialog/Taas/LinkDialogTaas'
import LinkDialogTaasRobot from '../../overview/Detailed/LinkDialog/TaasRobot/LinkDialogTaasRobot'
import { toggleLinkDialog } from '../../overview/Detailed/redux/reducer'
import { OverviewHeader } from '../../overview/OverviewHeader/OverviewHeader'
import { DashtransferState } from '../types'
import { clearErrorsDashtransfer, clearStateDashtransfer, dashtransferSelector, toggleDialog } from '../redux/reducer';
import DashtransferDialog from '../DashtransferDialog/DashtransferDialog'
import { getTransferData } from '../redux/thunks'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import { TbDashErrorDialog, TbDashTable, TbTableRowData } from 'tinybots-react-components'
import { useOverviewValueMapper } from '../../overview/tableMappers'
import { getOverviewQueryParams } from '../../overview/urlParamHelpers'
import { groupParamsByKey } from '../../../common/utilities/groupParamsByKey'

export const overviewTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#80cbc4'
    }
  }
})

export type DashtransferUrlParams = OverviewUrlParams
export interface DashtransferProps {
  $http: any
  StoreService: StoreService
  overview: OverviewState
  showDialog: boolean
  rows: OverviewItemRow[]
  columns: OverviewColumn[]
  showDetailed: boolean
  showLinkDialog: 'robot' | 'sub' | 'taas' | 'taasRobot' | null
  errorMessage: null | string
  showUnsavedWarn: boolean
  dispatch: AppDispatch
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  })
)

export const handleClickRow = (row: OverviewItemRow, dispatch: AppDispatch) => {
  dispatch(clearStateDashtransfer())
  dispatch(
    getTransferData({
      serialId: row.robotSerialId,
      chainId: row.chainId,
      taasId: row.taasId
    })
  )
  dispatch(toggleDialog({ show: true }))
}

export const searchByIds = ({
  serialIds,
  chainIds,
  taasIds
}: IdParams, setUrlParams: (params: OverviewUrlParams) => void, browserWindow: any = window) => {
  const currentUrlParams = new URLSearchParams(window.location.search)
  const setIdParams = (someArray: number[], name: string) => {
    someArray.forEach(element => {
      currentUrlParams.append(name, element.toString())
    });
  }
  currentUrlParams.delete('searchTerm')
  currentUrlParams.delete('searchType')
  currentUrlParams.delete('status')
  currentUrlParams.delete('token')
  currentUrlParams.delete('limit')
  currentUrlParams.delete('serialIds')
  currentUrlParams.delete('chainIds')
  currentUrlParams.delete('taasIds')
  const params = groupParamsByKey(currentUrlParams as any) as OverviewUrlParams
  if (serialIds.length) {
    params.serialIds = serialIds
    setIdParams(serialIds, 'serialIds')
  }
  if (chainIds.length) {
    params.chainIds = chainIds
    setIdParams(chainIds, 'chainIds')
  }
  if (taasIds.length) {
    params.taasIds = taasIds
    setIdParams(taasIds, 'taasIds')
  }
  setUrlParams(params)
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const DashtransferComponent: React.FunctionComponent<DashtransferProps> = React.memo(
  ({
    overview,
    showDialog,
    rows,
    dispatch,
    showLinkDialog,
    columns,
    errorMessage
  }) => {
    const classes = useStyles()
    const initialUrlParams = groupParamsByKey(
      new URLSearchParams(window.location.search) as any
    ) as DashtransferUrlParams
    const [urlParams, setUrlParams] = useState(initialUrlParams)
    const urlParamsPrevious: any = usePrevious(urlParams)
    const afterDashtransferSubmit = (params: IdParams) => {
      searchByIds(params, setUrlParams)
    }

    const handleCloseDialog = () => {
      dispatch(toggleDialog({ show: false }))
    }

    const handleScrollToEnd = (args: Waypoint.CallbackArgs) => {
      if (!overview.continuationToken) return
      dispatch(getOverview({ loadMore: true, urlParams }))
    }

    const clearErrors = () => {
      dispatch(clearErrorsOverview())
      dispatch(clearErrorsDashtransfer())
    }

    const handleCloseLinkDialog = () => {
      dispatch(toggleLinkDialog({ show: null }))
    }

    useEffect(() => {
      const urlParamsChanged = !fastDeepEqual(urlParams, urlParamsPrevious)
      if (urlParamsChanged) {
        dispatch(clearState())
        dispatch(getOverview({ loadMore: true, urlParams }))
      }
    }, [urlParams, urlParamsPrevious])

    const overviewValueMapper = useOverviewValueMapper((tab: Tabs, row: TbTableRowData) => {
      handleClickRow(row, dispatch)
    })

    return (
      <div className={classes.root}>
        <OverviewHeader color='#80cbc4' setUrlParams={setUrlParams} columns={columns.filter(c => c.canHide)} params={urlParams} onSubmit={({searchTerm, searchType}) => handleSearch({searchTerm, searchType}, setUrlParams)} />
        <TbDashTable
          valueMapper={overviewValueMapper}
          params={urlParams}
          onSortClick={(sort, sort_order) => handleSortClick(sort, sort_order, window, setUrlParams)}
          onScrollToEnd={handleScrollToEnd}
          loading={overview.getting}
          color='#80cbc4'
          rows={rows}
          columns={columns.filter(c => !c.hidden)}
          onClickRow={(row) => handleClickRow(row, dispatch)}
        />
        {showLinkDialog && 
          <><LinkDialogRobot
          color='#1d6494'
          open={showLinkDialog === 'robot'}
          dispatch={dispatch}
          afterSubmit={() => { dispatch(getOverview({ loadMore: false, urlParams })) }}
          handleClose={handleCloseLinkDialog}
        />
       <LinkDialogSub
          color='#1d6494'
          open={showLinkDialog === 'sub'}
          dispatch={dispatch}
          afterSubmit={() => { dispatch(getOverview({ loadMore: false, urlParams })) }}
          handleClose={handleCloseLinkDialog}
        />
        <LinkDialogTaas
          color='#1d6494'
          open={showLinkDialog === 'taas'}
          dispatch={dispatch}
          afterSubmit={() => { dispatch(getOverview({ loadMore: false, urlParams })) }}
          handleClose={handleCloseLinkDialog}
        />
        <LinkDialogTaasRobot
          color='#1d6494'
          open={showLinkDialog === 'taasRobot'}
          dispatch={dispatch}
          afterSubmit={() => { dispatch(getOverview({ loadMore: false, urlParams })) }}
          handleClose={handleCloseLinkDialog}
        /></> }
        {showDialog && <DashtransferDialog
          color='#80cbc4'
          open={showDialog}
          dispatch={dispatch}
          handleClose={handleCloseDialog}
          afterSubmit={afterDashtransferSubmit}
        />}
        <TbDashErrorDialog
          message={errorMessage}
          open={!!errorMessage}
          handleClose={() => {
            clearErrors()
          }}
        />
      </div>
    )
  },
  (prevProps: any, nextProps: any) => {
    const equal = Object.keys(nextProps).reduce((equal, key) => {
      if (key === 'detailed') return true
      if (!shallowEqual(prevProps[key], nextProps[key])) {
        return false
      }
      return equal
    }, true)
    return equal
  }
)

const ConnectedDashtransfer = (
  props: Pick<DashtransferProps, '$http' | 'StoreService'>
) => {
  const overview = useSelector<RootState, OverviewState>(
    ({ overview }) => overview
  )
  const { showDialog } = useSelector<RootState, DashtransferState>(dashtransferSelector)
  const rows = useSelector<RootState, OverviewItemRow[]>(
    ({ overview: { ids, entities } }) => ids.map(id => entities[id])
  )
  const columns = useSelector<RootState, OverviewColumn[]>(
    ({
      overview: {
        columns: { ids, entities }
      }
    }) => {
      const columns = ids.map(id => entities[id])
      return columns
    }
  )
  const showDetailed = useSelector<RootState, boolean>(
    ({ detailed: { showDetailed } }) => showDetailed
  )
  const showLinkDialog = useSelector<RootState, 'robot' | 'sub' | 'taas' | 'taasRobot' | null>(
    ({ detailed: { showLinkDialog } }) => showLinkDialog
  )
  const showUnsavedWarn = useSelector<RootState, boolean>(
    ({ detailed: { showUnsavedWarn } }) => showUnsavedWarn
  )
  const errorMessage = useSelector<RootState, null | string>(
    ({ detailed, overview }) =>
      detailed.getError ??
      detailed.patchError ??
      overview.getError ??
      overview.patchError
  )

  const dispatch = useDispatch<typeof props.StoreService.store.dispatch>()
  return (
    <DashtransferComponent
      {...props}
      showDialog={showDialog}
      showLinkDialog={showLinkDialog}
      showDetailed={showDetailed}
      showUnsavedWarn={showUnsavedWarn}
      errorMessage={errorMessage}
      overview={overview}
      rows={rows}
      columns={columns}
      dispatch={dispatch}
    />
  )
}

const DashtransferWrapper: React.FunctionComponent<Pick<DashtransferProps, '$http' | 'StoreService'>> = (
  props: Pick<DashtransferProps, '$http' | 'StoreService'>
) => {
  return (
    <ThemeProvider theme={overviewTheme}>
      <Provider store={props.StoreService.store}>
        <ConnectedDashtransfer {...props} />
      </Provider>
    </ThemeProvider>
  )
}

export default DashtransferWrapper
