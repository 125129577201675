export const setMfaStyles = (themeContext: any) => {
    const rowStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px',
        position: 'relative'
    } as React.CSSProperties

    const labelColumnStyle = {
        paddingTop: '4px',
        left: '0',
        position: 'absolute'
    } as React.CSSProperties

    const contentColumnStyle = {
        position: 'relative',
        margin: 'auto',
        color: themeContext.colors.darkestgrey
    } as React.CSSProperties

    const secretStyle = {
        textAlign: 'center',
        background: 'white',
        color: themeContext.colors.darkestgrey,
        padding: '5px 7px',
        letterSpacing: '0.5px',
        fontFamily: 'Roboto-Mono, monospace',
        textTransform: 'uppercase',
        marginBottom: '3px'
    } as React.CSSProperties

    const headingStyle = {
        fontSize: '14px',
        lineHeight: '16px',
        marginBottom: '10px',
        marginTop: '20px',
        color: themeContext.colors.darkestgrey
    } as React.CSSProperties

    const pStyle = {
        color: themeContext.colors.darkestgrey
    } as React.CSSProperties

    const submitStyle = {
        backgroundColor: 'rgb(63, 122, 25)',
        borderColor: 'rgb(63, 122, 25)',
        color: 'rgb(232, 228, 218)',
        display: 'inline-block',
        padding: '8px 22px',
        textAlign: 'center',
        verticalAlign: 'center',
        height: '36px',
        minWidth: '120px',
        outline: 'none',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        fontFamily: 'Montserrat !important',
        fontSize: '16px',
        lineHeight: '16px',
        WebkitBoxSizing: 'border-box',
        boxSizing: 'border-box',
        borderRadius: '5px',
        border: '1px solid',
        marginTop: 20,
        float: 'right'
    } as React.CSSProperties
    return { rowStyle, labelColumnStyle, contentColumnStyle, secretStyle, headingStyle, pStyle, submitStyle }
}
