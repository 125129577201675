export const isISO8601Date = (value: string | number) => {
    try {
        const regexDate = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)$/
        const isRegexDate = regexDate.test(typeof value === 'string' ? value : value.toString())
        const validDate = isRegexDate && new Date(value).toISOString() === value
        return validDate
    } catch (error) {
        return false
    }
}
