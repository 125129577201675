import template from './tb-header.html'
import './tb-header.scss'

const tbHeader = {
  bindings: {
    type: '@',
    buttonTitle: '<',
    items: '<',
    placeholder: '@',
    title: '@',
    onSearch: '&',
    onButton: '&',
    term: '=?',
    searchType: '=?'
  },
  template,
  controller: /* @ngInject */ class tbHeaderController {
    search () {
      const searchTerm = {
        term: this.term,
        type: this.searchType.searchParam
      }
      this.onSearch({ $event: { searchTerm } })
    }

    buttonClick () {
      this.onButton()
    }

    setPlaceholder () {
      switch (this.searchType.name) {
        case 'RELATION & ROBOT':
          this.placeholder = 'Search for relation or robot'
          break
        case 'RELATION':
          this.placeholder = 'Search for relation'
          break
        case 'ROBOT':
          this.placeholder = 'Search for robot'
          break
        case 'PAYMENT REF.':
          this.placeholder = 'Search for payment reference'
          break
        case 'BOX':
          this.placeholder = 'Search for box number'
          break
      }
    }

    selectSearch ({ item }) {
      this.searchType = item
      this.setPlaceholder()
    }

    $onInit () {
      this.items = this.items || [{
        name: 'RELATION & ROBOT',
        searchParam: 'all'
      }, {
        name: 'BOX',
        searchParam: 'boxNumber'
      }, {
        name: 'PAYMENT REF.',
        searchParam: 'paymentReference'
      },
      {
        name: 'RELATION',
        searchParam: 'name'
      }, {
        name: 'ROBOT',
        searchParam: 'serial'
      }]
      this.searchType = this.searchType ? this.searchType : this.items[0]
      this.setPlaceholder()
    }
  }
}

export default tbHeader
