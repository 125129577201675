import '../styles/global.scss'
import 'angular'
import 'angular-messages'
import '@uirouter/angularjs'
import 'ngstorage'
import 'angular-dynamic-locale'
import 'satellizer'
import 'angular-sanitize'
import 'xregexp'
import 'angular-xregexp'
import '@iamadamjowett/angular-click-outside'
import 'angularjs-scroll-glue'
import moment from 'moment'
import 'moment-timezone'
import 'chart.js'
import "angular-chart.js"
import 'ngreact'
import '../localization/i18n';
import jsonformatter from 'jsonformatter'
const freesolid = require('@fortawesome/fontawesome-free-solid')
import fontawesome from '@fortawesome/fontawesome'
fontawesome.library.add(freesolid.faSpinner)
fontawesome.library.add(freesolid.faPencilAlt)
import rootConfig from './root.config'
import rootComponent from './root.component'
import './components/components.module'
import './common/app.module'
moment.tz.setDefault('Europe/Amsterdam')

angular
  .module('root', [
    'jsonFormatter',
    'ui.router',
    'ngStorage',
    'tmh.dynamicLocale',
    'satellizer',
    'ngSanitize',
    'ngMessages',
    'angularXRegExp',
    'angular-click-outside',
    'luegg.directives',
    'components',
    'common'
  ])
  .component('root', rootComponent)
  .config(rootConfig)
  .run(() => moment.locale('nl'))
