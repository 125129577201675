/**
 * @file Showing info and apply changes to a specific regular sub
 * @author Max van Loosbroek
 */

import moment from 'moment'
import React, { useCallback } from 'react'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem,
  TbDashWarnDialog
} from 'tinybots-react-components'
import { AppDispatch } from '../../../../../common/redux/store'
import { getSubRef } from '../../../stringTransformers'
import { ChainDetailed, SubscriptionDetailed } from '../../../types/types'
import {
  selectSub,
  subscriptionAdapter,
  toggleExtend,
  toggleUnsavedWarnExtend,
  updateChain,
  updateSub
} from '../../redux/reducer'
import { patchDetailed } from '../../redux/thunks'
import SusbcriptionsTable from './SubscriptionsTable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../common/redux/root.reducer'
import { defaultSubSelector } from '../../redux/selectors'

export interface OverviewDetailedSubscriptionProps {
  chain: ChainDetailed
  subs: SubscriptionDetailed[]
  activeSub: SubscriptionDetailed
  dispatch: AppDispatch
  showErrors: boolean
  showUnsavedWarnExtend: boolean
}

export const OverviewDetailedSubscriptionComponent = React.memo(
  ({
    chain,
    subs,
    showUnsavedWarnExtend,
    activeSub,
    dispatch,
    showErrors
  }: OverviewDetailedSubscriptionProps) => {
    const teamleaderLink = chain?.relation
      ? `https://app.teamleader.eu/company_detail.php?id=${chain?.relation?.teamleaderId}`
      : undefined
    const subscriptionReference = getSubRef(activeSub)
    const handleChangeCanceled = useCallback(
      value => dispatch(updateChain({ cancelled: value as boolean })),
      []
    )
    const handleExtend = useCallback(
      () => dispatch(toggleExtend({ show: true })),
      []
    )
    const handleChangeSubscriptionLength = useCallback(
      value => dispatch(updateSub({ subscriptionLength: value as number })),
      []
    )
    const handleChangePaymentRef = useCallback(
      value => dispatch(updateSub({ paymentReference: value as string })),
      []
    )
    const handleChangeShippedAt = useCallback(
      value => dispatch(updateSub({ shippedAt: value as string })),
      []
    )
    const handleChangeStartDate = useCallback(
      value => dispatch(updateSub({ startAt: value as string })),
      []
    )
    const handleChangeEndDate = useCallback(
      value => dispatch(updateSub({ endAt: value as string })),
      []
    )
    const handleChangeNotes = useCallback(
      value => dispatch(updateSub({ notes: value as string })),
      []
    )
    const handleSelectRow = useCallback(s => {
      dispatch(selectSub(s.subscriptionId))
    }, [])

    const handleSubmit = async () => {
      dispatch(toggleUnsavedWarnExtend({ show: false }))
      const res = await dispatch(patchDetailed())
      if (res?.meta.requestStatus === 'fulfilled')
        dispatch(toggleExtend({ show: true, force: true }))
    }

    const subscriptionLengthHasError = (value: number) => {
      return (!value || value < 0) && showErrors
        ? 'Invalid subscription length'
        : undefined
    }
    const shippingDateHasError = (value: string) =>
      value && !moment(value).isValid() && showErrors
        ? 'Invalid shipping date'
        : undefined
    const subscriptionStartHasError = (value: string) =>
      ((value && !moment(value).isValid()) ||
        moment(value) >= moment(activeSub?.endAt)) &&
      showErrors
        ? 'Invalid subscription length'
        : undefined
    const subscriptionEndHasError = (value: string) =>
      ((value && !moment(value).isValid()) ||
        moment(activeSub?.startAt) >= moment(value)) &&
      showErrors
        ? 'Invalid subscription length'
        : undefined
    if (!chain) return null
    return (
      <>
        <TbDashDetailedContainer
          key={`subscriptionDetails-${activeSub?.subscriptionId}`}
        >
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='Relation'
                iconUrl='assets/relation.svg'
                value={chain?.relation.name}
              />
              <TbDashDetailedItem
                type='link'
                href={teamleaderLink}
                disabled
                label=''
                target='_href'
                value='View in teamleader'
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='Robot Serial'
                iconUrl='assets/tessa-dark.svg'
                value={chain?.robot?.serial ?? '-'}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='Subscription reference'
                iconUrl='assets/subref.svg'
                value={`${subscriptionReference} (${activeSub?.subscriptionLength} days)`}
              />
              <TbDashDetailedItem
                type='checkbox'
                label='Canceled'
                value={chain?.cancelled}
                onChange={handleChangeCanceled}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='number'
                label='Subscription length'
                iconUrl='assets/calendar.svg'
                hasError={subscriptionLengthHasError}
                value={activeSub?.subscriptionLength}
                onChange={handleChangeSubscriptionLength}
              />
              <TbDashDetailedItem
                disabled={
                  chain?.cancelled ||
                  !(
                    activeSub?.startAt &&
                    moment.utc(activeSub?.startAt).isValid()
                  )
                }
                label={''}
                value={'EXTEND'}
                type={'button'}
                onClick={handleExtend}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                label='Subscription Payment Reference'
                iconUrl='assets/invoice.svg'
                value={activeSub?.paymentReference}
                onChange={handleChangePaymentRef}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='date'
                label='Shipping date (DD-MM-YYYY)'
                iconUrl='assets/TB-DB_icon_Shipping.svg'
                value={activeSub?.shippedAt}
                onChange={handleChangeShippedAt}
                hasError={shippingDateHasError}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='date'
                label='Start date (DD-MM-YYYY)'
                hasError={subscriptionStartHasError}
                iconUrl='assets/calendar.svg'
                value={activeSub?.startAt}
                onChange={handleChangeStartDate}
              />
              <TbDashDetailedItem
                type='date'
                label='End date (DD-MM-YYYY)'
                hasError={subscriptionEndHasError}
                value={activeSub?.endAt}
                onChange={handleChangeEndDate}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type='textArea'
                label='Subscription notes'
                value={activeSub?.notes}
                onChange={handleChangeNotes}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <SusbcriptionsTable
                onSelectRow={handleSelectRow}
                subs={subs}
                activeSub={activeSub?.subscriptionId}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
        </TbDashDetailedContainer>
        <TbDashWarnDialog
          open={showUnsavedWarnExtend}
          message='Do you really want to continue? You have unsaved changes.'
          submitButton='SAVE AND CONTINUE'
          handleSubmit={handleSubmit}
          handleClose={() => {
            dispatch(toggleUnsavedWarnExtend({ show: false }))
          }}
        />
      </>
    )
  }
)

const ConnectedOverviewDetailedSubscription = (
  props: Pick<OverviewDetailedSubscriptionProps, 'showErrors'>
) => {
  const chain = useSelector<RootState, ChainDetailed>(
    ({ detailed: { chain } }) => chain
  )
  const showUnsavedWarnExtend = useSelector<RootState, boolean>(
    ({ detailed: { showUnsavedWarnExtend } }) => showUnsavedWarnExtend
  )
  const activeSub = useSelector<RootState, SubscriptionDetailed>(
    ({ detailed }) => {
      let sub: SubscriptionDetailed
      if (
        detailed.activeSubscription &&
        detailed.subscriptions.entities[detailed.activeSubscription]
      ) {
        sub = detailed.subscriptions.entities[detailed.activeSubscription]
      } else {
        sub = defaultSubSelector(detailed)
      }
      return sub
    }
  )
  const subs = useSelector((state: RootState) =>
    subscriptionAdapter.getSelectors().selectAll(state.detailed.subscriptions)
  )
  const dispatch: AppDispatch = useDispatch()

  return (
    <OverviewDetailedSubscriptionComponent
      {...props}
      activeSub={activeSub}
      subs={subs}
      chain={chain}
      showUnsavedWarnExtend={showUnsavedWarnExtend}
      dispatch={dispatch}
    />
  )
}

export default ConnectedOverviewDetailedSubscription
