/**
 * @file Wrapper module to load react overview component inside angular
 * @author Max van Loosbroek
 */

import Overview from './Overview'
import StoreService from '../../../common/redux/store.service'
import angular from 'angular'
import { IHttpService } from 'angular'

export default angular
  .module('components.tb.overviewReact', ['react'])
  .directive(
    'tbOverviewReact',
    (reactDirective, $auth, $http: IHttpService, StoreService: StoreService) =>
      reactDirective(Overview, [], {}, { $auth, $http, StoreService })
  )
