/**
 * @file Reducer for managing state of returns
 * @author Max van Loosbroek
 */

import { createSlice } from '@reduxjs/toolkit'
import { ReturnFormState } from '../types/types'
import {
  createReturn,
  getOrderForReturn,
  getReturnConceptById,
  getReturnConcepts
} from './thunks'
import { ReturnForm } from '../types/ReturnForm'
import { emptyObject } from '../../../../../common/utilities/emptyObject'

export const initialStateOrderReturn: ReturnFormState = {
  getting: true,
  searching: false,
  getError: null,
  patching: false,
  patchError: null,
  returnConceptsSearchResults: [],
  returnForm: {}
}

const prepareReturnConcept = ({
  returner,
  returnReason,
  id,
  returnConceptId,
  pickupAddress,
  orderId,
  notes,
  internalNotes
}: any): ReturnForm => {
  return {
    returner,
    returnReason,
    returnConceptId: id ?? returnConceptId,
    pickupAddress,
    orderId,
    notes,
    internalNotes
  }
}

export const orderReturnSlice = createSlice({
  name: 'orderReturn',
  initialState: initialStateOrderReturn,
  reducers: {
    clearStateReturnConcept: () => initialStateOrderReturn,
    clearErrorsReturnConcept: state => {
      state.getError = null
      state.patchError = null
    },
    updateReturnForm: (
      state,
      { payload }: { payload: Partial<ReturnForm> }
    ) => {
      const copiedPayload: ReturnForm = JSON.parse(JSON.stringify(payload))
      if (copiedPayload.returner && emptyObject(copiedPayload.returner)) {
        copiedPayload.returner = undefined
      }
      if (copiedPayload.pickupAddress) {
        const { addressType, ...baseAddress } = copiedPayload.pickupAddress
        if (copiedPayload.pickupAddress && emptyObject(baseAddress) && addressType === 'unknown') {
          copiedPayload.pickupAddress = undefined
        }
      } else {
        state.returnForm.pickupAddress = undefined
      }
      state.returnForm = prepareReturnConcept({ ...state.returnForm, ...copiedPayload })
    },
    setDone: state => {
      state.getting = false
    },
    clearSearch: state => {
      state.returnConceptsSearchResults = []
    },
    removeOrder: state => {
      state.salesOrder = undefined
    }
  },
  extraReducers: builder => {
    builder.addCase(getReturnConcepts.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getReturnConcepts.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(getReturnConcepts.fulfilled, (state, action) => {
      state.getting = false
      if (action.payload.length === 1) {
        state.returnForm = prepareReturnConcept(action.payload[0])
      } else if (action.payload.length === 0) {
        state.returnForm = {}
      } else {
        state.returnConceptsSearchResults = action.payload
      }
      state.getError = null
    })
    builder.addCase(getReturnConceptById.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getReturnConceptById.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(getReturnConceptById.fulfilled, (state, action) => {
      state.getting = false
      state.returnForm = prepareReturnConcept(action.payload)
      state.getError = null
    })
    builder.addCase(getOrderForReturn.pending, state => {
      state.searching = true
      state.salesOrder = undefined
      state.getError = null
      state.returnForm.orderId = undefined
    })
    builder.addCase(getOrderForReturn.rejected, state => {
      state.searching = false
      state.salesOrder = undefined
      state.getError = null
      state.returnForm.orderId = undefined
    })
    builder.addCase(getOrderForReturn.fulfilled, (state, action) => {
      state.searching = false
      state.salesOrder = action.payload
      state.getError = null
      state.returnForm.orderId = action.payload?.id
      state.returnForm.internalNotes = action.payload?.internalNotes
    })
    builder.addCase(createReturn.pending, state => {
      state.patching = true
      state.patchError = null
    })
    builder.addCase(createReturn.rejected, (state, action) => {
      state.patching = false
      state.patchError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(createReturn.fulfilled, (state, action) => {
      state.patching = false
      state.patchError = null
    })
  }
})

export const {
  clearStateReturnConcept,
  clearErrorsReturnConcept,
  updateReturnForm,
  setDone,
  clearSearch,
  removeOrder
} = orderReturnSlice.actions

// Extract the action creators object and the reducer
const { reducer } = orderReturnSlice

export const orderReturnReducer = reducer
