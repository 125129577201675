import { createTheme as createMuiTheme } from "@material-ui/core";
import { amber, teal } from "@material-ui/core/colors";

export const materialTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: teal,
        secondary: amber,
    },
    overrides: {
        MuiFormHelperText: {
            root: {
                position: 'absolute',
                bottom: '-18px',
                right: 0,
                textAlign: 'right',
                whiteSpace: 'nowrap',
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: '24px',
                width: '24px'
            }
        }
    },
});

export const materialThemeLight = createMuiTheme({
    palette: {
        type: 'light',
        primary: teal,
        secondary: amber,
    },
    overrides: {
        MuiFormHelperText: {
            root: {
                position: 'absolute',
                bottom: '-18px',
                right: 0,
                textAlign: 'right',
                whiteSpace: 'nowrap',
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: '24px',
                width: '24px'
            }
        }
    },
});