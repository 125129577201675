import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../common/redux/root.reducer'
import { DetailedState, Tabs } from '../../types/types'
import OverviewDetailedRobot from './OverviewDetailedRobot/OverviewDetailedRobot'
import OverviewDetailedSubscription from './OverviewDetailedSubscription/OverviewDetailedSubscription'
import { AppDispatch } from '../../../../common/redux/store'
import OverviewDetailedTaas from './OverviewDetailedTaas/OverviewDetailedTaas'
import { detailedValidSelector } from '../redux/selectors'
import { patchDetailed } from '../redux/thunks'
import { TbDashDetailedDialog } from 'tinybots-react-components'

export interface OverviewDetailedDialogProps {
  detailed?: DetailedState
  activeTab?: 'robot' | 'taas' | 'sub' | 'contact'
  open: boolean
  color: string
  dispatch: AppDispatch
  onTabChange: (tab: Tabs) => void
  handleClose: () => void
  afterSubmit: () => void
}

const getActiveTab = (tabs: {type: Tabs}[], active: Tabs) => {
  const index = tabs.findIndex(t => t.type === active)
  return index > -1 ? index : 0
}

export const OverviewDetailedDialogComponent = ({
  open,
  dispatch,
  handleClose,
  onTabChange,
  color,
  detailed,
  afterSubmit
}: OverviewDetailedDialogProps) => {
  const [showErrors, setShowErrors] = useState(false)
  const handleSubmit = async () => {
    setShowErrors(true)
    const res = await dispatch(patchDetailed())
    if (res?.meta.requestStatus === 'fulfilled') {
      afterSubmit()
    }
  }
  const tabs: {type: Tabs, title: string, content: any}[] = []
  const invalid = !detailedValidSelector(detailed).detailedValid

  if (open && detailed.chain) {
    tabs.push({
      type: 'sub',
      title: 'Subscription View',
      content: (
        <>
          <OverviewDetailedSubscription
            showErrors={showErrors}
          />
        </>
      )
    })
  }

  if (open && detailed.taas) {
    tabs.push({
      type: 'sub',
      title: 'Taas Subscription View',
      content: (
        <>
          <OverviewDetailedTaas
            showErrors={showErrors}
          />
        </>
      )
    })
  }

  if (open && detailed.robot) {
    const title = detailed.robot.taas ? 'TaaS Robot View' : 'Robot View'
    tabs.push({
      type: 'robot',
      title,
      content: <OverviewDetailedRobot showErrors={showErrors} />
    })
  }

  const activeTab = getActiveTab(tabs, detailed.activeTab)
  const handleTabChange = (index: number) => {
    if (invalid) {
      setShowErrors(true)
    } else {
      onTabChange(tabs[index]?.type)
    }
  }

  return (
    <TbDashDetailedDialog
      loading={detailed.getting || detailed.patching}
      open={open}
      color={color}
      tabs={tabs}
      activeTab={activeTab}
      handleTabChange={handleTabChange}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  )
}

const ConnectedOverviewDetailedDialog = (
  props: OverviewDetailedDialogProps
) => {
  const detailed = useSelector<RootState, DetailedState>(
    ({ detailed }) => detailed
  )

  const dispatch: AppDispatch = useDispatch()

  return (
    <OverviewDetailedDialogComponent
      {...props}
      detailed={detailed}
      activeTab={detailed.activeTab}
      dispatch={dispatch}
    />
  )
}

export default React.memo(ConnectedOverviewDetailedDialog)
