/* @ngInject */
export default function httpInterceptor ($injector, $q) {
  let inFlightRefreshTokenRequest = null
  let inFlightRefreshTokenRequestTinybots = null
  return {
    request: (request) => {
      return request
    },
    requestError: {},
    response: {},
    responseError: (response) => {
      const deferred = $q.defer()
      const $state = $injector.get('$state')
      const AuthService = $injector.get('AuthService')
      switch (response.status) {
        case 401:
          if (
            response.data.message !== 'not a valid reset token' &&
            response.data.message !== 'mfa incorrect' &&
            response.data.message !== 'Invalid username or password' &&
            response.data.message !== 'Invalid Credentials'
          ) {
            if (!inFlightRefreshTokenRequestTinybots) {
              inFlightRefreshTokenRequestTinybots = AuthService.tryRefreshToken()
            }
            inFlightRefreshTokenRequest = inFlightRefreshTokenRequestTinybots
            inFlightRefreshTokenRequest.then((res) => {
              inFlightRefreshTokenRequest = null
              inFlightRefreshTokenRequestTinybots = null
              $injector.get('$http')(response.config)
                .then(resp => {
                  deferred.resolve(resp)
                }).catch(() => deferred.reject())
            }).catch(() => {
              inFlightRefreshTokenRequest = null
              inFlightRefreshTokenRequestTinybots = null
              deferred.reject()
            })
          } else {
            deferred.reject(response)
          }
          return deferred.promise
        case 403:
          if(response.data && response.data.secret) {
            return $q.reject(response)
          } else if (response.data.message === 'Expected a super-user.') {
            return $q.reject(response)
          } else {
            $state.go('error-general')
            return $q.reject()
          }
        default:
          if (response.status > 299 && ![400, 401, 403, 404, 409, 429, 500].includes(response.status)) {
            $state.go('error-general')
            return $q.reject()
          } else {
            return $q.reject(response)
          }
      }
      // return response || $q.when(response)
    }
  }
}
