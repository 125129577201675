import { Grid } from '@material-ui/core'
import React from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { Contact } from '../../types/Contact'

export const OrderRequester = ({ requester }: { requester?: Contact }) => (
  <>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='Requester email'
        value={requester?.email ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Tel. nr.'
        value={requester?.phoneNumber ?? '-'}
      />
    </TbDashDetailedItemRow>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='First name'
        value={requester?.firstname ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Last name'
        value={requester?.lastname ?? '-'}
      />
    </TbDashDetailedItemRow>
  </>
)
