/**
 * @file Functions for making http requests for order detailed
 * @author Max van Loosbroek
 */

import { IHttpService } from 'angular'
import { URLS } from '../../../../../common/utilities/constants/constants.module'
import { OrderConcept } from '../types/OrderConcept'
import { parseHttpError } from '../../../../../common/utilities/errorHandling'
import { OrderFinal } from '../../../types/OrderFinal'
import { OrderBody } from '../types/types'

export interface SearchParamsOrderConcept {
  clientId?: string
  clientUuid?: string
}

export const getOrderConceptRequest = (
  $http: IHttpService,
  { clientId, clientUuid }: SearchParamsOrderConcept
) => {
  const params: SearchParamsOrderConcept = {}
  if (clientUuid) params.clientUuid = clientUuid
  if (clientId) params.clientId = clientId
  const url = URLS.admin.taasOrderConcepts
  return $http.get<OrderConcept[]>(url, { params }).catch(parseHttpError)
}

export const getOrderConceptByIdRequest = (
  $http: IHttpService,
  conceptId: number
) => {
  const url = `${URLS.admin.taasOrderConcepts}/${conceptId}`
  return $http.get<OrderConcept>(url).catch(parseHttpError)
}

export const createOrderFinalRequest = (
  $http: IHttpService,
  order: OrderBody
) => {
  const url = URLS.admin.taasOrdersV4
  return $http.post<OrderFinal>(url, order).catch(parseHttpError)
}
