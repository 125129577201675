/**
 * @file Reducer for managing detailed state of orders
 * @author Max van Loosbroek
 */

import { createSlice } from '@reduxjs/toolkit'
import { OrderConceptState } from '../types/types'
import { OrderConcept } from '../types/OrderConcept'
import {
  createOrderFinal,
  getOrderConcepts,
  getOrderConceptById
} from './thunks'
import { emptyObject } from '../../../../../common/utilities/emptyObject'

export const initialStateOrderConcept: OrderConceptState = {
  getting: true,
  getError: null,
  patching: false,
  patchError: null,
  orderConcept: {
    id: undefined,
    tessaExpertNeeded: 'unknown',
    deliveryAddress: {
      country: 'Netherlands',
    } as any
  },
  orderConceptsSearchResults: []
}

const prepareConcept = (response: any): OrderConcept => {
  if (!response) return response
  const { relation, ...concept } = response
  if (concept.tessaExpertNeeded === 'yes') {
    concept.deliveryAddress = undefined
  }
  if (concept.receiver && emptyObject(concept.receiver)) {
    concept.receiver = undefined
  }
  if (
    concept.healthcareProfessional &&
    emptyObject(concept.healthcareProfessional)
  ) {
    concept.healthcareProfessional = undefined
  }
  return {
    ...concept,
    ...(relation && {
      relation: {
        relationId: relation.id ?? relation.relationId,
        name: relation.name
      }
    })
  }
}

export const orderConceptSlice = createSlice({
  name: 'orderConcept',
  initialState: initialStateOrderConcept,
  reducers: {
    clearStateOrderConcept: () => initialStateOrderConcept,
    clearErrorsOrderConcept: state => {
      state.getError = null
      state.patchError = null
    },
    updateOrder: (state, { payload }: { payload: Partial<OrderConcept> }) => {
      const copiedPayload: OrderConcept = JSON.parse(JSON.stringify(payload))
      if (copiedPayload.receiver && emptyObject(copiedPayload.receiver)) {
        copiedPayload.receiver = undefined
      }
      if (copiedPayload.tessaExpertNeeded !== 'yes' && state.orderConcept?.tessaExpertNeeded === 'yes') {
        copiedPayload.deliveryAddress = {
          country: 'Netherlands'
        } as any
      }
      if (
        copiedPayload.healthcareProfessional &&
        emptyObject(copiedPayload.healthcareProfessional)
      ) {
        copiedPayload.healthcareProfessional = undefined
      }
      state.orderConcept = prepareConcept({
        ...state.orderConcept,
        ...copiedPayload
      })
    },
    setDone: state => {
      state.getting = false
    },
    clearSearch: state => {
      state.orderConceptsSearchResults = []
    }
  },
  extraReducers: builder => {
    builder.addCase(getOrderConcepts.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getOrderConcepts.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(getOrderConcepts.fulfilled, (state, action) => {
      state.getting = false
      if (action.payload.length === 1) {
        state.orderConcept = prepareConcept(action.payload[0])
      } else {
        state.orderConceptsSearchResults = action.payload
      }
      state.getError = null
    })
    builder.addCase(getOrderConceptById.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getOrderConceptById.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(getOrderConceptById.fulfilled, (state, action) => {
      state.getting = false
      state.orderConcept = prepareConcept(action.payload)
      state.getError = null
    })
    builder.addCase(createOrderFinal.pending, state => {
      state.patching = true
      state.patchError = null
    })
    builder.addCase(createOrderFinal.rejected, (state, action) => {
      state.patching = false
      state.patchError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(createOrderFinal.fulfilled, (state, action) => {
      state.patching = false
      state.patchError = null
    })
  }
})

export const {
  clearStateOrderConcept,
  clearErrorsOrderConcept,
  updateOrder,
  setDone,
  clearSearch
} = orderConceptSlice.actions

// Extract the action creators object and the reducer
const { reducer } = orderConceptSlice

export const orderConceptReducer = reducer
