/**
 * @file Invite owners to manage the owner dashboard for the selected relation
 * @author Max van Loosbroek
 */

import React, { useCallback, useEffect, useState } from 'react'
import {
  TbDashDetailedColumn,
  TbDashDetailedItem,
  TbDashDetailedItemRow,
  TbDashDialog,
  TbDashErrorDialog
} from 'tinybots-react-components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../common/redux/root.reducer'
import {
  clearErrorsInvite,
  clearSearch,
  searchByEmailAdapter
} from '../redux/reducer'
import { RelationsByEmailItem } from '../redux/api'
import { inviteOwner, searchTeamleader } from '../redux/thunks'
import { CircularProgress } from '@material-ui/core'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import { EntityState } from '@reduxjs/toolkit'

export interface InviteOwnerProps {
  relationId: number | null
}

export const InviteOwner = ({ relationId }: InviteOwnerProps) => {
  const [email, setEmail] = useState('')
  const [searched, setSearched] = useState('')
  const [success, setSuccess] = useState(false)
  const previousRelationId = usePrevious(relationId)
  const dispatch = useDispatch()

  useEffect(() => {
    if (previousRelationId !== relationId) {
      setEmail('')
      setSearched('')
      dispatch(clearSearch())
    }
  }, [relationId])

  const searchResults: RelationsByEmailItem[] = useSelector<
    RootState,
    RelationsByEmailItem[]
  >(state =>
    searchByEmailAdapter
      .getSelectors()
      .selectAll(state.organisationAccounts.invite)
  )

  const matchedEmail = searchResults.flatMap(result => {
    return result.emails
      .filter(e => e.email.includes(searched))
      .map(e => e.email)
  })[0]

  const { getting, getError, patchError, patching } = useSelector<
    RootState,
    {
      patching: boolean
      patchError: null | string
      getting: boolean
      getError: null | string
    } & EntityState<RelationsByEmailItem>
  >(state => state.organisationAccounts.invite)

  const errorMessage = getError || patchError

  const search = useCallback(() => {
    setSearched(email)
    dispatch(searchTeamleader({ email, relationId }))
  }, [email, relationId])

  const onEmailChange = useCallback((value: string) => {
    setEmail(value)
  }, [])

  const submit = useCallback(async () => {
    await dispatch(inviteOwner({ email: matchedEmail, relationId }))
    setSuccess(true)
  }, [matchedEmail, relationId])

  return getting || patching ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 340
      }}
    >
      <CircularProgress size={100} />
    </div>
  ) : (
    <>
      <TbDashDetailedColumn>
        <TbDashDetailedItemRow>
          <h2
            style={{
              paddingLeft: 16,
              margin: 0,
              marginBottom: 20,
              fontSize: 16
            }}
          >
            Send invite for dashboard
          </h2>
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='text'
            data-testid='email-search'
            label='Email address'
            value={email}
            onChange={onEmailChange}
          />
          <TbDashDetailedItem
            type='button'
            label=''
            value='SEARCH'
            onClick={search}
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          {
            <TbDashDetailedItem
              type='info'
              label='Search results'
              value={
                searchResults.length > 0
                  ? searchResults[0]?.name
                  : searched
                  ? 'No results'
                  : '-'
              }
              onChange={onEmailChange}
            />
          }
          <TbDashDetailedItem
            disabled={searchResults[0]?.name === undefined}
            type='button'
            label=''
            value='SEND INVITE'
            onClick={submit}
          />
        </TbDashDetailedItemRow>
      </TbDashDetailedColumn>
      <TbDashErrorDialog
        message={errorMessage}
        open={!!errorMessage}
        handleClose={() => {
          dispatch(clearErrorsInvite())
        }}
      />
      <TbDashDialog
        open={success}
        title={'Invite sent'}
        message={'Your invite has been sent successfully'}
        submitButton='CONTINUE'
        handleSubmit={() => {
          dispatch(clearSearch())
          setSuccess(false)
          setSearched(null)
          setEmail('')
        }}
        handleClose={() => {}}
        color={'#ba9a1b'}
      />
    </>
  )
}
