/**
 * @file Helper functions for getting strings from specific data
 * @author Max van Loosbroek
 */

import {
  RobotDetailed,
  SubscriptionDetailed,
  TaasBase,
  TaasDetailed
} from './types/types'
import moment from 'moment'
import { SubscriptionBase } from './types/types'
import { RobotBase } from './types/types'
import { RelationDetailed } from './types/RelationDetailed'
import { RelationBase } from './types/RelationBase'

export const padZero = (item?: number, n: number = 5) => {
  if (item === undefined) return ''
  const itemString = item.toString()
  return '0'.repeat(n - itemString.length) + item
}

export const isCustomer = (relation: RelationBase | RelationDetailed) =>
  relation?.relationId
    ? !['Depot', 'Distributer'].includes(relation.relationType) && !['Depot', 'Distributer'].includes((relation as RelationDetailed).type)
    : false

export const isShippable = (robot: RobotBase) => {
  const shippable = robot.shippableAt
    ? parseInt(moment(robot.shippableAt).format('YYYY')) < 9999
    : false
  return shippable
}

export const getAppUser = (robot?: RobotDetailed) => {
  if (!robot) return '-'
  if (!robot?.account) {
    return 'Not installed'
  } else if (!robot?.account?.primaryUser) {
    return 'Not paired'
  } else {
    return (
      robot.account.primaryUser.firstName +
      ' ' +
      robot.account.primaryUser.lastName
    )
  }
}

export const getRobotRef = (robot?: RobotDetailed | RobotBase) => {
  return robot?.taas ? `T-${robot.serial}` : robot?.serial
}

export const getSubRef = (sub?: SubscriptionDetailed | SubscriptionBase) => {
  const separator = sub?.chainId && sub?.subscriptionId ? '-' : ''
  return `${sub?.chainId}${separator}${sub?.subscriptionId}`
}

export const getSubRefLength = (
  sub?: SubscriptionDetailed | SubscriptionBase
) => (sub ? getSubRef(sub) + ' (' + sub?.subscriptionLength + ' days)' : '-')

export const getTaasRef = (taas?: TaasBase | TaasDetailed) => {
  return taas ? `T-${(taas as TaasDetailed)?.id ?? taas?.taasId}` : ''
}

export const robotDeactivated = (robot: RobotDetailed) => {
  const robotDeactivated =
    robot?.deactivatedAt &&
    parseInt(moment.utc(robot.deactivatedAt).format('YYYY')) < 9999
  return !!robotDeactivated
}
