import { Menu, MenuItem, Tooltip } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { exportToSpreadsheet, validate } from '../../../../../tools/excelParser/excelParser'
import FileSaver from 'file-saver'
import { ExportButton } from './ExportButton'

export const QueryResultExport = ({
    data,
    queryName,
    errors
}: {
    data?: any
    queryName?: string
    errors?: { message: string }[]
}) => {
    const hasErrors = errors?.length
    const getFileName = useCallback(() => {
        return `Data-Export${queryName ? `-${queryName}` : ''}__${moment().format('DD-MM-YYYY_HH:mm')}`
    }, [queryName])
    const [showExportMenu, setShowExportMenu] = useState(false)
    const [dataError, setDataError] = useState<string>()
    const invalid = !data || hasErrors
    const exportRef = useRef()
    useEffect(() => {
        const validationResult = validate(data)
        setDataError(validationResult.valid ? undefined : validationResult.error)
    }, [data])
    const exportJson = useCallback(() => {
        var blob = new Blob([JSON.stringify(data, undefined, 2)], { type: 'text/plain;charset=utf-8' })
        FileSaver.saveAs(blob, `${getFileName()}.json`)
        setShowExportMenu(false)
    }, [data, getFileName])
    const exportExcel = useCallback(() => {
        exportToSpreadsheet(data, getFileName(), queryName)
        setShowExportMenu(false)
    }, [data, queryName, getFileName])
    const handleClick = useCallback(() => {
        if (data && !hasErrors) {
            setShowExportMenu(true)
        }
    }, [data, queryName])
    let errorMessage: React.ReactNode = ''
    if (hasErrors) {
        errorMessage = 'Export unavailable: query resulted in error'
    } else if (dataError) {
        errorMessage = dataError
    } else if (!data) {
        errorMessage = 'Export unavailable: query data is empty'
    }
    return (
        <section>
            <Tooltip
                placement='bottom-end'
                disableFocusListener={invalid ? false : true}
                disableHoverListener={invalid ? false : true}
                disableTouchListener={invalid ? false : true}
                title={errorMessage}
            >
                <div data-testid='tooltip-export' ref={exportRef}>
                    <ExportButton aria-disabled={!!invalid} disabled={!!invalid} onClick={handleClick}>
                        EXPORT
                    </ExportButton>
                </div>
            </Tooltip>
            <Menu
                id='simple-menu'
                anchorEl={exportRef.current}
                getContentAnchorEl={null}
                keepMounted
                open={showExportMenu}
                onClose={() => setShowExportMenu(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MenuItem onClick={exportJson}>To JSON file</MenuItem>
                <Tooltip
                    placement='bottom-end'
                    disableFocusListener={!dataError}
                    disableHoverListener={!dataError}
                    disableTouchListener={!dataError}
                    title={errorMessage}
                >
                    <div>
                        <MenuItem onClick={exportExcel} disabled={!!dataError}>
                            To Excel file
                        </MenuItem>
                    </div>
                </Tooltip>
            </Menu>
        </section>
    )
}
