import { FunctionComponent, useCallback } from 'react'
import { getOrderConcepts } from '../../redux/thunks'
import {
  TbDashDetailedItem,
  TbDashDetailedItemRow
} from 'tinybots-react-components/lib/components/DashboardComponents'
import React from 'react'
import { ButtonBase, ButtonBaseProps, styled } from '@material-ui/core'
import { useDispatch } from 'react-redux'

export const StatusButton: FunctionComponent<ButtonBaseProps> = styled(ButtonBase)({
  display: 'inline-block',
  padding: '8px 22px',
  textAlign: 'center',
  verticalAlign: 'middle',
  background: '#8356ac',
  height: 36,
  minWidth: 80,
  outline: 'none',
  MozAppearance: 'none',
  fontFamily: 'Montserrat!important',
  fontSize: 16,
  lineHeight: 1,
  boxSizing: 'border-box',
  borderRadius: 5,
  marginTop: 8,
  color: 'white',
  marginLeft: 53
}) as any

export const ConceptOrderSearch = ({
  clientId,
  setClientId,
  uuid,
  setUuid
}: {
  clientId: string
  setClientId: React.Dispatch<React.SetStateAction<string>>
  uuid: string
  setUuid: React.Dispatch<React.SetStateAction<string>>
}) => {
  const dispatch = useDispatch()
  const onChangeClientId = useCallback(
    (value: string) => {
      setClientId(value)
    },
    [setClientId]
  )
  const onChangeUuid = useCallback(
    (value: string) => {
      setUuid(value)
    },
    [setUuid]
  )
  const onSearch = useCallback(() => {
    if (clientId.length + uuid.length > 0) {
      dispatch(
        getOrderConcepts({
          clientUuid: uuid,
          clientId
        })
      )
    }
  }, [dispatch, clientId, uuid])

  return (
    <>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='Client id'
          iconUrl=''
          value={clientId}
          onChange={onChangeClientId}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='Universally unique id (UUID)'
          iconUrl=''
          value={uuid}
          onChange={onChangeUuid}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <StatusButton onClick={onSearch}>SEARCH CONCEPTS</StatusButton>
      </TbDashDetailedItemRow>
      <div style={{ marginTop: 130 }}></div>
    </>
  )
}
