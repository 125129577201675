/**
 * @file Wrapper module to load react overview component inside angular
 * @author Max van Loosbroek
 */

import OrderOverview from './OrderOverview'
import angular from 'angular'
import { IHttpService } from 'angular'
import StoreService from '../../../../common/redux/store.service'

export default angular
  .module('components.tb.orderOverviewReact', ['react'])
  .directive(
    'tbOrderOverviewReact',
    (reactDirective, $auth, $http: IHttpService, StoreService: StoreService) =>
      reactDirective(OrderOverview, [], {}, { $auth, $http, StoreService })
  )
