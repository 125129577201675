import React, { useCallback } from 'react'
import { IconButton } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import { TbDymo } from '../../../../../../tools/TbDymo/TbDymo'

export const PrintButton = ({
  dymo,
  dataType,
  value
}: {
  dymo: TbDymo
  dataType: 'serial' | 'box' | 'mac'
  value: string | number | [string, string]
}) => {
  const onClick = useCallback(() => {
    switch (dataType) {
      case 'serial':
        dymo.printSerial(value as string)
        break
      case 'box':
        dymo.printBox(value as number)
        break
      case 'mac':
        dymo.printMac(...value as [string, string])
        break
      default:
        break
    }
  }, [dymo, dataType, value])
  return (
    <div style={{ position: 'relative' }}>
      <IconButton aria-label='print' onClick={onClick} style={{ position: 'absolute' }}>
        <PrintIcon fontSize='small' />
      </IconButton>
    </div>
  )
}
