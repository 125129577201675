import './tb-nav/tb-nav.module'
import './tb-spinner/tb-spinner.module'
import './tb-datalist/tb-datalist.module'
import './tb-header/tb-header.module'
import './tb-table/tb-table.module'
import './tb-pop-up-v2/tb-pop-up-v2.module'
import './tb-pop-up-item/tb-pop-up-item.module'
import './tb-integer-validation/tb-integer-validation.module'

export default angular
  .module('common.tb.core', [
    'tb.nav',
    'tb.spinner',
    'tb.pop-up-v2',
    'tb.pop-up-item',
    'tb.datalist',
    'tb.header',
    'tb.table',
    'tb.integer-validation'
  ])
  .name
