import { debounce } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../common/redux/store'
import { isCustomer } from '../../../stringTransformers'
import {
  LinkEntitiesBody,
  RobotDetailed,
  SubscriptionBase
} from '../../../types/types'
import { updateRobot } from '../../redux/reducer'
import { linkEntities } from '../../redux/thunks'
import { RelationBase } from '../../../types/RelationBase'

export const useChangeHandlersLinkRobot = ({
  patchSub
}: {
  patchSub: (value: Partial<SubscriptionBase>) => void
}) => {
  const dispatch = useDispatch()
  const handleChangePaymentReference = useCallback(
    value => dispatch(updateRobot({ paymentReference: value as string })),
    []
  )
  const handleChangeZohoReference = useCallback(
    value => dispatch(updateRobot({ zendeskTicketId: value })),
    []
  )
  const handleChangeStartDate = useCallback(
    value => patchSub({ startAt: value as string }),
    []
  )
  const handleChangeEndDate = useCallback(
    value => patchSub({ endAt: value as string }),
    []
  )
  const handleChangeShippedAt = useCallback(
    value => patchSub({ shippedAt: value as string }),
    []
  )
  const handleSubNoteChange = useCallback(
    value => patchSub({ notes: value as string }),
    []
  )
  const handleRobotNoteChange = useCallback(
    value => dispatch(updateRobot({ notes: value as string })),
    []
  )
  return {
    handleChangePaymentReference,
    handleChangeZohoReference,
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangeShippedAt,
    handleSubNoteChange,
    handleRobotNoteChange
  }
}

export const useSubmitLinkRobot = ({
  relation,
  selectedSub,
  selectedRelation,
  setShowErrors,
  dispatch,
  afterSubmit,
  robot
}: {
  relation: RelationBase
  selectedRelation: RelationBase
  selectedSub: SubscriptionBase
  setShowErrors: Function
  dispatch: AppDispatch
  afterSubmit: Function
  robot: RobotDetailed
}) => {
  const handleSubmit = async () => {
    const robotPaymentReference =
      robot?.paymentReference ??
      (robot?.zendeskTicketId ? null : selectedSub?.paymentReference)
    const relationId = relation?.relationId ?? selectedRelation?.relationId
    setShowErrors(true)
    if (!robotPaymentReference && !robot.zendeskTicketId) return
    if (isCustomer(relation) && !selectedSub?.subscriptionId) return
    if (isCustomer(relation) && !selectedSub?.shippedAt) return
    if (!robot.serialId) return
    if (!relationId) return
    const body: LinkEntitiesBody = {
      basic: {
        endAt: selectedSub?.endAt ?? null,
        relationId: selectedRelation?.relationId ?? relation?.relationId,
        robotNotes: robot?.notes,
        robotPaymentReference,
        serialId: robot?.serialId,
        shippedAt: selectedSub?.shippedAt ?? null,
        startAt: selectedSub?.startAt ?? null,
        subscriptionId: selectedSub?.subscriptionId,
        subscriptionNotes: selectedSub?.notes ?? null,
        zendeskTicketId: robot?.zendeskTicketId ?? null
      }
    }
    const res = await dispatch(linkEntities(body))
    if (res?.meta.requestStatus === 'fulfilled') {
      afterSubmit()
    }
  }
  return handleSubmit
}

export const useLinkRobotFormInfo = ({
  selectedSub,
  showErrors,
  robot,
  relation,
  selectedRelation
}: {
  selectedSub: SubscriptionBase
  showErrors: boolean
  robot: RobotDetailed
  relation: RelationBase
  selectedRelation: RelationBase
}) => {
  const shippedAtHasError = (value: string) =>
    selectedSub?.subscriptionId &&
    (!value || !moment(value).isValid()) &&
    showErrors
      ? 'Enter a date'
      : undefined
  const referenceError = (_value?: string) =>
    !robot?.paymentReference &&
    !selectedSub?.paymentReference &&
    !robot?.zendeskTicketId &&
    showErrors
      ? 'Enter payment reference or zendesk ticket id'
      : undefined
  const subscriptionStartHasError = (value: string) =>
    ((selectedSub && value && !moment(value).isValid()) ||
      (selectedSub && value && moment(value) >= moment(selectedSub?.endAt))) &&
    showErrors
      ? 'Invalid subscription length'
      : undefined
  const ticketRefHasError = (value: string | number) =>
    value && isNaN(parseInt(value as string)) && showErrors
      ? 'Enter a number'
      : undefined
  const subscriptionEndHasError = (value: string) =>
    ((selectedSub && value && !moment(value).isValid()) ||
      (selectedSub &&
        value &&
        moment(selectedSub?.startAt) >= moment(value))) &&
    showErrors
      ? 'Invalid subscription length'
      : undefined
  const relationHasError = () =>
    !relation && !selectedRelation && showErrors
      ? 'Search for a relation'
      : undefined

  const refHelperText =
    !robot?.paymentReference &&
    selectedSub?.paymentReference &&
    !robot?.zendeskTicketId
      ? 'By default inherits from subscription'
      : undefined
  return {
    error: {
      shippedAtHasError,
      referenceError,
      subscriptionStartHasError,
      ticketRefHasError,
      subscriptionEndHasError,
      relationHasError
    },
    helperText: {
      refHelperText
    }
  }
}

export const useDebouncedFunction = ({
  changeHandler,
  delay
}: {
  changeHandler: (...args: any) => any
  delay: number
}) => {
  let destroyed = false

  useEffect(() => {
    return () => {
      destroyed = true
    }
  }, [])

  // improves performance
  const debouncedOnChange = useCallback(
    debounce(
      (...args) => {
        if (!destroyed) {
          changeHandler?.(...args)
        }
      },
      delay,
      {
        leading: false,
        trailing: true
      }
    ),
    []
  )

  return debouncedOnChange
}
