import { createAsyncThunk } from '@reduxjs/toolkit'
import { IHttpService } from 'angular'
import { OrderFinal, OrderFinalV5 } from '../../../types/OrderFinal'
import { ReturnConcept } from '../types/types'
import {
  SearchParamsReturnConcept,
  createReturnRequest,
  getReturnConceptByIdRequest,
  getReturnConceptsRequest
} from './api'
import { ReturnForm } from '../types/ReturnForm'
import { getOrder } from '../../redux/api'

export const getReturnConcepts = createAsyncThunk<
  ReturnConcept[],
  SearchParamsReturnConcept,
  { extra: { $http: IHttpService } }
>('orderReturn/getReturnConcepts', async (searchParams, thunkApi) => {
  // Get return concepts
  const { data: returnConcepts } = await getReturnConceptsRequest(
    thunkApi.extra.$http,
    searchParams
  )
  return returnConcepts
})

export const getReturnConceptById = createAsyncThunk<
  ReturnConcept,
  number,
  { extra: { $http: IHttpService } }
>('orderReturn/getReturnConceptById', async (conceptId, thunkApi) => {
  // Get return concept
  const { data: returnConcept } = await getReturnConceptByIdRequest(
    thunkApi.extra.$http,
    conceptId
  )
  if (returnConcept.orderId) {
    await thunkApi.dispatch(getOrderForReturn(returnConcept.orderId))
  }
  return returnConcept
})

export const getOrderForReturn = createAsyncThunk<
  OrderFinalV5 | undefined,
  number | string,
  { extra: { $http: IHttpService } }
>('orderReturn/getOrder', async (orderId, thunkApi) => {
  // Get original order
  const { data: order } = await getOrder(thunkApi.extra.$http, orderId)
  return order
})

export const createReturn = createAsyncThunk<
  OrderFinal,
  { returnForm: ReturnForm },
  { extra: { $http: IHttpService } }
>('orderReturn/createReturn', async ({ returnForm }, thunkApi) => {
  const { orderId, returner, returnReason, returnConceptId, pickupAddress, notes, internalNotes } = returnForm
  // Create return
  const { data: order } = await createReturnRequest(
    thunkApi.extra.$http,
    { returner, returnReason, returnConceptId, pickupAddress, notes, internalNotes },
    orderId
  )
  return order
})
