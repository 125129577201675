export const validateSetMfaForm = ({
    password,
    otpCode
}: {
    password: string
    otpCode: string
}) => {
    const validPassword = password && password.length >= 8
    const validOtp = otpCode && otpCode.length === 6
    return { valid: validPassword && validOtp, validPassword, validOtp }
}
