/**
 * @file Displays owner accounts for an organisation
 * @author Max van Loosbroek
 */

import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { organisationAdminAccountsSelector } from './redux/reducer'
import { RootState } from '../../../common/redux/root.reducer'
import { Org, OrganisationAccountsState, OrganisationAdminAccount } from './types'
import { AppDispatch } from '../../../common/redux/store'
import { retrieveOrganisationAdminAcounts } from './redux/thunks'
import StoreService from '../../../common/redux/store.service'
import { ThemeProvider } from 'styled-components'
import { overviewTheme } from '../../overview/Overview/Overview'
import { TbDashDetailedContainer, TbDashDetailedColumn, TbDashDetailedItemRow } from 'tinybots-react-components'
import { AccountItem } from './AccountItem'
import { CircularProgress } from '@material-ui/core'
import { InviteOwner } from './InviteOwner/InviteOwner'
import styledComponentsTheme from '../../../common/react/theme/styledComponentsTheme'

export interface OrganisationAccountsProps {
    $http: any
    StoreService: StoreService
    organisation: Org
}

export const ConnectedOrganisationAccounts = ({ organisation }: OrganisationAccountsProps) => {
    const dispatch: AppDispatch = useDispatch()
    const accounts: OrganisationAdminAccount[] = useSelector<RootState, OrganisationAdminAccount[]>(
        organisationAdminAccountsSelector
    )
    const accountsLoading = useSelector<RootState, boolean>(rootState => rootState?.organisationAccounts?.getting)
    useEffect(() => {
        dispatch(retrieveOrganisationAdminAcounts({ relationId: organisation.relationId }))
    }, [])
    return (
        <div
            style={{
                minWidth: '1024px',
                maxWidth: '90vw',
                minHeight: 361,
                maxHeight: 550,
                overflow: 'auto',
                overflowX: 'hidden',
                paddingTop: 20
            }}
        >
            <div style={{ maxWidth: 1024 }}>
                {accountsLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 340
                        }}
                    >
                        <CircularProgress size={100} />
                    </div>
                ) : (
                    <TbDashDetailedContainer>
                        <TbDashDetailedColumn>
                            <TbDashDetailedItemRow placeholder={undefined}>
                                {accounts.length > 0 ? (
                                    <ThemeProvider theme={styledComponentsTheme}>
                                        <div
                                            style={{
                                                marginTop: 25,
                                                marginLeft: 54,
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                background: 'rgb(243, 247, 252)'
                                            }}
                                        >
                                            {accounts.map(account => (
                                                <AccountItem key={account.email} account={account} relationId={organisation.relationId} />
                                            ))}
                                        </div>
                                    </ThemeProvider>
                                ) : (
                                    <div style={{ padding: 20 }}>No accounts found</div>
                                )}
                            </TbDashDetailedItemRow>
                        </TbDashDetailedColumn>
                        <InviteOwner relationId={organisation.relationId} />
                    </TbDashDetailedContainer>
                )}
            </div>
        </div>
    )
}

const OrganisationAccountsWrapper: React.FunctionComponent<
    Pick<OrganisationAccountsProps, '$http' | 'StoreService'>
> = (props: Pick<OrganisationAccountsProps, '$http' | 'StoreService' | 'organisation'>) => {
    return (
        <ThemeProvider theme={overviewTheme}>
            <Provider store={props.StoreService.store}>
                <ConnectedOrganisationAccounts {...props} />
            </Provider>
        </ThemeProvider>
    )
}

export default OrganisationAccountsWrapper
