/**
 * @file Showing info and apply changes to a specific robot
 * @author Max van Loosbroek
 */

import { Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { RootState } from '../../../../../common/redux/root.reducer'
import { AppDispatch } from '../../../../../common/redux/store'
import {
  getAppUser,
  isCustomer,
  isShippable
} from '../../../stringTransformers'
import { DetailedState, RobotDetailed } from '../../../types/types'
import { toggleRobotActive, updateRobot } from '../../redux/reducer'
import { TbDymo } from '../../../../../../tools/TbDymo/TbDymo'
import { PrintButton } from './PrintButton'

export interface OverviewDetailedRobotProps {
  robot: RobotDetailed
  disableShippable: boolean
  dispatch: AppDispatch
  showErrors: boolean
}

export const OverviewDetailedRobotComponent = React.memo(
  ({ robot, disableShippable, dispatch }: OverviewDetailedRobotProps) => {
    const [ dymoConnected, setDymoConnected ] = useState(false)
    const [ tbDymo, setTbDymo ] = useState<TbDymo>()
    const isMounted = useRef(false);
    const disableTaasToggle = false
    const heartBeatLink = `/heartbeat/${robot.serial}`
    const active = robot?.account?.status === 'ACTIVE'
    const appUser = getAppUser(robot)
    const shippable = isShippable(robot)
    const handleChangePaymentReference = useCallback(
      value => dispatch(updateRobot({ paymentReference: value as string })),
      []
    )
    const handleChangeZohoReference = useCallback(
      value => dispatch(updateRobot({ zendeskTicketId: value as string })),
      []
    )
    const handleChangeActiveStatus = useCallback(
      value => dispatch(toggleRobotActive(value as boolean)),
      []
    )
    const handleChangeOKR = useCallback(
      value => dispatch(updateRobot({ excludeOkr: !value as boolean })),
      []
    )
    const handleChangeAnalytics = useCallback(
      value => dispatch(updateRobot({ deactivated: !value as boolean })),
      []
    )
    const handleChangeNotes = useCallback(
      value => dispatch(updateRobot({ notes: value as string })),
      []
    )
    const handleChangeTaas = useCallback(
      value => dispatch(updateRobot({ taas: value as boolean })),
      []
    )
    const handleChangeICCId = useCallback(
      value => dispatch(updateRobot({ iccId: value as string })),
      []
    )
    const handleChangeShippable = useCallback(value => {
      dispatch(
        updateRobot({
          shippable: value,
          shippableAt: value
            ? new Date().toISOString()
            : '9999-12-31T23:59:59.000Z'
        })
      )
    }, [])
    useEffect(() => {
      isMounted.current = true;
      return () => { isMounted.current = false }
    }, []);

    useEffect(() => {
      const dymo = new TbDymo()
      setTbDymo(dymo)
      dymo.connect()
      dymo.isConnected().then(connected => {
        if(isMounted.current) {
          setDymoConnected(connected)
        }
      })
    }, [])

    return (
      <TbDashDetailedContainer>
        <TbDashDetailedColumn>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Relation'
              iconUrl='assets/relation.svg'
              value={robot?.relation?.name}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='Robot serial'
              iconUrl='assets/tessa-dark.svg'
              value={robot.taas ? `T-${robot.serial}` : robot.serial}
            />
            { dymoConnected ? <PrintButton dymo={tbDymo} dataType={'serial'} value={robot.serial}/> : null }
            <TbDashDetailedItem
              type='text'
              disabled
              label='Hardware version'
              iconUrl=''
              value={robot?.hardwareVersion}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='link'
              href={heartBeatLink}
              iconUrl='assets/TB-DB_icon_heartbeat-dark.svg'
              label=''
              value='Robot heartbeat'
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              iconUrl='assets/category.svg'
              label='Boxnumber'
              value={robot?.boxNumber || '-'}
            />
            { dymoConnected ? <PrintButton dymo={tbDymo} dataType={'box'} value={robot?.boxNumber}/> : null }
            <TbDashDetailedItem
              type='text'
              disabled
              iconUrl=''
              label='Assembly date'
              value={robot?.assembledAt || '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              iconUrl='assets/category.svg'
              label='Ethernet Mac'
              value={robot?.ethMac || '-'}
            />
            { dymoConnected ? <PrintButton dymo={tbDymo} dataType={'mac'} value={[robot?.ethMac, robot?.wlanMac]}/> : null }
            <TbDashDetailedItem
              type='text'
              disabled
              iconUrl=''
              label='Wlan Mac'
              value={robot?.wlanMac || '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              iconUrl='assets/router_white_24dp.svg'
              label='ICC id'
              value={robot?.iccId ?? null}
              onChange={handleChangeICCId}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              iconUrl='assets/invoice.svg'
              label='Robot Payment Reference'
              value={robot?.paymentReference}
              onChange={handleChangePaymentReference}
            />
            <TbDashDetailedItem
              type='number'
              iconUrl='assets/tb_zendesk.png'
              label='Zoho Reference'
              value={robot?.zendeskTicketId}
              onChange={handleChangeZohoReference}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            {robot?.account ? (
              <TbDashDetailedItem
                type='switch'
                iconUrl='assets/block.svg'
                label='Block/unblock robot'
                switchText={active ? 'Robot active' : 'Robot blocked'}
                value={active}
                onChange={handleChangeActiveStatus}
              />
            ) : (
              <TbDashDetailedItem
                type='text'
                disabled
                iconUrl='assets/block.svg'
                label='Block/unblock robot'
                value='Not installed'
              />
            )}
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <TbDashDetailedItem
                  xs={3}
                  type='checkbox'
                  iconUrl=''
                  label='OKR'
                  value={!robot?.excludeOkr}
                  onChange={handleChangeOKR}
                />
                <TbDashDetailedItem
                  xs={3}
                  type='checkbox'
                  iconUrl=''
                  label='Analytics'
                  value={!robot?.deactivated}
                  onChange={handleChangeAnalytics}
                />
                <TbDashDetailedItem
                  xs={3}
                  type='checkbox'
                  disabled={disableShippable}
                  iconUrl=''
                  label='Shippable'
                  value={shippable}
                  onChange={handleChangeShippable}
                />
                <TbDashDetailedItem
                  xs={3}
                  type='checkbox'
                  disabled={disableTaasToggle}
                  iconUrl=''
                  label='TaaS'
                  value={robot?.taas}
                  onChange={handleChangeTaas}
                />
              </Grid>
            </Grid>
          </TbDashDetailedItemRow>
        </TbDashDetailedColumn>
        <TbDashDetailedColumn>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              xs={12}
              type='textArea'
              label='Robot notes'
              value={robot.notes}
              onChange={handleChangeNotes}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='App user'
              value={appUser}
            />
            <TbDashDetailedItem
              type='text'
              disabled
              label='Tel nr.'
              value={robot?.account?.primaryUser?.phoneNumber ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='E-mail'
              value={robot?.account?.primaryUser?.email ?? '-'}
            />
          </TbDashDetailedItemRow>
        </TbDashDetailedColumn>
      </TbDashDetailedContainer>
    )
  }
)

const ConnectedOverviewDetailedRobot = (
  props: Pick<OverviewDetailedRobotProps, 'showErrors'>
) => {
  const { robot } = useSelector<RootState, DetailedState>(
    ({ detailed }) => detailed
  )
  const disableShippable = robot.relation && isCustomer(robot.relation)
  const dispatch: AppDispatch = useDispatch()

  return (
    <OverviewDetailedRobotComponent
      dispatch={dispatch}
      robot={robot}
      disableShippable={disableShippable}
      {...props}
    />
  )
}

export default ConnectedOverviewDetailedRobot
