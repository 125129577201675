import { IsInt, IsString, Min, MinLength } from "class-validator"

export class ThirdpartyIntegration {
  @Min(1)
  @IsInt()
  id: number

  @IsString()
  @MinLength(1)
  name: string
}