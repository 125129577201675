/**
 * @file Reducer for managing detailed state
 * @author Max van Loosbroek
 */

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  ChainDetailed,
  DetailedState,
  RobotDetailed,
  SubscriptionDetailed,
  TaasDetailed,
  Tabs
} from '../../types/types'
import { SubscriptionBase } from '../../types/types'
import { EMPTY_SUB } from '../../constants'
import {
  getDetailed,
  patchDetailed,
  linkEntities,
  extendSub,
  searchRobots,
  searchSubscriptions,
  searchRelations
} from './thunks'
import {
  getDefaultSub,
  hasUnsavedChanges,
  subsLatestSelector
} from './selectors'
import { searchTaas } from './thunks'
import { getChainPatch, getRobotPatch, getTaasPatch } from './patchMappers'
import { RelationBase } from '../../types/RelationBase'

export const subscriptionAdapter = createEntityAdapter<SubscriptionDetailed>({
  selectId: entity => entity.subscriptionId
})

export const subscriptionsSearchAdapter = createEntityAdapter<SubscriptionBase>(
  {
    selectId: entity => entity.subscriptionId
  }
)

export const taasSearchAdapter = createEntityAdapter<TaasDetailed>({
  selectId: entity => entity.id
})

export const robotsAdapter = createEntityAdapter<RobotDetailed>({
  selectId: entity => entity.serialId
})

export const relationsAdapter = createEntityAdapter<RelationBase>({
  selectId: entity => entity.relationId
})

export const initialStateDetailed: DetailedState = {
  showDetailed: false,
  showExtend: false,
  showLinkDialog: null,
  showUnsavedWarn: false,
  showUnsavedWarnExtend: false,
  getting: true,
  getError: null,
  patching: false,
  patchError: null,
  taasSearch: taasSearchAdapter.getInitialState(),
  subscriptionsSearch: subscriptionsSearchAdapter.getInitialState(),
  subscriptions: subscriptionAdapter.getInitialState(),
  robotsSearch: robotsAdapter.getInitialState(),
  relationsSearch: relationsAdapter.getInitialState()
}

export const detailedSlice = createSlice({
  name: 'detailed',
  initialState: initialStateDetailed,
  reducers: {
    clearStateDetailed: () => initialStateDetailed,
    clearErrorsDetailed: state => {
      state.getError = null
      state.patchError = null
    },
    updateSub: (
      { subscriptions, activeSubscription },
      { payload }: { payload: Partial<SubscriptionDetailed> }
    ) => {
      const id = payload?.subscriptionId ?? activeSubscription
      const changes = { ...payload }
      const original = {
        ...subscriptionAdapter.getSelectors().selectById(subscriptions, id)
      }
      if (
        payload.subscriptionLength &&
        payload.subscriptionLength !== original.subscriptionLength
      ) {
        changes.subscriptionLength = Math.abs(payload.subscriptionLength)
        changes.endAt = original.startAt
          ? moment
              .utc(original.startAt)
              .add(changes.subscriptionLength, 'days')
              .toISOString()
          : null
      }
      if (payload.endAt && payload.endAt !== original.endAt) {
        const valid = moment.utc(payload.endAt).isValid()
        changes.subscriptionLength = valid
          ? payload.endAt
            ? moment
                .utc(payload.endAt)
                .diff(moment.utc(original.startAt), 'days')
            : null
          : 0
      }
      if (
        payload.startAt &&
        payload.startAt !== original.startAt &&
        original.subscriptionLength
      ) {
        const valid = moment.utc(payload.startAt).isValid()
        if (valid) {
          changes.endAt = payload.startAt
            ? moment
                .utc(payload.startAt)
                .add(original.subscriptionLength, 'days')
                .toISOString()
            : null
        }
      }
      subscriptionAdapter.updateOne(subscriptions, {
        id,
        changes
      })
    },
    selectSub: (state, { payload }: { payload: number }) => {
      state.activeSubscription = payload
    },
    updateChain: (state, { payload }: { payload: Partial<ChainDetailed> }) => {
      state.chain = { ...state.chain, ...payload }
    },
    updateRobot: (state, { payload }: { payload: Partial<RobotDetailed> }) => {
      state.robot = { ...state.robot, ...payload }
    },
    toggleRobotActive: (state, { payload }: { payload: boolean }) => {
      state.robot.account.status = payload ? 'ACTIVE' : 'BLOCKED'
    },
    updateTaas: (state, { payload }: { payload: Partial<TaasDetailed> }) => {
      state.taas = { ...state.taas, ...payload }
    },
    changeTab: (state, { payload }: { payload: Tabs }) => {
      state.activeTab = payload
    },
    toggleDetailed: (
      state,
      {
        payload: { show, force, activeTab }
      }: {
        payload: {
          show: boolean
          force?: boolean
          activeTab?: Tabs
        }
      }
    ) => {
      const unsaved = hasUnsavedChanges(state)
      if (force || show === true) {
        state.activeTab = activeTab
        state.showDetailed = show
      } else if (unsaved) {
        state.showUnsavedWarn = true
      } else {
        if (!show) {
          return initialStateDetailed
        }
        state.showDetailed = show
        state.activeTab = undefined
      }
    },
    toggleLinkDialog: (
      state,
      {
        payload: { show }
      }: { payload: { show: 'robot' | 'sub' | 'taas' | 'taasRobot' | null } }
    ) => {
      state.showLinkDialog = show
    },
    toggleUnsavedWarn: (
      state,
      { payload: { show } }: { payload: { show: boolean } }
    ) => {
      state.showUnsavedWarn = show
    },
    toggleUnsavedWarnExtend: (
      state,
      { payload: { show } }: { payload: { show: boolean } }
    ) => {
      state.showUnsavedWarnExtend = show
    },
    toggleExtend: (
      state,
      {
        payload: { show, force }
      }: { payload: { show: boolean; force?: boolean } }
    ) => {
      const updateSubscriptions = subscriptionAdapter
        .getSelectors()
        .selectAll(state.subscriptions)
      const chainPatch = getChainPatch({
        updateChain: state.chain,
        originalChain: state.originalChain,
        updateSubscriptions
      })
      const robotPatch = getRobotPatch({
        originalRobot: state.originalRobot,
        updateRobot: state.robot
      })
      if ((chainPatch || robotPatch) && !force) {
        state.showUnsavedWarnExtend = true
      } else {
        state.showExtend = show
        if (show) {
          const tempId = -1 // subs with id -1 are temporary, and should only exist while extend dialog is open
          const latest = subsLatestSelector(state)
          const newSub: SubscriptionDetailed = EMPTY_SUB(latest, tempId)
          subscriptionAdapter.addOne(state.subscriptions, newSub)
        } else {
          subscriptionAdapter.removeOne(state.subscriptions, -1)
        }
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getDetailed.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getDetailed.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(getDetailed.fulfilled, (state, action) => {
      const { chain, robot, taas } = action.payload
      state.getting = false
      state.getError = null
      state.chain = chain
      state.originalChain = chain
      state.robot = robot
      state.originalRobot = robot
      state.taas = taas
      state.originalTaas = taas
      state.activeSubscription = getDefaultSub(
        chain?.subscriptions
      )?.subscriptionId
      subscriptionAdapter.setAll(
        state.subscriptions,
        chain?.subscriptions ?? []
      )
    })
    builder.addCase(patchDetailed.pending, state => {
      state.patching = true
      state.patchError = null
    })
    builder.addCase(patchDetailed.rejected, (state, action) => {
      if (action.error?.message.includes('Invalid form')) {
        state.patching = false
        state.patchError = null
      } else {
        state.patching = false
        state.patchError = action.error?.message ?? 'Unknown error'
      }
    })
    builder.addCase(patchDetailed.fulfilled, (state, { payload }) => {
      const { chain, robot, taas } = payload
      state.patching = false
      state.patchError = null
      state.chain = chain ? { ...state.originalChain, ...chain } : state.chain
      state.originalChain = chain
        ? { ...state.originalChain, ...chain }
        : state.chain
      state.robot = robot ? { ...state.originalRobot, ...robot } : state.robot
      state.originalRobot = robot
        ? { ...state.originalRobot, ...robot }
        : state.robot
      state.taas = taas ? { ...state.originalTaas, ...taas } : state.taas
      state.originalTaas = taas
        ? { ...state.originalTaas, ...taas }
        : state.taas
      if (chain?.subscriptions) {
        subscriptionAdapter.setAll(
          state.subscriptions,
          chain?.subscriptions ?? []
        )
        state.activeSubscription = getDefaultSub(
          chain?.subscriptions
        )?.subscriptionId
      }
    })
    builder.addCase(linkEntities.pending, state => {
      state.patching = true
      state.patchError = null
    })
    builder.addCase(linkEntities.rejected, (state, action) => {
      if (action.error?.message.includes('Invalid form')) {
        state.patching = false
        state.patchError = null
      } else {
        state.patching = false
        state.patchError = action.error?.message ?? 'Unknown error'
      }
    })
    builder.addCase(linkEntities.fulfilled, state => {
      state.patching = false
      state.patchError = null
      state.showLinkDialog = null
    })
    builder.addCase(extendSub.pending, state => {
      state.patching = true
      state.patchError = null
    })
    builder.addCase(extendSub.rejected, (state, action) => {
      state.patching = false
      state.patchError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(extendSub.fulfilled, state => {
      state.patching = false
      state.patchError = null
      state.showExtend = false
    })
    builder.addCase(searchRobots.pending, state => {
      state.getError = null
    })
    builder.addCase(searchRobots.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(searchRobots.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      robotsAdapter.setAll(state.robotsSearch, action.payload)
    })
    builder.addCase(searchSubscriptions.pending, state => {
      state.getError = null
    })
    builder.addCase(searchSubscriptions.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(searchSubscriptions.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      subscriptionsSearchAdapter.setAll(
        state.subscriptionsSearch,
        action.payload
      )
    })
    builder.addCase(searchTaas.pending, state => {
      state.getError = null
    })
    builder.addCase(searchTaas.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(searchTaas.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      taasSearchAdapter.setAll(state.taasSearch, action.payload)
    })
    builder.addCase(searchRelations.pending, state => {
      state.getError = null
    })
    builder.addCase(searchRelations.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(searchRelations.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      relationsAdapter.setAll(
        state.relationsSearch,
        action.payload.slice(0, 20)
      )
    })
  }
})

export const {
  clearStateDetailed,
  updateSub,
  updateChain,
  updateRobot,
  updateTaas,
  toggleRobotActive,
  selectSub,
  toggleDetailed,
  toggleExtend,
  toggleUnsavedWarn,
  toggleUnsavedWarnExtend,
  clearErrorsDetailed,
  toggleLinkDialog,
  changeTab
} = detailedSlice.actions

// Extract the action creators object and the reducer
const { reducer } = detailedSlice

export const detailedReducer = reducer
