import template from './login.html'

const login = {
  template,
  controller: /* @ngInject */ class LoginController {
    constructor (FIELDSETS, $auth, AuthService, $state, DASHBOARD_URL) {
      this.fieldsets = FIELDSETS
      this.$auth = $auth
      this.AuthService = AuthService
      this.$state = $state
      this.dashUrl = DASHBOARD_URL
    }

    $onInit () {
      this.showPreloader = false

      this.user = {
        email: '',
        password: ''
      }
      this.clearErrors()
    }

    clearErrors () {
      this.error = {
        errorAuth: '',
        errorOther: ''
      }
    }

    logIn ({ user }) {
      user && angular.copy(user, this.user)
      if (this.user && this.user.email && this.user.password) {
        this.showPreloader = true
        this.clearErrors()
        this.$auth.login({
          username: this.user.email,
          password: this.user.password
        }).then(response => {
          if (response && response.hasOwnProperty('data') && response.data.token && response.data.token.access_token) {
            this.$auth.setToken(response.data.token.access_token);
            this.AuthService.setSession(response.data.token);
            return this.$state.go('overview')
          } else if (response && response.hasOwnProperty('data') && response.data.needMfa) {
            return this.$state.go('auth.loginMfa', { password: this.user.password, username: this.user.email });
          } else {
            this.error = {
              errorAuth: 'Something went wrong, please try again later',
              errorOther: ''
            }
          }
        }).catch(error => {
          if (error.status === 403 && error.data.secret) {
            return this.$state.go('auth.setMfaPage', { secret: error.data.secret, email: error.config.data.username });
          } else if (error.status === 401) {
            this.error = {
              errorAuth: 'The email address and/or password is incorrect',
              errorOther: 'Please check your account details and try to login again'
            }
          } else {
            this.error = {
              errorAuth: 'Something went wrong, please try again later',
              errorOther: ''
            }
          }
        }).finally(() => {
          this.showPreloader = false
        })
      } else {
        return false
      }
    }
  }
}

export default login
