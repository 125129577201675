import { IsInt, IsOptional, IsString, MinLength } from "class-validator"

export class Address {
  @MinLength(1)
  @IsString()
  recipient: string

  @MinLength(1)
  @IsString()
  street: string

  @IsInt()
  homeNumber: number | string

  @IsOptional()
  @IsString()
  homeNumberExtension?: string

  @MinLength(1)
  @IsString()
  city: string

  @MinLength(1)
  @IsString()
  zipcode: string

  @IsOptional()
  @IsString()
  locationDescription?: string

  @MinLength(1)
  @IsString()
  country: string

  @IsOptional()
  @IsString()
  addressType?: string | null
}