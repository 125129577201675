
/**
 * @file Async thunks for getting and patching data for detailed state
 * @author Max van Loosbroek
 */

export const parseHttpError = (e: any) => {
  let message = `An error occurred with status ${e?.status ?? 'NO_STATUS_AVAILABLE'} and the following message:\n ${e?.data?.message ?? 'NO_MESSAGE_AVAILABLE'}`
  throw new Error(message)
}
