import { ButtonBase } from '@material-ui/core'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { TbTableRowData } from 'tinybots-react-components'
import {
  OrderOverviewColumnKey,
  OrderFinalRow,
  PossibleStatuses
} from '../types/types'
import { STATUS_COLORS, STATUS_LABELS } from './constants'
import { OrderFinalV5 } from '../types/OrderFinal'
import { OrderStatus } from '../types/OrderStatus'

export const getStatusString = (status?: OrderStatus): PossibleStatuses => {
  if (!status) {
    return undefined
  }
  if (status.declinedAt) {
    return 'declined'
  } else if (status.returnedAt) {
    return 'returned'
  } else if (status.returnRejectedAt) {
    return 'returnRejected'
  } else if (status.returnAcceptedAt) {
    return 'returnAccepted'
  } else if (status.returnProcessStartedAt) {
    return 'returnProcessStarted'
  } else if (status.activatedAt) {
    return 'activated'
  } else if (status.deliveredAt) {
    return 'delivered'
  } else if (status.shippedAt) {
    if (
      moment(status.shippedAt)
        .endOf('day')
        .isBefore(moment().endOf('day'))
    ) {
      return 'shipped'
    } else {
      return 'shipping'
    }
  } else if (status.processingAt) {
    return 'processing'
  } else {
    return 'ordered'
  }
}

export const mapOrderFinalsToTable = (
  items: OrderFinalV5[]
): OrderFinalRow[] => {
  return items.map(
    ({
      id,
      taasId,
      client,
      teamId,
      relation,
      integration,
      status,
      notes,
      robot,
      tessaExpertNeeded
    }) => {
      const key = id.toString()
      let row: OrderFinalRow = {
        key,
        orderId: id,
        integration: integration?.name,
        orderDate: status.orderedAt,
        relation: relation?.name,
        status: getStatusString(status),
        subRef: taasId,
        boxNr: robot?.boxNumber,
        serial: robot?.serial,
        shippingDate: status.shippedAt,
        clientId: client?.clientNumber ?? null,
        teamId: teamId,
        notes: notes,
        tessaExpertNeeded
      }
      return row
    }
  )
}

export const useOrderOverviewValueMapper = ({
  row,
  valueKey,
  classNameButton
}: {
  row: TbTableRowData
  valueKey: string
  classNameButton?: string
}) => {
  const inputValue = row[valueKey as keyof OrderFinalRow]
  const stringOrNumber =
    typeof inputValue === 'string' || typeof inputValue === 'number'
  let value: ReactElement | string | number
  let tooltipValue: ReactElement | string | number
  let tooltip = true
  const color = 'black' //getStatusColor(row.status)
  const style = {
    color: value === 'N/A' ? 'rgb(163 163 163)' : color,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    lineHeight: '36px',
    fontFamily: value === 'N/A' ? 'Roboto-Mono-Light' : 'inherit'
  }
  switch (valueKey as OrderOverviewColumnKey) {
    case 'serial':
      value = inputValue ? `T-${inputValue}` : '-'
      break
    case 'subRef':
      value = inputValue ? `T-${inputValue}` : '-'
      break
    case 'orderId':
      value = `SO-${inputValue}`
      break
    case 'status':
      tooltipValue = inputValue
      const color = STATUS_COLORS[inputValue as PossibleStatuses]
      value = inputValue ? (
        <ButtonBase
          className={classNameButton ?? ''}
          style={{
            color: STATUS_COLORS[inputValue as PossibleStatuses],
            borderColor: color,
            pointerEvents: 'none',
            width: '175px'
          }}
        >
          {STATUS_LABELS[inputValue as PossibleStatuses]}
        </ButtonBase>
      ) : (
        '-'
      )
      break
    default:
      if (
        inputValue &&
        ['Date', 'At'].some(el =>
          (valueKey as OrderOverviewColumnKey).includes(el)
        )
      ) {
        value = moment(inputValue as string).format('DD-MM-YYYY')
      } else {
        value = stringOrNumber ? inputValue : '-'
      }
      break
  }
  value = <span style={style}>{value}</span>
  return { value, tooltip, tooltipValue }
}
