/**
 * @file CustomQuery page.
 * @author Max van Loosbroek
 */

import React, { useCallback, useEffect, useState } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useSafeAsync } from 'tinybots-react-components/lib/components/hooks'
import { Org } from '../../organisations/OrganisationAccounts/types'
import { OrganisationsSearch } from './OrganisationsSearch/OrganisationsSearch'
import { QueryResultExport } from './QueryResultExport/QueryResultExport'
import { createTheme } from '@material-ui/core'
import { TbMuiThemeProvider } from '../../../common/react/theme/TbMuiThemeProvider'

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#333333'
        }
    }
})
export interface CustomQueryProps {
    RelationsService: any
    result: { data: any; queryName: string; errors: any }
}

export const CustomQueryComponent = ({ RelationsService, result }: CustomQueryProps) => {
    const [organisations, setOrganisations] = useState<Org[]>([])
    const safeAsync = useSafeAsync()
    const getrelations = useCallback(
        () =>
            safeAsync(
                RelationsService.getOrganisations().then(() => {
                    setOrganisations(RelationsService.organisations)
                })
            ),
        []
    )
    useEffect(() => {
        getrelations()
    }, [])

    return (
        <div className='react-component'>
            <TbMuiThemeProvider theme={theme}>
                <section
                    style={{
                        height: 72,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#e3e7ea',
                        color: '#333333',
                        justifyContent: 'center',
                        position: 'relative'
                    }}
                >
                    <a
                        href=''
                        style={{
                            color: '#333333',
                            fontSize: 16,
                            fontFamily: 'Montserrat-SemiBold',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textDecoration: 'none',
                            position: 'absolute',
                            left: 64
                        }}
                    >
                        <ArrowBackIcon
                            fontSize='inherit'
                            style={{
                                fontSize: 24,
                                color: '#333333',
                                marginRight: 10
                            }}
                        />
                        BACK
                    </a>
                    <h1
                        style={{
                            fontFamily: 'Montserrat-Bold',
                            fontSize: 20,
                            textTransform: 'uppercase'
                        }}
                    >
                        Query creator
                    </h1>
                </section>
                <section
                    style={{
                        height: 100,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <OrganisationsSearch organisations={organisations} />
                    <QueryResultExport data={result?.data} queryName={result?.queryName} errors={result?.errors} />
                </section>
            </TbMuiThemeProvider>
        </div>
    )
}

export default (props: CustomQueryProps) => <CustomQueryComponent {...props} />
