/**
 * @file Form to login with an otp input field and a provided username and password.
 * @author Max van Loosbroek
 */

import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useFormik } from 'formik'
import {
  Button,
  ButtonRow,
  Form,
  OTPInput,
  Spinner
} from 'tinybots-react-components'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { materialTheme } from '../../../../common/react/theme/materialTheme'
import styledComponentsTheme from '../../../../common/react/theme/styledComponentsTheme'

const BtnRow = styled(ButtonRow)`
  margin-top: 10px;
`

const Error = styled.span`
  && {
    position: absolute;
    top: 80px;
    display: inline-block;
    color: #da4725;
    font-size: 12px;
    font-family: Montserrat;
  }
`

const validate = () => (values: { otpCode: string }) => {
  const errors: { otpCode?: string } = {}
  if (values.otpCode.trim().length < 6) {
    errors.otpCode = 'Deze code is niet geldig. Vul een code in van 6 cijfers'
  }
  return errors
}

const showError = (
  { errorAuth, errorOther }: { errorAuth: string; errorOther: string },
  formik: any,
  focused: boolean
): string => {
  if (errorAuth || errorOther) {
    return errorAuth || errorOther
  }
  if (formik.errors.otpCode && !focused) {
    return formik.errors.otpCode
  }
  return ''
}

const MfaForm = ({
  $state,
  $auth,
  AuthService
}: {
  $state: any
  $auth: any
  AuthService: any
}) => {
  const errorInitial = {
    errorAuth: '',
    errorOther: ''
  }
  const [done, setDone] = useState(true)
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState(errorInitial)
  const password = $state.params?.password
  const username = $state.params?.username
  const onClearError = () => {
    setError(errorInitial)
  }
  const handleSubmit = async (otpCode: string) => {
    setDone(false)
    if (username && password && otpCode?.length === 6) {
      onClearError()
      $auth
        .login({
          username,
          password,
          otpCode
        })
        .then((response: any) => {
          setDone(true)
          if (
            response &&
            response.hasOwnProperty('data') &&
            response.data.token.access_token
          ) {
            $auth.setToken(response.data.token.access_token)
            AuthService.setSession(response.data.token)
            return $state.go('app')
          } else {
            setError({
              errorAuth: 'Oeps! Er ging iets niet goed. Probeer het later nog eens.',
              errorOther: ''
            })
          }
        })
        .catch((error: any) => {
          setDone(true)
          error.status === 401
            ? setError({
                errorAuth: 'Deze code is niet (meer) geldig. Controleer of u een geldige en recente code heeft ingevoerd',
                errorOther: ''
              })
            : setError(
                (error = {
                  errorAuth: 'Oeps! Er ging iets niet goed. Probeer het later nog eens.',
                  errorOther: ''
                })
              )
        })
    } else {
      return false
    }
  }

  const formik = useFormik({
    initialValues: {
      otpCode: ''
    },
    validate: validate(),
    onSubmit: values => {
      handleSubmit(values.otpCode)
    }
  })

  if (!password || !password) {
    $state.go('auth.login')
  }

  return (
    <>
      {!done && <Spinner />}
      <h3 className='tb-title tb-title_s tb-title_main'>U heeft 2FA aanstaan. Vul uw 2FA code van uw authenticator in:&nbsp;</h3>
      <Form
        className='react-component'
        style={{ maxWidth: 290 }}
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div style={{ position: 'relative', marginBottom: '90px' }}>
          <b style={{ fontFamily: 'Montserrat-Bold' }}>
            2FA 6-digit code
          </b>
          <OTPInput
            inputStyle={{ borderLeft: 0, borderRight: 0, borderTop: 0 }}
            fontSize={26}
            wrapperStyle={{
              border: '1px solid black',
              display: 'flex',
              justifyContent: 'center'
            }}
            onChange={value => {
              formik.handleChange({ target: { value, id: 'otpCode' } })
              onClearError()
            }}
            handleFocus={() => setFocused(true)}
            handleBlur={() => setFocused(false)}
          />

          {showError(error, formik, focused) && (
            <Error>{showError(error, formik, focused)}</Error>
          )}
        </div>
        <BtnRow>
          <Button type='submit'>Log in</Button>
        </BtnRow>
      </Form>
    </>
  )
}

export default (props: any) => (
  <MuiThemeProvider theme={materialTheme}>
    <ThemeProvider theme={styledComponentsTheme}>
      <MfaForm {...props} />
    </ThemeProvider>
  </MuiThemeProvider>
)
