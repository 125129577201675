import template from './tb-datalist.html'
import './tb-datalist.scss'

const tbDatalist = {
  bindings: {
    type: '@',
    path: '@',
    items: '<',
    relation: '<',
    tabIndex: '@',
    onSelectItem: '&'
  },
  transclude: {
    tbDatalistInput: '?tbDatalistInput'
  },
  template,
  controller: /* @ngInject */ class NewTbPopUp {
    select (item) {
      this.focus = false
      this.onSelectItem({ $event: { item } })
    }

    getValue (item) {
      return item[this.path]
    }

    isRobotDissabled (item) {
      const itemRelation = item.relation && item.relation.relationId
      return item.chainId || !(item.shippable || (this.relation && this.relation === itemRelation))
    }

    $onChanges (changes) {
      if (changes.data) {
        this.data = angular.copy(this.data)
      }
      if (changes.items) {
        this.items = angular.copy(this.items)
      }
    }

    $onInit () {
      this.focus = false
    }
  }
}

export default tbDatalist
