/* @ngInject */
export default class RelationsService {
  constructor($http, URLS) {
    this.$http = $http
    this.URLS = URLS
  }

  getOrganisations() {
    const url = `${this.URLS.admin.companies}?type=Customer&type=Depot`
    return this.$http.get(url).then(response => {
      this.organisations = response.data
      return response
    })
  }

  patchOrganisation(data, relationId) {
    const url = `${this.URLS.admin.companies}/${relationId}`
    const orgPatch = {}
    const { premiumPackage, servicePackage, notes } = data
    if (premiumPackage !== undefined) orgPatch.premiumPackage = premiumPackage
    if (servicePackage !== undefined) orgPatch.servicePackage = servicePackage
    if (notes !== undefined) orgPatch.notes = notes
    return this.$http.patch(url, orgPatch)
  }

  getRelationsByName(name) {
    const url = `${this.URLS.admin.relations}?name=${name}`
    return this.$http.get(url)
  }

  getRelationsByEmail(email) {
    const url = `${this.URLS.admin.relations}?email=${email}`
    return this.$http.get(url)
  }
};
