/**
 * @file Linking a robot to a specific TaaS sub
 * @author Max van Loosbroek
 */

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem,
  TbDashDetailedDialog
} from 'tinybots-react-components'
import { RootState } from '../../../../../common/redux/root.reducer'
import {
  DetailedState,
  RobotDetailed,
  TaasDetailed
} from '../../../types/types'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import { getTaasRef } from '../../../stringTransformers'
import { AppDispatch } from '../../../../../common/redux/store'
import {
  useChangeHandlersLinkTaas,
  useLinkSubFormInfo,
  useSubmitLinkTaas
} from './hooks'
import { robotsTaasSearchSelector } from '../../redux/selectors'
import { searchRobots } from '../../redux/thunks'
import moment from 'moment'
import RobotSearch from '../RobotSearch'
import { RelationDetailed } from '../../../types/RelationDetailed'

export interface LinkDialogTaasProps {
  open: boolean
  color: string
  dispatch: AppDispatch
  handleClose: () => void
  afterSubmit: () => void
  taas: TaasDetailed
  loading: boolean
  robotsSearch: (RobotDetailed & { disabled: boolean })[]
}

export const LinkDialogTaasComponent = ({
  open,
  dispatch,
  handleClose,
  afterSubmit,
  color,
  taas,
  loading,
  robotsSearch
}: LinkDialogTaasProps) => {
  const [showErrors, setShowErrors] = useState(false)
  const [selectedRobot, setSelectedRobot] = useState<RobotDetailed>()
  const patchRobot = useCallback(newValue => {
    setSelectedRobot(oldValue => {
      return { ...oldValue, ...newValue }
    })
  }, [])
  const taasReference = taas && getTaasRef(taas)
  const relation: RelationDetailed | undefined =
    taas?.relation ?? selectedRobot?.relation

  const {
    handleChangeStartDate,
    handleChangeShippedAt,
    handleTaasNoteChange,
    handleRobotNoteChange
  } = useChangeHandlersLinkTaas({
    patchRobot
  })

  const {
    error: { shippedAtHasError, taasStartHasError, robotHasError }
  } = useLinkSubFormInfo({
    taas,
    showErrors,
    selectedRobot
  })

  const handleSubmit = useSubmitLinkTaas({
    relation,
    taas,
    setShowErrors,
    dispatch,
    afterSubmit,
    selectedRobot
  })

  const previousShipped = usePrevious(taas?.shippedAt)
  useEffect(() => {
    if (
      taas?.shippedAt &&
      previousShipped !== taas?.shippedAt &&
      !taas?.startAt
    ) {
      const startDefault = moment(taas?.shippedAt)
        .add(1, 'day')
        .toISOString()
      handleChangeStartDate(startDefault)
    }
  }, [taas, previousShipped])

  const tabs = [
    {
      title: 'Link Robot with TaaS',
      content: (
        <TbDashDetailedContainer>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='Relation'
                iconUrl='assets/relation.svg'
                value={relation?.name}
              />
            </TbDashDetailedItemRow>
            <RobotSearch
              selectedRobot={selectedRobot}
              setSelectedRobot={setSelectedRobot}
              searchRobots={(params) => dispatch(searchRobots(params))}
              robotHasError={robotHasError}
              robotsSearch={robotsSearch}
            />
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                type='text'
                disabled
                label='TaaS reference'
                iconUrl='assets/subref.svg'
                value={taasReference}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedRobot?.serialId}
                type='date'
                label='Shipping date (DD-MM-YYYY)'
                iconUrl='assets/TB-DB_icon_Shipping.svg'
                hasError={shippedAtHasError}
                value={taas?.shippedAt}
                onChange={handleChangeShippedAt}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedRobot?.serialId}
                type='date'
                label='Start date (DD-MM-YYYY)'
                hasError={taasStartHasError}
                iconUrl='assets/calendar.svg'
                value={taas?.startAt}
                onChange={handleChangeStartDate}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
          <TbDashDetailedColumn>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                xs={12}
                type='textArea'
                label='taas notes'
                value={taas?.notes}
                onChange={handleTaasNoteChange}
              />
            </TbDashDetailedItemRow>
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled={!selectedRobot?.serialId}
                xs={12}
                type='textArea'
                label='Robot notes'
                value={selectedRobot?.notes}
                onChange={handleRobotNoteChange}
              />
            </TbDashDetailedItemRow>
          </TbDashDetailedColumn>
        </TbDashDetailedContainer>
      )
    }
  ]

  return (
    <TbDashDetailedDialog
      loading={loading}
      open={open}
      color={color}
      tabs={tabs}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      activeTab={0}
    />
  )
}

const ConnectedLinkDialogTaas = (
  props: Pick<
    LinkDialogTaasProps,
    'open' | 'handleClose' | 'afterSubmit' | 'color' | 'dispatch'
  >
) => {
  const { taas, getting, patching } = useSelector<
    RootState,
    Pick<DetailedState, 'taas' | 'getting' | 'patching'>
  >(({ detailed: { taas, getting, patching } }) => ({
    taas,
    getting,
    patching
  }))
  const robotsSearch = useSelector(robotsTaasSearchSelector(taas?.relation))

  return (
    <LinkDialogTaasComponent
      loading={getting || patching}
      robotsSearch={robotsSearch}
      {...props}
      taas={taas}
    />
  )
}

export default React.memo(ConnectedLinkDialogTaas)
