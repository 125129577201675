/**
 * @file Functions for making http requests for getting owner accounts
 * @author Max van Loosbroek
 */

import { IHttpService } from 'angular'
import { URLS } from '../../../../common/utilities/constants/constants.module'
import { parseHttpError } from '../../../../common/utilities/errorHandling'
import { OrganisationAdminAccount } from '../types'

export const getOrganisationAdminAcounts = (
  $http: IHttpService,
  relationId: number | string
) => {
  const url = URLS.admin.organisationAdminAccounts.replace(
    '{relationId}',
    `${relationId}`
  )
  return $http.get<OrganisationAdminAccount[]>(url).catch(parseHttpError)
}

export const sendInviteRequest = (
  $http: IHttpService,
  data: {
    email: string
    relationId: number | string
  }
) => {
  const url = URLS.admin.invite
  return $http.post(url, data).catch(parseHttpError)
}

/**
 * @description Super user only: deletes owner account
 */
export const deleteOwnerRequest = ($http: IHttpService, email: string) => {
  const url = `${URLS.admin.accounts}?email=${email}`
  return $http.delete(url)
}

export interface RelationsByEmailItem {
  relationId: number
  name: string
  teamleaderType: string
  teamleaderId: string
  type: string | null
  emails: {
    email: string
    type: 'primary' | string
  }[]
  companies: [
    {
      relationId: number
      name: string
      teamleaderType: 'company' | string
      teamleaderId: string
      type: 'Customer' | string
    }
  ]
}

export const getRelationsByEmail = ($http: IHttpService, email: string) => {
  const url = `${URLS.admin.relations}?email=${email}`
  return $http.get<RelationsByEmailItem[]>(url)
}
