import errorWindow from "./error.component";

export default angular
    .module('components.error', [])
    .component('error', errorWindow)
    .config($stateProvider => {
        $stateProvider
            .state('error-general', {
                url: '/error-general',
                template: '<error type="general"></error>'
            })
            .state('error-4XX', {
                url: '/error-4XX',
                template: '<error type="4XX"></error>'
            })
    })
    .name;
