import { IsBoolean, IsInt, IsOptional, IsString } from "class-validator"

export class RelationDetailedBase {
  @IsOptional()
  @IsString()
  relationType?: string

  @IsString()
  name: string

  @IsOptional()
  @IsString()
  teamleaderId?: string

  @IsOptional()
  @IsString()
  teamleaderType?: string

  @IsOptional()
  @IsString()
  type?: string

  @IsOptional()
  @IsBoolean()
  isCustomer?: boolean
}

export class RelationDetailed extends RelationDetailedBase {
  @IsInt()
  relationId: number
}

export class RelationDetailedConceptOrder extends RelationDetailedBase {
  @IsOptional()
  @IsInt()
  id?: number

  @IsOptional()
  @IsInt()
  relationId?: number
}