import React, { useCallback, useState } from 'react'
import { TbDashDialog } from 'tinybots-react-components'
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles
} from '@material-ui/core'
import moment from 'moment'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: 'rgba(51,51,51,1)'
    }
  }
})

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    content: {
      padding: 0,
      fontFamily: 'Montserrat'
    },
    title: {
      padding: 0,
      marginBottom: 10,
      '& .MuiTypography-h6': {
        fontFamily: 'Montserrat',
        fontSize: '28px',
        color: 'red'
      }
    }
  })
})
interface SelectDialogProps {
  handleClose: () => void
  onPick: (id: number) => void
  open: boolean
  items: { id: number; email: string; date: Date }[]
}

export const SelectConceptDialog = ({
  handleClose,
  open,
  onPick,
  items
}: SelectDialogProps) => {
  const classes = useStyles()
  const [selectedConcept, setSelectedConcept] = useState<number>(0)
  const handleChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setSelectedConcept(parseInt(value))
    },
    [items, setSelectedConcept]
  )
  const onSubmit = useCallback(() => {
    onPick(items[selectedConcept].id)
  }, [items, selectedConcept])

  return (
    <TbDashDialog
      title='Select correct concept'
      color='red'
      open={open}
      closeButton={'CLOSE'}
      handleClose={handleClose}
      submitButton={'CONTINUE'}
      handleSubmit={onSubmit}
    >
      <DialogTitle className={classes.title}>
        Select correct concept
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          <p>
            There are more than one concept found. Please select the correct
            one.
          </p>
          <ThemeProvider theme={theme}>
            <FormControl fullWidth>
              <RadioGroup
                aria-label='concept-select'
                name='concept'
                value={selectedConcept}
                onChange={handleChange}
              >
                {items.map((item, i) => (
                  <FormControlLabel
                    color='primary'
                    key={i}
                    value={i}
                    control={<Radio color='primary' />}
                    label={`${
                      item.email
                    } - ${moment(item.date).format('DD-MM-YYYY HH:mm:ss')}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </DialogContentText>
      </DialogContent>
    </TbDashDialog>
  )
}
