import { OrderFinal, OrderFinalV5 } from './types/OrderFinal'

export const mapV4ToV5 = (order: OrderFinal): OrderFinalV5 => {
    const copiedOrder = { ...order }
    delete copiedOrder.clientId
    delete copiedOrder.clientUuid
    return {
        ...(copiedOrder as Omit<OrderFinal, 'clientId' | 'clientUuid'>),
        client: {
            id: null,
            clientNumber: order.clientId,
            clientUuid: order.clientUuid,
            system: null
        }
    }
}

export const mapV5ToV4 = (order: OrderFinalV5): OrderFinal => {
    const { client, ...copiedOrder } = order
    return {
        ...(copiedOrder as Omit<OrderFinalV5, 'client'>),
        clientId: client?.clientNumber ?? null,
        clientUuid: client?.clientUuid ?? null
    }
}
