import template from './organisation-detailed.html'
import './organisation-detailed.scss'

const organisationDetailed = {
  bindings: {
    item: '<',
    relationWarning: '<',
    relations: '<',
    onClearWarning: '&',
    onDataChange: '&',
    onAddSubscriptions: '&'
  },
  template,
  controller: /* @ngInject */ class SubscriptionsAddController {
    constructor(PREMIUM_PACKAGES, SERVICE_PACKAGES, URLS) {
      this.premiumPackages = PREMIUM_PACKAGES;
      this.servicePackages = SERVICE_PACKAGES;
    }

    setServicePackage ({item}) {
      this.data.relation.servicePackage = item.value
      this.forwardData()
    }

    setPremiumPackage ({item}) {
      this.data.relation.premiumPackage = item.value
      this.forwardData()
    }

    get getPremiumPackageItem() {
      return this.premiumPackages.find(p => p.value === this.data.relation.premiumPackage)
    }

    get getServicePackageItem() {
      return this.servicePackages.find(p => p.value === this.data.relation.servicePackage)
    }

    addSubscriptions () {
      this.onAddSubscriptions({ $event: { item: this.item } })
    }

    setOrganisationNote ({ value }) {
      this.data.relation.notes = value === '' ? null : value
      this.forwardData()
    }

    clearWarning (name) {
      this.onClearWarning({ $event: { name } })
    }

    forwardData () {
      this.onDataChange({ $event: { data: this.data } })
    }

    $onChanges (changes) {
      if (changes.data) {
        this.data = angular.copy(this.data)
      }
    }

    $onInit () {
      this.data = {}
      if(this.item) {
       this.data.relation = angular.copy(this.item)
      }
    }
  }
}

export default organisationDetailed
