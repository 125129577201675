import template from './set-mfa-page.html'

const setMfaPage = {
    bindings: {
        subscriptions: '<',
        user: '<'
    },
    template,
    controller: /* @ngInject */ class MfaController {
        constructor(AuthService, $state, $q) {
            this.AuthService = AuthService
            this.$state = $state
            this.$q = $q
        }
    }
}

export default setMfaPage
