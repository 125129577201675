/**
 * @file Function to initialize redux store
 * @author Max van Loosbroek
 */

import { configureStore, ThunkAction, Action, getDefaultMiddleware} from '@reduxjs/toolkit';
import rootReducer, { RootState } from './root.reducer';
import { IHttpService } from 'angular';

export const initStore = ($http: IHttpService) => configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware({
    thunk: {extraArgument: {
			$http
		}},
		serializableCheck: {
			// Ignore these field paths in all actions
			ignoredActionPaths: ['payload.headers', 'payload.config']
		},
  })
});
export type AppThunk = ThunkAction<void, RootState, IHttpService, Action<string>>
export type AppDispatch = (ReturnType<typeof initStore>)['dispatch']