import { IsNumber, IsOptional, IsString } from 'class-validator'

export class Client {
    @IsOptional()
    @IsNumber()
    id: number | null
    
    @IsOptional()
    @IsString()
    clientNumber: string | null

    @IsOptional()
    @IsString()
    clientUuid: string | null

    @IsOptional()
    @IsString()
    system: string | null
}
