export const copyStringToClipboard = (string: string) => {
    const textarea = document.createElement('textarea');
    textarea.style.opacity = '0';
    textarea.style.position = 'absolute';
    textarea.style.top = '0';
    textarea.style.margin = '0';
    textarea.style.height = '0';
    textarea.style.overflow = 'hidden';
    textarea.style.pointerEvents = 'none';
    textarea.textContent = string;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
};