/**
 * @file Dashtransfer form to transfer data between entities
 * @author Max van Loosbroek
 */

import React from 'react'
import { useSelector } from 'react-redux'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { getAppUser } from '../../overview/stringTransformers'
import { RobotDetailed, SubscriptionBase, TaasBase } from '../../overview/types/types'
import { dashtransferSelector, toggleTransferData } from '../redux/reducer'
import RelationItem from './RelationItem'
import RobotItem from './RobotItem'
import SubscriptionItem from './SubscriptionItem'
import TaasItem from './TaasItem'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

export interface DashtransferFormProps {
  showErrors: boolean
  robot: RobotDetailed
  activeSub: SubscriptionBase
  currentTaasSubscription: TaasBase
  transferData: boolean
  errorLog: string[]
}

export const DashtransferFormComponent = React.memo(
  ({
    showErrors,
    robot,
    activeSub,
    transferData,
    currentTaasSubscription,
    errorLog
  }: DashtransferFormProps) => {
    const taas = !!currentTaasSubscription || robot?.taas
    const appUser = getAppUser(robot)
    const dispatch = useDispatch()
    const paymentReference =
      (activeSub as SubscriptionBase)?.paymentReference ?? '-'
    const shippedAt =
      currentTaasSubscription?.shippedAt ?? activeSub?.shippedAt ?? '-'
    const startAt =
      currentTaasSubscription?.startAt ?? activeSub?.startAt ?? '-'
    const endAt = currentTaasSubscription?.endAt ?? activeSub?.endAt ?? '-'
    const handleDataTransferToggle = useCallback(
      (value: boolean) => {
        if (value !== transferData) dispatch(toggleTransferData())
      },
      [transferData]
    )

    return (
      <TbDashDetailedContainer>
        <TbDashDetailedColumn>
          <RelationItem showErrors={showErrors} />
          <RobotItem showErrors={showErrors} taas={taas} />
          {taas ? <TaasItem /> : <SubscriptionItem showErrors={showErrors} />}
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              disabled={!robot}
              type='checkbox'
              label='Transfer data to new robot'
              iconUrl=''
              value={transferData}
              onChange={handleDataTransferToggle}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              disabled
              type='text'
              iconUrl='assets/invoice.svg'
              label='Robot Payment Reference'
              value={robot?.paymentReference ?? '-'}
              helperText={null}
              hasError={null}
            />
            <TbDashDetailedItem
              disabled
              type='number'
              iconUrl='assets/tb_zendesk.png'
              label='Zoho Reference'
              value={robot?.zendeskTicketId ?? '-'}
              hasError={null}
            />
          </TbDashDetailedItemRow>
          {(activeSub as any)?.paymentReference ? (
            <TbDashDetailedItemRow>
              <TbDashDetailedItem
                disabled
                type='text'
                label='Subscription Payment Reference'
                iconUrl='assets/invoice.svg'
                value={paymentReference}
              />
            </TbDashDetailedItemRow>
          ) : null}
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              disabled
              type='date'
              label='Shipping date (DD-MM-YYYY)'
              iconUrl='assets/TB-DB_icon_Shipping.svg'
              value={shippedAt}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              disabled
              type='date'
              label='Start date (DD-MM-YYYY)'
              iconUrl='assets/calendar.svg'
              value={startAt}
            />
            <TbDashDetailedItem
              disabled
              type='date'
              label='End date (DD-MM-YYYY)'
              iconUrl=''
              value={endAt}
            />
          </TbDashDetailedItemRow>
        </TbDashDetailedColumn>
        <TbDashDetailedColumn>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              xs={12}
              type='console'
              disabled
              label='Log console'
              value=''
            >
              {errorLog.map(error => (
                <p style={{ margin: '7px 0' }}>{error}</p>
              ))}
            </TbDashDetailedItem>
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='App user'
              value={appUser}
            />
            <TbDashDetailedItem
              type='text'
              disabled
              label='Tel nr.'
              value={robot?.account?.primaryUser?.phoneNumber ?? '-'}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              disabled
              label='E-mail'
              value={robot?.account?.primaryUser?.email ?? '-'}
            />
          </TbDashDetailedItemRow>
        </TbDashDetailedColumn>
      </TbDashDetailedContainer>
    )
  }
)

const ConnectedDashtransferForm = (
  props: Pick<DashtransferFormProps, 'showErrors'>
) => {
  const {
    currentRobot,
    currentSub,
    currentTaasSubscription,
    errorLog,
    transferData
  } = useSelector(dashtransferSelector)

  return (
    <DashtransferFormComponent
      {...props}
      transferData={transferData}
      errorLog={errorLog}
      robot={currentRobot}
      activeSub={currentSub}
      currentTaasSubscription={currentTaasSubscription}
    />
  )
}

export default ConnectedDashtransferForm
