/**
 * @file Dialog where you can add a robot to a sub
 * @author Max van Loosbroek
 */

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem,
  TbDashDetailedDialog
} from 'tinybots-react-components'
import { RootState } from '../../../../../common/redux/root.reducer'
import {
  ChainDetailed,
  DetailedState,
  RobotDetailed,
  SubscriptionBase
} from '../../../types/types'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import { getSubRefLength } from '../../../stringTransformers'
import { AppDispatch } from '../../../../../common/redux/store'
import {
  useChangeHandlersLinkSub,
  useLinkSubFormInfo,
  useSubmitLinkSub
} from './hooks'
import { defaultSubSelector, robotsSearchSelector } from '../../redux/selectors'
import { searchRobots } from '../../redux/thunks'
import RobotSearch from '../RobotSearch'
import { RelationDetailed } from '../../../types/RelationDetailed'

export interface LinkDialogSubProps {
  open: boolean
  color: string
  dispatch: AppDispatch
  handleClose: () => void
  afterSubmit: () => void
  chain: ChainDetailed
  loading: boolean
  subscriptionReference: string
  defaultSub: SubscriptionBase
  robotsSearch: (RobotDetailed & { disabled: boolean })[]
}

export const LinkDialogSubComponent = React.memo(
  ({
    open,
    dispatch,
    handleClose,
    afterSubmit,
    color,
    chain,
    loading,
    subscriptionReference,
    defaultSub,
    robotsSearch
  }: LinkDialogSubProps) => {
    const [showErrors, setShowErrors] = useState(false)
    const [selectedRobot, setSelectedRobot] = useState<RobotDetailed>()
    const previousRobot = usePrevious(selectedRobot)
    const patchRobot = useCallback(newValue => {
      setSelectedRobot(oldValue => {
        return { ...oldValue, ...newValue }
      })
    }, [])
    const relation: RelationDetailed | undefined =
      chain?.relation ?? selectedRobot?.relation
    const {
      handleChangePaymentReference,
      handleChangeZohoReference,
      handleChangeStartDate,
      handleChangeEndDate,
      handleChangeShippedAt,
      handleSubNoteChange,
      handleRobotNoteChange
    } = useChangeHandlersLinkSub({
      patchRobot
    })

    const {
      error: {
        shippedAtHasError,
        referenceError,
        subscriptionStartHasError,
        subscriptionEndHasError,
        robotHasError
      },
      helperText: { refHelperText }
    } = useLinkSubFormInfo({
      defaultSub,
      showErrors,
      selectedRobot
    })

    const handleSubmit = useSubmitLinkSub({
      relation,
      defaultSub,
      setShowErrors,
      dispatch,
      afterSubmit,
      selectedRobot
    })

    useEffect(() => {
      if (selectedRobot && !previousRobot && !selectedRobot.paymentReference) {
        handleChangePaymentReference(defaultSub?.paymentReference)
      }
    }, [previousRobot, selectedRobot])

    const tabs = [
      {
        title: 'Link Robot with Subscription',
        content: (
          <TbDashDetailedContainer>
            <TbDashDetailedColumn>
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  type='text'
                  disabled
                  label='Relation'
                  iconUrl='assets/relation.svg'
                  value={relation?.name}
                />
              </TbDashDetailedItemRow>
              <RobotSearch
                selectedRobot={selectedRobot}
                setSelectedRobot={setSelectedRobot}
                searchRobots={(params) => dispatch(searchRobots(params))}
                robotHasError={robotHasError}
                robotsSearch={robotsSearch}
              />
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  type='text'
                  disabled
                  label='Subscription reference'
                  iconUrl='assets/subref.svg'
                  value={subscriptionReference}
                />
              </TbDashDetailedItemRow>
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  helperText={refHelperText}
                  disabled={!selectedRobot?.serialId}
                  type='text'
                  iconUrl='assets/invoice.svg'
                  label='Robot Payment Reference'
                  value={selectedRobot?.paymentReference}
                  onChange={handleChangePaymentReference}
                  hasError={referenceError}
                />
                <TbDashDetailedItem
                  disabled={!selectedRobot?.serialId}
                  type='number'
                  iconUrl='assets/tb_zendesk.png'
                  label='Zoho Reference'
                  value={selectedRobot?.zendeskTicketId}
                  onChange={handleChangeZohoReference}
                  hasError={referenceError}
                />
              </TbDashDetailedItemRow>
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  disabled={!selectedRobot?.serialId}
                  type='date'
                  label='Shipping date (DD-MM-YYYY)'
                  iconUrl='assets/TB-DB_icon_Shipping.svg'
                  hasError={shippedAtHasError}
                  value={defaultSub?.shippedAt}
                  onChange={handleChangeShippedAt}
                />
              </TbDashDetailedItemRow>
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  disabled={!selectedRobot?.serialId}
                  type='date'
                  label='Start date (DD-MM-YYYY)'
                  hasError={subscriptionStartHasError}
                  iconUrl='assets/calendar.svg'
                  value={defaultSub?.startAt}
                  onChange={handleChangeStartDate}
                />
                <TbDashDetailedItem
                  disabled={!selectedRobot?.serialId}
                  type='date'
                  label='End date (DD-MM-YYYY)'
                  hasError={subscriptionEndHasError}
                  value={defaultSub?.endAt}
                  onChange={handleChangeEndDate}
                />
              </TbDashDetailedItemRow>
            </TbDashDetailedColumn>
            <TbDashDetailedColumn>
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  xs={12}
                  type='textArea'
                  label='Subscription notes'
                  value={defaultSub?.notes}
                  onChange={handleSubNoteChange}
                />
              </TbDashDetailedItemRow>
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  disabled={!selectedRobot?.serialId}
                  xs={12}
                  type='textArea'
                  label='Robot notes'
                  value={selectedRobot?.notes}
                  onChange={handleRobotNoteChange}
                />
              </TbDashDetailedItemRow>
            </TbDashDetailedColumn>
          </TbDashDetailedContainer>
        )
      }
    ]

    return (
      <TbDashDetailedDialog
        loading={loading}
        open={open}
        color={color}
        tabs={tabs}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        activeTab={0}
      />
    )
  }
)

const ConnectedLinkDialogSub = (
  props: Pick<
    LinkDialogSubProps,
    'open' | 'handleClose' | 'afterSubmit' | 'color' | 'dispatch'
  >
) => {
  const { chain, getting, patching } = useSelector<
    RootState,
    Pick<DetailedState, 'chain' | 'getting' | 'patching'>
  >(({ detailed: { chain, getting, patching } }) => ({
    chain,
    getting,
    patching
  }))
  const defaultSub = useSelector((state: RootState) =>
    defaultSubSelector(state.detailed)
  )
  const robotsSearch = useSelector(robotsSearchSelector(chain?.relation))
  const subscriptionReference = defaultSub && getSubRefLength(defaultSub)

  return (
    <LinkDialogSubComponent
      {...props}
      subscriptionReference={subscriptionReference}
      loading={getting || patching}
      chain={chain}
      defaultSub={defaultSub}
      robotsSearch={robotsSearch}
    />
  )
}

export default ConnectedLinkDialogSub
