/**
 * @file Wrapper module to load react overview component inside angular
 * @author Max van Loosbroek
 */

import OrganisationAccounts from './OrganisationAccounts'
import angular from 'angular'
import { IHttpService } from 'angular'
import StoreService from '../../../common/redux/store.service'

export default angular
  .module('components.tb.organisationAccountsReact', ['react'])
  .directive(
    'tbOrganisationAccountsReact',
    (reactDirective, $auth, $http: IHttpService, StoreService: StoreService) =>
      reactDirective(OrganisationAccounts, [], {}, { $auth, $http, StoreService })
  )