/**
 * @file Reducer for managing detailed state of orders
 * @author Max van Loosbroek
 */

import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getStatusString } from '../../overview/tableMappers'
import {
  OrdersDetailedState,
  OrderTabs,
  PossibleStatuses
} from '../../types/types'
import { hasStateChanges } from './selectors'
import { getOrderDetailed, updateDetailedOrder } from './thunks'
import { OrderFinalV5 } from '../../types/OrderFinal'

interface TogglePayload {
  show: boolean
  activeTab?: OrderTabs
  force?: boolean
}

export const initialStateOrdersDetailed: OrdersDetailedState = {
  showDetailed: false,
  showUnsavedWarn: false,
  getting: true,
  getError: null,
  patching: false,
  patchError: null
}

export const ordersDetailedSlice = createSlice({
  name: 'ordersDetailed',
  initialState: initialStateOrdersDetailed,
  reducers: {
    clearStateOrdersDetailed: () => initialStateOrdersDetailed,
    clearErrorsOrdersDetailed: state => {
      state.getError = null
      state.patchError = null
    },
    updateOrder: (
      state,
      { payload }: { payload: Partial<Omit<OrderFinalV5, 'client'>> }
    ) => {
      state.order = { ...state.order, ...payload }
      if (
        state.statusString === 'shipped' ||
        (state.statusString === 'shipping' && payload.status?.shippedAt)
      ) {
        state.statusString = moment(payload.status?.shippedAt)
          .endOf('day')
          .isBefore(moment().endOf('day'))
          ? 'shipped'
          : 'shipping'
      }
    },
    updateOrderStatus: (state, { payload }: { payload: PossibleStatuses }) => {
      state.statusString = payload
    },
    toggleUnsavedWarnOrders: (
      state,
      { payload: { show } }: { payload: { show: boolean } }
    ) => {
      state.showUnsavedWarn = show
    },
    toggleOrdersDetailed: (
      state,
      {
        payload: { show, force, activeTab }
      }: {
        payload: TogglePayload
      }
    ) => {
      const changes: any = hasStateChanges(state)
      if (force || show) {
        state.activeTab = activeTab
        state.showDetailed = show
      } else if (changes) {
        state.showUnsavedWarn = true
        state.activeTab = undefined
      } else {
        state.showDetailed = show
        state.activeTab = undefined
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getOrderDetailed.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getOrderDetailed.rejected, (state, action) => {
      state.getting = false
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(getOrderDetailed.fulfilled, (state, action) => {
      state.getting = false
      state.order = action.payload
      state.originalOrder = action.payload
      state.statusString = getStatusString(action.payload.status)
      state.getError = null
    })
    builder.addCase(updateDetailedOrder.pending, state => {
      state.patching = true
      state.patchError = null
    })
    builder.addCase(updateDetailedOrder.rejected, (state, action) => {
      state.patching = false
      state.patchError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(updateDetailedOrder.fulfilled, (state, action) => {
      state.patching = false
      state.patchError = null
      state.order = { ...state.order, ...action.payload }
      state.originalOrder = { ...state.originalOrder, ...action.payload }
      state.statusString = getStatusString(action.payload.status)
    })
  }
})

export const {
  clearStateOrdersDetailed,
  clearErrorsOrdersDetailed,
  toggleOrdersDetailed,
  updateOrder,
  toggleUnsavedWarnOrders,
  updateOrderStatus
} = ordersDetailedSlice.actions

// Extract the action creators object and the reducer
const { reducer } = ordersDetailedSlice

export const ordersDetailedReducer = reducer
