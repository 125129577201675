import template from './app.html'
import './app.scss'
import { version } from '../../../package.json';

const appComponent = {
  bindings: {
    referenceNumber: '<'
  },
  template,
  controller: /* @ngInject */ class AppController {
    constructor (AuthService, $state, $window) {
      $window.document.title = 'Admin Dashboard ' + version;
      this.AuthService = AuthService
      this.$state = $state
    }

    logOut () {
      this.AuthService.logout()
      return this.$state.go('auth.login')
    }

    search ({ term }) {
      if (term && term.length > 0) {
        this.searchTerm = term
        this.$state.go('searchResult', { term })
      } else {
        return false
      }
    }

    removeOrganisation () {
      this.organisation = null
    }

    setOrganisation (organisation) {
      this.organisation = organisation
    }

    getOrganisation (organisation) {
      return this.organisation
    }
  }
}

export default appComponent
