/**
 * @file Extending a regular subscription
 * @author Max van Loosbroek
*/

import moment from 'moment'
import React, { useCallback, useState } from 'react'
import {
  TbDashDetailedContainer,
  TbDashDetailedColumn,
  TbDashDetailedItemRow,
  TbDashDetailedItem,
  TbDashDetailedDialog
} from 'tinybots-react-components'
import { AppDispatch } from '../../../../../common/redux/store'
import { getSubRef } from '../../../stringTransformers'
import { ChainDetailed, SubscriptionDetailed } from '../../../types/types'
import { updateSub } from '../../redux/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../common/redux/root.reducer'
import { extendSub } from '../../redux/thunks'
import { extensionSubSelector, subsLatestSelector } from '../../redux/selectors'

export interface ExtendSubscriptionProps {
  chain: ChainDetailed
  lastSub: SubscriptionDetailed
  extensionSub: SubscriptionDetailed
  dispatch: AppDispatch
  color: string
  onClose: () => void
  afterSubmit: () => void
  showExtend: boolean
  loading: boolean
}

export const ExtendSubscriptionComponent = ({
  dispatch,
  color,
  onClose,
  afterSubmit,
  chain,
  lastSub,
  extensionSub,
  showExtend,
  loading
}: ExtendSubscriptionProps) => {
  const [showErrors, setShowErrors] = useState(false)
  const teamleaderLink = chain?.relation ? `https://app.teamleader.eu/company_detail.php?id=${chain?.relation?.teamleaderId}` : undefined
  const subscriptionReference = getSubRef(extensionSub)
  const handleChangeSubscriptionLength = useCallback(
    value => dispatch(updateSub({ subscriptionLength: value as number, subscriptionId: -1 })),
    []
  )
  const handleChangePaymentRef = useCallback(
    value => dispatch(updateSub({ paymentReference: value as string, subscriptionId: -1 })),
    []
  )
  const handleChangeStartDate = useCallback(
    value => dispatch(updateSub({ startAt: value as string, subscriptionId: -1 })),
    []
  )
  const handleChangeEndDate = useCallback(
    value => dispatch(updateSub({ endAt: value as string, subscriptionId: -1 })),
    []
  )
  const handleChangeNotes = useCallback(
    value => dispatch(updateSub({ notes: value as string, subscriptionId: -1 })),
    []
  )
  const subscriptionLengthHasError = (value: number) => {
    return (!value || value < 0) && showErrors ? 'Invalid subscription length' : undefined
  }
  const subscriptionStartHasError = (value: string) =>
    (!value || (value && !moment(value).isValid()) ||
    moment(value) >= moment(lastSub?.endAt)) && showErrors
      ? 'Invalid subscription length'
      : undefined
  const subscriptionEndHasError = (value: string) =>
  (!value || (value && !moment(value).isValid()) ||
    moment(lastSub?.startAt) >= moment(value)) && showErrors
      ? 'Invalid subscription length'
      : undefined

  const handleSubmit = async () => {
    setShowErrors(true)
    const { chainId, startAt, endAt, subscriptionLength, notes, parentId, paymentReference } = extensionSub
    if(!subscriptionLength) return
    if(!startAt || !moment(startAt).isValid()) return
    if(!endAt || !moment(endAt).isValid()) return
    if(!paymentReference) return
    const res = await dispatch(
      extendSub({
        parentId,
        paymentReference,
        numberOfSubscriptions: 1,
        relation: { relationId: chain.relation.relationId },
        chainId,
        startAt,
        endAt,
        subscriptionLength,
        notes: notes || undefined
      })
    )
    if (res?.meta.requestStatus === 'fulfilled') {
      afterSubmit()
    }
  }
  
  const content = extensionSub ? (
    <TbDashDetailedContainer key={`subscriptionExtend-${extensionSub.subscriptionId}`}>
      <TbDashDetailedColumn>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='text'
            disabled
            label='Relation'
            iconUrl='assets/relation.svg'
            value={chain?.relation.name}
          />
          <TbDashDetailedItem
            type='link'
            href={teamleaderLink}
            disabled
            label=''
            target='_href'
            value='View in teamleader'
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='text'
            disabled
            label='Robot Serial'
            iconUrl='assets/tessa-dark.svg'
            value={chain?.robot?.serial ?? '-'}
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='text'
            disabled
            label='Subscription reference'
            iconUrl='assets/subref.svg'
            value={`${subscriptionReference} (${extensionSub.subscriptionLength} days)`}
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='number'
            label='Subscription length'
            iconUrl='assets/calendar.svg'
            hasError={subscriptionLengthHasError}
            value={extensionSub.subscriptionLength}
            onChange={handleChangeSubscriptionLength}
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='text'
            label='Subscription Payment Reference'
            iconUrl='assets/invoice.svg'
            hasError={(value) => !value && showErrors ? 'Enter payment reference' : ''}
            value={extensionSub.paymentReference}
            onChange={handleChangePaymentRef}
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            disabled
            type='date'
            label='Shipping date (DD-MM-YYYY)'
            iconUrl='assets/TB-DB_icon_Shipping.svg'
            value={extensionSub?.shippedAt}
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='date'
            label='Start date (DD-MM-YYYY)'
            hasError={subscriptionStartHasError}
            iconUrl='assets/calendar.svg'
            value={extensionSub?.startAt}
            onChange={handleChangeStartDate}
          />
          <TbDashDetailedItem
            type='date'
            label='End date (DD-MM-YYYY)'
            hasError={subscriptionEndHasError}
            value={extensionSub?.endAt}
            onChange={handleChangeEndDate}
          />
        </TbDashDetailedItemRow>
      </TbDashDetailedColumn>
      <TbDashDetailedColumn>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            xs={12}
            type='textArea'
            label='Subscription notes'
            value={extensionSub?.notes}
            onChange={handleChangeNotes}
          />
        </TbDashDetailedItemRow>
      </TbDashDetailedColumn>
    </TbDashDetailedContainer>
  ) : null
  return (
    <TbDashDetailedDialog
      loading={loading}
      open={showExtend}
      color={color}
      tabs={[
        {
          title: 'Extend',
          content
        }
      ]}
      handleClose={onClose}
      handleSubmit={handleSubmit}
      activeTab={0}
    />
  )
}

const ConnectedExtendSubscription = (
  props: Pick<ExtendSubscriptionProps, 'afterSubmit' | 'color' | 'onClose'>
) => {
  const chain = useSelector<RootState, ChainDetailed>(
    ({ detailed: { chain } }) => chain
  )
  const lastSub = useSelector<RootState, SubscriptionDetailed>((state) => subsLatestSelector(state.detailed))
  const extensionSub = useSelector<RootState, SubscriptionDetailed>(extensionSubSelector)
  const dispatch: AppDispatch = useDispatch()
  const loading = useSelector<RootState, boolean>(
    ({ detailed: { getting, patching } }) => getting || patching
  )
  const showExtend = useSelector<RootState, boolean>(
    ({ detailed: { showExtend } }) => showExtend
  ) 
  return (
    <ExtendSubscriptionComponent
      {...props}
      extensionSub={extensionSub}
      lastSub={lastSub}
      chain={chain}
      loading={loading}
      showExtend={showExtend}
      dispatch={dispatch}
    />
  )
}

export default ConnectedExtendSubscription
