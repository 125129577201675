/**
 * @file
 * Form for changing general return values
 * @author Max van Loosbroek
 */

import { useCallback } from 'react'
import { ReturnForm } from '../../types/ReturnForm'
import { OrderFinalV5 } from '../../../../types/OrderFinal'
import { AppDispatch } from '../../../../../../common/redux/store'
import { useSalesOrderSearchItem } from './useSalesOrderSearchItem'
import React from 'react'
import {
  TbDashDetailedItem,
  TbDashDetailedItemRow
} from 'tinybots-react-components'

export interface GeneralReturnOrderFormProps {
  salesOrder: OrderFinalV5
  returnForm: Partial<ReturnForm>
  onChangeForm: (changes: Partial<ReturnForm>) => void
  showErrors: boolean
  dispatch: AppDispatch
  searching: boolean
}

export const GeneralReturnOrderForm = ({
  salesOrder,
  onChangeForm,
  showErrors,
  dispatch,
  returnForm,
  searching
}: GeneralReturnOrderFormProps) => {
  const salesOrderSearchItem = useSalesOrderSearchItem({
    returnForm,
    salesOrder,
    showErrors,
    onChangeForm,
    dispatch,
    searching
  })
  const handleChangeInternalNotes = useCallback((value: string) => {
    onChangeForm({ internalNotes: value })
  }, [])

  return (
    <>
      {salesOrderSearchItem}
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Source'
          iconUrl='assets/TB_DB_Icon_SOURCE.svg'
          value={salesOrder?.integration?.name ?? '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Relation'
          iconUrl='assets/relation.svg'
          value={salesOrder?.relation?.name ?? '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Robot serial'
          iconUrl='assets/tessa-dark.svg'
          value={salesOrder?.robot?.serial ?? '-'}
        />
        <TbDashDetailedItem
          type='text'
          disabled
          label='Boxnumber'
          value={salesOrder?.robot?.boxNumber ?? '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Subscription reference'
          iconUrl='assets/subref.svg'
          value={salesOrder?.taasId ? `T-${salesOrder?.taasId}` : '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          xs={12}
          disabled
          label='Client id'
          iconUrl='assets/person.svg'
          value={salesOrder?.client.clientNumber ?? '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Universally unique id (UUID)'
          iconUrl='assets/TB-DB_icon_UUID.svg'
          value={salesOrder?.client.clientUuid ?? '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Team id'
          iconUrl='assets/people_black_24dp.svg'
          value={salesOrder?.teamId ?? '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Discipline'
          iconUrl='assets/TB-DB_icon_medical_services_black_24dp.svg'
          value={salesOrder?.discipline ?? '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Healthcare demand(s)'
          iconUrl='assets/TB-DB_icon_medical_information_black_24dp.svg'
          value={salesOrder?.healthCareDemand ?? '-'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          disabled
          label='Tessa Expert requested?'
          iconUrl='assets/TB-DB_icon_Tessa_Expert.svg'
          value={salesOrder?.tessaExpertNeeded ?? 'unknown'}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='textArea'
          textAreaAutoSize
          disabled={!salesOrder}
          label='Internal notes'
          iconUrl='assets/TB-APP_Icon_Editing.svg'
          value={returnForm?.internalNotes}
          onChange={handleChangeInternalNotes}
        />
      </TbDashDetailedItemRow>
    </>
  )
}
