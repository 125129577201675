export const DEFAULT_QUERY = `# Welcome to the query creator with GraphiQL!
#
# Use the GraphiQL explorer in the side menu to help you build queries. You can retrieve raw data using
# the RawDataQuery or get ready-made tables with the ReportQuery.
#
# Type queries into this side of the screen, and you will see intelligent
# typeaheads aware of the current GraphQL type schema and live syntax and
# validation errors highlighted within the text.
#
# GraphQL queries typically start with a “{” character. Lines that start
# with a # are ignored.
#
# An example query might look like:
#
# query MyCustomQueryName {
#   reports {
#     allReports {
#       taasReport(relationId: 160) {
#         id
#         serial
#       }
#     }
#   }
# }
#
# In this example query, a table is made showing the TaaS subscriptions id’s and robot serials
# for the organisation with id 160. You can look up and copy organisation id’s using the searchable
# dropdown menu next to the export button.
#
# Keyboard shortcuts:
#
#    Prettify query: Shift-Ctrl-P (or press the prettify button)
#
#    Merge fragments: Shift-Ctrl-M (or press the merge button)
#
#    Run Query: Ctrl-Enter (or press the play button)
#
#    Auto Complete: Ctrl-Space (or just start typing)
`