import organisations from './organisations.component'
import SubscriptionService from '../subscriptions/subscriptions.service'
import RelationsService from '../relations/relations.service'
import subscriptionsAdd from './subscriptions-add/subscriptions-add.component'
import taasAdd from './taas-add/taas-add.component'
import organisationDetailed from './organisation-detailed/organisation-detailed.component'
import './OrganisationAccounts/OrganisationAccounts.module'

export const subscriptionsModule = angular
  .module('components.organisations', ['components.tb.organisationAccountsReact'])
  .component('organisations', organisations)
  .component('organisationDetailed', organisationDetailed)
  .component('subscriptionsAdd', subscriptionsAdd)
  .component('taasAdd', taasAdd)
  .service('SubscriptionService', SubscriptionService)
  .service('RelationsService', RelationsService)
  .config(($stateProvider) => {
    $stateProvider
      .state('organisations', {
        parent: 'app',
        url: `^/organisations?name&relationId`,
        component: 'organisations',
        reloadOnSearch: false,
        params: {},
        resolve: {}
      })
  })
  .name
