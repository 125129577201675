/**
 * @file Async thunks for getting and patching data for detailed order state
 * @author Max van Loosbroek
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { IHttpService } from 'angular'
import { getDetailed } from '../../../overview/Detailed/redux/thunks'
import { endTaas, getOrder, patchTaasOrder, sendStatus, shipTaas } from './api'
import { UrlStatuses } from '../../types/types'
import {
  willAccept,
  willDecline,
  willShip,
  willDeliver,
  willAcceptReturn,
  willRejectReturn,
  willSetReturned,
  willAddTrackTrace,
  selectOrderDetailedState,
  stateStatusString
} from './selectors'
import { RootState } from '../../../../common/redux/root.reducer'
import moment from 'moment'
import { URL_STATUS } from '../../overview/constants'
import { OrderFinalV5 } from '../../types/OrderFinal'
import { mapV4ToV5 } from '../../mapper'

export const getOrderDetailed = createAsyncThunk<
  OrderFinalV5,
  { orderId?: number },
  { extra: { $http: IHttpService } }
>('ordersDetailed/getOrderDetailed', async ({ orderId }, thunkApi) => {
  // Get order
  const { data: order } = await getOrder(thunkApi.extra.$http, orderId)
  await thunkApi.dispatch(
    getDetailed({
      taasId: order.taasId
    })
  )
  return order
})

export const updateDetailedOrder = createAsyncThunk<
  OrderFinalV5,
  void,
  { extra: { $http: IHttpService } }
>('ordersDetailed/updateDetailedOrder', async (_, thunkApi): Promise<OrderFinalV5> => {
  const state: RootState = thunkApi.getState() as any
  const orderDetailed = selectOrderDetailedState(state)
  const orderId = orderDetailed.originalOrder?.id
  const shippedAt = orderDetailed.order?.status?.shippedAt
  const taasId = state.ordersDetailed?.order?.taasId
  const declineReason = state.ordersDetailed?.order?.declineReason
  const returnRejectReason = state.ordersDetailed?.order?.returnRejectReason
  if (
    willAccept(state) ||
    willDecline(state) ||
    willDeliver(state) ||
    willAcceptReturn(state) ||
    willRejectReturn(state)
  ) {
    const urlStatus = URL_STATUS[stateStatusString(state) as UrlStatuses]
    const body = declineReason ? { declineReason } : { returnRejectReason }
    const data = await sendStatus(thunkApi.extra.$http, orderId, urlStatus, body).then(
      res => res.data
    )
    await thunkApi.dispatch(
      getDetailed({
        taasId: data.taasId,
        serialId: data.robot?.id
      })
    )
    return mapV4ToV5(data)
  } else if (willShip(state)) {
    const startAt = moment(shippedAt)
      .add(1, 'days')
      .toISOString()
    await shipTaas(thunkApi.extra.$http, taasId, { shippedAt, startAt }).then(
      res => res.data
    )
    await thunkApi.dispatch(
      getDetailed({
        taasId
      })
    )
  } else if (willSetReturned(state)) {
    // patch taas
    const returnedAt = state.detailed.taas.endAt
    await endTaas(thunkApi.extra.$http, taasId, returnedAt)
    await thunkApi.dispatch(
      getDetailed({
        taasId
      })
    )
  }
  if (willAddTrackTrace(state)) {
    const trackTraceCode = state.ordersDetailed?.order?.trackTraceCode
    return patchTaasOrder(thunkApi.extra.$http, orderId, { trackTraceCode }).then(
      res => mapV4ToV5(res.data)
    )
  }
  if (state.ordersDetailed?.order?.internalNotes !== state.ordersDetailed.originalOrder?.internalNotes) {
    const internalNotes = state.ordersDetailed?.order?.internalNotes
    return patchTaasOrder(thunkApi.extra.$http, orderId, { internalNotes }).then(
      res => mapV4ToV5(res.data)
    )
  }
  return getOrder(thunkApi.extra.$http, orderId).then(res => res.data)
})
