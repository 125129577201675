import './CustomQueryView.module'
import customQuery from './customQueryPage.component'

export default angular
    .module('components.customQueryPage', ['components.tb.customQuery'])
    .component('customQueryPage', customQuery)
    .config($stateProvider => {
        $stateProvider.state('customQueryPage', {
            parent: 'app',
            url: '^/custom-query-page',
            component: 'customQueryPage'
        })
    }).name
