import { plainToClass } from "class-transformer"
import { ValidationError, validateSync } from "class-validator"

const convertValidationError = (errors: ValidationError[]): string => {
  return 'Validation failed: ' + errors.map((error: ValidationError) => {
    if (error.children && error.children.length) {
      return convertValidationError(error.children)
    } else {
      return Object.values(error.constraints).join(', ')
    }
  }).join(', ')
}

export const validateObj = <T>(obj: any, type: any): T => {
  const classObj: any = plainToClass(type, obj)
  const validationErrors = validateSync(classObj, { whitelist: true, forbidNonWhitelisted: true })
  if (validationErrors.length) {
    const message = convertValidationError(validationErrors)
    throw Error(message)
  }
  return classObj as T
}