import { IsString, MinLength, IsOptional, ValidateNested, IsNumber } from 'class-validator'
import { Type } from 'class-transformer'
import { Contact } from '../../../types/Contact'
import { Address } from '../../../types/Address'

export class ReturnForm {
  @IsNumber()
  @IsOptional()
  returnConceptId?: number | null

  @IsOptional()
  @IsString()
  createdAt?: string | null

  @IsNumber()
  @IsOptional()
  orderId?: number | null

  @ValidateNested()
  @Type(() => Contact)
  returner: Contact

  @IsString()
  @MinLength(1)
  returnReason: string

  @ValidateNested()
  @IsOptional()
  @Type(() => Address)
  pickupAddress?: Address

  @IsOptional()
  @IsString()
  internalNotes?: string | null

  @IsOptional()
  @IsString()
  notes?: string | null
}