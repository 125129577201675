/**
 * @file Async thunks for getting and patching data for dashtransfer state
 * @author Max van Loosbroek
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { IHttpService } from 'angular'
import { RootState } from '../../../common/redux/root.reducer'
import { URLS } from '../../../common/utilities/constants/constants.module'
import { parseHttpError } from '../../../common/utilities/errorHandling'
import { isShippable, robotDeactivated } from '../../overview/stringTransformers'
import {
  ChainDetailed,
  DetailedData,
  RobotAccount,
  RobotBase,
  RobotDetailed,
  SubscriptionBase,
  TaasDetailed
} from '../../overview/types/types'
import { dashtransferBodySelector } from './reducer'
import { RelationDetailed } from '../../overview/types/RelationDetailed'

export const transferEntities = createAsyncThunk<
  any,
  void,
  { extra: { $http: IHttpService } }
>('dashtransfer/transferEntities', async (_, thunkApi) => {
  const body = dashtransferBodySelector(thunkApi.getState() as RootState)
  const url = URLS.admin.dashTransfer
  const { data } = await thunkApi.extra.$http
    .post<any>(url, body)
    .catch(parseHttpError)
  return data
})

export const getTransferData = createAsyncThunk<
  DetailedData,
  { serialId?: number; chainId?: number; taasId?: number },
  { extra: { $http: IHttpService } }
>(
  'dashtransfer/getTransferData',
  async ({ serialId, chainId, taasId }, thunkApi) => {
    // Get robot
    const robotUrl = `${URLS.admin.robotsV3}/${serialId}`
    const { data: robot } = serialId
      ? await thunkApi.extra.$http
          .get<RobotDetailed>(robotUrl)
          .catch(parseHttpError)
      : { data: undefined }
    // Get robotAccount
    const robotAccountUrl = `${URLS.admin.robotsV3}/${serialId}/account`
    const { data: robotAccount } = serialId
      ? await thunkApi.extra.$http
          .get<RobotAccount>(robotAccountUrl)
          .catch(e => {
            if (e?.status === 404) {
              // no account
              return { data: undefined }
            } else {
              return parseHttpError(e)
            }
          })
      : { data: undefined }
    // Get chain
    const chainUrl = `${URLS.admin.chains}/${chainId}`
    const { data: chain } = chainId
      ? await thunkApi.extra.$http
          .get<ChainDetailed>(chainUrl)
          .catch(parseHttpError)
      : { data: undefined }
    // Get TaaS
    const taasUrl = `${URLS.admin.taas}/${taasId}`
    const { data: taas } = taasId
      ? await thunkApi.extra.$http
          .get<TaasDetailed>(taasUrl)
          .catch(parseHttpError)
      : { data: undefined }
    // Apply canceled status if needed
    if (robot) {
      robot.deactivated = robotDeactivated(robot)
      robot.shippable = isShippable(robot)
      robot.account = robotAccount
    }
    if (taas) taas.canceled = !!taas.endAt
    if (chain) chain.cancelled = !!chain.canceledAt
    return {
      taas,
      robot,
      chain,
      relation: taas?.relation || robot?.relation || chain?.relation
    }
  }
)

export const searchSubscriptionsDashtransfer = createAsyncThunk<
  SubscriptionBase[],
  { relationId: string },
  { extra: { $http: IHttpService } }
>(
  'dashtransfer/searchSubscriptionsDashtransfer',
  async ({ relationId }, thunkApi) => {
    const url = `${URLS.admin.subscriptions}?relationid=${relationId}`
    const { data: subs } = await thunkApi.extra.$http
      .get<SubscriptionBase[]>(url)
      .catch(parseHttpError)
    return subs
  }
)

export const searchRobotsDashtransfer = createAsyncThunk<
  RobotDetailed[],
  { serial: string; relationId: string },
  { extra: { $http: IHttpService } }
>(
  'dashtransfer/searchRobotsDashtransfer',
  async ({ serial, relationId }, thunkApi) => {
    const url = `${URLS.admin.robotsV3}?serial=${
      serial ? serial.replace('T-', '') : ''
    }${relationId ? `&relationId=${relationId}` : ''}` // remove taas indicator
    const { data: robots } = await thunkApi.extra.$http
      .get<RobotDetailed[]>(url)
      .catch(parseHttpError)
    return robots
  }
)

export const getNewRobot = createAsyncThunk<
  RobotDetailed & { subscriptions: SubscriptionBase[] },
  RobotBase,
  { extra: { $http: IHttpService } }
>('dashtransfer/getNewRobot', async (robot, thunkApi) => {
  const chainUrl = `${URLS.admin.chains}/${robot?.chainId}`
  const robotUrl = `${URLS.admin.robotsV3}/${robot?.serialId}`
  const { data } = await thunkApi.extra.$http
    .get<RobotDetailed>(robotUrl)
    .catch(parseHttpError)
  const payload: RobotDetailed & { subscriptions: SubscriptionBase[] } = {
    ...data,
    subscriptions: []
  }
  if (robot?.chainId) {
    const { data: chain } = await thunkApi.extra.$http
      .get<ChainDetailed>(chainUrl)
      .catch(parseHttpError)
    payload.subscriptions = chain.subscriptions
  }
  return payload
})

export const searchRelationsDashtransfer = createAsyncThunk<
  RelationDetailed[],
  { name: string },
  { extra: { $http: IHttpService } }
>('dashtransfer/searchRelationsDashtransfer', async ({ name }, thunkApi) => {
  const url = `${URLS.admin.relations}?name=${encodeURI(name)}`
  const { data: relations } = await thunkApi.extra.$http
    .get<RelationDetailed[]>(url)
    .catch(parseHttpError)
  return relations
})

export const searchTaasDashtransfer = createAsyncThunk<
  TaasDetailed[],
  { relationId: string },
  { extra: { $http: IHttpService } }
>('dashtransfer/v', async ({ relationId }, thunkApi) => {
  const url = `${URLS.admin.taas}${
    relationId ? `?relationId=${relationId}` : ''
  }`
  const { data: taas } = await thunkApi.extra.$http
    .get<TaasDetailed[]>(url)
    .catch(parseHttpError)
  return taas
})
