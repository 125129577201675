/**
 * @file Dialog for creating order
 * @author Max van Loosbroek
 */

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TbDashDetailedColumn,
  TbDashDetailedContainer,
  TbDashDetailedDialog,
  TbDashDetailedItem,
  TbDashDetailedItemRow,
  TbDashErrorDialog
} from 'tinybots-react-components'
import { AppDispatch } from '../../../../common/redux/store'
import { createOrderFinal, getOrderConceptById } from './redux/thunks'
import { RootState } from '../../../../common/redux/root.reducer'
import { OrderConceptState } from './types/types'
import { ConceptOrderSearch } from './Form/ConceptOrderSearch/ConceptOrderSearch'
import {
  clearErrorsOrderConcept,
  clearSearch,
  setDone,
  updateOrder
} from './redux/reducer'
import { OrderFinal } from '../../types/OrderFinal'
import { validOrder } from './redux/selectors'
import { clearStateDetailed } from '../../../overview/Detailed/redux/reducer'
import { ContactsConceptOrderForm } from './Form/Contacts/ContactsConceptOrderForm'
import { GeneralConceptOrderForm } from './Form/General/GeneralConceptOrderForm'
import { SelectConceptDialog } from './SelectConcept/SelectConceptDialog'
import { overviewTheme } from '../../../overview/Overview/Overview'
import { ThemeProvider } from '@material-ui/core'

export type CreateOrderDialogProps = {
  orderConceptId?: number
  orderFormValid: boolean
  orderConceptState: OrderConceptState
  open: boolean
  color: string
  dispatch: AppDispatch
  handleClose: () => void
  afterSubmit: (id: string | number) => void
}

export const CreateOrderDialogComponent = ({
  orderConceptId,
  orderFormValid,
  open,
  orderConceptState,
  color,
  handleClose,
  afterSubmit,
  dispatch
}: CreateOrderDialogProps) => {
  const {
    orderConcept,
    patchError,
    getError,
    getting,
    patching,
    orderConceptsSearchResults
  } = orderConceptState
  const errorMessage = getError || patchError
  const loading = getting || patching
  const [clientId, setClientId] = useState<string>('')
  const [uuid, setUuid] = useState<string>('')
  const [showErrors, setShowErrors] = useState(false)
  const handleSubmit = useCallback(async () => {
    setShowErrors(true)
    if (!orderFormValid) {
      return
    } else {
      const res = await dispatch(createOrderFinal(orderConcept))
      if (res?.meta.requestStatus === 'fulfilled') {
        afterSubmit((res.payload as OrderFinal).id)
      }
    }
  }, [orderConcept, orderFormValid])
  const onChangeForm = useCallback(
    changes => {
      dispatch(updateOrder(changes))
    },
    [dispatch]
  )
  useEffect(() => {
    if (orderConceptId) {
      dispatch(getOrderConceptById(orderConceptId))
    } else {
      dispatch(setDone())
    }
  }, [orderConceptId])
  const clearErrors = useCallback(() => {
    dispatch(clearErrorsOrderConcept())
  }, [dispatch])
  useEffect(() => {
    return () => {
      dispatch(clearStateDetailed())
    }
  }, [])
  const closeSelect = useCallback(() => {
    dispatch(clearSearch())
  }, [])
  const pickConcept = useCallback((id: number) => {
    dispatch(getOrderConceptById(id))
    dispatch(clearSearch())
  }, [])
  const tabs: { title: string; content: any }[] = [
    {
      title: 'Adding order',
      content: (
        <ThemeProvider theme={overviewTheme}>
          <TbDashDetailedContainer>
            <TbDashDetailedColumn>
              <ConceptOrderSearch
                clientId={clientId}
                setClientId={setClientId}
                uuid={uuid}
                setUuid={setUuid}
              />
              <GeneralConceptOrderForm
                orderConcept={orderConcept}
                onChangeForm={onChangeForm}
                dispatch={dispatch}
                showErrors={showErrors}
              />
            </TbDashDetailedColumn>
            <TbDashDetailedColumn>
              <TbDashDetailedItemRow>
                <TbDashDetailedItem
                  xs={12}
                  type='textArea'
                  label='Sales order notes'
                  value={orderConcept?.notes}
                  onChange={e => onChangeForm({ notes: e })}
                />
              </TbDashDetailedItemRow>
              <ContactsConceptOrderForm
                orderConcept={orderConcept}
                onChangeForm={onChangeForm}
                showErrors={showErrors}
              />
            </TbDashDetailedColumn>
          </TbDashDetailedContainer>
        </ThemeProvider>
      )
    }
  ]

  return (
    <>
      <TbDashDetailedDialog
        height={1200}
        loading={loading}
        open={open}
        color={color}
        tabs={tabs}
        activeTab={0}
        handleTabChange={() => {}}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <SelectConceptDialog
        handleClose={closeSelect}
        onPick={pickConcept}
        open={orderConceptsSearchResults.length > 0}
        items={orderConceptsSearchResults.map(
          ({ id, requester, createdAt: date }) => ({
            id,
            email: requester?.email,
            date: new Date(date)
          })
        )}
      />
      <TbDashErrorDialog
        message={errorMessage}
        open={!!errorMessage}
        handleClose={() => {
          clearErrors()
        }}
      />
    </>
  )
}

const ConnectedCreateOrderDialog = (
  props: Omit<
    CreateOrderDialogProps,
    'dispatch' | 'orderFormValid' | 'orderConceptState'
  >
) => {
  const dispatch: AppDispatch = useDispatch()
  const orderConceptState = useSelector(
    (state: RootState) => state.orderConcept
  )
  const orderFormValid = useSelector(validOrder)
  return (
    <CreateOrderDialogComponent
      {...props}
      orderFormValid={orderFormValid !== false}
      orderConceptState={orderConceptState}
      dispatch={dispatch}
    />
  )
}

export default React.memo(ConnectedCreateOrderDialog)
