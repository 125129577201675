/**
 * @file Divider for separating different sections in form
 * @author Max van Loosbroek
 */

import React from 'react'
import { TbDashDetailedItemRow } from 'tinybots-react-components'

export const DividerRow = ({ opacity }: { opacity?: number }) => (
  <TbDashDetailedItemRow>
    <div
      style={{
        background: 'black',
        width: '100%',
        opacity: opacity ?? 0.138,
        height: 1,
        marginLeft: 18,
        marginRight: 18
      }}
    ></div>
  </TbDashDetailedItemRow>
)
