import { IsEmail, IsOptional, IsPhoneNumber, IsString, MinLength } from "class-validator"

export class Requester {
  @MinLength(1)
  @IsString()
  firstname: string | null

  @MinLength(1)
  @IsString()
  lastname: string | null

  @MinLength(1)
  @IsEmail()
  email: string

  @IsOptional()
  @IsString()
  phoneNumber?: string | null
}