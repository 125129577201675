/**
 * @file Delivery address for TaaS order
 * @author Max van Loosbroek
 */

import React from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { Address } from '../../types/Address'

export const OrderDeliveryAddress = ({
  deliveryAddress
}: {
  deliveryAddress?: Address
}) => (
  <>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='Delivery address'
        value={deliveryAddress?.street ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='House no. + extension'
        value={
          deliveryAddress && deliveryAddress.homeNumber
            ? deliveryAddress.homeNumber +
              ' ' +
              (deliveryAddress.homeNumberExtension ?? '')
            : '-'
        }
      />
    </TbDashDetailedItemRow>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='Zipcode'
        value={deliveryAddress?.zipcode ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='City'
        value={deliveryAddress?.city ?? '-'}
      />
    </TbDashDetailedItemRow>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='Location description'
        value={deliveryAddress?.locationDescription ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Country'
        value={deliveryAddress?.country ?? '-'}
      />
    </TbDashDetailedItemRow>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='Recipient name'
        value={deliveryAddress?.recipient ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Delivery address of'
        value={deliveryAddress?.addressType ?? '-'}
      />
    </TbDashDetailedItemRow>
  </>
)
