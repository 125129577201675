import './SetMfa/SetMfa.module'
import setMfaPage from './set-mfa-page.component'

export default angular
    .module('setMfaPage', ['components.tb.setMfa'])
    .component('setMfaPage', setMfaPage)
    .config($stateProvider => {
        $stateProvider.state('auth.setMfaPage', {
            url: '^/set-mfa',
            component: 'setMfaPage',
            params: {
                email: null,
                password: null,
                secret: null
            }
        })
    }).name
