/**
 * @file Async thunks for getting and patching data for detailed state
 * @author Max van Loosbroek
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { IHttpService } from 'angular'
import { RootState } from '../../../../common/redux/root.reducer'
import { URLS } from '../../../../common/utilities/constants/constants.module'
import { parseHttpError } from '../../../../common/utilities/errorHandling'
import { OrderFinal } from '../../../orders/types/OrderFinal'
import { isShippable, robotDeactivated } from '../../stringTransformers'
import {
  DetailedData,
  RobotDetailed,
  ChainDetailed,
  TaasDetailed,
  LinkEntitiesBody,
  AddSubsBody,
  SubscriptionBase,
  RobotAccount
} from '../../types/types'
import {
  chainPatchSelector,
  robotPatchSelector,
  taasPatchSelector,
  statusPatchSelector,
  detailedValidSelector,
  extendValidSelector
} from './selectors'
import { RelationDetailed } from '../../types/RelationDetailed'

export const getDetailed = createAsyncThunk<
  DetailedData,
  { serialId?: number; chainId?: number; taasId?: number },
  { extra: { $http: IHttpService } }
>('detailed/getDetailed', async ({ serialId, chainId, taasId }, thunkApi) => {
  // Get TaaS
  const taasUrl = `${URLS.admin.taas}/${taasId}`
  const { data: taas } = taasId
    ? await thunkApi.extra.$http
        .get<TaasDetailed>(taasUrl)
        .catch(parseHttpError)
    : { data: undefined }
  const urlSerialId = serialId ?? taas?.robot?.serialId
  // Get order
  const orderUrl = `${URLS.admin.taasOrdersV5}`
  const order = taasId
    ? await thunkApi.extra.$http
        .get<OrderFinal[]>(orderUrl, {
          params: {
            'taasId[]': taasId
          }
        })
        .then(res => res.data[0])
        .catch(parseHttpError)
    : undefined
  if (taas && order) {
    taas.order = order
  }
  // Get robot
  const robotUrl = `${URLS.admin.robotsV3}/${urlSerialId}`
  const { data: robot } = urlSerialId
    ? await thunkApi.extra.$http
        .get<RobotDetailed>(robotUrl)
        .catch(parseHttpError)
    : { data: undefined }
  // Get robotAccount
  const robotAccountUrl = `${URLS.admin.robotsV3}/${urlSerialId}/account`
  const { data: robotAccount } = urlSerialId
    ? await thunkApi.extra.$http.get<RobotAccount>(robotAccountUrl).catch(e => {
        if (e?.status === 404) {
          // no account
          return { data: undefined }
        } else {
          return parseHttpError(e)
        }
      })
    : { data: undefined }
  // Get chain
  const chainUrl = `${URLS.admin.chains}/${chainId}`
  const { data: chain } = chainId
    ? await thunkApi.extra.$http
        .get<ChainDetailed>(chainUrl)
        .catch(parseHttpError)
    : { data: undefined }
  // Apply canceled status if needed
  if (robot) {
    robot.deactivated = robotDeactivated(robot)
    robot.account = robotAccount
    robot.shippable = isShippable(robot)
  }
  if (taas) {
    taas.canceled = !!taas.endAt
  } else if (chain) {
    chain.cancelled = !!chain.canceledAt
  }
  return {
    taas,
    robot,
    chain
  }
})

export const linkEntities = createAsyncThunk<
  LinkEntitiesBody,
  LinkEntitiesBody,
  { extra: { $http: IHttpService } }
>('detailed/linkEntities', async (body, thunkApi) => {
  if (body.basic) {
    const url = URLS.admin.link
    const { data } = await thunkApi.extra.$http
      .post<any>(url, body.basic)
      .catch(parseHttpError)
    return data
  } else if (body.taas) {
    const {
      shippedAt,
      startAt,
      serialId,
      taasId,
      taasNotes,
      robotNotes
    } = body.taas
    const url = URLS.admin.linkTaas.replace('{taasId}', taasId.toString())
    const { data } = await thunkApi.extra.$http
      .post<any>(url, {
        serialId,
        shippedAt,
        startAt
      })
      .catch(parseHttpError)
    if (taasNotes) {
      const taasUrl = `${URLS.admin.taas}/${taasId}`
      await thunkApi.extra.$http
        .patch<TaasDetailed>(taasUrl, { notes: taasNotes })
        .catch(parseHttpError)
    }
    if (robotNotes) {
      const robotUrl = `${URLS.admin.robotsV3}/${serialId}`
      await thunkApi.extra.$http
        .patch<RobotDetailed>(robotUrl, { notes: robotNotes })
        .catch(parseHttpError)
    }
    return data
  }
  throw Error('Invalid body')
})

export const extendSub = createAsyncThunk<
  AddSubsBody,
  AddSubsBody,
  { state: RootState; extra: { $http: IHttpService } }
>('detailed/addSubs', async (body, thunkApi) => {
  const url = URLS.admin.subscriptions
  const { detailed } = thunkApi.getState()
  if (!extendValidSelector(detailed).detailedValid) {
    throw Error(`Invalid form: ${extendValidSelector(detailed).errorMessage}`)
  }
  const { data } = await thunkApi.extra.$http
    .post<any>(url, body)
    .catch(parseHttpError)
  thunkApi.dispatch(getDetailed({ chainId: body.chainId }))
  return data
})

export const searchSubscriptions = createAsyncThunk<
  SubscriptionBase[],
  { relationId: string },
  { extra: { $http: IHttpService } }
>('detailed/searchSubscriptions', async ({ relationId }, thunkApi) => {
  const url = `${URLS.admin.subscriptions}?relationid=${relationId}&hasrobot=false`
  const { data: subs } = relationId
    ? await thunkApi.extra.$http
        .get<SubscriptionBase[]>(url)
        .catch(parseHttpError)
    : { data: [] as SubscriptionBase[] }
  return subs
})

export const searchRobots = createAsyncThunk<
  RobotDetailed[],
  { serial?: string; boxNumber?: string },
  { extra: { $http: IHttpService } }
>('detailed/searchRobots', async ({ serial, boxNumber }, thunkApi) => {
  const url = boxNumber
    ? `${URLS.admin.robotsV3}?boxNr=${boxNumber}` // remove taas indicator
    : `${URLS.admin.robotsV3}?serial=${serial ? serial.replace('T-', '') : ''}` // remove taas indicator
  const { data: robots } =
    serial || boxNumber
      ? await thunkApi.extra.$http
          .get<RobotDetailed[]>(url)
          .catch(parseHttpError)
      : { data: [] as RobotDetailed[] }
  return robots
})

export const searchRelations = createAsyncThunk<
  RelationDetailed[],
  { name: string },
  { extra: { $http: IHttpService } }
>('detailed/searchRelations', async ({ name }, thunkApi) => {
  const url = `${URLS.admin.relations}?name=${name}`
  const { data: relations } = name
    ? await thunkApi.extra.$http
        .get<RelationDetailed[]>(url)
        .catch(parseHttpError)
    : { data: [] as RelationDetailed[] }
  return relations
})

export const searchTaas = createAsyncThunk<
  TaasDetailed[],
  { relationId: string },
  { extra: { $http: IHttpService } }
>('detailed/searchTaas', async ({ relationId }, thunkApi) => {
  const url = `${URLS.admin.taas}?relationId=${relationId}&hasRobot=false`
  const { data: taas } = relationId
    ? await thunkApi.extra.$http.get<TaasDetailed[]>(url).catch(parseHttpError)
    : { data: [] as TaasDetailed[] }
  return taas
})

export const patchDetailed = createAsyncThunk<
  {
    robot?: RobotDetailed
    chain?: ChainDetailed
    taas?: TaasDetailed
  },
  void,
  { state: RootState; extra: { $http: IHttpService } }
>('detailed/patchDetailed', async (_, thunkApi) => {
  const { detailed } = thunkApi.getState()
  const { robot: { serialId, account } = {} } = detailed
  const { chain: { chainId } = {} } = detailed
  const { taas: { id: taasId } = {} } = detailed
  const chainPatch = chainPatchSelector(detailed)
  const robotPatch = robotPatchSelector(detailed)
  const taasPatch = taasPatchSelector(detailed)
  const statusPatch = statusPatchSelector(detailed)
  const result: {
    robot?: RobotDetailed
    chain?: ChainDetailed
    taas?: TaasDetailed
  } = {}
  if (!detailedValidSelector(detailed).detailedValid) {
    throw Error(`Invalid form: ${detailedValidSelector(detailed).errorMessage}`)
  }
  if (statusPatch && account) {
    const statusUrl = `${URLS.admin.robots}/${account.robotId}/status`
    await thunkApi.extra.$http
      .post<RobotAccount>(statusUrl, statusPatch)
      .catch(parseHttpError)
  }
  if (chainPatch) {
    const chainUrl = `${URLS.admin.chains}/${chainId}`
    result.chain = await thunkApi.extra.$http
      .patch<ChainDetailed>(chainUrl, chainPatch)
      .then(res => res.data)
      .catch(parseHttpError)
  }
  if (taasPatch) {
    const taasUrl = `${URLS.admin.taas}/${taasId}`
    result.taas = await thunkApi.extra.$http
      .patch<TaasDetailed>(taasUrl, taasPatch)
      .then(res => res.data)
      .catch(parseHttpError)
  }
  if (detailed.taas?.delete) {
    const taasUrl = `${URLS.admin.taas}/${taasId}`
    await thunkApi.extra.$http.delete(taasUrl).catch(parseHttpError)
    result.taas = undefined
  }
  if (robotPatch) {
    const robotUrl = `${URLS.admin.robotsV3}/${serialId}`
    result.robot = await thunkApi.extra.$http
      .patch<RobotDetailed>(robotUrl, robotPatch)
      .then(res => res.data)
      .catch(parseHttpError)
  }
  return result
})
