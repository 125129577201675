import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  OrderOverviewColumn,
  OrderOverviewColumnKey,
  OrderFinalRow,
  OrderOverviewState
} from '../../types/types'
import { ORDER_OVERVIEW_COLUMNS } from '../constants'
import { getOrderOverview, retrieveConcepts } from './thunks'

export const overviewColumnAdapter = createEntityAdapter<
  OrderOverviewColumn & { key: OrderOverviewColumnKey }
>({
  selectId: entity => entity.key
})

export const orderOverviewAdapter = createEntityAdapter<OrderFinalRow>({
  selectId: entity => entity.key
})

const initialColumns = () => {
  const columnsJSON = localStorage.getItem('overviewTableColumns')
  const savedColumns: OrderOverviewColumn[] =
    columnsJSON && JSON.parse(columnsJSON)
  const columnsUnchanged = savedColumns
    ? ORDER_OVERVIEW_COLUMNS.every((c, i) => {
        return (
          savedColumns[i]?.headerName === c.headerName &&
          savedColumns[i]?.canHide === c.canHide &&
          savedColumns[i]?.key === c.key &&
          savedColumns[i]?.percentWidth === c.percentWidth &&
          savedColumns[i]?.fontFamily === c.fontFamily &&
          savedColumns[i]?.width === c.width
        )
      })
    : false
  return overviewColumnAdapter.setAll(
    overviewColumnAdapter.getInitialState(),
    columnsUnchanged
      ? savedColumns ?? ORDER_OVERVIEW_COLUMNS
      : ORDER_OVERVIEW_COLUMNS
  )
}

export const initialStateOrderOverview = (): OrderOverviewState =>
  orderOverviewAdapter.getInitialState({
    getting: true,
    getError: null,
    patching: false,
    patchError: null,
    outdated: false,
    columns: initialColumns()
  })

export const overviewSlice = createSlice({
  name: 'ordersOverview',
  initialState: initialStateOrderOverview(),
  reducers: {
    clearErrorsOrderOverview: state => {
      state.getError = null
      state.patchError = null
    },
    clearState: () => initialStateOrderOverview(),
    clearRetrieveResult: state => {
      state.retrieveResult = undefined
    },
    showColumn: (
      { columns },
      { payload }: { payload: OrderOverviewColumnKey }
    ) => {
      overviewColumnAdapter.updateOne(columns, {
        id: payload,
        changes: { hidden: false }
      })
      const allColumns = overviewColumnAdapter.getSelectors().selectAll(columns)
      localStorage.setItem('overviewTableColumns', JSON.stringify(allColumns))
    },
    hideColumn: (
      { columns },
      { payload }: { payload: OrderOverviewColumnKey }
    ) => {
      overviewColumnAdapter.updateOne(columns, {
        id: payload,
        changes: { hidden: true }
      })
      const allColumns = overviewColumnAdapter.getSelectors().selectAll(columns)
      localStorage.setItem('overviewTableColumns', JSON.stringify(allColumns))
    }
  },
  extraReducers: builder => {
    builder.addCase(getOrderOverview.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getOrderOverview.rejected, state => {
      state.getting = false
      state.getError = 'Unknown error'
    })
    builder.addCase(getOrderOverview.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      const { lastId, rows, loadMore } = action.payload
      state.lastId = lastId
      if (loadMore) {
        orderOverviewAdapter.addMany(state, rows)
      } else {
        orderOverviewAdapter.setAll(state, rows)
      }
    })
    builder.addCase(retrieveConcepts.rejected, (state, action) => {
      state.getError = action.error?.message ?? 'Unknown error'
    })
    builder.addCase(retrieveConcepts.fulfilled, (state, action) => {
      state.retrieveResult = action.payload
    })
  }
})

export const {
  clearState,
  showColumn,
  hideColumn,
  clearErrorsOrderOverview,
  clearRetrieveResult
} = overviewSlice.actions

// Extract the action creators object and the reducer
const { reducer } = overviewSlice

export const orderOverviewReducer = reducer
