import React, { useState, useCallback, useEffect } from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { GeneralConceptOrderFormComponentProps } from './GeneralConceptOrderForm'
import { searchRelations } from '../../../../../overview/Detailed/redux/thunks'

export const useRelationItem = ({
  orderConcept,
  relationsSearch,
  showErrors,
  dispatch,
  onChangeForm
}: GeneralConceptOrderFormComponentProps) => {
  const [relationName, setRelationName] = useState<string>(
    orderConcept?.relation?.name
  )
  const [selectedRelationId, setSelectedRelationId] = useState<string>()
  const selectedRelation = relationsSearch.find(
    r => r.relationId.toString() === selectedRelationId
  )
  const relationHasError = useCallback(
    () =>
      !orderConcept?.relation && !selectedRelation && showErrors
        ? 'Search for a relation'
        : undefined,
    [orderConcept?.relation, selectedRelation, showErrors]
  )
  useEffect(() => {
    relationName && dispatch(searchRelations({ name: relationName.toString() }))
  }, [relationName])
  const selectRelation = useCallback(
    (option: { label: string, key: string, disabled: boolean }) => {
      option && setSelectedRelationId(option.key.toString())
      const selectedRelation = relationsSearch.find(
        r => r.relationId?.toString() === option.key
      )
      onChangeForm({
        relation: {
          relationId: selectedRelation.relationId,
          name: selectedRelation.name,
          teamleaderId: '',
          type: selectedRelation.type
        }
      })
    },
    [setSelectedRelationId, relationsSearch]
  )
  const relationItem = (
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        hasError={relationHasError}
        type='autoComplete'
        label='Relation*'
        iconUrl='assets/relation.svg'
        onChange={v => setRelationName(v as string)}
        onSelect={selectRelation}
        options={relationsSearch.map(r => ({
          label: r.name,
          disabled: false,
          key: r.relationId.toString()
        }))}
        value={relationName}
      />
    </TbDashDetailedItemRow>
  )
  return relationItem
}
