/**
 * @file Selector functions for selecting part of the state
 * @author Max van Loosbroek
 */

import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'
import { RootState } from '../../../../common/redux/root.reducer'
import { getTaasRef, isShippable } from '../../stringTransformers'
import {
  DetailedState,
  RobotDetailed,
  SubscriptionDetailed
} from '../../types/types'
import { getChainPatch, getRobotPatch, getTaasPatch } from './patchMappers'
import {
  subscriptionAdapter,
  subscriptionsSearchAdapter,
  relationsAdapter,
  robotsAdapter,
  taasSearchAdapter
} from './reducer'
import { subsValidation, taasValidation, robotValidation } from './validators'
import { RelationDetailed } from '../../types/RelationDetailed'
import { RelationBase } from '../../types/RelationBase'

export const subsSelector = (state: DetailedState) => {
  return subscriptionAdapter.getSelectors().selectAll(state.subscriptions)
}

export const subsLatestSelector = createSelector(
  subsSelector,
  subscriptions => {
    return subscriptions.sort(
      (a, b) => moment(b.endAt).valueOf() - moment(a.endAt).valueOf()
    )[0]
  }
)

export const activeSubSelector = (state: RootState) => {
  return state.detailed.subscriptions.entities[
    state.detailed.activeSubscription
  ]
}

export const extensionSubSelector = (state: RootState) => {
  return state.detailed.subscriptions.entities[-1]
}

export const subsSearchSelector = (state: DetailedState) =>
  subscriptionsSearchAdapter.getSelectors().selectAll(state.subscriptionsSearch)

export const taasSearchSelector = (ref?: string) => (state: RootState) => {
  if (!ref) {
    return taasSearchAdapter
      .getSelectors()
      .selectAll(state.detailed.taasSearch)
      .slice(0, 20)
  } else {
    return taasSearchAdapter
      .getSelectors()
      .selectAll(state.detailed.taasSearch)
      .filter(t => getTaasRef(t).includes(ref))
      .slice(0, 20)
  }
}

export const relationsSearchSelector = (state: DetailedState) =>
  relationsAdapter.getSelectors().selectAll(state.relationsSearch)

export const robotsSearchSelector = (
  relation: RelationBase | RelationDetailed
) => (state: RootState): (RobotDetailed & { disabled: boolean })[] =>
  robotsAdapter
    .getSelectors()
    .selectAll(state.detailed.robotsSearch)
    .map(r => {
      const shippable = isShippable(r)
      const relationMatchOrShippable = !(
        shippable ||
        (relation?.relationId &&
          relation?.relationId === r?.relation?.relationId)
      )
      const disabled: boolean =
        !!r?.taas || // is taas robot
        !!r?.taasId || // has taas
        !!r?.chainId || // has chain
        relationMatchOrShippable
      return { ...r, shippable, disabled: disabled }
    })
    .sort((a, b) => {
      if (a?.disabled && !b?.disabled) {
        return 1
      } else if (!a?.disabled && b?.disabled) {
        return -1
      } else {
        return 0
      }
    })
    .slice(0, 20)

export const robotsTaasSearchSelector = (
  relation: RelationBase | RelationDetailed
) => (state: RootState) =>
  robotsAdapter
    .getSelectors()
    .selectAll(state.detailed.robotsSearch)
    .map(r => {
      const shippable = r.shippableAt
        ? parseInt(moment(r.shippableAt).format('YYYY')) < 9999
        : false
      const relationMatchOrShippable = !(
        shippable ||
        (relation?.relationId &&
          relation?.relationId === r?.relation?.relationId)
      )
      const disabled: boolean =
        !r?.taas || // is not taas
        !!r?.taasId || // has taas
        !!r?.chainId || // has chain
        relationMatchOrShippable
      return { ...r, shippable, disabled: disabled }
    })
    .sort((a, b) => {
      if (a?.disabled && !b?.disabled) {
        return 1
      } else if (!a?.disabled && b?.disabled) {
        return -1
      } else {
        return 0
      }
    })
    .slice(0, 20)

export const relationsSelector = (state: DetailedState) =>
  relationsAdapter.getSelectors().selectAll(state.relationsSearch)

export const getDefaultSub = (subs?: (any & { startAt: string })[]) => {
  let defaultSub: SubscriptionDetailed
  if (!subs) return null
  const currentSubs = subs
    .filter(
      sub =>
        moment(sub.startAt).isBefore(moment()) &&
        moment(sub.endAt).isAfter(moment())
    )
    .sort((a, b) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf())
  const futureSubs = subs
    .filter(sub => moment(sub.startAt).isAfter(moment()))
    .sort((a, b) => moment(a.startAt).valueOf() - moment(b.startAt).valueOf())
  const pastSubs = subs
    .filter(sub => moment(sub.endAt).isBefore(moment()))
    .sort((a, b) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf())
  if (subs.length === 1) {
    return subs[0]
  } else if (currentSubs.length > 0) {
    defaultSub = currentSubs[0]
  } else if (futureSubs.length > 0) {
    defaultSub = futureSubs[0]
  } else if (pastSubs.length > 0) {
    defaultSub = pastSubs[0]
  } else {
    return subs[0]
  }
  return defaultSub
}

export const chainPatchSelector = (detailed: DetailedState) => {
  const updateSubscriptions = subscriptionAdapter
    .getSelectors()
    .selectAll(detailed.subscriptions)
  const chainPatch = getChainPatch({
    updateChain: detailed.chain,
    originalChain: detailed.originalChain,
    updateSubscriptions
  })
  return chainPatch
}

export const robotPatchSelector = (detailed: DetailedState) => {
  return getRobotPatch({
    originalRobot: detailed.originalRobot,
    updateRobot: detailed.robot
  })
}

export const taasPatchSelector = (detailed: DetailedState) => {
  return getTaasPatch({
    originalTaas: detailed?.originalTaas,
    updateTaas: detailed?.taas
  })
}

export const statusPatchSelector = (detailed: DetailedState) => {
  if (
    detailed.robot?.account &&
    detailed.robot.account.status !== detailed.originalRobot.account?.status
  ) {
    return { status: detailed.robot.account.status }
  }
}

export const detailedValidSelector = (detailed: DetailedState) => {
  const updateSubscriptions = subscriptionAdapter
    .getSelectors()
    .selectAll(detailed.subscriptions)
  const subscriptions = subsValidation(updateSubscriptions)
  const taas = taasValidation(detailed.taas)
  const robot = robotValidation(detailed.robot)
  const errorMessage = [
    subscriptions.message,
    robot.message,
    taas.message
  ].join(' | ')
  const detailedValid = subscriptions.valid && taas.valid && robot.valid
  return {
    taas,
    robot,
    subscriptions,
    detailedValid,
    errorMessage
  }
}

export const extendValidSelector = (detailed: DetailedState) => {
  const updateSubscriptions = subscriptionAdapter
    .getSelectors()
    .selectAll(detailed.subscriptions)
  const subscriptions = subsValidation(updateSubscriptions)
  const errorMessage = [subscriptions.message].join(' | ')
  const detailedValid = subscriptions.valid
  return {
    subscriptions,
    detailedValid,
    errorMessage
  }
}

export const hasUnsavedChanges = (detailedState: DetailedState) => {
  const updateSubscriptions = subscriptionAdapter
    .getSelectors()
    .selectAll(detailedState.subscriptions)
  const chainPatch = getChainPatch({
    updateChain: detailedState.chain,
    originalChain: detailedState.originalChain,
    updateSubscriptions
  })
  const robotPatch = getRobotPatch({
    originalRobot: detailedState.originalRobot,
    updateRobot: detailedState.robot
  })
  const taasPatch = getTaasPatch({
    originalTaas: detailedState.originalTaas,
    updateTaas: detailedState.taas
  })
  return !!chainPatch || !!robotPatch || !!taasPatch
}

export const defaultSubSelector = createSelector(subsSelector, getDefaultSub)
