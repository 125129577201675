import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../common/redux/store'
import {
  LinkEntitiesBody,
  RobotDetailed,
  TaasDetailed
} from '../../../types/types'
import { updateTaas } from '../../redux/reducer'
import { linkEntities } from '../../redux/thunks'
import { RelationBase } from '../../../types/RelationBase'

export const useChangeHandlersLinkTaas = ({
  patchRobot
}: {
  patchRobot: (value: Partial<RobotDetailed>) => void
}) => {
  const dispatch = useDispatch()
  const handleChangeStartDate = useCallback(
    value => dispatch(updateTaas({ startAt: value as string })),
    []
  )
  const handleChangeShippedAt = useCallback(
    value => dispatch(updateTaas({ shippedAt: value as string })),
    []
  )
  const handleTaasNoteChange = useCallback(
    value => dispatch(updateTaas({ notes: value as string })),
    []
  )
  const handleRobotNoteChange = useCallback(
    value => {
      patchRobot({ notes: value as string })
    },
    []
  )
  return {
    handleChangeStartDate,
    handleChangeShippedAt,
    handleTaasNoteChange,
    handleRobotNoteChange
  }
}

export const useSubmitLinkTaas = ({
  relation,
  taas,
  setShowErrors,
  dispatch,
  afterSubmit,
  selectedRobot
}: {
  relation: RelationBase
  taas: TaasDetailed
  setShowErrors: Function
  dispatch: AppDispatch
  afterSubmit: Function
  selectedRobot: RobotDetailed
}) => {
  const handleSubmit = async () => {
    setShowErrors(true)
    if (relation && !taas?.shippedAt) return
    if (relation && !taas?.startAt) return
    if (!selectedRobot?.serialId) return
    if (!relation?.relationId) return
    const body: LinkEntitiesBody = {
      taas: {
        robotNotes: selectedRobot?.notes,
        serialId: selectedRobot?.serialId,
        shippedAt: taas?.shippedAt ?? null,
        startAt: taas?.startAt ?? null,
        taasId: taas?.id,
        taasNotes: taas?.notes ?? null
      }
    }
    const res = await dispatch(linkEntities(body))
    if (res?.meta.requestStatus === 'fulfilled') {
      afterSubmit()
    }
  }
  return handleSubmit
}

export const useLinkSubFormInfo = ({
  taas,
  showErrors,
  selectedRobot
}: {
  taas: TaasDetailed
  showErrors: boolean
  selectedRobot: RobotDetailed
}) => {
  const robotHasError = (_value: string) =>
  !selectedRobot?.serialId && showErrors
    ? 'Select a valid robot'
    : undefined
  const shippedAtHasError = (value: string) =>
    taas?.id && (!value || !moment(value).isValid()) && showErrors
      ? 'Enter a date'
      : undefined
  const taasStartHasError = (value: string) => {
    return ((taas && (!value || !moment(value).isValid())) ||
       (taas && value && taas?.endAt && moment(value) >= moment(taas?.endAt))) &&
       showErrors
      ? 'Invalid start date or taas duration'
      : undefined}
  return {
    error: {
      robotHasError,
      shippedAtHasError,
      taasStartHasError
    }
  }
}
