/**
 * @file Constants used for overview feature
 * @author Max van Loosbroek
 */

import { OverviewColumn, OverviewFilter, SubscriptionDetailed } from './types/types'

export const OVERVIEW_COLUMNS: OverviewColumn[] = [
  {
    key: 'relation',
    headerName: 'relation',
    width: 240
  },
  {
    key: 'subRef',
    headerName: 'sub. ref.',
    width: 120
  },
  {
    key: 'boxNr',
    headerName: 'box nr.',
    width: 85,
    fontFamily: 'Roboto-Mono-Light'
  },
  {
    key: 'serial',
    headerName: 'robot',
    width: 178
  },
  {
    key: 'startAt',
    headerName: 'start date',
    width: 120,
    canHide: true
  },
  {
    key: 'endAt',
    headerName: 'end date',
    width: 120,
    canHide: true
  },
  {
    key: 'length',
    headerName: 'length',
    width: 85,
    canHide: true
  },
  {
    key: 'robotPaymentReference',
    headerName: 'r. pay ref.',
    width: 150,
    canHide: true
  },
  {
    key: 'subscriptionPaymentReference',
    headerName: 's. pay ref.',
    width: 150,
    canHide: true
  },
  {
    key: 'iccId',
    headerName: 'ICC id',
    width: 200,
    fontFamily: 'Roboto-Mono-Light',
    canHide: true
  },
  {
    key: 'clientId',
    headerName: 'client id',
    width: 150,
    fontFamily: 'Roboto-Mono-Light',
    canHide: true
  },
  {
    key: 'teamId',
    headerName: 'team id',
    width: 150,
    fontFamily: 'Roboto-Mono-Light',
    canHide: true
  },
  {
    key: 'subNotes',
    headerName: 'sub. notes',
    fontFamily: 'Roboto-Mono-Light',
    width: 600,
    canHide: true
  },
  {
    key: 'robotNotes',
    headerName: 'r. notes',
    fontFamily: 'Roboto-Mono-Light',
    width: 600,
    canHide: true
  }
]

export const OVERVIEW_FILTERS: OverviewFilter[] = [
  {
    label: 'All',
    filterType: 'status',
    filterTerm: 'robotRelation'
  },
  {
    label: 'Valid',
    filterType: 'status',
    filterTerm: 'valid'
  },
  {
    label: 'Expiring',
    filterType: 'status',
    filterTerm: 'expiring'
  },
  {
    label: 'Expired',
    filterType: 'status',
    filterTerm: 'expired'
  },
  {
    label: 'Canceled',
    filterType: 'status',
    filterTerm: 'canceled'
  }
]

export const EMPTY_SUB = (
  latest: SubscriptionDetailed,
  tempId: number
): SubscriptionDetailed => ({
  subscriptionId: tempId, // temporary id to distinguish temp sub from actual sub
  subscriptionLength: null,
  chainId: latest.chainId,
  parentId: latest.subscriptionId,
  paymentReference: '',
  startAt: latest.endAt,
  endAt: '',
  notes: ''
})