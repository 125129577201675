import { useCallback, useRef } from 'react'
import { FIELDSETS } from './constants/constants.module'
import { PhoneNumberUtil } from 'google-libphonenumber'

export const useFormItemValidation = (showErrors: boolean) => {
  const phoneUtil = useRef<PhoneNumberUtil>(PhoneNumberUtil.getInstance()).current;
  const regexEmail = new RegExp(FIELDSETS.regexes.email)
  const regexPhone = new RegExp(FIELDSETS.regexes.phone)
  const regexNumber = new RegExp(FIELDSETS.regexes.snumeric)
  const requiredErrorMessage = 'This field is required'
  const generalEmptyError = useCallback(
    (value: string) => (value?.length < 1 && showErrors ? requiredErrorMessage : ''),
    [showErrors]
  )
  const phoneErrorMessage = 'Enter a valid phone number'
  const phoneError = useCallback(
    (value: string) => {
      try {
        let parsedPhone: libphonenumber.PhoneNumber
        try {
          parsedPhone = value ? phoneUtil.parseAndKeepRawInput(value) : undefined
        } catch (error) {
          parsedPhone = value ? phoneUtil.parseAndKeepRawInput(value, 'NL') : undefined
        }
        const isPhone = value && value?.length > 0 && phoneUtil.isValidNumber(parsedPhone)
        return (value?.length < 1 || !isPhone) && showErrors ? phoneErrorMessage : ''
      } catch (error) {
        return phoneErrorMessage
      }
    },
    [showErrors, regexPhone]
  )
  const phoneErrorOptional = useCallback(
    (value: string) => {
      if (!value) {
        return ''
      }
      try {
        let parsedPhone: libphonenumber.PhoneNumber
        try {
          parsedPhone = value ? phoneUtil.parseAndKeepRawInput(value) : undefined
        } catch (error) {
          parsedPhone = value ? phoneUtil.parseAndKeepRawInput(value, 'NL') : undefined
        }
        const isPhone = !value || value?.length < 1 || phoneUtil.isValidNumber(parsedPhone)
        return (value?.length < 1 || !isPhone) && showErrors ? phoneErrorMessage : ''
      } catch (error) {
        return phoneErrorMessage
      }
    },
    [showErrors, regexPhone]
  )
  const emailErrorMessage = 'Enter a valid email address'
  const emailError = useCallback(
    (value: string) => {
      const isEmail = regexEmail.exec(value)
      return (value?.length < 1 || !isEmail) && showErrors ? emailErrorMessage : ''
    },
    [showErrors, regexEmail]
  )
  const emailErrorOptional = useCallback(
    (value: string) => {
      const isEmail = regexEmail.exec(value)
      return value?.length > 0 && !isEmail && showErrors ? emailErrorMessage : ''
    },
    [showErrors, regexEmail]
  )
  const numberErrorMessage = 'Enter a valid number'
  const numberError = useCallback(
    (value: string) => {
      const isNumber = regexNumber.exec(value)
      return value?.length < 1 && !isNumber && showErrors ? numberErrorMessage : ''
    },
    [showErrors, regexNumber]
  )
  const numberErrorOptional = useCallback(
    (value: string) => {
      const isNumber = regexNumber.exec(value)
      return value?.length > 0 && !isNumber && showErrors ? numberErrorMessage : ''
    },
    [showErrors, regexNumber]
  )
  return {
    generalEmptyError,
    emailError,
    emailErrorOptional,
    phoneError,
    phoneErrorOptional,
    numberError,
    numberErrorOptional
  }
}
