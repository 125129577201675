/* @ngInject */
import { clearState } from '../../common/redux/root.reducer';
import { setGetting } from '../overview/redux/reducer';
import StoreService from '../../common/redux/store.service';
export default class AuthService {
    constructor($http, URLS, $localStorage, $auth, $state, $q, StoreService){
        'ngInject';
        this.$http = $http;
        this.URLS = URLS;
        this.$localStorage = $localStorage;
        this.store = StoreService.store;
        this.$auth = $auth;
        this.$state = $state;
        this.$q = $q;
        this.dashboardUserToken = null;
    }
    refreshToken(){
        const
            url = this.URLS.wonkers.refreshtoken,
            data = {refreshToken: this.getSession().refresh_token};
        return this.$http.post(url, data);
    }
    tryRefreshToken(){
        const deferred = this.$q.defer();
        this.refreshToken()
            .then(response => {
                this.$auth.setToken(response.data.access_token);
                this.setSession(response.data);
                return deferred.resolve(response.data.access_token);

            })
            .catch(() => {
                deferred.reject();
                this.logout();
                this.$state.go('auth.login')

            });
        return deferred.promise;
    }

    passwordAnalysis(password){
        const url = this.URLS.prowl.passwordanalysis;
        return this.$http.post(url, password);
    }
    checkRegistrationKey(key){
        const url = this.URLS.wonkers.checkregistrationkey.replace('{key}', key);
        return this.$http.get(url, key)
    }
    setSession(data){
        this.$localStorage.session = data;
    }
    removeSession(){
        this.$localStorage.session && delete this.$localStorage.session;
    }
    getSession(){
        return this.$localStorage.session;
    }
    getCustomerAccount(){
        const url = this.URLS.wonkers.getcustomeraccount;
        return this.$http.get(url);

    }
    setMfa({ email, password, otpCode, enableMfa }) {
        const url = this.URLS.wonkers.setMfa;
        return this.$http.patch(url, { email, password, otpCode, enableMfa });
    }
    forgotPassword(data){
        const url = this.URLS.wonkers.forgotpassword;
        return this.$http.post(url, data);
    }
    resetPassword(data){
        const url = this.URLS.wonkers.resetpassword;
        return this.$http.post(url, data);
    }
    logout(){
        this.store.dispatch(clearState())
        this.$auth.logout();
        this.$auth.removeToken();
        this.removeSession();
    }

};
