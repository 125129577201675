/**
 * @file Functions for making http requests for overview
 * @author Max van Loosbroek
 */

import { IHttpService, IRequestShortcutConfig } from 'angular'
import { URLS } from '../../../../common/utilities/constants/constants.module'
import { parseHttpError } from '../../../../common/utilities/errorHandling'
import { OrderFinal, OrderFinalV5 } from '../../types/OrderFinal'

export const getOrders = (
  $http: IHttpService,
  config: IRequestShortcutConfig
) => {
  const url = URLS.admin.taasOrdersV5
  return $http.get<OrderFinalV5[]>(url, config).catch(parseHttpError)
}

export const retrieveConceptsRequest = (
  $http: IHttpService,
  config: IRequestShortcutConfig
) => {
  const url = URLS.admin.retrieveConcepts
  return $http.post<any[]>(url, config).catch(parseHttpError)
}
