import template from './subscriptions-add.html'

const subscriptionsAdd = {
  bindings: {
    item: '<',
    relationWarning: '<',
    subscriptionLengthWarning: '<',
    numberOfSubscriptionsWarning: '<',
    paymentReferenceWarning: '<',
    relations: '<',
    onRelationChange: '&',
    onClearWarning: '&',
    onDataChange: '&',
  },
  template,
  controller: /* @ngInject */ class SubscriptionsAddController {
    searchRelation ({ searchTerm }) {
      this.subRefs = []
      if (searchTerm.length < 3) {
        this.relations = []
      }
      this.onRelationChange({ $event: { searchTerm } })
    }

    setRelation ({ item }) {
      this.relationWarning = null
      this.data.relation = item
      this.forwardData()
    }

    setSubscriptionLength ({ value }) {
      this.subscriptionLengthWarning = !value || value <= 0 || isNaN(value) ? 'Add a subscription length' : null
      this.data.subscriptionLength = value
      this.forwardData()
    }

    setPaymentReference ({ value }) {
      this.paymentReferenceWarning = value ? null : 'Add a payment reference'
      this.data.paymentReference = value
      this.forwardData()
    }

    setAmountOfSubscriptions ({ value }) {
      this.numberOfSubscriptionsWarning = value ? null : 'Add the amount of subscriptions'
      this.data.numberOfSubscriptions = value
      this.forwardData()
    }

    setSubscriptionNotes ({ value }) {
      this.data.notes = value
    }

    clearWarning (name) {
      this.onClearWarning({ $event: { name } })
    }

    forwardData () {
      this.onDataChange({ $event: { data: this.data } })
    }

    $onChanges (changes) {
      if (changes.data) {
        this.data = angular.copy(this.data)
      }
    }

    $onInit () {
      this.data = {}
      if(this.item) {
        this.setRelation({item: this.item})
      }
    }
  }
}

export default subscriptionsAdd
