/**
 * @file Wrapper module in AngularJS for routing to overview page
 * @author Max van Loosbroek
 */

import './Overview/Overview.module'
import StoreService from '../../common/redux/store.service'
import overviewPage from './overviewPage.component'

export default angular
  .module('components.overviewPage', ['components.tb.overviewReact'])
  .component('overviewPage', overviewPage)
  .service('StoreService', StoreService)
  .config($stateProvider => {
    $stateProvider.state('overview', {
      parent: 'app',
      url: `^/overview/?
        searchType&
        searchValue&
        sort&
        sort_order&
        token`,
      component: 'overviewPage',
      reloadOnSearch: false,
      params: {},
      resolve: {}
    })
  }).name
