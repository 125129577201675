import login from './login.component'

export default angular
  .module('login', [])
  .component('login', login)
  .config(($stateProvider) => {
    $stateProvider
      .state('auth.login', {
        url: '^/login',
        component: 'login',
        params: {
          successfulSignUp: null
        }
      })
  })
  .name
