import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../common/redux/store'
import {
  LinkEntitiesBody,
  RobotDetailed,
  TaasBase,
  TaasDetailed
} from '../../../types/types'
import { updateRobot } from '../../redux/reducer'
import { linkEntities } from '../../redux/thunks'
import { RelationBase } from '../../../types/RelationBase'

export const useChangeHandlersLinkTaasRobot = ({
  patchTaas
}: {
  patchTaas: (value: Partial<TaasBase | TaasDetailed>) => void
}) => {
  const dispatch = useDispatch()
  const handleChangeStartDate = useCallback(
    value => patchTaas({ startAt: value as string }),
    []
  )
  const handleChangeShippedAt = useCallback(
    value => patchTaas({ shippedAt: value as string }),
    []
  )
  const handleSubNoteChange = useCallback(
    value => patchTaas({ notes: value as string }),
    []
  )
  const handleRobotNoteChange = useCallback(
    value => dispatch(updateRobot({ notes: value as string })),
    []
  )
  return {
    handleChangeStartDate,
    handleChangeShippedAt,
    handleSubNoteChange,
    handleRobotNoteChange
  }
}

export const useSubmitLinkTaasRobot = ({
  relation,
  selectedTaas,
  selectedRelation,
  setShowErrors,
  dispatch,
  afterSubmit,
  robot
}: {
  relation: RelationBase
  selectedRelation: RelationBase
  selectedTaas: TaasDetailed
  setShowErrors: Function
  dispatch: AppDispatch
  afterSubmit: Function
  robot: RobotDetailed
}) => {
  const handleSubmit = async () => {
    const relationId = relation?.relationId ?? selectedRelation?.relationId
    setShowErrors(true)
    if (relation && !selectedTaas?.id) return
    if (relation && !selectedTaas?.startAt) return
    if (relation && !selectedTaas?.shippedAt) return
    if (!robot.serialId) return
    if (!relationId) return
    const body: LinkEntitiesBody = {
      taas: {
        robotNotes: robot?.notes,
        serialId: robot?.serialId,
        shippedAt: selectedTaas?.shippedAt ?? null,
        startAt: selectedTaas?.startAt ?? null,
        taasId: selectedTaas?.id,
        taasNotes: selectedTaas?.notes ?? null
      }
    }
    const res = await dispatch(linkEntities(body))
    if (res?.meta.requestStatus === 'fulfilled') {
      afterSubmit()
    }
  }
  return handleSubmit
}

export const useLinkTaasRobotFormInfo = ({
  selectedTaas,
  showErrors,
  relation,
  selectedRelation
}: {
  selectedTaas: TaasDetailed
  showErrors: boolean
  relation: RelationBase
  selectedRelation: RelationBase
}) => {
  const shippedAtHasError = (value: string) =>
    selectedTaas?.id &&
    (!value || !moment(value).isValid()) &&
    showErrors
      ? 'Enter a date'
      : undefined
  const subscriptionStartHasError = (value: string) =>
    ((selectedTaas && (!value || !moment(value).isValid())) ||
      (selectedTaas && value && selectedTaas?.endAt && moment(value) >= moment(selectedTaas?.endAt))) &&
    showErrors
      ? 'Invalid start date or taas duration'
      : undefined
  const ticketRefHasError = (value: string | number) =>
    value && isNaN(parseInt(value as string)) && showErrors
      ? 'Enter a number'
      : undefined
  const relationHasError = () =>
    !relation && !selectedRelation && showErrors
      ? 'Search for a relation'
      : undefined
  return {
    error: {
      shippedAtHasError,
      subscriptionStartHasError,
      ticketRefHasError,
      relationHasError
    }
  }
}
