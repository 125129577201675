const rootConfig = /* @ngInject */ (
  $locationProvider,
  $httpProvider,
  $authProvider,
  $localStorageProvider,
  tmhDynamicLocaleProvider,
  API_URL,
  URLS) => {
  tmhDynamicLocaleProvider.localeLocationPattern('/base/node_modules/angular-i18n/angular-locale_{{locale}}.js')
  $localStorageProvider.setKeyPrefix('Tinybots.')

  $locationProvider.html5Mode(true)
  $locationProvider.hashPrefix('')

  $authProvider.baseUrl = API_URL
  $authProvider.loginUrl = URLS.wonkers.login
  $authProvider.signupUrl = URLS.wonkers.signup
  $authProvider.tokenType = 'Bearer'
  $authProvider.storageType = 'localStorage'

  $httpProvider.interceptors.push('httpInterceptor')
}

export default rootConfig
