import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../common/redux/store'
import {
  LinkEntitiesBody,
  RobotDetailed,
  SubscriptionBase
} from '../../../types/types'
import { updateSub } from '../../redux/reducer'
import { linkEntities } from '../../redux/thunks'
import { RelationBase } from '../../../types/RelationBase'

export const useChangeHandlersLinkSub = ({
  patchRobot
}: {
  patchRobot: (value: Partial<RobotDetailed>) => void
}) => {
  const dispatch = useDispatch()

  const handleChangePaymentReference = useCallback(
    value =>
    patchRobot({ paymentReference: value as string }),
    []
  )
  const handleChangeZohoReference = useCallback(
    value => patchRobot({ zendeskTicketId: value }),
    []
  )
  const handleChangeStartDate = useCallback(
    value => dispatch(updateSub({ startAt: value as string })),
    []
  )
  const handleChangeEndDate = useCallback(
    value => dispatch(updateSub({ endAt: value as string })),
    []
  )
  const handleChangeShippedAt = useCallback(
    value => dispatch(updateSub({ shippedAt: value as string })),
    []
  )
  const handleSubNoteChange = useCallback(
    value => dispatch(updateSub({ notes: value as string })),
    []
  )
  const handleRobotNoteChange = useCallback(
    value => patchRobot({ notes: value as string }),
    []
  )
  return {
    handleChangePaymentReference,
    handleChangeZohoReference,
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangeShippedAt,
    handleSubNoteChange,
    handleRobotNoteChange
  }
}

export const useSubmitLinkSub = ({
  relation,
  defaultSub,
  setShowErrors,
  dispatch,
  afterSubmit,
  selectedRobot
}: {
  relation: RelationBase
  defaultSub: SubscriptionBase
  setShowErrors: Function
  dispatch: AppDispatch
  afterSubmit: Function
  selectedRobot: RobotDetailed
}) => {
  const handleSubmit = async () => {
    setShowErrors(true)
    if (
      !defaultSub?.paymentReference &&
      !selectedRobot?.paymentReference &&
      !selectedRobot?.zendeskTicketId
    )
      return
    if (relation && !defaultSub?.shippedAt) return
    if (!selectedRobot?.serialId) return
    if (!relation?.relationId) return
    const body: LinkEntitiesBody = {
      basic: {
        endAt: defaultSub?.endAt ?? null,
        relationId: relation?.relationId,
        robotNotes: selectedRobot?.notes,
        robotPaymentReference:
          defaultSub?.paymentReference ??
          (selectedRobot?.zendeskTicketId
            ? null
            : selectedRobot?.paymentReference),
        serialId: selectedRobot?.serialId,
        shippedAt: defaultSub?.shippedAt ?? null,
        startAt: defaultSub?.startAt ?? null,
        subscriptionId: defaultSub?.subscriptionId,
        subscriptionNotes: defaultSub?.notes ?? null,
        zendeskTicketId: selectedRobot?.zendeskTicketId ?? null
      }
    }
    const res = await dispatch(linkEntities(body))
    if (res?.meta.requestStatus === 'fulfilled') {
      afterSubmit()
    }
  }
  return handleSubmit
}

export const useLinkSubFormInfo = ({
  defaultSub,
  showErrors,
  selectedRobot
}: {
  defaultSub: SubscriptionBase
  showErrors: boolean
  selectedRobot: RobotDetailed
}) => {
  const robotHasError = (_value: string) =>
    !selectedRobot?.serialId && showErrors ? 'Select a valid robot' : undefined
  const shippedAtHasError = (value: string) =>
    defaultSub?.subscriptionId &&
    (!value || !moment(value).isValid()) &&
    showErrors
      ? 'Enter a date'
      : undefined
  const referenceError = (_value?: string) =>
    !selectedRobot?.paymentReference &&
    !defaultSub?.paymentReference &&
    !selectedRobot?.zendeskTicketId &&
    showErrors
      ? 'Enter payment reference or zendesk ticket id'
      : undefined
  const subscriptionStartHasError = (value: string) =>
    ((defaultSub && value && !moment(value).isValid()) ||
      (defaultSub && value && moment(value) >= moment(defaultSub?.endAt))) &&
    showErrors
      ? 'Invalid subscription length'
      : undefined
  const subscriptionEndHasError = (value: string) =>
    ((defaultSub && value && !moment(value).isValid()) ||
      (defaultSub && value && moment(defaultSub?.startAt) >= moment(value))) &&
    showErrors
      ? 'Invalid subscription length'
      : undefined
  const refHelperText =
    !selectedRobot?.paymentReference &&
    defaultSub?.paymentReference &&
    !selectedRobot?.zendeskTicketId
      ? 'By default inherits from robot'
      : undefined
  return {
    error: {
      robotHasError,
      shippedAtHasError,
      referenceError,
      subscriptionStartHasError,
      subscriptionEndHasError
    },
    helperText: {
      refHelperText
    }
  }
}
