import { IsDateString, IsOptional } from "class-validator"

export class OrderStatus {
  @IsOptional()
  @IsDateString()
  orderedAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  processingAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  shippedAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  returnProcessStartedAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  returnAcceptedAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  returnRejectedAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  returnedAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  declinedAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  deliveredAt?: string // Datestring

  @IsOptional()
  @IsDateString()
  activatedAt?: string // Datestring
}