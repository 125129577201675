const tbWhenScrolled = /* @ngInject */ () => {
  return {
    restrict: 'A',
    link: ($scope, $element, $attrs) => {
      const raw = $element[0]
      $element.bind('scroll', () => {
        if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight - 1000) {
          $scope.$apply($attrs.tbWhenScrolled)
        }
      })
    }
  }
}

export default tbWhenScrolled
