/**
 * @file
 * Form for changing general order values
 * @author Max van Loosbroek
 */

import React, { useCallback } from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { Address } from '../../../../types/Address'
import { Contact } from '../../../../types/Contact'
import { OrderConcept } from '../../types/OrderConcept'
import { useFormItemValidation } from '../../../../../../common/utilities/useFormItemValidation'
import { AddressType } from '../../../AddressType/AddressType'

const useChangeHandlers = (
  orderConcept: OrderConcept,
  onChangeForm: (changes: Partial<OrderConcept>) => void
) => {
  const {
    requester = undefined,
    receiver = undefined,
    healthcareProfessional = undefined,
    deliveryAddress = undefined
  } = orderConcept ?? {}
  const onChangeRequester = useCallback(
    (changes: Partial<Contact>) => {
      onChangeForm({ requester: { ...requester, ...changes } })
    },
    [requester]
  )
  const onChangeReceiver = useCallback(
    (changes: Partial<Contact>) => {
      onChangeForm({ receiver: { ...receiver, ...changes } })
    },
    [receiver]
  )
  const onChangeProfessional = useCallback(
    (changes: Partial<Contact>) => {
      onChangeForm({
        healthcareProfessional: { ...healthcareProfessional, ...changes }
      })
    },
    [healthcareProfessional]
  )
  const onChangeAddress = useCallback(
    (changes: Partial<Address>) => {
      onChangeForm({ deliveryAddress: { ...deliveryAddress, ...changes } })
    },
    [deliveryAddress]
  )
  return {
    onChangeRequester,
    onChangeReceiver,
    onChangeProfessional,
    onChangeAddress
  }
}

export interface ContactsConceptOrderFormProps {
  orderConcept?: OrderConcept
  onChangeForm: (changes: Partial<OrderConcept>) => void
  showErrors: boolean
}

export const ContactsConceptOrderForm = ({
  orderConcept,
  onChangeForm,
  showErrors
}: ContactsConceptOrderFormProps) => {
  const {
    requester = undefined,
    receiver = undefined,
    healthcareProfessional = undefined,
    deliveryAddress = undefined
  } = orderConcept ?? {}
  const {
    onChangeRequester,
    onChangeReceiver,
    onChangeProfessional,
    onChangeAddress
  } = useChangeHandlers(orderConcept, onChangeForm)
  const {
    generalEmptyError,
    emailError,
    phoneError,
    phoneErrorOptional,
    numberError
  } = useFormItemValidation(showErrors)
  return (
    <>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='Requester email*'
          hasError={emailError}
          value={requester?.email ?? ''}
          onChange={e => onChangeRequester({ email: e as string })}
        />
        <TbDashDetailedItem
          type='text'
          label='Tel. nr.*'
          hasError={phoneError}
          value={requester?.phoneNumber ?? ''}
          onChange={e => onChangeRequester({ phoneNumber: e as string })}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='First name*'
          hasError={generalEmptyError}
          value={requester?.firstname ?? ''}
          onChange={e => onChangeRequester({ firstname: e as string })}
        />
        <TbDashDetailedItem
          type='text'
          label='Last name*'
          hasError={generalEmptyError}
          value={requester?.lastname ?? ''}
          onChange={e => onChangeRequester({ lastname: e as string })}
        />
      </TbDashDetailedItemRow>
      <div style={{ marginTop: 100 }} />
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='Care professional email'
          hasError={healthcareProfessional ? emailError : () => ''}
          value={healthcareProfessional?.email ?? ''}
          onChange={e => onChangeProfessional({ email: e as string })}
        />
        <TbDashDetailedItem
          type='text'
          label='Tel. nr.'
          hasError={phoneErrorOptional}
          value={healthcareProfessional?.phoneNumber ?? ''}
          onChange={e => onChangeProfessional({ phoneNumber: e as string })}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='First name'
          value={healthcareProfessional?.firstname ?? ''}
          onChange={e => onChangeProfessional({ firstname: e as string })}
        />
        <TbDashDetailedItem
          type='text'
          label='Last name'
          value={healthcareProfessional?.lastname ?? ''}
          onChange={e => onChangeProfessional({ lastname: e as string })}
        />
      </TbDashDetailedItemRow>
      <div style={{ marginTop: 100 }} />
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='Informal caregiver (receiver) email'
          hasError={receiver ? emailError : () => ''}
          value={receiver?.email ?? ''}
          onChange={e => onChangeReceiver({ email: e as string })}
        />
        <TbDashDetailedItem
          type='text'
          label='Tel. nr.'
          value={receiver?.phoneNumber ?? ''}
          hasError={phoneErrorOptional}
          onChange={e => onChangeReceiver({ phoneNumber: e as string })}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='text'
          label='First name'
          value={receiver?.firstname ?? ''}
          onChange={e => onChangeReceiver({ firstname: e as string })}
        />
        <TbDashDetailedItem
          type='text'
          label='Last name'
          value={receiver?.lastname ?? ''}
          onChange={e => onChangeReceiver({ lastname: e as string })}
        />
      </TbDashDetailedItemRow>
      {
        orderConcept?.tessaExpertNeeded !== 'yes' ? <>
          <div style={{ marginTop: 100 }} />
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              label='Delivery address*'
              hasError={deliveryAddress ? generalEmptyError : () => ''}
              value={deliveryAddress?.street ?? ''}
              onChange={e => onChangeAddress({ street: e as string })}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='number'
              label='House no.*'
              hasError={deliveryAddress ? numberError : () => ''}
              value={deliveryAddress?.homeNumber ?? ''}
              onChange={e =>
                onChangeAddress({
                  homeNumber: e ? parseInt(e as string) : (e as number)
                })
              }
            />
            <TbDashDetailedItem
              type='text'
              label='extension'
              value={deliveryAddress?.homeNumberExtension ?? ''}
              onChange={e =>
                onChangeAddress({ homeNumberExtension: e as string })
              }
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              label='Zipcode*'
              hasError={deliveryAddress ? generalEmptyError : () => ''}
              value={deliveryAddress?.zipcode ?? ''}
              onChange={e => onChangeAddress({ zipcode: e as string })}
            />
            <TbDashDetailedItem
              type='text'
              label='City*'
              hasError={deliveryAddress ? generalEmptyError : () => ''}
              value={deliveryAddress?.city ?? ''}
              onChange={e => onChangeAddress({ city: e as string })}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              label='Location description'
              value={deliveryAddress?.locationDescription ?? ''}
              onChange={e =>
                onChangeAddress({ locationDescription: e as string })
              }
            />
            <TbDashDetailedItem
              type='text'
              label='Country*'
              hasError={deliveryAddress ? generalEmptyError : () => ''}
              value={deliveryAddress?.country ?? ''}
              onChange={e => onChangeAddress({ country: e as string })}
            />
          </TbDashDetailedItemRow>
          <TbDashDetailedItemRow>
            <TbDashDetailedItem
              type='text'
              label='Recipient name*'
              hasError={deliveryAddress ? generalEmptyError : () => ''}
              value={deliveryAddress?.recipient ?? ''}
              onChange={e => onChangeAddress({ recipient: e as string })}
            />
            <AddressType
              addressType={deliveryAddress?.addressType ?? ''}
              onChange={(addressType: string) =>
                onChangeAddress({
                  addressType
                })
              }
            />
          </TbDashDetailedItemRow>
        </> : null
      }
      <div style={{ marginTop: 100 }} />
    </>
  )
}
