import ImageService from './image-service/image.service'
import NotesService from './notes/notes.service'
import httpInterceptor from './http-interceptor/http-interceptor.factory'
import './constants/constants.module'

export default angular
  .module('common.utilities', ['constants'])
  .service('ImageService', ImageService)
  .service('NotesService', NotesService)
  .factory('httpInterceptor', httpInterceptor)
  .name
