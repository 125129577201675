import template from './auth-form.html'

const authForm = {
  bindings: {
    user: '<',
    type: '@',
    error: '<',
    passwordStrength: '<',
    button: '@',
    onSubmit: '&',
    onShareData: '&'
  },
  template,
  controller: /* @ngInject */ class AuthFormController {
    constructor (FIELDSETS) {
      this.fieldsets = FIELDSETS
    }

    $onChanges (changes) {
      if (changes.user) {
        this.user = angular.copy(this.user)
      }
      if (changes.error) {
        this.error = angular.copy(this.error)
      }
    }

    $onInit () {
      this.showPassword = false
      if (this.type === 'signup_password') {
        this.passwordError = false
      }
    }

    validateEquals () {
      this.passwordError = false
      this.passwordError = this.user.password &&
        this.user.password &&
        this.user.passwordConfirm &&
        this.user.password !== this.user.passwordConfirm
    }

    submit () {
      this.onSubmit({
        $event: {
          user: this.user
        }
      })
    }

    shareData () {
      this.onShareData({
        $event: {
          user: this.user
        }
      })
    }

    clearError () {
      this.error = {
        errorAuth: '',
        errorOther: ''
      }
    }
  }
}

export default authForm
