/**
 * @file Reducer for overview state, load overview data and show and hide columns
 * @author Max van Loosbroek
 */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import { URLS } from '../../../common/utilities/constants/constants.module'
import {
  OverviewColumn,
  OverviewColumnKey,
  OverviewItem,
  OverviewItemRow,
  OverviewState,
  OverviewThunkPayload
} from '../types/types'
import { IHttpService } from 'angular'
import { OVERVIEW_MOCK } from '../mock/overviewMocks'
import { RootState } from '../../../common/redux/root.reducer'
import { OVERVIEW_COLUMNS } from '../constants'
import { IdParams, OverviewQueryParams } from '../Overview/Overview'
import { parseHttpError } from '../../../common/utilities/errorHandling'
import { mapOverviewItemsToTable } from '../tableMappers'
import { OverviewResponse } from '../types/types';

export const columnAdapter = createEntityAdapter<
  OverviewColumn & { key: OverviewColumnKey }
>({
  selectId: entity => entity.key
})

export const overviewAdapter = createEntityAdapter<OverviewItemRow>({
  selectId: entity => entity.key
})

export const getOverview = createAsyncThunk<
  OverviewThunkPayload,
  { loadMore: boolean; urlParams: OverviewQueryParams | IdParams },
  { extra: { $http: IHttpService } }
>('overview/getOverview', async ({ loadMore, urlParams }, thunkApi) => {
  const idParams: boolean = !!(urlParams as IdParams).serialIds?.length || !!(urlParams as IdParams).chainIds?.length || !!(urlParams as IdParams).taasIds?.length
  if(!idParams) {
    (urlParams as OverviewQueryParams).limit = loadMore ? 15 : 30
  }
  if (!urlParams.sortBy) {
    urlParams.sortBy = 'relation'
    urlParams.sortOrder = 'asc'
  }
  let url = URLS.admin.overviewV3
  if(idParams) {
    url += '/ids'
  }
  const { overview } = thunkApi.getState() as RootState
  const continuationTokenState = loadMore
    ? overview.continuationToken
    : undefined
  if (continuationTokenState && !idParams) {
    (urlParams as OverviewQueryParams).token = continuationTokenState
  }
  const convertedParams = Object.fromEntries(
    Object.entries(urlParams).map(
      ([key, value], i) => {
        if (key.includes('Ids')) {
          return [`${key}[]`, value]
        }
        return [key, value]
      }
    )
  )
  const getOverview = () => thunkApi.extra.$http
    .get<OverviewResponse | OverviewItem[]>(url, {
      params: convertedParams
    })
  const { data } = await getOverview().catch(e => {
    if(e?.status === 409) {
      loadMore = undefined;
      (urlParams as OverviewQueryParams).token = undefined;
      (urlParams as OverviewQueryParams).limit = 30
      return getOverview()
    } else {
      throw e
    }
  }).catch(parseHttpError)
  const payload: OverviewThunkPayload = {
    rows: (data as OverviewResponse).data ? mapOverviewItemsToTable((data as OverviewResponse).data) : mapOverviewItemsToTable(data as any as OverviewItem[]),
    continuationToken: (data as OverviewResponse).continuationToken,
    loadMore,
    outdated: (data as OverviewResponse).outdated
  }
  return payload
})

const initialColumns = () => {
  const columnsJSON = localStorage.getItem('overviewTableColumns')
  const savedColumns: OverviewColumn[] = columnsJSON && JSON.parse(columnsJSON)
  const columnsUnchanged = savedColumns ? OVERVIEW_COLUMNS.every((c, i) => {
    return savedColumns[i]?.headerName === c.headerName &&
      savedColumns[i]?.canHide === c.canHide &&
      savedColumns[i]?.key === c.key &&
      savedColumns[i]?.percentWidth === c.percentWidth &&
      savedColumns[i]?.fontFamily === c.fontFamily &&
      savedColumns[i]?.width === c.width
  }) : false
  return columnAdapter.setAll(
    columnAdapter.getInitialState(),
    columnsUnchanged ? savedColumns ?? OVERVIEW_COLUMNS : OVERVIEW_COLUMNS
  )
}

export const initialStateOverview = (): OverviewState =>
  overviewAdapter.getInitialState({
    getting: true,
    getError: null,
    patching: false,
    patchError: null,
    outdated: false,
    columns: initialColumns()
  })

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: initialStateOverview(),
  reducers: {
    clearErrorsOverview: state => {
      state.getError = null
      state.patchError = null
    },
    clearState: () => initialStateOverview(),
    showColumn: ({ columns }, { payload }: { payload: OverviewColumnKey }) => {
      columnAdapter.updateOne(columns, {
        id: payload,
        changes: { hidden: false }
      })
      const allColumns = columnAdapter.getSelectors().selectAll(columns)
      localStorage.setItem('overviewTableColumns', JSON.stringify(allColumns))
    },
    hideColumn: ({ columns }, { payload }: { payload: OverviewColumnKey }) => {
      columnAdapter.updateOne(columns, {
        id: payload,
        changes: { hidden: true }
      })
      const allColumns = columnAdapter.getSelectors().selectAll(columns)
      localStorage.setItem('overviewTableColumns', JSON.stringify(allColumns))
    }
  },
  extraReducers: builder => {
    builder.addCase(getOverview.pending, state => {
      state.getting = true
      state.getError = null
    })
    builder.addCase(getOverview.rejected, state => {
      state.getting = false
      state.getError = 'Unknown error'
    })
    builder.addCase(getOverview.fulfilled, (state, action) => {
      state.getting = false
      state.getError = null
      const { continuationToken, rows, loadMore, outdated } = action.payload
      state.continuationToken = continuationToken
      state.outdated = outdated
      if (loadMore) {
        overviewAdapter.addMany(state, rows)
      } else {
        overviewAdapter.setAll(state, rows)
      }
    })
  }
})

export const {
  clearState,
  showColumn,
  hideColumn,
  clearErrorsOverview
} = overviewSlice.actions

// Extract the action creators object and the reducer
const { reducer } = overviewSlice

export const overviewReducer = reducer
