/**
 * @file Combines different reducers from features to one store, and handles global clear state
 * @author Max van Loosbroek
 */

import { AnyAction, CombinedState, combineReducers, createAction } from "@reduxjs/toolkit"
import { dashtransferReducer } from "../../components/dashtransfer/redux/reducer"
import { ordersDetailedReducer } from "../../components/orders/detailed/redux/reducer"
import { orderConceptReducer } from "../../components/orders/detailed/CreateOrder/redux/reducer"
import { orderOverviewReducer } from "../../components/orders/overview/redux/reducer"
import { detailedReducer } from "../../components/overview/Detailed/redux/reducer"
import { overviewReducer } from "../../components/overview/redux/reducer"
import { orderReturnReducer } from "../../components/orders/detailed/CreateReturn/redux/reducer"
import { organisationAccountsReducer } from "../../components/organisations/OrganisationAccounts/redux/reducer"

const appReducer = combineReducers({
  orderOverview: orderOverviewReducer,
  overview: overviewReducer,
  detailed: detailedReducer,
  dashtransfer: dashtransferReducer,
  ordersDetailed: ordersDetailedReducer,
  orderConcept: orderConceptReducer,
  orderReturn: orderReturnReducer,
  organisationAccounts: organisationAccountsReducer
})

export type RootState = ReturnType<typeof appReducer>
export const clearState = createAction('root/clearState')

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction
): CombinedState<ReturnType<typeof appReducer>> => {
  if (action.type === clearState.type) {
    return appReducer(undefined, { type: undefined })
  }

  return appReducer(state, action)
}

export default rootReducer
