/**
 * @file Transfer inputs for relations
 * @author Max van Loosbroek
 */

import { ArrowRightAlt } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TbDashDetailedItemRow, TbDashDetailedItem } from 'tinybots-react-components'
import { RootState } from '../../../common/redux/root.reducer'
import { AppDispatch } from '../../../common/redux/store'
import { useDebouncedFunction } from '../../overview/Detailed/LinkDialog/Robot/hooks'
import {
  dashtransferSelector,
  relationsSearchAdapter,
  setCurrentRelation,
  setNewRelation,
  setRelationName
} from '../redux/reducer'
import { searchRelationsDashtransfer } from '../redux/thunks'
import { DashtransferState } from '../types'
import { RelationBase } from '../../overview/types/RelationBase'

export interface RelationItemProps {
  relationName?: string
  currentRelation?: RelationBase | undefined
  newRelation?: RelationBase | undefined
  relationsSearch: RelationBase[]
  relationsSearchNew: RelationBase[]
  dispatch: AppDispatch
  showErrors: boolean
}

export const RelationItemComponent = React.memo(
  ({
    currentRelation,
    relationsSearch,
    dispatch,
    relationName,
    newRelation
  }: RelationItemProps) => {
    const setNewRelationName = (name: string) => dispatch(setRelationName(name))
    const debouncedSearch = useDebouncedFunction({
      changeHandler: (relationName: string) => {
        dispatch(searchRelationsDashtransfer({ name: relationName.toString() }))
      },
      delay: 100
    })
    const relationOptions = relationsSearch.map(r => ({
      label: r.name,
      disabled: false,
      key: r.relationId.toString()
    }))

    useEffect(() => {
      if(!relationName || relationName === newRelation?.name) return
      const relation = relationsSearch.find(r => r.name === relationName)
      dispatch(setNewRelation(relation))
      relationName && debouncedSearch(relationName)
    }, [relationName, relationsSearch])

    return (
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          hasError={v => null}
          type='text'
          disabled
          label='Current Relation'
          iconUrl='assets/relation.svg'
          value={currentRelation?.name}
        />
        <TbDashDetailedItem
          hasError={v => null}
          type='autoComplete'
          label='New Relation'
          iconUrl=''
          onChange={setNewRelationName}
          options={relationOptions}
          value={relationName ? relationName : (newRelation ? newRelation?.name : '')}
        />
        <ArrowRightAlt
          fontSize='inherit'
          style={{
            fontSize: 28,
            transform: 'scaleX(-1)',
            position: 'absolute',
            left: '50%',
            marginLeft: 8,
            bottom: 13
          }}
        />
      </TbDashDetailedItemRow>
    )
  }
)

const ConnectedRelationItem = (
  props: Pick<RelationItemProps, 'showErrors'>
) => {
  const { currentRelation, newRelation, relationName } = useSelector<
    RootState,
    DashtransferState
  >(dashtransferSelector)
  const relationsSearch = useSelector((state: RootState) =>
    relationsSearchAdapter
      .getSelectors()
      .selectAll(state.dashtransfer.relationsSearch)
  )
  const relationsSearchNew = useSelector((state: RootState) =>
    relationsSearchAdapter
      .getSelectors()
      .selectAll(state.dashtransfer.relationsSearch)
  )
  const dispatch: AppDispatch = useDispatch()

  return (
    <RelationItemComponent
      {...props}
      relationName={relationName}
      currentRelation={currentRelation}
      newRelation={newRelation}
      relationsSearch={relationsSearch}
      relationsSearchNew={relationsSearchNew}
      dispatch={dispatch}
    />
  )
}

export default ConnectedRelationItem
