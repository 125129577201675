import React, {  } from 'react'
import { useSelector } from 'react-redux'
import { TbDashDetailedItemRow, TbDashDetailedItem } from 'tinybots-react-components'
import { RootState } from '../../../common/redux/root.reducer'
import {
  TaasDetailed
} from '../../overview/types/types'
import {
  dashtransferSelector} from '../redux/reducer'
import { DashtransferState } from '../types'

export interface TaasItemProps {
  currentTaasSubscription?: TaasDetailed | undefined
}

export const TaasItemComponent = React.memo(
  ({
    currentTaasSubscription
  }: TaasItemProps) => {
    return (
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          hasError={v => null}
          type='text'
          disabled
          label='Current Subscription'
          iconUrl='assets/subref.svg'
          value={ currentTaasSubscription ? `T-${currentTaasSubscription?.id}` : '-' }
        />
      </TbDashDetailedItemRow>
    )
  }
)

const ConnectedTaasItem = () => {
  const {
    currentTaasSubscription
  } = useSelector<RootState, DashtransferState>(dashtransferSelector)

  return (
    <TaasItemComponent
      currentTaasSubscription={currentTaasSubscription}
    />
  )
}

export default ConnectedTaasItem
