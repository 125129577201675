import moment from "moment"
import { RobotDetailed, ChainDetailed, SubscriptionDetailed, SusbcriptionPatch, TaasDetailed } from "../../types/types"

export const getRobotPatch = ({
  originalRobot,
  updateRobot
}: {
  originalRobot: RobotDetailed
  updateRobot: RobotDetailed
}): Partial<RobotDetailed> | undefined => {
  if (!updateRobot) return null

  const robotPatch: Partial<Pick<
    RobotDetailed,
    | 'iccId'
    | 'taas'
    | 'notes'
    | 'deactivated'
    | 'shippable'
    | 'paymentReference'
    | 'zendeskTicketId'
    | 'excludeOkr'
  >> = {}

  if (
    originalRobot.notes !== updateRobot.notes
  ) {
    robotPatch.notes = updateRobot.notes || null
  }
  if (
    originalRobot.excludeOkr !== updateRobot.excludeOkr
  ) {
    robotPatch.excludeOkr = updateRobot.excludeOkr
  }
  if (originalRobot.deactivated !== updateRobot.deactivated) {
    robotPatch.deactivated = updateRobot.deactivated
  }
  if (originalRobot.taas !== updateRobot.taas) {
    robotPatch.taas = updateRobot.taas
  }
  if (originalRobot.iccId !== updateRobot.iccId) {
    robotPatch.iccId = updateRobot.iccId
  }
  if (originalRobot.shippable !== updateRobot.shippable) {
    robotPatch.shippable = updateRobot.shippable
  }
  if (originalRobot.paymentReference !== updateRobot.paymentReference) {
    robotPatch.paymentReference = updateRobot.paymentReference || null
  }
  if (originalRobot.zendeskTicketId !== updateRobot.zendeskTicketId) {
    robotPatch.zendeskTicketId = updateRobot.zendeskTicketId || null
  }

  if (Object.keys(robotPatch)?.length > 0) {
    return robotPatch
  }
}

export const getChainPatch = ({
  updateChain,
  originalChain,
  updateSubscriptions
}: {
  updateChain: ChainDetailed
  originalChain: ChainDetailed
  updateSubscriptions: SubscriptionDetailed[]
}): undefined | Partial<ChainDetailed> => {
  if (!updateChain) return null
  const chainPatch: Partial<ChainDetailed> = { chainId: updateChain.chainId }
  if (originalChain.cancelled !== updateChain.cancelled) {
    chainPatch.canceledAt = updateChain.cancelled
      ? moment.utc().toISOString()
      : null
  }
  const subscriptionsChanged = updateSubscriptions.reduce((filtered, sub) => {
    const originalSub = originalChain.subscriptions.find(
      s => s.subscriptionId === sub.subscriptionId
    )
    let subPatch: SusbcriptionPatch = {}
    if (originalSub?.subscriptionLength !== sub.subscriptionLength)
      subPatch.subscriptionLength = sub.subscriptionLength
    if (originalSub?.paymentReference !== sub.paymentReference)
      subPatch.paymentReference = sub.paymentReference
    if (originalSub?.shippedAt !== sub.shippedAt)
      subPatch.shippedAt = sub.shippedAt
    if (originalSub?.startAt !== sub.startAt) subPatch.startAt = sub.startAt
    if (originalSub?.endAt !== sub.endAt) subPatch.endAt = sub.endAt
    if (originalSub?.notes !== sub.notes && (originalSub?.notes || sub.notes))
      subPatch.notes = sub.notes || null
    if (Object.keys(subPatch)?.length > 0) {
      subPatch.subscriptionId = sub.subscriptionId
      filtered.push(subPatch)
    }
    return filtered
  }, [])
  if (subscriptionsChanged.length > 0)
    chainPatch.subscriptions = subscriptionsChanged
  if (
    chainPatch.canceledAt !== undefined ||
    (chainPatch.subscriptions && chainPatch.subscriptions.length > 0)
  ) {
    if (!chainPatch.subscriptions) {
      chainPatch.subscriptions = []
    }
    return chainPatch
  } else {
    return null
  }
}

export const getTaasPatch = ({
  updateTaas,
  originalTaas
}: {
  updateTaas: TaasDetailed
  originalTaas: TaasDetailed
}): undefined | Partial<TaasDetailed> => {
  if (!updateTaas) return null
  const taasPatch: Partial<TaasDetailed> = {}
  if (originalTaas?.shippedAt !== updateTaas.shippedAt)
    taasPatch.shippedAt = updateTaas.shippedAt
  if (originalTaas?.clientId !== updateTaas.clientId)
    taasPatch.clientId = updateTaas.clientId
  if (originalTaas?.teamId !== updateTaas.teamId)
    taasPatch.teamId = updateTaas.teamId
  if (originalTaas?.startAt !== updateTaas.startAt)
    taasPatch.startAt = updateTaas.startAt
  if (originalTaas?.endAt !== updateTaas.endAt)
    taasPatch.endAt = updateTaas.endAt
  if (
    originalTaas?.notes !== updateTaas.notes &&
    (originalTaas?.notes || updateTaas.notes)
  )
    taasPatch.notes = updateTaas.notes || null
  if (Object.keys(taasPatch).length > 0) {
    return taasPatch
  } else {
    return null
  }
}