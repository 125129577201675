import { createAsyncThunk } from '@reduxjs/toolkit'
import { OrderConcept } from '../types/OrderConcept'
import { IHttpService } from 'angular'
import {
  SearchParamsOrderConcept,
  createOrderFinalRequest,
  getOrderConceptByIdRequest,
  getOrderConceptRequest
} from './api'
import { OrderFinal } from '../../../types/OrderFinal'
import { mapOrderBody } from './mapOrderBody'

export const getOrderConcepts = createAsyncThunk<
  OrderConcept[],
  SearchParamsOrderConcept,
  { extra: { $http: IHttpService } }
>(
  'orderConcept/getOrderConcepts',
  async (searchParams: SearchParamsOrderConcept, thunkApi) => {
    // Get order concept
    const { data: orderConcepts } = await getOrderConceptRequest(
      thunkApi.extra.$http,
      searchParams
    )
    return orderConcepts
  }
)

export const getOrderConceptById = createAsyncThunk<
  OrderConcept,
  number,
  { extra: { $http: IHttpService } }
>('orderConcept/getOrderConceptById', async (conceptId: number, thunkApi) => {
  // Get order concept
  const { data: orderConcept } = await getOrderConceptByIdRequest(
    thunkApi.extra.$http,
    conceptId
  )
  return orderConcept
})

export const createOrderFinal = createAsyncThunk<
  OrderFinal,
  OrderConcept,
  { extra: { $http: IHttpService } }
>('orderConcept/createOrderFinal', async (order: OrderConcept, thunkApi) => {
  // Get order concept
  const { data: orderConcept } = await createOrderFinalRequest(
    thunkApi.extra.$http,
    mapOrderBody(order)
  )
  return orderConcept
})
