/**
 * @file Overview page component with search functionality and data table
 * @author Max van Loosbroek
 */

import React, { useCallback, useEffect, useState } from 'react'
import fastDeepEqual from 'fast-deep-equal'
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../common/redux/root.reducer'
import {
  OverviewColumn,
  OverviewItemRow,
  OverviewState,
  SortOptions,
  Tabs
} from '../types/types'
import StoreService from '../../../common/redux/store.service'
import { OverviewHeader } from '../OverviewHeader/OverviewHeader'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import {
  createStyles,
  createTheme,
  makeStyles,
  ThemeProvider
} from '@material-ui/core'
import { clearErrorsOverview, clearState, getOverview } from '../redux/reducer'
import {
  changeTab,
  clearErrorsDetailed,
  clearStateDetailed,
  toggleDetailed,
  toggleExtend,
  toggleLinkDialog,
  toggleUnsavedWarn
} from '../Detailed/redux/reducer'
import { Waypoint } from 'react-waypoint'
import OverviewDetailedDialog from '../Detailed/OverviewDetailedDialog/OverviewDetailedDialog'
import LinkDialogRobot from '../Detailed/LinkDialog/Robot/LinkDialogRobot'
import LinkDialogSub from '../Detailed/LinkDialog/Sub/LinkDialogSub'
import { AppDispatch } from '../../../common/redux/store'
import ExtendSubscription from '../Detailed/OverviewDetailedDialog/ExtendSubscription/ExtendSubscription'
import { getDetailed } from '../Detailed/redux/thunks'
import LinkDialogTaas from '../Detailed/LinkDialog/Taas/LinkDialogTaas'
import LinkDialogTaasRobot from '../Detailed/LinkDialog/TaasRobot/LinkDialogTaasRobot'
import {
  TbDashErrorDialog,
  TbDashOutdatedSnackbar,
  TbDashTable,
  TbDashWarnDialog,
  TbTableRowData
} from 'tinybots-react-components'
import { useOverviewValueMapper } from '../tableMappers'
import {
  getOverviewQueryParams,
  getDetailedParams,
  getIdParams
} from '../urlParamHelpers'
import { groupParamsByKey } from '../../../common/utilities/groupParamsByKey'
import { hasUnsavedChanges } from '../Detailed/redux/selectors'
import useSavedMessage from '../../../common/hooks/useSavedMessage'

export const overviewTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#8356ac'
    }
  }
})
export interface DetailedParams {
  chainId?: string
  serialId?: string
  taasId?: string
  tab?: Tabs
}

export interface OverviewQueryParams {
  searchType?: string
  searchTerm?: string
  sortBy?: SortOptions
  sortOrder?: 'asc' | 'desc'
  token?: string
  status?: 'valid' | 'expiring' | 'canceled' | 'expired'
  limit?: number
}
export type IdParams = {
  chainIds: number[]
  serialIds: number[]
  taasIds: number[]
} & Pick<OverviewQueryParams, 'sortBy' | 'sortOrder'>
export type OverviewUrlParams = OverviewQueryParams &
  DetailedParams & {
    chainIds?: number[]
    serialIds?: number[]
    taasIds?: number[]
  }
export interface OverviewProps {
  $http: any
  StoreService: StoreService
  overview: OverviewState
  rows: OverviewItemRow[]
  columns: OverviewColumn[]
  showDetailed: boolean
  showLinkDialog: 'robot' | 'sub' | 'taas' | 'taasRobot' | null
  errorMessage: null | string
  showUnsavedWarn: boolean
  dispatch: AppDispatch
  unsaved?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  })
)

export const handleSortClick = (
  sortBy: string,
  sortOrder: 'asc' | 'desc',
  browserWindow: any = window,
  setUrlParams: (params: OverviewUrlParams) => void
) => {
  const currentUrlParams = new URLSearchParams(window.location.search)
  currentUrlParams.set('sortBy', sortBy)
  currentUrlParams.set('sortOrder', sortOrder)
  setUrlParams(groupParamsByKey(currentUrlParams as any) as OverviewUrlParams)
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const handleClickRow = (
  row: OverviewItemRow,
  dispatch: AppDispatch,
  setUrlParams: (params: OverviewUrlParams) => void,
  browserWindow: any = window,
  tab?: Tabs
) => {
  const { robotSerialId: serialId, chainId, taasId } = row
  const currentUrlParams = new URLSearchParams(browserWindow.location.search)
  if (serialId) currentUrlParams.set('serialId', serialId.toString())
  if (chainId) {
    currentUrlParams.set('chainId', chainId.toString())
  } else if (taasId) {
    currentUrlParams.set('taasId', taasId.toString())
  }
  if (tab) {
    currentUrlParams.set('tab', tab)
  }
  setUrlParams(groupParamsByKey(currentUrlParams as any) as OverviewUrlParams)
  dispatch(clearStateDetailed())
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const clearDetailedParams = (
  setUrlParams: (params: OverviewUrlParams) => void,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(browserWindow.location.search)
  currentUrlParams.delete('serialId')
  currentUrlParams.delete('chainId')
  currentUrlParams.delete('taasId')
  currentUrlParams.delete('tab')
  setUrlParams(groupParamsByKey(currentUrlParams as any) as OverviewUrlParams)
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const changeDetailedTab = (
  setUrlParams: (params: OverviewUrlParams) => void,
  tab?: Tabs,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(browserWindow.location.search)
  if (tab) {
    currentUrlParams.set('tab', tab)
  }
  setUrlParams(groupParamsByKey(currentUrlParams as any) as OverviewUrlParams)
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const handleSearch = (
  {
    searchTerm,
    searchType
  }: {
    searchTerm: string
    searchType: string
  },
  setUrlParams: (params: OverviewUrlParams) => void,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(window.location.search)
  currentUrlParams.delete('serialIds')
  currentUrlParams.delete('chainIds')
  currentUrlParams.delete('taasIds')
  currentUrlParams.set('searchTerm', searchTerm ?? '')
  currentUrlParams.set('searchType', searchType)
  setUrlParams(groupParamsByKey(currentUrlParams as any) as OverviewUrlParams)
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const Overview: React.FunctionComponent<OverviewProps> = React.memo(
  ({
    overview,
    rows,
    dispatch,
    showUnsavedWarn,
    showDetailed,
    showLinkDialog,
    columns,
    errorMessage,
    unsaved
  }) => {
    const classes = useStyles()
    const initialUrlParams = groupParamsByKey(
      new URLSearchParams(window.location.search) as any
    ) as OverviewUrlParams
    const initialUrlParamsPrev = usePrevious(initialUrlParams)
    const [urlParams, setUrlParams] = useState(initialUrlParams)
    const {
      setShowSavedDialog,
      savedMessage
    } = useSavedMessage({
      handleClose: () => {
        dispatch(clearStateDetailed())
        clearDetailedParams(setUrlParams)
      }
    })
    const urlParamsPrevious: any = usePrevious(urlParams)

    const handleCloseDetailed = () => {
      dispatch(
        toggleDetailed({
          show: false
        })
      )
      if (!unsaved) {
        clearDetailedParams(setUrlParams)
      }
    }

    const handleCloseLinkDialog = () => {
      dispatch(toggleLinkDialog({ show: null }))
    }

    const handleCloseExtend = useCallback(
      () => dispatch(toggleExtend({ show: false, force: true })),
      []
    )

    const handleScrollToEnd = (_args: Waypoint.CallbackArgs) => {
      if (!overview.continuationToken) return
      dispatch(
        getOverview({
          loadMore: true,
          urlParams: getOverviewQueryParams(urlParams)
        })
      )
    }

    const clearErrors = () => {
      dispatch(clearErrorsOverview())
      dispatch(clearErrorsDetailed())
    }

    useEffect(() => {
      const overviewParamsChanged = !fastDeepEqual(
        getOverviewQueryParams(urlParams),
        getOverviewQueryParams(urlParamsPrevious)
      )
      const idParamsChanged = !fastDeepEqual(
        getIdParams(urlParams),
        getIdParams(urlParamsPrevious)
      )
      if (overviewParamsChanged || idParamsChanged) {
        dispatch(clearState())
        dispatch(
          getOverview({
            loadMore: false,
            urlParams: {
              ...getOverviewQueryParams(urlParams),
              ...getIdParams(urlParams)
            }
          })
        )
      }
      const { tab, ...detailedIds } = getDetailedParams(urlParams) ?? {}
      const { tab: tabPrevious, ...detailedIdsPrevious } =
        getDetailedParams(urlParamsPrevious) ?? {}
      const detailedParamsChanged = !fastDeepEqual(
        detailedIds,
        detailedIdsPrevious
      )
      if (tabPrevious !== tab) {
        dispatch(changeTab(tab))
      }
      if (detailedParamsChanged && Object.keys(detailedIds)?.length) {
        dispatch(getDetailed(getDetailedParams(urlParams)))
        dispatch(toggleDetailed({ show: true, activeTab: tab }))
      } else if (detailedParamsChanged) {
        clearDetailedParams(setUrlParams)
        if (showDetailed) {
          dispatch(toggleDetailed({ show: false }))
        }
      }
    }, [urlParams, urlParamsPrevious])

    useEffect(() => {
      if (!fastDeepEqual(initialUrlParams, initialUrlParamsPrev)) {
        setUrlParams(initialUrlParams)
      }
    }, [initialUrlParams, initialUrlParamsPrev])

    const overviewValueMapper = useOverviewValueMapper(
      (tab: Tabs, row: TbTableRowData) => {
        handleClickRow(row, dispatch, setUrlParams, undefined, tab)
      }
    )

    return (
      <div className={classes.root}>
        <OverviewHeader
          color='#8356ac'
          setUrlParams={setUrlParams}
          columns={columns.filter(c => c.canHide)}
          params={urlParams}
          onSubmit={({ searchTerm, searchType }) =>
            handleSearch({ searchTerm, searchType }, setUrlParams)
          }
        />
        <TbDashTable
          valueMapper={overviewValueMapper}
          params={urlParams}
          onSortClick={(sort, sortOrder) =>
            handleSortClick(sort, sortOrder, window, setUrlParams)
          }
          onScrollToEnd={handleScrollToEnd}
          loading={overview.getting}
          color='#8356ac'
          rows={rows}
          columns={columns.filter(c => !c.hidden)}
          onClickRow={row => handleClickRow(row, dispatch, setUrlParams)}
        />
        {showLinkDialog && (
          <>
            <LinkDialogRobot
              color='#1d6494'
              open={showLinkDialog === 'robot'}
              dispatch={dispatch}
              afterSubmit={() => {
                dispatch(clearStateDetailed())
                dispatch(
                  getOverview({
                    loadMore: false,
                    urlParams: getOverviewQueryParams(urlParams)
                  })
                )
              }}
              handleClose={handleCloseLinkDialog}
            />
            <LinkDialogSub
              color='#1d6494'
              open={showLinkDialog === 'sub'}
              dispatch={dispatch}
              afterSubmit={() => {
                dispatch(clearStateDetailed())
                dispatch(
                  getOverview({
                    loadMore: false,
                    urlParams: getOverviewQueryParams(urlParams)
                  })
                )
              }}
              handleClose={handleCloseLinkDialog}
            />
            <LinkDialogTaas
              color='#1d6494'
              open={showLinkDialog === 'taas'}
              dispatch={dispatch}
              afterSubmit={() => {
                dispatch(clearStateDetailed())
                dispatch(
                  getOverview({
                    loadMore: false,
                    urlParams: getOverviewQueryParams(urlParams)
                  })
                )
              }}
              handleClose={handleCloseLinkDialog}
            />
            <LinkDialogTaasRobot
              color='#1d6494'
              open={showLinkDialog === 'taasRobot'}
              dispatch={dispatch}
              afterSubmit={() => {
                dispatch(clearStateDetailed())
                dispatch(
                  getOverview({
                    loadMore: false,
                    urlParams: getOverviewQueryParams(urlParams)
                  })
                )
              }}
              handleClose={handleCloseLinkDialog}
            />
          </>
        )}
        {showDetailed && (
          <OverviewDetailedDialog
            onTabChange={(tab: Tabs) => changeDetailedTab(setUrlParams, tab)}
            color='#8356ac'
            open={showDetailed}
            dispatch={dispatch}
            handleClose={handleCloseDetailed}
            afterSubmit={() => {
              setShowSavedDialog(true)
              dispatch(
                getOverview({
                  loadMore: false,
                  urlParams: getOverviewQueryParams(urlParams)
                })
              )
            }}
          />
        )}
        <TbDashWarnDialog
          open={showUnsavedWarn}
          message='Do you really want to close? You have unsaved changes.'
          submitButton='CLOSE'
          handleSubmit={() => {
            dispatch(toggleUnsavedWarn({ show: false }))
            dispatch(toggleDetailed({ show: false, force: true }))
            clearDetailedParams(setUrlParams)
          }}
          handleClose={() => {
            dispatch(toggleUnsavedWarn({ show: false }))
          }}
        />
        {
          savedMessage
        }
        <TbDashErrorDialog
          message={errorMessage}
          open={!!errorMessage}
          handleClose={() => {
            clearErrors()
          }}
        />
        <ExtendSubscription
          color='#683166'
          onClose={handleCloseExtend}
          afterSubmit={() =>
            dispatch(
              getOverview({
                loadMore: false,
                urlParams: getOverviewQueryParams(urlParams)
              })
            )
          }
        />
        <TbDashOutdatedSnackbar
          outdated={overview.outdated}
          action={() => {
            dispatch(
              getOverview({
                loadMore: false,
                urlParams: getOverviewQueryParams(urlParams)
              })
            )
          }}
        />
      </div>
    )
  },
  (prevProps: any, nextProps: any) => {
    const equal = Object.keys(nextProps).reduce((equal, key) => {
      if (key === 'detailed') return true
      if (!shallowEqual(prevProps[key], nextProps[key])) {
        return false
      }
      return equal
    }, true)
    return equal
  }
)

const ConnectedOverview = (
  props: Pick<OverviewProps, '$http' | 'StoreService'>
) => {
  const overview = useSelector<RootState, OverviewState>(
    ({ overview }) => overview
  )
  const rows = useSelector<RootState, OverviewItemRow[]>(
    ({ overview: { ids, entities } }) => ids.map(id => entities[id])
  )
  const unsaved = useSelector<RootState, boolean>(
    ({ detailed }) => hasUnsavedChanges(detailed)
  )
  const columns = useSelector<RootState, OverviewColumn[]>(
    ({
      overview: {
        columns: { ids, entities }
      }
    }) => {
      const columns = ids.map(id => entities[id])
      return columns
    }
  )
  const showDetailed = useSelector<RootState, boolean>(
    ({ detailed: { showDetailed } }) => showDetailed
  )
  const showLinkDialog = useSelector<
    RootState,
    'robot' | 'sub' | 'taas' | 'taasRobot' | null
  >(({ detailed: { showLinkDialog } }) => showLinkDialog)
  const showUnsavedWarn = useSelector<RootState, boolean>(
    ({ detailed: { showUnsavedWarn } }) => showUnsavedWarn
  )
  const errorMessage = useSelector<RootState, null | string>(
    ({ detailed, overview }) =>
      detailed.getError ??
      detailed.patchError ??
      overview.getError ??
      overview.patchError
  )

  const dispatch = useDispatch<typeof props.StoreService.store.dispatch>()
  return (
    <Overview
      {...props}
      showLinkDialog={showLinkDialog}
      showDetailed={showDetailed}
      showUnsavedWarn={showUnsavedWarn}
      errorMessage={errorMessage}
      overview={overview}
      unsaved={unsaved}
      rows={rows}
      columns={columns}
      dispatch={dispatch}
    />
  )
}

const OverviewWrapper: React.FunctionComponent<Pick<
  OverviewProps,
  '$http' | 'StoreService'
>> = (props: Pick<OverviewProps, '$http' | 'StoreService'>) => {
  return (
    <ThemeProvider theme={overviewTheme}>
      <Provider store={props.StoreService.store}>
        <ConnectedOverview {...props} />
      </Provider>
    </ThemeProvider>
  )
}

export default OverviewWrapper
