import React from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { Address } from '../../types/Address'
import { Contact } from '../../types/Contact'

export const OrderReturner = ({
  returner,
  pickupAddress
}: {
  returner?: Contact
  pickupAddress?: Address
}) => (
  <>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='Returner email'
        value={returner?.email ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Tel. nr.'
        value={returner?.phoneNumber ?? '-'}
      />
    </TbDashDetailedItemRow>
    <TbDashDetailedItemRow>
      <TbDashDetailedItem
        type='text'
        disabled
        label='First name'
        value={returner?.firstname ?? '-'}
      />
      <TbDashDetailedItem
        type='text'
        disabled
        label='Last name'
        value={returner?.lastname ?? '-'}
      />
    </TbDashDetailedItemRow>
    {pickupAddress && (
      <>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='text'
            disabled
            label='Returner address'
            value={pickupAddress?.street ?? '-'}
          />
          <TbDashDetailedItem
            type='text'
            disabled
            label='House no. + extension'
            value={
              pickupAddress.homeNumber
                ? pickupAddress.homeNumber +
                  ' ' +
                  (pickupAddress.homeNumberExtension ?? '')
                : '-'
            }
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='text'
            disabled
            label='Zipcode'
            value={pickupAddress?.zipcode ?? '-'}
          />
          <TbDashDetailedItem
            type='text'
            disabled
            label='City'
            value={pickupAddress?.city ?? '-'}
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            type='text'
            disabled
            label='Location description'
            value={pickupAddress?.locationDescription ?? '-'}
          />
          <TbDashDetailedItem
            type='text'
            disabled
            label='Country'
            value={pickupAddress?.country ?? '-'}
          />
        </TbDashDetailedItemRow>
        <TbDashDetailedItemRow>
          <TbDashDetailedItem
            xs={12}
            type='text'
            disabled
            label='Return address contact (recipient)'
            value={pickupAddress?.recipient ?? '-'}
          />
        </TbDashDetailedItemRow>
      </>
    )}
  </>
)
