import { ButtonBase } from '@material-ui/core'
import moment from 'moment'
import React, { ReactElement, MouseEventHandler } from 'react'
import { useDispatch } from 'react-redux'
import { TbTableRowData } from 'tinybots-react-components'
import { toggleLinkDialog } from './Detailed/redux/reducer'
import { getDetailed } from './Detailed/redux/thunks'
import { isCustomer, getTaasRef, isShippable, getRobotRef } from './stringTransformers'
import {
  OverviewItemRow,
  OverviewColumnKey,
  OverviewItem,
  SubscriptionStatus,
  Tabs
} from './types/types'

export const getStatusColor = (status: SubscriptionStatus) => {
  let color = '#333333'
  switch (status) {
    case 'expiring':
      color = 'orange'
      break
    case 'canceled':
      color = '#a4a4a4'
      break
    case 'expired':
      color = 'red'
      break
    default:
      break
  }
  return color
}

export const useOverviewValueMapper = (openTab: (tab: Tabs, row: TbTableRowData) => void) => ({
  row,
  valueKey,
  classNameButton
}: {
  row: TbTableRowData
  valueKey: string
  classNameButton?: string
}) => {
  const dispatch = useDispatch()
  const inputValue = row[valueKey as keyof OverviewItemRow]
  const stringOrNumber =
    typeof inputValue === 'string' || typeof inputValue === 'number'
  let value: ReactElement | string | number
  let tooltipValue: ReactElement | string | number
  let tooltip = true
  const color = getStatusColor(row.status)
  const style = {
    color: value === 'N/A' ? 'rgb(163 163 163)' : color,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    lineHeight: '36px',
    fontFamily: value === 'N/A' ? 'Roboto-Mono-Light' : 'inherit'
  }
  const linkRobotWithSub: MouseEventHandler<any> = e => {
    e.stopPropagation()
    dispatch(
      getDetailed({
        serialId: row.robotSerialId,
        chainId: row.chainId,
        taasId: row.taasId
      })
    )
    dispatch(toggleLinkDialog({ show: row.taasRobot ? 'taasRobot' : 'robot' }))
  }
  const linkSubWithRobot: MouseEventHandler<any> = e => {
    e.stopPropagation()
    dispatch(
      getDetailed({
        serialId: row.robotSerialId,
        chainId: row.chainId,
        taasId: row.taasId
      })
    )
    dispatch(toggleLinkDialog({ show: row.taasId ? 'taas' : 'sub' }))
  }
  if (
    inputValue &&
    ['date', 'At'].some(el => (valueKey as OverviewColumnKey).includes(el))
  ) {
    value = moment(inputValue as string).format('DD-MM-YYYY')
  } else if (!inputValue && (valueKey as OverviewColumnKey) === 'serial') {
    value = (
      <ButtonBase className={classNameButton ?? ''} onClick={linkSubWithRobot}>
        LINK ROBOT
      </ButtonBase>
    )
    tooltip = false
  } else if ((valueKey as OverviewColumnKey) === 'serial') {
    tooltipValue = inputValue
    value = inputValue ? (
      <ButtonBase style={{padding: '6px 0px', ...style}} onClick={() => openTab('robot', row)}>
        {inputValue}
      </ButtonBase>
    ) : (
      '-'
    )
  } else if (!inputValue && (valueKey as OverviewColumnKey) === 'subRef') {
    const isCustomer = row.relation && row.isCustomer
    const shippable = row.shippable
    const disabled = !shippable || isCustomer
    value = (
      <ButtonBase
        className={classNameButton ?? ''}
        disabled={disabled}
        onClick={linkRobotWithSub}
      >
        LINK
      </ButtonBase>
    )
    tooltip = false
  } else if ((valueKey as OverviewColumnKey) === 'subRef') {
    tooltipValue = inputValue
    value = inputValue ? (
      <ButtonBase style={{padding: '6px 0px', ...style}} onClick={() => openTab('sub', row)}>{inputValue}</ButtonBase>
    ) : inputValue
  } else {
    value = stringOrNumber ? inputValue : '-'
  }
  value = (
    <span
      style={style}
    >
      {value}
    </span>
  )
  return { value, tooltip, tooltipValue }
}

export const mapOverviewItemsToTable = (
  items: OverviewItem[]
): OverviewItemRow[] => {
  return items.map(({ relation, subscription, taas, robot }) => {
    const key =
      (subscription?.subscriptionId ? `S-${subscription.subscriptionId}` : '') +
      (subscription?.chainId ? `C-${subscription?.chainId}` : '') +
      (taas ? `T-${taas.taasId}` : '') +
      (robot ? `R-${robot.serial}` : '')
    let row: OverviewItemRow = { key }
    if (relation) {
      row.relationType = relation.relationType
      row.relation = relation.name
      row.isCustomer = isCustomer(relation)
    }
    if (subscription) {
      row.subRef = `${subscription.chainId}${
        subscription.subscriptionId ? `-${subscription.subscriptionId}` : ``
      }`
      row.status = subscription.status
      row.startAt = subscription.startAt
      row.endAt = subscription.endAt
      row.length = subscription.subscriptionLength
      row.subscriptionPaymentReference = subscription.paymentReference
      row.subNotes = subscription.notes
      row.chainId = subscription.chainId
    } else if (taas) {
      row.status = taas.status
      row.subRef = getTaasRef(taas)
      row.startAt = taas.startAt
      row.endAt = taas.endAt
      row.clientId = taas.clientId
      row.teamId = taas.teamId
      row.subNotes = taas.notes
      row.taasId = taas.taasId
    }
    if (robot) {
      row.iccId = robot.iccId
      row.robotNotes = robot.notes
      row.shippable = isShippable(robot)
      row.boxNr = robot.boxNumber
      row.serial = getRobotRef(robot)
      row.robotSerialId = robot.serialId
      row.taasRobot = !!robot.taas
      row.robotPaymentReference = robot.paymentReference
    }
    if(subscription || (robot && !robot.taas)) {
      row.teamId = 'N/A'
      row.clientId = 'N/A'
    }
    return row
  })
}
