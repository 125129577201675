/**
 * @file
 * Form for changing general order values
 * @author Max van Loosbroek
 */

import React, { useCallback } from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { useRelationItem } from './useRelationItem'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../common/redux/root.reducer'
import { AppDispatch } from '../../../../../../common/redux/store'
import { relationsSearchSelector } from '../../../../../overview/Detailed/redux/selectors'
import { RelationBase } from '../../../../../overview/types/RelationBase'
import { OrderConcept } from '../../types/OrderConcept'

const useChangeHandlers = (
  onChangeForm: (changes: Partial<OrderConcept>) => void
) => {
  const onChangeClientId = useCallback((value: string) => {
    onChangeForm({
      clientId: value
    })
  }, [])
  const onChangeUuid = useCallback((value: string) => {
    onChangeForm({
      clientUuid: value
    })
  }, [])
  const onChangeTeamId = useCallback((value: string) => {
    onChangeForm({
      teamId: value
    })
  }, [])
  const onChangeDiscipline = useCallback((value: string) => {
    onChangeForm({
      discipline: value
    })
  }, [])
  const onChangeHealthCareDemand = useCallback((value: string) => {
    onChangeForm({
      healthCareDemand: value
    })
  }, [])
  const onChangeTessaExpertRequested = useCallback((value: string) => {
    onChangeForm({
      tessaExpertNeeded: value
    })
  }, [])
  const onChangeInternalNotes = useCallback((value: string) => {
    onChangeForm({
      internalNotes: value
    })
  }, [])
  return {
    onChangeClientId,
    onChangeUuid,
    onChangeDiscipline,
    onChangeTeamId,
    onChangeHealthCareDemand,
    onChangeTessaExpertRequested,
    onChangeInternalNotes
  }
}

const useErrors = (
  showErrors: boolean
): { [key: string]: (value?: string | number | boolean) => string } => {
  const requiredError = 'This field is required'
  const generalEmptyError = useCallback(
    (value: string) => (value.length < 1 && showErrors ? requiredError : ''),
    [showErrors]
  )
  const clientIdError = generalEmptyError
  const teamIdError = generalEmptyError
  return {
    clientIdError,
    teamIdError
  }
}

export interface GeneralConceptOrderFormComponentProps {
  relationsSearch: RelationBase[]
  orderConcept?: OrderConcept
  onChangeForm: (changes: Partial<OrderConcept>) => void
  showErrors: boolean
  dispatch: AppDispatch
}

export const GeneralConceptOrderFormComponent = ({
  orderConcept,
  onChangeForm,
  showErrors,
  dispatch,
  relationsSearch
}: GeneralConceptOrderFormComponentProps) => {
  const { clientIdError, teamIdError } = useErrors(showErrors)
  const relationItem = useRelationItem({
    orderConcept,
    onChangeForm,
    showErrors,
    dispatch,
    relationsSearch
  })
  const {
    onChangeClientId,
    onChangeUuid,
    onChangeDiscipline,
    onChangeTeamId,
    onChangeHealthCareDemand,
    onChangeTessaExpertRequested,
    onChangeInternalNotes
  } = useChangeHandlers(onChangeForm)
  return (
    <>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          disabled
          type='text'
          label='Source'
          iconUrl='assets/TB_DB_Icon_SOURCE.svg'
          value={orderConcept?.integration?.name ?? 'Tinybots'}
        />
      </TbDashDetailedItemRow>
      {relationItem}
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='text'
          label='Client id*'
          hasError={clientIdError}
          iconUrl='assets/person.svg'
          value={orderConcept?.clientId ?? ''}
          onChange={onChangeClientId}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='text'
          label='Universally unique id (UUID)'
          iconUrl='assets/TB-DB_icon_UUID.svg'
          value={orderConcept?.clientUuid ?? ''}
          onChange={onChangeUuid}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='text'
          label='Team id*'
          hasError={teamIdError}
          iconUrl='assets/people_black_24dp.svg'
          value={orderConcept?.teamId ?? ''}
          onChange={onChangeTeamId}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='text'
          label='Discipline'
          iconUrl='assets/TB-DB_icon_medical_services_black_24dp.svg'
          value={orderConcept?.discipline ?? ''}
          onChange={onChangeDiscipline}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='text'
          label='Healthcare demand(s)'
          iconUrl='assets/TB-DB_icon_medical_information_black_24dp.svg'
          value={orderConcept?.healthCareDemand ?? ''}
          onChange={onChangeHealthCareDemand}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          type='select'
          label='Tessa Expert requested?*'
          iconUrl='assets/TB-DB_icon_Tessa_Expert.svg'
          options={[
            { label: 'Yes', key: 'yes', disabled: false },
            { label: 'No', key: 'no', disabled: false },
            { label: 'Unknown', key: 'unknown', disabled: false }
          ]}
          value={orderConcept?.tessaExpertNeeded ?? 'no'}
          onChange={onChangeTessaExpertRequested}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='textArea'
          label='Internal notes'
          textAreaAutoSize
          iconUrl='assets/TB-APP_Icon_Editing.svg'
          value={orderConcept?.internalNotes}
          onChange={onChangeInternalNotes}
        />
      </TbDashDetailedItemRow>
    </>
  )
}

export const GeneralConceptOrderForm = (
  props: Omit<GeneralConceptOrderFormComponentProps, 'relationsSearch'>
) => {
  const relationsSearch = useSelector((state: RootState) =>
    relationsSearchSelector(state.detailed)
  )
  return (
    <GeneralConceptOrderFormComponent
      {...props}
      relationsSearch={relationsSearch}
    />
  )
}
