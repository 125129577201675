import template from './login-mfa.html';

const login = {
    bindings: {
        $transition$: '<'
    },
    template,
    controller: /* @ngInject */ class MfaLoginController {
        constructor(FIELDSETS, $auth, AuthService, $state) {
            this.fieldsets = FIELDSETS;
            this.$auth = $auth;
            this.AuthService = AuthService;
            this.$state = $state;
        }
        $onInit() {
            this.successfulSignUp = this.$transition$.params().successfulSignUp;

            this.user = {
                email: '',
                password: '',
                otpCode: ''
            };
        }
    }

};

export default login;