import template from './tb-nav.html'
import './tb-nav.scss'

const tbNav = {
  bindings: {
      user: '<',
      organisation: '<',
      searchTerm: '<',
      onLogout: '&',
      onSearch: '&'
  },
  template,
  controller: /* @ngInject */ class TbNavController {
    constructor ($state) {
      this.$state = $state
    }

    toggleDropdown () {
      this.dropdownVisible = !this.dropdownVisible
    }

    hideDropdown () {
      this.dropdownVisible = false
    }

    showDropdown () {
      this.dropdownVisible = true
    }

    logout () {
      this.onLogout({ $event: {} })
    }

    search () {
      this.term && this.onSearch({ $event: { term: this.term } })
      this.term = null
    }

    $onInit () {
      this.hideDropdown()
      this.relationNumber = null
    }
  }
}

export default tbNav
