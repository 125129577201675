import * as angular from "angular";
declare var ENV_API: any

const getUrlOrigin = () => {
    const locOrigin = window.location.origin;
    return ['localhost', 'academy', '127.0.0.1'].includes(locOrigin) ? 'https://api.tinybots.academy' : 'https://api.tinybots.io';
};

const getApiUrl = () => typeof ENV_API !== 'undefined' ? ENV_API : getUrlOrigin();

export const
    API_URL = getApiUrl(),
    DASHBOARD_URL = `https://dashboard.tinybots.${API_URL.includes('academy') ? 'academy' : 'io'}`,
    DASHADMIN_URL = `https://dashadmin.tinybots.${API_URL.includes('academy') ? 'academy' : 'io'}`,
    URLS = {
        wonkers: {
            login: `${API_URL}/v3/admin/accounts/login`,
            signup: `${API_URL}/v1/dashboard/accounts`,
            checkregistrationkey: `${API_URL}/v1/dashboard/accounts/create?key={key}`,
            getcustomeraccount: `${API_URL}/v1/dashboard/accounts/self`,
            forgotpassword: `${API_URL}/v1/dashboard/accounts/forgotten`,
            resetpassword: `${API_URL}/v1/dashboard/accounts/reset`,
            subscripitions: `${API_URL}/v1/dashboard/accounts/self/subscriptions`,
            robotsonline: `${API_URL}/v1/dashboard/accounts/self/online`,
            refreshtoken: `${API_URL}/v3/admin/accounts/token`,
            setMfa: `${API_URL}/v3/admin/accounts/mfa`,
            checkPassword: `${API_URL}/v2/dashboard/accounts/verifypassword`,
            updatesubscriptionfield: `${API_URL}/v1/dashboard/accounts/robots`,
            activateaccount: `${API_URL}/v1/dashboard/accounts/activate?key={key}`
        },
        prowl: {
            passwordanalysis: `${API_URL}/v1/passwords/analysis`
        },
        admin: {
            taas: `${API_URL}/v3/admin/taas`,
            accounts: `${API_URL}/v3/admin/accounts`,
            taasOrders: `${API_URL}/v3/admin/taas-orders`,
            taasOrdersV4: `${API_URL}/v4/admin/taas-orders`,
            taasOrdersV5: `${API_URL}/v5/admin/taas-orders`,
            organisationAdminAccounts: `${API_URL}/v3/admin/accounts/relations/{relationId}`,
            taasOrderConcepts: `${API_URL}/v4/admin/taas-orders/concepts/orders`,
            taasReturnConcepts: `${API_URL}/v4/admin/taas-orders/concepts/returns`,
            retrieveConcepts: `${API_URL}/v4/admin/taas-orders/concepts/retrieve`,
            linkTaas: `${API_URL}/v3/admin/taas/{taasId}/link`,
            invite: `${API_URL}/v2/admin/invite`,
            getodooid: `${API_URL}/v1/dashboard/admin/odooPartner/{relationNumber}`,
            robots: `${API_URL}/v2/admin/robots`,
            robotsV3: `${API_URL}/v3/admin/robots`,
            subscriptions: `${API_URL}/v2/admin/subscriptions`,
            chains: `${API_URL}/v2/admin/chains`,
            relations: `${API_URL}/v2/admin/relations`,
            dashTransfer: `${API_URL}/v3/admin/transfer`,
            overview: `${API_URL}/v2/admin/overview`,
            overviewV3: `${API_URL}/v3/admin/overview`,
            link: `${API_URL}/v2/admin/link`,
            health: `${API_URL}/v1/health`,
            companies: `${API_URL}/v3/admin/relations/companies`,
            graphql: `${API_URL}/v4/dashboard/graphql`,
            s3robotlogs: `https://s3.console.aws.amazon.com/s3/object/dull-bookworm.tinybots.${API_URL.includes('academy') ? 'academy' : 'io'}`
        }
    },
    FIELDSETS = {
        sections:{
            login:{
                title: [
                    "Admin dashboard"
                ],
                pop_up: {
                    title: "",
                    message: "",
                    tips: [
                        {
                            title: "",
                            text: ""
                        }
                    ],
                    main_button: "",
                    secondary_button: ""
                }
            },
            signup_password: {
                pop_up : {
                    title : "Let op! Uw opgegeven wachtwoord is zwak",
                    message : "Dit betekent dat uw account gemakkelijk is te hacken. Enkele tips om uw wachtwoord sterker te maken:",
                    tips : [
                        {
                            title : "Maak het wachtwoord langer:",
                            text : "dit-is-een-sterk-wachtwoord"
                        },
                        {
                            title : "Gebruik hoofdletters en speciale tekens:",
                            text : "dit-is-een-STERKER-wachtwoord!"
                        }
                    ],
                    main_button : "ACCOUNT MAKEN",
                    secondary_button : "AANPASSEN"
                }
            },
            signup_password_error: {
                pop_up : {
                    title : "Let op! Dit e-mail adres is al geregistreerd",
                    message : "Het gaat om het volgende e-mail adres:",
                    tips : [
                        {
                            title : "Dit betekend dat u al een account bij ons heeft. U kunt met dit e-mail adres inloggen of een nieuw account aanmaken met een ander e-mail adres.",
                            text : ""
                        }
                    ],
                    main_button : "INLOGGEN",
                    secondary_button : "E-MAIL AANPASSEN"
                }
            },
            reset_password: {
                title: "",
                pop_up : {
                    title : "Let op! Uw opgegeven wachtwoord is zwak",
                    message : "Dit betekent dat uw account gemakkelijk is te hacken. Enkele tips om uw wachtwoord sterker te maken:",
                    tips : [
                        {
                            title : "Maak het wachtwoord langer:",
                            text : "dit-is-een-sterk-wachtwoord"
                        },
                        {
                            title : "Gebruik hoofdletters en speciale tekens:",
                            text : "dit-is-een-STERKER-wachtwoord!"
                        }
                    ],
                    main_button : "WACHTWOORD WIJZIGEN",
                    secondary_button : "AANPASSEN"
                }
            },
            forgot_password_success: {
                title: "We hebben u zojuist een <br> e-mail gestuurd",
                text: "In deze e-mail vindt u een link om uw wachtwoord te herstellen. Heeft u niets ontvangen? <br> Kijk dan in uw spam folder.",
                button: {
                    text: "Terug naar de app",
                    url: "auth.login"
                },
                navigationUrl: ""
            },
            reset_password_success: {
                title: "Uw wachtwoord is gewijzigd!",
                text: "Vanaf nu kunt u met uw e-mail adres en uw nieuwe wachtwoord inloggen.",
                button: {
                    text: "Inloggen",
                    url: "auth.login"
                },
                navigationUrl: ""
            },
            terms: {
                title: "Algemene voorwaarden",
                text: `<p class="tb-text">Om gebruik te maken van deze app hebben wij uw toestemming nodig.
                                          Lees de voorwaarden goed door zodat u weet waar u aan toe bent.</p>`,
                textAdditional: `    <ul>
                                        <li>Deze app maakt gebruik van cookies</li>
                                        <li>Om u zo goed mogelijk te ondersteunen vragen wij om enkele persoonsgegevens, wij zullen hier uiteraard veilig mee omgaan en niet verstrekken aan derden</li>
                                        <li>Wij zijn niet aansprakelijk voor enige schaden ten gevolge van het gebruik van Tessa. </li>
                                        <li>Wij behouden ons het recht voor deze gebruiksvoorwaarden te wijzigen. </li>
                                    </ul>`,
                navigationUrl: "auth.signup.contacts"
            },
            account_activation: {
                title: "Uw e-mail adres is <br> bevestigd",
                text: "Uw  account is succesvol geactiveerd. U kunt nu door naar het dashboard voor het overzicht van uw Tessa's.",
                button: {
                    text: "Naar de app",
                    url: "app"
                }
            }
        },
        form: {
            email: ""

        },
        links: {
            forgot_password: "Ik ben mijn wachtwoord vergeten"
        },
        regexes: {
            names: "^[\\p{L} .\"-]+$",
            email: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.\\-_]+\\.[a-zA-Z]{2,8}$",
            phone: "^0[1-9]{1}[0-9]{8}$",
            password: "^(?=.*[A-Z])(?=.*[\\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$",
            cpu: "^[\\w]{4}$",
            time: "^([0-9]{2})$",
            timepicker: "^(0[6-9]|1[0-9]|2[0-3]):([0-5][0-9])$",
            key: "^[\\w]{32}$",
            snumeric: "^[0-9]{2}$"
        }


    },
    PREMIUM_PACKAGES = [{
        displayName: 'Robot Premium',
        value: 'ROBOT'
      }, {
        displayName: 'Beheer Premium',
        value: 'DASHBOARD'
      }, {
        displayName: 'Pro-version',
        value: 'PRO'
      }, {
        displayName: 'None',
        value: null
      }],
    SERVICE_PACKAGES = [{
        displayName: 'Basic',
        value: 'BASIC'
      }, {
        displayName: 'TaaS',
        value: 'TAAS'
      }]


export default angular
    .module('constants', [])
    .constant('API_URL', API_URL)
    .constant('URLS', URLS)
    .constant('FIELDSETS', FIELDSETS)
    .constant('DASHBOARD_URL', DASHBOARD_URL)
    .constant('PREMIUM_PACKAGES', PREMIUM_PACKAGES)
    .constant('SERVICE_PACKAGES', SERVICE_PACKAGES)
    .name;
