/**
 * @file Dialog to display warnings, asking to continue or cancel
 * @author Max van Loosbroek
 */

import {
  createStyles,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField
} from '@material-ui/core'
import React from 'react'
import { TbDashDialog } from 'tinybots-react-components'
import { TbDialogProps } from 'tinybots-react-components/lib/components/DashboardComponents/TbDialog'
import { useState } from 'react'
import { useEffect } from 'react';

export type TbConfirmDeleteDialogProps = {
  title?: string,
  salesOrderLink?: string
} & Pick<
  TbDialogProps,
  'open' | 'handleClose' | 'submitButton' | 'closeButton'
> & { handleSubmit: (value: string) => void }

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      display: 'flex',
      paddingTop: 7
    },
    content: {
      padding: 0,
      fontFamily: 'Montserrat'
    },
    title: {
      padding: 0,
      marginBottom: 10,
      marginTop: 0,
      '& .MuiTypography-h6': {
        fontFamily: 'Montserrat',
        fontSize: '28px',
        color: 'red'
      }
    }
  })
})

export const TbConfirmDeleteDialog = ({
  open,
  handleSubmit,
  handleClose,
  closeButton,
  salesOrderLink
}: TbConfirmDeleteDialogProps) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  useEffect(() => {
    if(open) {
      setValue('')
    }
  }, [open])
  const viewSalesOrder = () => {
    handleClose({})
    window.location.href = location.origin + salesOrderLink
  }

  return salesOrderLink ? (
    <TbDashDialog
      color='rgb(218, 71, 37)'
      open={open}
      closeButton={'CANCEL'}
      handleClose={handleClose}
      submitButton={'VIEW SALES ORDER'}
      handleSubmit={viewSalesOrder}
    >
      <DialogTitle className={classes.title}>
        Delete TaaS subscription
      </DialogTitle>
      <DialogContent className={classes.content}>
        <p>Deletion of TaaS subscription is not allowed if it is generated from a Sales Order.</p>
        <p>Go to Sales Order to decline the order. This will remove the taas subscription too.</p>
        <br></br>
        <br></br>
      </DialogContent>
    </TbDashDialog>
  ): (
    <TbDashDialog
      color='rgb(218, 71, 37)'
      open={open}
      closeButton={closeButton ?? 'CANCEL'}
      handleClose={handleClose}
      submitButton={'DELETE'}
      handleSubmit={() => handleSubmit(value)}
    >
      <DialogTitle className={classes.title}>
        Delete TaaS subscription
      </DialogTitle>
      <DialogContent className={classes.content}>
        <p>Are you sure that you want to delete the TaaS subscription?</p>
        <TextField
          aria-label={`Delete TaaS Confirm Input`}
          placeholder={'Type "DELETE TAAS"'}
          helperText={'Type "DELETE TAAS"'}
          classes={{ root: classes.root }}
          value={(value as string) ?? ''}
          onChange={e => setValue?.(e.target.value)}
        />
      </DialogContent>
    </TbDashDialog>
  )
}
