import React from 'react'
import {
  TbDashDetailedItemRow,
  TbDashDetailedItem
} from 'tinybots-react-components'
import { OrderFinal, OrderFinalV5 } from '../../../../types/OrderFinal'
import { ReturnForm } from '../../types/ReturnForm'

export interface NotesFormProps {
  salesOrder: OrderFinalV5
  returnForm: Partial<ReturnForm>
  onChangeForm: (changes: Partial<ReturnForm>) => void
}
export const NotesForm = ({
  salesOrder,
  onChangeForm,
  returnForm
}: NotesFormProps) => {
  return (
    <>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='console'
          disabled
          label='Sales order notes (ordering)'
          value={salesOrder?.notes}
        />
      </TbDashDetailedItemRow>
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          xs={12}
          type='textArea'
          textAreaAutoSize
          label='Sales order notes (returning)'
          value={returnForm?.notes ?? ''}
          onChange={(value: string) =>
            onChangeForm({
              notes: value
            })
          }
        />
      </TbDashDetailedItemRow>
    </>
  )
}
