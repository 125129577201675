import {
  makeStyles,
  createStyles,
  ThemeProvider,
  createTheme
} from '@material-ui/core'
import fastDeepEqual from 'fast-deep-equal'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { shallowEqual, useSelector, useDispatch, Provider } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import {
  TbDashDialog,
  TbDashErrorDialog,
  TbDashTable,
  TbDashWarnDialog
} from 'tinybots-react-components'
import { usePrevious } from 'tinybots-react-components/lib/components/hooks'
import { RootState } from '../../../../common/redux/root.reducer'
import { AppDispatch } from '../../../../common/redux/store'
import StoreService from '../../../../common/redux/store.service'
import { groupParamsByKey } from '../../../../common/utilities/groupParamsByKey'
import { getOrderDetailed } from '../../detailed/redux/thunks'
import {
  OrderOverviewState,
  OrderFinalRow,
  OrderOverviewColumn,
  OrderOverviewQueryParams,
  OrderTabs
} from '../../types/types'
import { OrderOverviewHeader } from '../OrderOverviewHeader/OrderOverviewHeader'
import { clearState, clearErrorsOrderOverview, clearRetrieveResult } from '../redux/reducer'
import { getOrderOverview } from '../redux/thunks'
import { useOrderOverviewValueMapper } from '../tableMappers'
import OrderDetailedDialog from '../../detailed/OrderDetailed/OrderDetailedDialog'
import {
  clearErrorsOrdersDetailed,
  clearStateOrdersDetailed,
  toggleOrdersDetailed,
  toggleUnsavedWarnOrders
} from '../../detailed/redux/reducer'
import LinkDialogTaas from '../../../overview/Detailed/LinkDialog/Taas/LinkDialogTaas'
import {
  clearStateDetailed,
  toggleLinkDialog
} from '../../../overview/Detailed/redux/reducer'
import { hasStateChanges } from '../../detailed/redux/selectors'
import useSavedMessage from '../../../../common/hooks/useSavedMessage'
import CreateOrderDialog from '../../detailed/CreateOrder/CreateOrderDialog'
import CreateReturnDialog from '../../detailed/CreateReturn/CreateReturnDialog'
import { OrderOverviewSpeedDial } from '../SpeedDial/OrderOverviewSpeedDial'
import { clearStateOrderConcept } from '../../detailed/CreateOrder/redux/reducer'
import { clearStateReturnConcept } from '../../detailed/CreateReturn/redux/reducer'

export const orderOverviewTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ba9a1b'
    }
  }
})

export interface OrderOverviewProps {
  $http: any
  StoreService: StoreService
  orderOverview: OrderOverviewState
  rows: OrderFinalRow[]
  columns: OrderOverviewColumn[]
  showDetailed: boolean
  showLinkDialog: 'robot' | 'sub' | 'taas' | 'taasRobot' | null
  errorMessage: null | string
  showUnsavedWarn: boolean
  dispatch: AppDispatch
  detailedId: number
  unsavedDetailed?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  })
)

export const handleSortClick = (
  sortBy: string,
  sortOrder: 'asc' | 'desc',
  browserWindow: any = window,
  setUrlParams: (params: OrderOverviewQueryParams) => void
) => {
  const currentUrlParams = new URLSearchParams(window.location.search)
  currentUrlParams.set('sortBy', sortBy)
  currentUrlParams.set('sortOrder', sortOrder)
  setUrlParams(
    groupParamsByKey(currentUrlParams as any) as OrderOverviewQueryParams
  )
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

const openOrder = (
  orderId: number,
  dispatch: AppDispatch,
  setUrlParams: (params: OrderOverviewQueryParams) => void,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(browserWindow.location.search)
  if (orderId) currentUrlParams.set('orderId', orderId.toString())
  setUrlParams(
    groupParamsByKey(currentUrlParams as any) as OrderOverviewQueryParams
  )
  dispatch(clearStateOrdersDetailed())
  dispatch(clearStateDetailed())
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const handleClickRow = (
  row: OrderFinalRow,
  dispatch: AppDispatch,
  setUrlParams: (params: OrderOverviewQueryParams) => void,
  browserWindow: any = window,
  _tab?: OrderTabs
) => {
  const { orderId } = row
  openOrder(
    orderId,
    dispatch,
    setUrlParams,
    browserWindow)
}

export const handleSearch = (
  {
    searchTerm,
    searchType
  }: {
    searchTerm: string
    searchType: string
  },
  setUrlParams: (params: OrderOverviewQueryParams) => void,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(window.location.search)
  currentUrlParams.set('searchTerm', searchTerm ?? '')
  currentUrlParams.set('searchType', searchType)
  setUrlParams(groupParamsByKey(currentUrlParams))
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}


export const handleAction = (
  action: 'create' | 'return', 
  setUrlParams: (params: OrderOverviewQueryParams) => void,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(window.location.search)
  currentUrlParams.set('action', action)
  setUrlParams(groupParamsByKey(currentUrlParams))
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const changeDetailedTab = (
  setUrlParams: (params: OrderOverviewQueryParams) => void,
  tab?: OrderTabs,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(browserWindow.location.search)
  if (tab) {
    currentUrlParams.set('tab', tab)
  }
  setUrlParams(
    groupParamsByKey(currentUrlParams as any) as OrderOverviewQueryParams
  )
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const clearOrdersDetailedParams = (
  setUrlParams: (params: OrderOverviewQueryParams) => void,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(browserWindow.location.search)
  currentUrlParams.delete('orderId')
  currentUrlParams.delete('tab')
  setUrlParams(
    groupParamsByKey(currentUrlParams as any) as OrderOverviewQueryParams
  )
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const clearConceptDetailedParams = (
  setUrlParams: (params: OrderOverviewQueryParams) => void,
  browserWindow: any = window
) => {
  const currentUrlParams = new URLSearchParams(browserWindow.location.search)
  currentUrlParams.delete('returnConceptId')
  currentUrlParams.delete('orderConceptId')
  currentUrlParams.delete('action')
  setUrlParams(
    groupParamsByKey(currentUrlParams as any) as OrderOverviewQueryParams
  )
  browserWindow.history.replaceState(
    null,
    null,
    location.origin + location.pathname + '?' + currentUrlParams.toString()
  )
}

export const OrderOverview: React.FunctionComponent<OrderOverviewProps> = React.memo(
  ({
    orderOverview,
    rows,
    dispatch,
    columns,
    errorMessage,
    showDetailed,
    showLinkDialog,
    detailedId,
    showUnsavedWarn,
    unsavedDetailed
  }) => {
    const classes = useStyles()
    const initialUrlParams = groupParamsByKey(
      new URLSearchParams(window.location.search) as any
    ) as OrderOverviewQueryParams
    const initialUrlParamsPrev = usePrevious(initialUrlParams)
    const [urlParams, setUrlParams] = useState(initialUrlParams)
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [showReturnDialog, setShowReturnDialog] = useState(false)
    const { setShowSavedDialog, savedMessage } = useSavedMessage({
      handleClose: () => {
        dispatch(clearStateOrdersDetailed())
        clearOrdersDetailedParams(setUrlParams)
      }
    })
    const {
      searchType,
      searchTerm,
      sortBy,
      sortOrder,
      lastId,
      limit,
      status
    } = urlParams
    const overviewParams = {
      searchType,
      searchTerm,
      sortBy,
      sortOrder,
      lastId,
      limit,
      status
    }
    const urlParamsPrevious: any = usePrevious(urlParams)
    const handleCloseLinkDialog = () => {
      dispatch(toggleLinkDialog({ show: null }))
    }

    const handleScrollToEnd = (_args: Waypoint.CallbackArgs) => {
      if (
        !orderOverview.lastId ||
        orderOverview.getError ||
        orderOverview.getting
      ) {
        return
      }
      dispatch(
        getOrderOverview({
          loadMore: true,
          urlParams: overviewParams
        })
      )
    }

    const handleCloseDetailed = () => {
      dispatch(
        toggleOrdersDetailed({
          show: false
        })
      )
      if (!unsavedDetailed) {
        clearOrdersDetailedParams(setUrlParams)
      }
    }

    const handleCloseCreateDialog = () => {
      dispatch(clearStateOrderConcept())
      setShowCreateDialog(false)
      clearConceptDetailedParams(setUrlParams)
    }

    const handleCloseReturnDialog = () => {
      dispatch(clearStateReturnConcept())
      setShowReturnDialog(false)
      clearConceptDetailedParams(setUrlParams)
    }

    const clearErrors = () => {
      dispatch(clearErrorsOrderOverview())
      dispatch(clearErrorsOrdersDetailed())
    }

    const handleCreate = useCallback(() => {
      handleAction('create', setUrlParams)
    }, [])
    
    const handleReturn = useCallback(() => {
      handleAction('return', setUrlParams)
    }, [])

    useEffect(() => {
      const { action, orderId, tab, ...queryParams } = urlParams ?? {}
      const { action: actionPrevious, orderId: orderIdPrev, tabPrev, ...queryParamsPrev } =
        urlParamsPrevious ?? {}
      urlParamsPrevious
      const orderOverviewParamsChanged = !shallowEqual(
        queryParams,
        queryParamsPrev
      )
      const detailedIdChanged = !shallowEqual(
        { orderId },
        { orderId: orderIdPrev }
      )
      switch (action) {
        case 'create':
          setShowCreateDialog(true)
          break;
        case 'return':
          setShowReturnDialog(true)
          break;
        default:
          setShowCreateDialog(false)
          setShowCreateDialog(false)
          break;
      }
      if (detailedIdChanged && orderId) {
        dispatch(getOrderDetailed({ orderId }))
      }
      if (orderId && orderId !== orderIdPrev) {
        dispatch(toggleOrdersDetailed({ show: true, activeTab: tab }))
      }
      if (orderOverviewParamsChanged || !urlParamsPrevious) {
        dispatch(clearState())
        dispatch(
          getOrderOverview({
            loadMore: false,
            urlParams: overviewParams
          })
        )
      }
    }, [urlParams, urlParamsPrevious])

    useEffect(() => {
      if (!fastDeepEqual(initialUrlParams, initialUrlParamsPrev)) {
        setUrlParams(initialUrlParams)
      }
    }, [initialUrlParams, initialUrlParamsPrev])

    const orderOverviewValueMapper = useOrderOverviewValueMapper

    return (
      <div className={classes.root}>
        <OrderOverviewHeader
          color='#ba9a1b'
          setUrlParams={setUrlParams}
          columns={columns.filter(c => c.canHide)}
          params={urlParams}
          onSubmit={({ searchTerm, searchType }) =>
            handleSearch({ searchTerm, searchType }, setUrlParams)
          }
        />
        <TbDashTable
          valueMapper={orderOverviewValueMapper}
          params={urlParams}
          onSortClick={(sort, sortOrder) =>
            handleSortClick(sort, sortOrder, window, setUrlParams)
          }
          onScrollToEnd={handleScrollToEnd}
          loading={orderOverview.getting}
          color='#ba9a1b'
          rows={rows}
          columns={columns.filter(c => !c.hidden)}
          onClickRow={row =>
            handleClickRow(row as OrderFinalRow, dispatch, setUrlParams)
          }
        />
        {showDetailed && (
          <OrderDetailedDialog
            onTabChange={(tab: OrderTabs) =>
              changeDetailedTab(setUrlParams, tab)
            }
            color='#8356ac'
            open={showDetailed}
            dispatch={dispatch}
            handleClose={handleCloseDetailed}
            afterSubmit={() => {
              setShowSavedDialog(true)
              dispatch(
                getOrderOverview({
                  loadMore: false,
                  urlParams: overviewParams
                })
              )
            }}
          />
        )}
        {showCreateDialog && (
          <CreateOrderDialog
            color='#8356ac'
            open={showCreateDialog}
            handleClose={handleCloseCreateDialog}
            afterSubmit={(orderId: number) => {
              handleCloseCreateDialog()
              dispatch(
                getOrderOverview({
                  loadMore: false,
                  urlParams: overviewParams
                })
              )
              openOrder(orderId, dispatch, setUrlParams)
            }}
            orderConceptId={urlParams.orderConceptId} />
        )}
        {showReturnDialog && (
          <CreateReturnDialog
            color='#8356ac'
            open={showReturnDialog}
            handleClose={handleCloseReturnDialog}
            afterSubmit={(orderId: number) => {
              handleCloseReturnDialog()
              dispatch(
                getOrderOverview({
                  loadMore: false,
                  urlParams: overviewParams
                })
              )
              openOrder(orderId, dispatch, setUrlParams)
            }}
            returnConceptId={urlParams.returnConceptId}
          />
        )}
        <LinkDialogTaas
          key={`link-taas-order-${detailedId}`}
          color='#1d6494'
          open={showLinkDialog === 'taas'}
          dispatch={dispatch}
          afterSubmit={() => {
            dispatch(
              getOrderOverview({
                loadMore: false,
                urlParams: overviewParams
              })
            )
            dispatch(
              getOrderDetailed({
                orderId: detailedId
              })
            )
          }}
          handleClose={handleCloseLinkDialog}
        />
        <TbDashWarnDialog
          open={showUnsavedWarn}
          message='Do you really want to close? You have unsavedDetailed changes.'
          submitButton='CLOSE'
          handleSubmit={() => {
            dispatch(toggleUnsavedWarnOrders({ show: false }))
            dispatch(toggleOrdersDetailed({ show: false, force: true }))
            dispatch(clearStateOrdersDetailed())
            clearOrdersDetailedParams(setUrlParams)
          }}
          handleClose={() => {
            dispatch(toggleUnsavedWarnOrders({ show: false }))
          }}
        />
        <TbDashDialog
          open={orderOverview.retrieveResult !== undefined}
          title={`${orderOverview.retrieveResult?.orders + orderOverview.retrieveResult?.returns  ?? 0} orders found`}
          message={`${orderOverview.retrieveResult?.orders + orderOverview.retrieveResult?.returns ?? 0} orders are found. If orders are found
          please check the e-mail inbox of operations@tinybots.nl.`}
          submitButton='CONTINUE'
          handleSubmit={() => {
            dispatch(clearRetrieveResult())
          } } handleClose={() => {}} color={'#ba9a1b'}        />
        {savedMessage}
        <TbDashErrorDialog
          message={errorMessage}
          open={!!errorMessage}
          handleClose={() => {
            clearErrors()
          }}
        />
        <OrderOverviewSpeedDial 
          handleCreate={handleCreate}
          handleReturn={handleReturn}
        />
      </div>
    )
  },
  (prevProps: any, nextProps: any) => {
    const equal = Object.keys(nextProps).reduce((equal, key) => {
      if (key === 'detailed') return true
      if (!shallowEqual(prevProps[key], nextProps[key])) {
        return false
      }
      return equal
    }, true)
    return equal
  }
)

const ConnectedOrderOverview = (
  props: Pick<OrderOverviewProps, '$http' | 'StoreService'>
) => {
  const orderOverview = useSelector<RootState, OrderOverviewState>(
    ({ orderOverview }) => orderOverview
  )
  const rows = useSelector<RootState, OrderFinalRow[]>(
    ({ orderOverview: { ids, entities } }) => ids.map(id => entities[id])
  )
  const columns = useSelector<RootState, OrderOverviewColumn[]>(
    ({
      orderOverview: {
        columns: { ids, entities }
      }
    }) => {
      const columns = ids.map(id => entities[id])
      return columns
    }
  )
  const showDetailed = useSelector<RootState, boolean>(
    ({ ordersDetailed: { showDetailed } }) => showDetailed
  )
  const detailedId = useSelector<RootState, number>(
    ({ ordersDetailed: { order } }) => order?.id
  )
  const showLinkDialog = useSelector<
    RootState,
    'robot' | 'sub' | 'taas' | 'taasRobot' | null
  >(({ detailed: { showLinkDialog } }) => showLinkDialog)
  const showUnsavedWarn = useSelector<RootState, boolean>(
    ({ ordersDetailed: { showUnsavedWarn } }) => showUnsavedWarn
  )
  const errorMessage = useSelector<RootState, null | string>(
    ({ ordersDetailed, orderOverview }) =>
      ordersDetailed.getError ??
      ordersDetailed.patchError ??
      orderOverview.getError ??
      orderOverview.patchError
  )
  const unsavedDetailed = useSelector<RootState, boolean>(({ ordersDetailed }) =>
    hasStateChanges(ordersDetailed)
  )

  const dispatch = useDispatch<typeof props.StoreService.store.dispatch>()
  return (
    <OrderOverview
      {...props}
      unsavedDetailed={unsavedDetailed}
      detailedId={detailedId}
      showLinkDialog={showLinkDialog}
      showDetailed={showDetailed}
      showUnsavedWarn={showUnsavedWarn}
      errorMessage={errorMessage}
      orderOverview={orderOverview}
      rows={rows}
      columns={columns}
      dispatch={dispatch}
    />
  )
}

const OrderOverviewWrapper: React.FunctionComponent<Pick<
  OrderOverviewProps,
  '$http' | 'StoreService'
>> = (props: Pick<OrderOverviewProps, '$http' | 'StoreService'>) => {
  return (
    <ThemeProvider theme={orderOverviewTheme}>
      <Provider store={props.StoreService.store}>
        <ConnectedOrderOverview {...props} />
      </Provider>
    </ThemeProvider>
  )
}

export default OrderOverviewWrapper
