/**
 * @file Dashtransfer dialog to transfer data between entities
 * @author Max van Loosbroek
 */

import { AppDispatch } from '../../../common/redux/store'
import { transferEntities } from '../redux/thunks'
import { useState } from 'react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../common/redux/root.reducer'
import DashtransferForm from './DashtransferForm'
import { clearErrorsDashtransfer } from '../redux/reducer'
import { TbDashDetailedDialog } from 'tinybots-react-components'
import { IdParams } from '../../overview/Overview/Overview'
export interface DashtransferDialogProps {
  loading?: boolean
  open: boolean
  color: string
  dispatch: AppDispatch
  handleClose: () => void
  afterSubmit: (params: IdParams) => void
}

export const DashtransferDialogComponent = ({
  open,
  dispatch,
  handleClose,
  color,
  loading,
  afterSubmit
}: DashtransferDialogProps) => {
  const [showErrors, setShowErrors] = useState(false)
  const handleSubmit = async () => {
    setShowErrors(true)
    const res: {
      payload: {
        serialId?: number
        chainId?: number
        taasId?: number
      }[]
    } = await dispatch(transferEntities()) as any
    if (!res.payload) {
      return
    }
    const params = res.payload.reduce((accum, item) => {
      const {serialId, chainId, taasId} = item
      if(serialId) {
        accum.serialIds.push(serialId)
      }
      if(chainId) {
        accum.chainIds.push(chainId)
      }
      if(taasId) {
        accum.taasIds.push(taasId)
      }
      return accum
    }, {
      serialIds: [],
      chainIds: [],
      taasIds: []
    })
    afterSubmit(params)
  }
  const tabs = [
    {
      title: 'Dashtransfer',
      content: <DashtransferForm showErrors={showErrors} />
    }
  ]

  return (
    <TbDashDetailedDialog
      loading={loading}
      open={open}
      color={color}
      tabs={tabs}
      handleClose={() => {
        handleClose()
        dispatch(clearErrorsDashtransfer())
      }}
      handleSubmit={handleSubmit}
      activeTab={0}
    />
  )
}

const ConnectedDashtransferDialog = (props: DashtransferDialogProps) => {
  const loading = useSelector<RootState, boolean>(
    ({ dashtransfer }) => dashtransfer.getting || dashtransfer.patching
  )

  const dispatch: AppDispatch = useDispatch()

  return (
    <DashtransferDialogComponent
      {...props}
      loading={loading}
      dispatch={dispatch}
    />
  )
}

export default React.memo(ConnectedDashtransferDialog)
