/**
 * @file Functions for making http requests for order detailed
 * @author Max van Loosbroek
 */

import { IHttpService } from 'angular'
import { URLS } from '../../../../common/utilities/constants/constants.module'
import { parseHttpError } from '../../../../common/utilities/errorHandling'
import { TaasDetailed } from '../../../overview/types/types'
import { OrderFinal, OrderFinalV5 } from '../../types/OrderFinal'

interface DeclineDto {
  declineReason?: string
}

interface RejectReturnDto {
  returnRejectReason?: string
}

export const getOrder = ($http: IHttpService, orderId: number | string) => {
  const url = `${URLS.admin.taasOrdersV5}/` + orderId
  return $http.get<OrderFinalV5>(url).catch(parseHttpError)
}

export const sendStatus = (
  $http: IHttpService,
  orderId: number,
  status:
    | 'accept'
    | 'decline'
    | 'delivered'
    | 'accept-return'
    | 'reject-return',
  body?: DeclineDto | RejectReturnDto
) => {
  const url = `${URLS.admin.taasOrders}/${orderId}/status/${status}`
  if (body) {
    return $http.post<OrderFinal>(url, body).catch(parseHttpError)
  } else {
    return $http.post<OrderFinal>(url, {}).catch(parseHttpError)
  }
}

export const patchTaasOrder = (
  $http: IHttpService,
  orderId: number,
  patch: { trackTraceCode?: string, internalNotes?: string }
) => {
  const url = `${URLS.admin.taasOrdersV4}/${orderId}`
  return $http
    .patch<OrderFinal>(url, patch)
    .catch(parseHttpError)
}

export const endTaas = (
  $http: IHttpService,
  taasId: number,
  returnedAt: string
) => {
  const taasUrl = `${URLS.admin.taas}/${taasId}`
  return $http
    .patch<TaasDetailed>(taasUrl, { endAt: returnedAt })
    .catch(parseHttpError)
}

export const shipTaas = (
  $http: IHttpService,
  taasId: number,
  dates: { shippedAt: string; startAt: string }
) => {
  const taasUrl = `${URLS.admin.taas}/${taasId}`
  return $http.patch<TaasDetailed>(taasUrl, dates).catch(parseHttpError)
}
