import moment from 'moment'
import { SubscriptionDetailed, TaasDetailed, RobotDetailed } from '../../types/types'

export const subsValidation = (
  updateSubscriptions: SubscriptionDetailed[]
): { valid: boolean; message?: string } => {
  if (!(updateSubscriptions?.length > 0)) return { valid: true }
  return updateSubscriptions.reduce(
    (valid, s) => {
      const overlap = updateSubscriptions
        .filter(sub => sub.subscriptionId !== s.subscriptionId)
        .find(otherSub => {
          const startOverlap =
            moment(s.startAt).isBefore(moment(otherSub.endAt)) &&
            moment(otherSub.startAt).isAfter(moment(otherSub.startAt))
          const endOverlap =
            moment(s.startAt).isBefore(moment(otherSub.endAt)) &&
            moment(s.startAt).isAfter(moment(otherSub.startAt))
          const sameStartOrEnd =
            s.startAt === otherSub.startAt || s.endAt === otherSub.endAt
          return startOverlap || endOverlap || sameStartOrEnd
        })
      if (overlap) {
        return { valid: false, message: 'Subscriptions are overlapping' }
      }
      if (s.endAt !== null && !moment(s.endAt).isValid()) {
        return { valid: false, message: 'Subscription endAt invalid' }
      }
      if (s.startAt !== null && !moment(s.startAt).isValid()) {
        return { valid: false, message: 'Subscription startAt invalid' }
      }
      if (s.shippedAt !== null && !moment(s.shippedAt).isValid()) {
        return { valid: false, message: 'Subscription shippedAt invalid' }
      }
      if (moment(s.endAt).isBefore(moment(s.startAt))) {
        return { valid: false, message: 'Subscription length invalid' }
      }
      if (s.subscriptionLength < 1) {
        return { valid: false, message: 'Subscription length invalid' }
      }
      return valid
    },
    { valid: true }
  )
}

export const taasValidation = (
  taas: TaasDetailed
): { valid: boolean; message?: string } => {
  if (!taas) return { valid: true }
  if (taas.clientId?.length > 256) {
    return { valid: false, message: 'Client id should be 256 characters or less' }
  }
  if (taas.teamId?.length > 256) {
    return { valid: false, message: 'Client id should be 256 characters or less' }
  }
  if (taas.endAt !== null && !moment(taas.endAt).isValid()) {
    return { valid: false, message: 'TaaS Subscription endAt invalid' }
  }
  if (taas.startAt !== null && !moment(taas.startAt).isValid()) {
    return { valid: false, message: 'TaaS Subscription startAt invalid' }
  }
  if (taas.shippedAt !== null && !moment(taas.shippedAt).isValid()) {
    return { valid: false, message: 'TaaS Subscription shippedAt invalid' }
  }
  if (
    taas.endAt !== null &&
    (!moment(taas.startAt).isValid() || !moment(taas.shippedAt).isValid())
  ) {
    return {
      valid: false,
      message: 'TaaS Subscription shippedAt or startAt invalid'
    }
  }
  if (moment(taas.endAt).isSameOrBefore(moment(taas.startAt))) {
    return { valid: false, message: 'TaaS Subscription length invalid' }
  }
  return { valid: true }
}

export const robotValidation = (
  robot: RobotDetailed
): { valid: boolean; message?: string } => {
  if (!robot) return { valid: true }
  return { valid: true }
}
