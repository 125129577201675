/**
 * @file Transfer inputs for subs
 * @author Max van Loosbroek
 */

import { ArrowRightAlt } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TbDashDetailedItemRow, TbDashDetailedItem } from 'tinybots-react-components'
import { RootState } from '../../../common/redux/root.reducer'
import { AppDispatch } from '../../../common/redux/store'
import { getSubRefLength } from '../../overview/stringTransformers'
import { RobotBase, SubscriptionBase } from '../../overview/types/types'
import {
  dashtransferSelector,
  subscriptionsSearchAdapter,
  setNewSub,
  setSubRef
} from '../redux/reducer'
import { searchSubscriptionsDashtransfer } from '../redux/thunks'
import { DashtransferState } from '../types'
import { RelationBase } from '../../overview/types/RelationBase'

export interface SubscriptionItemProps {
  subRef?: string
  newRobot?: RobotBase
  currentSub?: SubscriptionBase | undefined
  newSub?: SubscriptionBase | undefined
  currentRelation?: RelationBase | undefined
  newRelation?: RelationBase | undefined
  subscriptionsSearch: SubscriptionBase[]
  dispatch: AppDispatch
  showErrors: boolean
}

export const SubscriptionItemComponent = React.memo(
  ({
    newRelation,
    currentSub,
    newSub,
    subscriptionsSearch,
    dispatch,
    subRef
  }: SubscriptionItemProps) => {
    const setNewSubRef = (ref: string) => dispatch(setSubRef(ref))
    const subOptions = subscriptionsSearch.map(s => ({
      key: getSubRefLength(s),
      disabled: false
    }))
    useEffect(() => {
      newRelation &&
        dispatch(
          searchSubscriptionsDashtransfer({
            relationId: newRelation.relationId.toString()
          })
        )
    }, [newRelation])
    useEffect(() => {
      if(!subRef || subRef === getSubRefLength(newSub)) return
      const sub = subscriptionsSearch.find(
        s => getSubRefLength(s) === subRef
      )
      dispatch(setNewSub(sub))
    }, [subRef, subscriptionsSearch])

    return (
      <TbDashDetailedItemRow>
        <TbDashDetailedItem
          hasError={v => null}
          type='text'
          disabled
          label='Current Subscription'
          iconUrl='assets/subref.svg'
          value={getSubRefLength(currentSub)}
        />
        <TbDashDetailedItem
          hasError={v => null}
          type='autoComplete'
          label='New Subscription'
          iconUrl=''
          onChange={v => setNewSubRef(v as string)}
          options={subOptions}
          value={subRef ? subRef : (newSub ? getSubRefLength(newSub) : '')}
        />
        <ArrowRightAlt
          fontSize='inherit'
          style={{
            fontSize: 28,
            transform: 'scaleX(-1)',
            position: 'absolute',
            left: '50%',
            marginLeft: 8,
            bottom: 13
          }}
        />
      </TbDashDetailedItemRow>
    )
  }
)

const ConnectedSubscriptionItem = (
  props: Pick<SubscriptionItemProps, 'showErrors'>
) => {
  const {
    currentSub,
    newSub,
    newRobot,
    currentRelation,
    newRelation,
    subRef
  } = useSelector<RootState, DashtransferState>(dashtransferSelector)
  const subscriptionsSearch = useSelector((state: RootState) =>
    subscriptionsSearchAdapter
      .getSelectors()
      .selectAll(state.dashtransfer.subscriptionsSearch)
  )
  const dispatch: AppDispatch = useDispatch()

  return (
    <SubscriptionItemComponent
      {...props}
      newRobot={newRobot}
      subRef={subRef}
      currentRelation={currentRelation}
      newRelation={newRelation}
      currentSub={currentSub}
      newSub={newSub}
      subscriptionsSearch={subscriptionsSearch}
      dispatch={dispatch}
    />
  )
}

export default ConnectedSubscriptionItem
