import template from './tb-pop-up-item.html'
import './tb-pop-up-item.scss'

const tbPopupItem = {
  bindings: {
    type: '@',
    label: '@',
    switchTrueLabel: '@',
    switchFalseLabel: '@',
    itemPath: '@',
    placeholder: '@',
    icon: '@',
    dropdownType: '@',
    size: '@',
    boxType: '@',
    dropdownRelation: '<',
    disabled: '<',
    multiLabel: '<',
    selectLabelId: '<',
    value: '<',
    items: '<',
    warning: '<',
    info: '<',
    onSelect: '&',
    onClearWarning: '&',
    onSearch: '&',
    onSetValue: '&',
    onSwitchIndex: '&'
  },
  template,
  controller: /* @ngInject */ class tbPopUpItemController {
    filterCondition (items, param, searchTerm) {
      const item = items[param]
      return item && item.toString().toLowerCase().includes(searchTerm.toLowerCase())
    }

    filterItems () {
      const searchTerm = this.value
      if (!(this.items.length > 0 && this.previousSearchTerm &&
        searchTerm.slice(0, this.previousSearchTerm.length) === this.previousSearchTerm) &&
        searchTerm.length > 2) {
        this.onSearch({ $event: { searchTerm } })
        this.previousSearchTerm = searchTerm
      } else if (searchTerm.length < 3) {
        // this.items = []
        this.filteredItems = []
      } else {
        this.filteredItems = this.items.filter(item => this.filterCondition(item, this.itemPath, searchTerm))
      }
    }

    getItemValue (item) {
      return item[this.path]
    }

    getClasses () {
      let classes = ''
      if (['robot', 'allRobots'].includes(this.dropdownType)) {
        classes += 'tb-pop-up-item_item_robot '
      }
      if (this.disabled) {
        classes += 'tb-pop-up-item_item_disabled '
      }
      if (this.size === 'small') {
        classes += 'tb-pop-up-item_item_small '
      }
      if (this.boxType === 'dashTransfer') {
        classes += 'tb-pop-up-item_item_fullbox '
      }
      return classes
    }

    filterSelect () {
      const searchTerm = this.value
      this.filteredItems = this.items.filter(item => this.filterCondition(item, this.itemPath, searchTerm))
    }

    clearWarning () {
      const name = this.label
      this.onClearWarning({ $event: { name } })
    }

    selectItem ({ item }) {
      item.label = this.label
      if (item) {
        const searchTerm = item[this.itemPath]
        this.clearWarning()
        this.filteredItems = this.items.filter(item => this.filterCondition(item, this.itemPath, searchTerm))
        this.value = searchTerm
        this.onSelect({ $event: { item } })
      }
    }

    onSelectInputChange (item) {
      this.onSelect({ $event: { item } })
      this.clearWarning();
      this.setValue();
    }

    clearItem () {
      const item = null
      this.onSelect({ $event: { item } })
    }

    setValue () {
      const value = this.value
      this.onSetValue({ $event: { value } })
    }

    switchLabel (index) {
      this.onSwitchIndex({ $event: { index } })
    }

    onSwitchChange() {
      this.setValue()
      this.$onChanges({ currentValue: this.value, previousValue: !this.value })
    }

    $onChanges (changes) {
      if (changes.items) {
        this.filteredItems = angular.copy(this.items)
      }
      if (changes.value) {
        this.value = angular.copy(this.value)
      }
      if (changes.selectLabelId) {
        this.selectLabelId = angular.copy(this.selectLabelId)
      }
    }

    $onInit () {
      if(!this.items) {
        this.items = []
      }
      this.previousSearchTerm = null
    }
  }
}

export default tbPopupItem
