import { useCallback, useEffect, useRef, useState } from 'react'
import { validateSetMfaForm } from './validateSetMfaForm'
import Auth from '../../auth.service'

type MfaSettingsStatus = 'idle' | 'patching' | 'otp-error' | 'pw-error' | 'error' | 'success'

export const useSetMfaForm = ($state: any, AuthService: Auth) => {
    const [submitted, setSubmitted] = useState(false)
    const [otpCode, setOtpCode] = useState(undefined)
    const [password, setPassword] = useState('')
    const [formStatus, setStatus] = useState<MfaSettingsStatus>('idle')
    const previousStatus = useRef<MfaSettingsStatus>('idle')
    const email = $state.params?.email
    const formValidation = validateSetMfaForm({ password, otpCode })

    useEffect(() => {
        if (formStatus !== previousStatus.current) {
            previousStatus.current = formStatus
            if (formStatus === 'success') {
                $state.go('auth.login')
            }
        }
    }, [formStatus, previousStatus])

    const enableMfa = useCallback(async (): Promise<any> => {
        setSubmitted(true)
        const { valid } = formValidation
        if (!valid) return
        try {
            setStatus('patching')
            await AuthService.setMfa({ email, password, otpCode, enableMfa: true })
            setStatus('success')
        } catch (error) {
            if (error.data && error.data.message === 'mfa incorrect') {
                setStatus('otp-error')
            } else if (error.data && error.data.message === 'Invalid username or password') {
                setStatus('pw-error')
            } else {
                setStatus('error')
            }
        }
    }, [email, password, otpCode, AuthService])

    return { setOtpCode, setPassword, setStatus, enableMfa, formValidation, formStatus, email, submitted }
}
